import React from 'react';
import './index.css';

function SignupScreen() {
  return (
    <div className="SignupScreen">
      <div>
        <h2 className="SignupScreen-h2">
          email&nbsp;
          <a href="mailto:hi@eggybytes.com?subject=handle?&body=hi!inserthandlehere" target="_blank" rel="noopener noreferrer" className="SignupScreen-a">hi@eggybytes.com</a><br />
          if you'd like an account when this is generally available
        </h2>
      </div>
    </div>
  );
}

export default SignupScreen;
