import { person } from '../types';
import v from './v';
import v01p from './v01p';
import m from './m';
import vplan from './vplan';

type dataType = {
  [identifier: string]: person
}

const data: dataType = {
  'vplan': vplan,
  'v': v,
  'vivian': v,
  'vliang': v,
  'vliang88': v,
  'v01p': v01p,
  'm': m,
  'me': m,
  'melinda': m,
}

export default data;
