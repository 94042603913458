import { itemType, person } from '../types';
import { addCounts } from '../utils';

const m: person = {
  handle: 'm',
  eventLists: [{
    year: 2021,
    goals: {
      books: 100,
    },
    events: [
      {
        date: new Date('2020-01-02'),
        item: {
          type: itemType.POST,
          title: 'welcome to counting',
          text: ``
        },
      },
      {
        date: new Date('2021-01-01'),
        item: {
          type: itemType.FILM,
          title: 'Code Unknown',
          year: 2000,
          director: 'Michael Haneke',
          frames: [
            'code-unknown-03',
          ],
        },
      },
    ],
  }, {
    year: 2020,
    goals: {
      books: 100,
    },
    events: [
      {
        date: new Date('2020-12-25'),
        item: {
          type: itemType.FILM,
          title: 'Kajillionaire',
          year: 2020,
          director: 'Miranda July',
          frames: [
            'kajillionaire-01',
            'kajillionaire-02',
          ],
        },
      },
      {
        date: new Date('2020-12-24'),
        item: {
          type: itemType.BOOK,
          title: 'Three by Annie Dillard: The Writing Life, An American Childhood, Pilgrim at Tinker Creek',
          year: 1990,
          author: 'Annie Dillard',
        },
      },
      {
        date: new Date('2020-12-23'),
        item: {
          type: itemType.FILM,
          title: 'Education',
          year: 2020,
          director: 'Steve McQueen',
        },
      },
      {
        date: new Date('2020-12-19'),
        item: {
          type: itemType.FILM,
          title: 'Ma Rainey\'s Black Bottom',
          year: 2020,
          director: 'George Wolfe',
        },
      },
      {
        date: new Date('2020-12-14'),
        item: {
          type: itemType.FILM,
          title: 'Alex Wheatle',
          year: 2020,
          director: 'Steve McQueen',
          frames: [
            'alex-wheatle-03',
          ],
        },
      },
      {
        date: new Date('2020-12-12'),
        item: {
          type: itemType.FILM,
          title: 'Red, White and Blue',
          year: 2020,
          director: 'Steve McQueen',
        },
      },
      {
        date: new Date('2020-12-06'),
        item: {
          type: itemType.FILM,
          title: 'Sorry We Missed You',
          year: 2020,
          director: 'Ken Loach',
          frames: [
            'sorry-we-missed-you-01',
            'sorry-we-missed-you-02',
          ],
        },
      },
      {
        date: new Date('2020-11-29'),
        item: {
          type: itemType.FILM,
          title: 'Lovers Rock',
          year: 2020,
          director: 'Steve McQueen',
        },
      },
      {
        date: new Date('2020-11-22'),
        item: {
          type: itemType.FILM,
          title: 'Mangrove',
          year: 2020,
          director: 'Steve McQueen',
        },
      },
      {
        date: new Date('2020-11-16'),
        item: {
          type: itemType.BOOK,
          title: 'Cut to Bloom',
          year: 2020,
          author: 'Arhm Choi Wild',
        },
      },
      {
        date: new Date('2020-11-02'),
        item: {
          type: itemType.BOOK,
          title: 'The Romance of American Communism',
          year: 2020,
          author: 'Vivian Gornick',
        },
      },
      {
        date: new Date('2020-10-31'),
        item: {
          type: itemType.BOOK,
          title: 'A Place of My Own',
          year: 2011,
          author: 'Michael Pollan',
        },
      },
      {
        date: new Date('2020-10-21'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `there is a faith in love fiercely one  
especially if you have waited years  
and especially if part of you never believed  
you could deserve this loved hand  
held out to you this way

which are the testaments of loneliness  
and what do we feel we are worthy of in this world?

this feeling of looking down through green jungle  
from the edge of a world we had chosen together

the vanishing point of the sun extinguishing time forever,  
to the instant before we had met.
`
        },
      },
      {
        date: new Date('2020-10-21'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `there is only one life you can call your own  
the world was made to be free in  
the mountains framing, only for now, our horizon  
in this high place leave everything you know behind.
`
        },
      },
      {
        date: new Date('2020-10-21'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `in that first moment in which you wake  
there is a slanting light, a small opening into the day  
which closes the moment you begin your plans

what you can plan is too small for you to live
`
        },
      },
      {
        date: new Date('2020-10-21'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `to feel abandoned is to deny the intimacy of your surroundings.

sometimes it has to be written across the heavens  
sometimes it takes a sky  
to find that first slice of freedom in your own heart

sometimes with the bones the black strands left over when the fire has gone out  
someone has written something new in the ashes of your life.

you are not leaving, even as the light fades quickly. you are arriving
`
        },
      },
      {
        date: new Date('2020-10-21'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `That winter we spent together  
everything we said seemed necessary and important.

(the familiar loss)

I live in the shadow of those memories  
as I sometimes live in the shadow of  
those extraordinary gifts. sometimes the days  
are generous and miraculous in what they can bestow
`
        },
      },
      {
        date: new Date('2020-10-21'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `and one day you wake up and you are not just imagining an empty chair where your loved one sat.

everything is waiting for you.
`
        },
      },
      {
        date: new Date('2020-10-20'),
        item: {
          type: itemType.FILM,
          title: 'The Trial of the Chicago 7',
          year: 2020,
          director: 'Aaron Sorkin',
        },
      },
      {
        date: new Date('2020-10-18'),
        item: {
          type: itemType.BOOK,
          title: 'The New Oxford Annotated Bible with Apocrypha: New Revised Standard Version',
          year: 2011,
          author: 'Moses',
        },
      },
      {
        date: new Date('2020-10-12'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `one small thing I've learned these years,  
how to be alone,  
and at the edge of aloneness  
how to be found by the world
`
        },
      },
      {
        date: new Date('2020-10-12'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `Possibly heaven is just  
a door opening to a deeper world  
letting the body fall at last  
toward the griefs it can call its own,  
and the kind of rest in the love that asks nothing  
where you lie down and no longer are found at all.

So strange the way we are larger in grief than we imagined we deserved  
and when loss floods into us like the long darkness it is  
and the old nurtured hope is drowned again —

What will you do and what will you say  
in the times when you are left alone to meet, like this, the quiet fury of the world?
`
        },
      },
      {
        date: new Date('2020-10-12'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `how do I open eyes long closed  
see the far-off things  
for the silence they hold.  
the heart after years of secret conversing  
speaking out loud in the clear air

whoever asks of darkness  
must touch the darkness in themself
`
        },
      },
      {
        date: new Date('2020-10-12'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `You, ready to stay or go  
into what you loved,  
your arrival in my life  
so sudden and then  
so quickly, your final disappearance.

some place (perhaps in the brimming dark)  
where the story ends

how can I earn forgiveness?
`
        },
      },
      {
        date: new Date('2020-10-12'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `This house,  
the bright home in which I live  
this is where I want to love all the things  
it has taken me so long to learn to love.  
This is the house of my adult aloneness  
and I belong to that aloneness  
as I belong to my life.

Where I wish to live forever  
in the eternity of my own  
fleeting and momentary  
happiness  
in the arms of a fierce but healing world
`
        },
      },
      {
        date: new Date('2020-10-12'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `Here — even the mountains only appear to hold fast under the light of the stars;  
they are slowly, secretly, devoured by time.  
Nothing is forever.  
Immortality has fled the world  
to find an uncertain abode  
in the darkness of the human heart  
that still has the capacity to remember and to say,  
forever.
`
        },
      },
      {
        date: new Date('2020-10-09'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `the torment of the soul. the wounds on it so grotesque we fear we can't show them,  
crave someone who will see them  
and hold us regardless.

shame necessitates salvation; the craving is what we desire; that sharpness and false clarity  
but it's a negative one.  
not peace or freedom.

the shame we fear we cannot live without  
the shame we fear we cannot live within
`
        },
      },
      {
        date: new Date('2020-10-09'),
        item: {
          type: itemType.BOOK,
          title: 'The Human Condition',
          year: 1958,
          author: 'Hannah Arendt',
        },
      },
      {
        date: new Date('2020-10-09'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `we need to make the world so alive,  
so captivatingly free,  
that it becomes oppressive and impossible to breathe the dust of this one
`
        },
      },
      {
        date: new Date('2020-10-09'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `
sometime one night I'll fall down  
on a bed far from my heart's desire  
in a city as it is  

in the dark windowglass a blurred face — is it mine?  
humans, lovers, they keep on using each other to hide their own fate.
`
        },
      },
      {
        date: new Date('2020-10-09'),
        doNotRenderDate: true,
        item: {
          type: itemType.POST,
          title: '.',
          text: `
always I have my escape route planned when others have none  
and disappear  
head & heart perforated.

for beauty is nothing but the beginning of terror,  
which we are only just able to endure`
        },
      },
      {
        date: new Date('2020-10-09'),
        item: {
          type: itemType.BOOK,
          title: 'ESV Study Bible',
          year: 2011,
          author: 'Crossway',
        },
      },
      {
        date: new Date('2020-10-09'),
        item: {
          type: itemType.BOOK,
          title: 'The Study Quran: A New Translation and Commentary',
          year: 2011,
          author: 'Seyyed Hossein Nasr',
        },
      },
      {
        date: new Date('2020-10-08'),
        item: {
          type: itemType.BOOK,
          title: 'More Than Two: A Practical Guide to Ethical Polyamory',
          year: 2014,
          author: 'Franklin Veaux and Eve Rickert',
        },
      },
      {
        date: new Date('2020-10-06'),
        item: {
          type: itemType.BOOK,
          title: 'The Ethical Slut: A Practical Guide to Polyamory, Open Relationships, and Other Freedoms in Sex and Love',
          year: 2017,
          author: 'Dossie Easton and Janet Hardy',
        },
      },
      {
        date: new Date('2020-10-05'),
        item: {
          type: itemType.BOOK,
          title: 'Upheavals of Thought: The Intelligence of Emotions',
          year: 2001,
          author: 'Martha Nussbaum',
        },
      },
      {
        date: new Date('2020-10-04'),
        item: {
          type: itemType.BOOK,
          title: 'I Myself Am a Woman: Selected Writings of Ding Ling',
          year: 1989,
          author: 'Ding Ling',
        },
      },
      {
        date: new Date('2020-10-02'),
        item: {
          type: itemType.BOOK,
          title: 'The Battle for Justice in Palestine',
          year: 2014,
          author: 'Ali Abunimah',
        },
      },
      {
        date: new Date('2020-09-29'),
        item: {
          type: itemType.BOOK,
          title: 'Between Past and Future',
          year: 1961,
          author: 'Hannah Arendt',
        },
      },
      {
        date: new Date('2020-09-28'),
        item: {
          type: itemType.BOOK,
          title: 'Mirrors: Stories of Almost Everyone',
          year: 2009,
          author: 'Eduardo Galeano',
        },
      },
      {
        date: new Date('2020-09-25'),
        item: {
          type: itemType.BOOK,
          title: 'Beyond Survival: Strategies and Stories from the Transformative Justice Movement',
          year: 2020,
          author: 'Ejeris Dixon and Leah Lakshmi Piepzna-Samarasinha, eds.',
        },
      },
      {
        date: new Date('2020-09-24'),
        item: {
          type: itemType.BOOK,
          title: 'Azadi',
          year: 2020,
          author: 'Arundhati Roy',
        },
      },
      {
        date: new Date('2020-09-20'),
        item: {
          type: itemType.BOOK,
          title: 'The Book of J',
          year: 1990,
          author: 'Harold Bloom',
        },
      },
      {
        date: new Date('2020-09-17'),
        item: {
          type: itemType.BOOK,
          title: 'Almost Everything: Notes on Hope',
          year: 2018,
          author: 'Anne Lamott',
        },
      },
      {
        date: new Date('2020-09-17'),
        item: {
          type: itemType.BOOK,
          title: 'Golden Gulag: Prisons, Surplus, Crisis, and Opposition in Globalizing California',
          year: 2007,
          author: 'Ruth Wilson Gilmore',
        },
      },
      {
        date: new Date('2020-09-08'),
        item: {
          type: itemType.BOOK,
          title: 'The Book of Promethea',
          year: 1991,
          author: 'Hélène Cixous',
        },
      },
      {
        date: new Date('2020-09-06'),
        item: {
          type: itemType.BOOK,
          title: 'Good Clean Fun: Misadventures in Sawdust at Offerman Woodshop',
          year: 2016,
          author: 'Nick Offerman',
        },
      },
      {
        date: new Date('2020-09-05'),
        item: {
          type: itemType.BOOK,
          title: 'Debt: The First 5000 Years',
          year: 2011,
          author: 'David Graeber',
        },
      },
      {
        date: new Date('2020-08-23'),
        item: {
          type: itemType.BOOK,
          title: 'Principia Ethica',
          year: 1903,
          author: 'G.E. Moore',
        },
      },
      {
        date: new Date('2020-08-21'),
        item: {
          type: itemType.BOOK,
          title: 'The Origins of Totalitarianism',
          year: 1951,
          author: 'Hannah Arendt',
        },
      },
      {
        date: new Date('2020-07-31'),
        item: {
          type: itemType.BOOK,
          title: 'On Revolution',
          year: 1963,
          author: 'Hannah Arendt',
        },
      },
      {
        date: new Date('2020-07-26'),
        item: {
          type: itemType.BOOK,
          title: 'The Self-Coached Climber: The Guide to Movement, Training, Performance',
          year: 2001,
          author: 'Dan Hague',
        },
      },
      {
        date: new Date('2020-06-26'),
        item: {
          type: itemType.FILM,
          title: 'LA 92',
          year: 2017,
          director: 'Daniel Lindsay and T.J. Martin',
        },
      },
      {
        date: new Date('2020-06-15'),
        item: {
          type: itemType.BOOK,
          title: 'Color of Violence: The INCITE! Anthology',
          year: 2016,
          author: 'INCITE! Women of Color Against Violence',
        },
      },
      {
        date: new Date('2020-06-14'),
        item: {
          type: itemType.BOOK,
          title: 'Healing Resistance: A Radically Different Response to Harm',
          year: 2020,
          author: 'Kazu Haga',
        },
      },
      {
        date: new Date('2020-06-10'),
        item: {
          type: itemType.BOOK,
          title: 'Our Word is Our Weapon: Selected Writings',
          year: 2002,
          author: 'Subcomandante Marcos',
        },
      },
      {
        date: new Date('2020-06-03'),
        item: {
          type: itemType.BOOK,
          title: 'To Die for the People',
          year: 2009,
          author: 'Huey P. Newton',
        },
      },
      {
        date: new Date('2020-05-31'),
        item: {
          type: itemType.BOOK,
          title: 'This Bridge Called My Back, Fourth Edition: Writings by Radical Women of Color',
          year: 2015,
          author: 'Cherríe Moraga and Gloria Anzaldúa, eds.',
        },
      },
      {
        date: new Date('2020-05-26'),
        item: {
          type: itemType.BOOK,
          title: 'Honda MSX125 (Grom) \'13 to \'18: Haynes Service & Repair Manual',
          year: 2020,
          author: 'Editors of Haynes Manuals',
        },
      },
      {
        date: new Date('2020-05-25'),
        item: {
          type: itemType.BOOK,
          title: 'A Thousand Plateaus: Capitalism and Schizophrenia',
          year: 1987,
          author: 'Gilles Deleuze and Felix Guattari',
        },
      },
      {
        date: new Date('2020-05-25'),
        item: {
          type: itemType.BOOK,
          title: 'The Collected Poetry of Nikki Giovanni: 1968-1998',
          year: 2007,
          author: 'Nikki Giovanni',
        },
      },
      {
        date: new Date('2020-05-15'),
        item: {
          type: itemType.BOOK,
          title: 'Blindness',
          year: 1999,
          author: 'Jose Saramago',
        },
      },
      {
        date: new Date('2020-05-05'),
        item: {
          type: itemType.BOOK,
          title: 'The Shock Doctrine',
          year: 2008,
          author: 'Naomi Klein',
        },
      },
      {
        date: new Date('2020-04-29'),
        item: {
          type: itemType.BOOK,
          title: '3 Asian American Writers Speak Out on Feminism',
          year: 2003,
          author: 'Mitsuye Yamada, Merle Woo, and Nellie Wong',
        },
      },
      {
        date: new Date('2020-04-28'),
        item: {
          type: itemType.BOOK,
          title: 'Love Works',
          year: 2003,
          author: 'Janice Mirikitani',
        },
      },
      {
        date: new Date('2020-04-14'),
        item: {
          type: itemType.FILM,
          title: 'Never Rarely Sometimes Always',
          year: 2020,
          director: 'Eliza Hittman',
        },
      },
      {
        date: new Date('2020-04-05'),
        item: {
          type: itemType.BOOK,
          title: 'Revolutionary Suicide',
          year: 2009,
          author: 'Huey P. Newton',
        },
      },
      {
        date: new Date('2020-04-02'),
        item: {
          type: itemType.FILM,
          title: 'Okja',
          year: 2017,
          director: 'Bong Joon-ho',
        },
      },
      {
        date: new Date('2020-03-19'),
        item: {
          type: itemType.FILM,
          title: 'Honey Boy',
          year: 2019,
          director: 'Alma Har\'el',
          frames: [
            'honey-boy-01',
          ],
        },
      },
      {
        date: new Date('2020-03-02'),
        item: {
          type: itemType.BOOK,
          title: 'Voice & Vision: A Creative Approach to Narrative Filmmaking, Third Edition',
          year: 2018,
          author: 'Mick Hurbis-Cherrier',
        },
      },
      {
        date: new Date('2020-03-02'),
        item: {
          type: itemType.BOOK,
          title: 'Bare Bones Camera Course for Film and Video',
          year: 2015,
          author: 'Tom Schroeppel',
        },
      },
      {
        date: new Date('2020-03-01'),
        item: {
          type: itemType.BOOK,
          title: 'Bloodlands: Europe Between Hitler and Stalin',
          year: 2010,
          author: 'Timothy Snyder',
        },
      },
      {
        date: new Date('2020-02-25'),
        item: {
          type: itemType.FILM,
          title: 'Fences',
          year: 2017,
          director: 'Denzel Washington',
        },
      },
      {
        date: new Date('2020-02-15'),
        item: {
          type: itemType.BOOK,
          title: 'i shimmer sometimes, too',
          year: 2019,
          author: 'Porsha Olayiwola',
        },
      },
      {
        date: new Date('2020-02-03'),
        item: {
          type: itemType.BOOK,
          title: 'Uncanny Valley: A Memoir',
          year: 2020,
          author: 'Anna Wiener',
        },
      },
      {
        date: new Date('2020-01-11'),
        item: {
          type: itemType.BOOK,
          title: 'Another Country',
          year: 1992,
          author: 'James Baldwin',
        },
      },
      {
        date: new Date('2020-01-10'),
        item: {
          type: itemType.BOOK,
          title: 'Bluets',
          year: 2009,
          author: 'Maggie Nelson',
        },
      },
      {
        date: new Date('2020-01-03'),
        item: {
          type: itemType.BOOK,
          title: 'Cultivating the Mind of Love',
          year: 2004,
          author: 'Thich Nhat Hanh',
        },
      },
      {
        date: new Date('2020-01-02'),
        item: {
          type: itemType.BOOK,
          title: 'The Complete Poems of Anna Akhmatova',
          year: 2000,
          author: 'Anna Akhmatova',
        },
      },
      {
        date: new Date('2020-01-01'),
        item: {
          type: itemType.BOOK,
          title: 'Selected Poems',
          year: 2011,
          author: 'Mary Ruefle',
        },
      },
      {
        date: new Date('2020-01-01'),
        item: {
          type: itemType.BOOK,
          title: 'My Private Property',
          year: 2017,
          author: 'Mary Ruefle',
        },
      },
    ],
  }, {
    year: 2019,
    events: [
      {
        date: new Date('2019-12-30'),
        item: {
          type: itemType.BOOK,
          title: 'The Revolution Will Not Be Funded: Beyond the Non-Profit Industrial Complex',
          year: 2017,
          author: 'INCITE! Women of Color Against Violence',
        },
      },
      {
        date: new Date('2019-12-05'),
        item: {
          type: itemType.BOOK,
          title: 'Zero Trust Networks',
          year: 2017,
          author: 'Evan Gilman and Doug Barth',
        },
      },
      {
        date: new Date('2019-11-08'),
        item: {
          type: itemType.FILM,
          title: 'Parasite',
          year: 2019,
          director: 'Bong Joon-ho',
          frames: [
            'parasite-01',
            'parasite-02',
          ],
        },
      },
      {
        date: new Date('2019-06-18'),
        item: {
          type: itemType.FILM,
          title: 'Call Me by Your Name',
          year: 2017,
          director: 'Luca Guadagnino',
          frames: [
            'call-me-by-your-name-01',
            'call-me-by-your-name-02',
          ],
        },
      },
    ],
  }],
}

export default addCounts(m);
