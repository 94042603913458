import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LandingScreen from './screens/LandingScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import PersonScreen from './screens/PersonScreen';
import SignupScreen from './screens/SignupScreen';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CategoryDashboard from './screens/PersonScreen/components/CategoryDashboard';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App>
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/index.html" element={<LandingScreen />} />
          <Route path="/404" element={<NotFoundScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/:identifier" element={<PersonScreen />} />
          <Route path="/:identifier/tag/:tag" element={<PersonScreen />} />
          <Route path="/:identifier/post/:item" element={<PersonScreen />} />
          <Route path="/:identifier/year/:year" element={<PersonScreen />} />
          <Route path="/:identifier/category/:category" element={<PersonScreen />} />
          <Route path="/:identifier/category/:category/dashboard" element={<CategoryDashboard />} />
          <Route element={<NotFoundScreen />} />
        </Routes>
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
