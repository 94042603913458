import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { book, climb, film, filterCategory, itemType, person, post } from '../../../types';
import { sanitize } from '../../../utils';
import BookEvent from './BookEvent';
import ClimbEvent from './ClimbEvent';
import DateSpacer from './DateSpacer';
import FilmEvent from './FilmEvent';
import PhotoOverlay from './PhotoOverlay';
import PostEvent from './PostEvent';
import './CategoryDashboard.css';
import data from '../../../data';

const PIXELS_PER_DAY = 5;
const PIXELS_PER_DAY_FILTERED = 1;

// TODO: move this out of PersonScreen and into its own Screen.
// And move BookEvent,ClimbEvent, etc. out to some shared space.
//
// This page is not implemented. It's intended to hold data related to the category like
// visualizations and things.

function CategoryDashboard() {
  const navigate = useNavigate();

  const { identifier } = useParams<{ identifier: string }>();
  const { tag: urlTag } = useParams<{ tag: string }>();
  const { item: urlItem } = useParams<{ item: string }>();
  const { year: urlYear } = useParams<{ year: string }>();
  const { category: urlCategory } = useParams<{ category: string }>();
  const [ person, setPerson ] = useState<person | null>(null);
  const [ notFound, setNotFound ] = useState<boolean | null>(null);

  const [ category, setCategory ] = useState(filterCategory.ALL);
  const [ year, setYear ] = useState<number | null>(null);
  const [ tag, setTag ] = useState<string | null>(null);
  const [ item, setItem ] = useState<string | null>(null);

  useEffect(() => {
    if (identifier) {
      if (data[identifier]) {
        setPerson(data[identifier]);
      } else {
        setNotFound(true);
      }
    }
  }, [identifier]);

  useEffect(() => {
    if (urlTag) {
      setTag(urlTag);
    }
  }, [urlTag]);

  useEffect(() => {
    if (urlItem) {
      setItem(urlItem);
    }
  }, [urlItem]);

  useEffect(() => {
    if (urlYear) {
      setYear(parseInt(urlYear, 10));
    }
  }, [urlYear]);

  useEffect(() => {
    if (urlCategory) {
      switch (urlCategory) {
        case 'all': {
          setCategory(filterCategory.ALL);
          break;
        }
        case 'books': {
          setCategory(filterCategory.BOOKS);
          break;
        }
        case 'films': {
          setCategory(filterCategory.FILMS);
          break;
        }
        case 'climbs': {
          setCategory(filterCategory.CLIMBS);
          break;
        }
        case 'other': {
          setCategory(filterCategory.OTHER);
          break;
        }
      }
    }
  }, [urlCategory]);

  if (notFound) {
    navigate('/404', { replace: true });
  }



  const [ photo, setPhoto ] = useState<string | null>(null);

  let eventList = person && person.eventLists.find(el => el.year === 2023)
  let events = eventList!.events.filter(e => category === filterCategory.CLIMBS ? e.item.type === itemType.CLIMB : category === filterCategory.BOOKS ? e.item.type === itemType.BOOK : false)

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        if (photo) {
          setPhoto(null);
        }
      }
    }

    window.addEventListener("keydown", keydownHandler, false);

    return () => {
      window.removeEventListener("keydown", keydownHandler, false);
    };
  }, [photo]);

  return (
    <div className="Dashboard">
      {photo && <PhotoOverlay photo={photo} setPhoto={setPhoto} />}
      <div className="Dashboard-verticalLine" />
      {events.map((e, i) => {
        return (
          <Fragment key={i}>
            <div className="Dashboard-event">
            {e.item.title}
            </div>
          </Fragment>
        )
      })}
      {person && person.eventLists.map((eventList, i) => {
        return (!year || year === eventList.year) && (!tag || (eventList.events.reduce<string[]>((postTags, ev) => {
          if (ev.filterTags) { postTags = postTags.concat(ev.filterTags) }
          return postTags;
        }, []).includes(tag))) && (!item || (eventList.events.reduce<string[]>((sanitizedItems, ev) => {
          if (ev.item.title) { sanitizedItems = sanitizedItems.concat(sanitize(ev.item.title)) }
          return sanitizedItems;
        }, []).includes(item))) && (
            <Fragment key={i}>
              <div className="Dashboard-yearDiv-spacer" />
              <div className="Dashboard-yearDiv">{eventList.year}</div>
              {eventList.events.map((event, j) => {
                const lastDate = j > 0 ? eventList.events[j - 1].date : undefined;
                return (
                  <Fragment key={j}>
                    {lastDate && !item && <DateSpacer date={event.endDate? event.endDate : event.date} lastDate={lastDate} pixelsPerDay={category === filterCategory.ALL && !tag ? PIXELS_PER_DAY : PIXELS_PER_DAY_FILTERED} />}
                    {{
                      [itemType.POST]: ((category === filterCategory.ALL || category === filterCategory.OTHER) &&
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <PostEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            post={event.item as post}
                            showCollapseText={event.showCollapseText}
                            textCollapsedOnInit={event.textCollapsedOnInit}
                            showCollapseFrames={event.showCollapseFrames}
                            framesCollapsedOnInit={event.framesCollapsedOnInit}
                            useSerif={event.useSerif}
                            setPhoto={setPhoto} />),
                      [itemType.FILM]: ((category === filterCategory.ALL || category === filterCategory.FILMS) && 
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <FilmEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            film={event.item as film} setPhoto={setPhoto}
                            showCollapseText={event.showCollapseText}
                            filmGoal={eventList.goals?.films} />),
                      [itemType.BOOK]: ((category === filterCategory.ALL || category === filterCategory.BOOKS) && 
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <BookEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            book={event.item as book}
                            setPhoto={setPhoto}
                            showCollapseText={event.showCollapseText}
                            bookGoal={eventList.goals?.books} />),
                      [itemType.CLIMB]: ((category === filterCategory.ALL || category === filterCategory.CLIMBS) && 
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <ClimbEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            climb={event.item as climb}
                            setPhoto={setPhoto}
                            showCollapseText={event.showCollapseText}
                            climbGoal={eventList.goals?.climbs} />),
                    }[event.item.type]}
                  </Fragment>);
              })}
            </Fragment>
          );
      })}
    </div>
  );
}

export default CategoryDashboard;
