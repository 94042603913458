// filterCategory is the top-level filter category displayed in the side nav.
// This does not have to be 1:1 with the item types below
export enum filterCategory {
  ALL = 1,
  FILMS = 2,
  BOOKS = 3,
  OTHER = 4,
  CLIMBS = 5,
}

// These are data model types. They're currently 1:1 with presentation format
// too, but this can change later
export enum itemType {
  POST = 1,
  FILM = 2,
  BOOK = 3,
  CLIMB = 4,
  // LEGO_SET = 5,
  // LEGO_MOC = 6,
}

export type post = {
  type: itemType.POST,
  title?: string,
  text?: string,
  frames?: string[],
}

export type film = {
  type: itemType.FILM,
  title: string,
  year: number,
  director: string,
  marker?: '+',
  text?: string,
  frames?: string[],
  numTowardGoal?: number,
}

export type book = {
  type: itemType.BOOK,
  title: string,
  year: number,
  author: string,
  marker?: '+',
  text?: string,
  frames?: string[],
  numTowardGoal?: number,
  inProgress?: boolean,
  inProgressCurrentPage?: number,
  inProgressTotalPage?: number,
  inProgressCurrentChapter?: number,
  inProgressTotalChapter?: number,
}

export enum climbType {
  SPORT = 1,
  TRAD = 2,
  BOULDER = 3,
  TOPROPE = 4, // should be separate? top-ropeable (can set up TR without leading the route) / did top rope.
  OTHER = 5,
}

export type climb = {
  type: itemType.CLIMB,
  title: string,
  gradeYDS: string, // e.g Easy 5th, 5.7, 5.7+, 5.10-, 5.10a/b (https://www.mountainproject.com/international-climbing-grades)
  // todo: boulders are now included here so they don't use yds
  protection?: string, // X, R, PG13
  location?: string, 
  area: string, // e.g. Lake Tahoe   | Yosemite
  crag: string, // e.g. Lover's Leap | Swan Slab
  climbType: climbType, // e.g. trad, sport, toprope
  lengthFt: number, // e.g. 130ft (39 m)
  numPitches?: number,
  mountainProjectUrl?: string,

  stars?: number,
  sent?: boolean,
  attempt?: number, // or something like this

  marker?: '+',
  text?: string,
  frames?: string[],
  numTowardGoal?: number,
}


export type event = {
  date: Date,
  endDate?: Date,
  doNotRenderDate?: boolean,
  useSerif?: boolean,
  showCollapseText?: boolean, // default is false
  // if showCollapseText is true, whether text is collapsed on init
  textCollapsedOnInit?: boolean, // default is false
  showCollapseFrames?: boolean, // default is false
  // if showCollapseFrames is true, whether frames are collapsed on init
  framesCollapsedOnInit?: boolean, // default is false
  filterTags?: string[],
  item: post | film | book | climb,
}

export type goal = {
  books?: number,
  films?: number,
  climbs?: number,
}

export type eventList = {
  year: number,
  goals?: goal,
  events: event[],
}

export type personSettings = {
  extendRightSide?: boolean,
}

export type person = {
  handle: string,
  settings?: personSettings,
  eventLists: eventList[],
}
