import { Link } from 'react-router-dom';

import './index.css';

function LandingScreen() {
  return (
    <div className="LandingScreen">
      <div>
        <h1 className="LandingScreen-h1">
          anti-(social media)<br />
          social media
        </h1>
        <h2 className="LandingScreen-h2">
          an <a href="https://eggybytes.com" className="LandingScreen-a">eggybytes</a> product
        </h2>
        <section className="LandingScreen-handleSection">
          <Link to="/signup" className="LandingScreen-handleLink">
            claim your handle now <span className="LandingScreen-handleArrowSpan">&rarr;</span>
          </Link>
        </section>
      </div>
    </div>
  );
}

export default LandingScreen;
