import React, { Dispatch, useState } from 'react';

import { climb, climbType, itemType } from '../../../types';
import { datesToString } from '../../../utils';
import Marker from './Marker';
import MarkdownText from './MarkdownText';
import MasonryPhotoGrid from './MasonryPhotoGrid';
import ProgressCounter, { ProgressCounterType } from './ProgressCounter';
import './ClimbEvent.css';
import Star from './Star';

type ClimbEventProps = {
  date?: Date,
  endDate?: Date,
  climb: climb,
  showCollapseText?: boolean,
  textCollapsedOnInit?: boolean,
  showCollapseFrames?: boolean,
  framesCollapsedOnInit?: boolean,
  setPhoto: Dispatch<React.SetStateAction<string | null>>,
  climbGoal?: number,
}

function ClimbEvent({ date, endDate, climb, showCollapseText, textCollapsedOnInit,
  showCollapseFrames, framesCollapsedOnInit, setPhoto, climbGoal }: ClimbEventProps) {

  const [ textCollapsed, setTextCollapsed ] = useState(textCollapsedOnInit === true ? true : false);
  const [ framesCollapsed, setFramesCollapsed ] = useState(framesCollapsedOnInit === true ? true : false);

  return (
    <>
      <div className="ClimbEvent">
        <div className="ClimbEvent-subway" />
        <div className="ClimbEvent-content">
          {climbGoal && climb.numTowardGoal && 
          <ProgressCounter
            goal={climbGoal}
            completed={climb.numTowardGoal}
            type={itemType.FILM}
            progressCounterType={ProgressCounterType.SHORT}/>} 
          {date && <div className="ClimbEvent-dateDiv">
            {datesToString(date, endDate)}
          </div>}
          <a className="ClimbEvent-titleDiv" href={climb.mountainProjectUrl}
            // for climb events, link to mountain project instead of permalink page (for now)
            // onClick={() => {
            //   window.location.replace(`/${urlIdentifier}/post/${sanitize(climb.title)}`)
            // }}
            >
            <Marker marker={climb.marker} type={climb.type} />
            {climb.title}&nbsp;
            <span className="ClimbEvent-gradeSpan">
              ({{
                  [climbType.TRAD]: <span className="ClimbEvent-climbTypeTrad">T</span>,
                  [climbType.SPORT]: <span className="ClimbEvent-climbTypeSport">S</span>,
                  [climbType.BOULDER]: 'B',
                  [climbType.OTHER]: '',
                  [climbType.TOPROPE]: 'TR',
                }[climb.climbType]}
                &nbsp;  
                {climb.gradeYDS})
            </span>
            {climb.stars && climb.stars > 0 &&
            <span className="ClimbEvent-starRating">
              {[...Array(climb.stars)].map((_, i) => <Star key={i} />)}
            </span>}
          </a>

          <div className="ClimbEvent-locationDiv">
            {climb.location &&
            <>
              {climb.location} &gt;&nbsp;
            </>}
            {climb.area} &gt; {climb.crag}
          </div>
          <span className="ClimbEvent-lengthSpan">
            {climb.lengthFt} ft ({Math.floor(climb.lengthFt / 3.2808)} m)
            {climb.numPitches &&
            <>
            , {climb.numPitches} pitches
            </>}
          </span>

          {showCollapseText && climb.text ?
          <>
            {textCollapsed ?
              <div className="ClimbEvent-collapseButtonDiv" onClick={() => setTextCollapsed(false)}>+</div> :
              <>
                <div className="ClimbEvent-collapseButtonDiv" onClick={() => setTextCollapsed(true)}>–</div>
                <MarkdownText text={climb.text} />
              </>}
          </> :
          climb.text && <MarkdownText text={climb.text} />}

          {showCollapseFrames && climb.frames && climb.frames.length > 0 ?
          <>
            {framesCollapsed ?
            <div className="PostEvent-collapseButtonDiv" onClick={() => setFramesCollapsed(false)}>+</div> :
            <>
              <div className="PostEvent-collapseButtonDiv" onClick={() => setFramesCollapsed(true)}>–</div>
              <MasonryPhotoGrid photos={climb.frames} setPhoto={setPhoto} align="left" />
            </>}
          </> :
          climb.frames && climb.frames.length > 0 && <MasonryPhotoGrid photos={climb.frames} setPhoto={setPhoto} align="left" />}
        </div>
      </div>
      <div className="ClimbEvent-spacer" />
    </>
  );
}

export default ClimbEvent;
