import React, { Dispatch, useState } from 'react';
import { useParams } from 'react-router-dom';

import { book, itemType } from '../../../types';
import { datesToString, sanitize } from '../../../utils';
import Marker from './Marker';
import MarkdownText from './MarkdownText';
import MasonryPhotoGrid from './MasonryPhotoGrid';
import ProgressCounter, { ProgressCounterType } from './ProgressCounter';
import './BookEvent.css';

type BookEventProps = {
  date?: Date,
  endDate?: Date,
  book: book,
  showCollapseText?: boolean,
  textCollapsedOnInit?: boolean,
  setPhoto: Dispatch<React.SetStateAction<string | null>>,
  bookGoal?: number,
}

function BookEvent({ date, endDate, book, showCollapseText, textCollapsedOnInit, setPhoto, bookGoal }: BookEventProps) {
  const { identifier: urlIdentifier } = useParams<{ identifier: string }>();

  const [textCollapsed, setTextCollapsed] = useState(textCollapsedOnInit === true ? true : false);

  return (
    <>
      <div className="BookEvent">
        <div className="BookEvent-subway" />
        <div className="BookEvent-content">
          {((book.inProgressCurrentChapter && book.inProgressTotalChapter) || (book.inProgressCurrentPage && book.inProgressTotalPage)) ?
            <ProgressCounter
              goal={book.inProgressTotalChapter ? book.inProgressTotalChapter : book.inProgressTotalPage!}
              completed={book.inProgressCurrentChapter ? book.inProgressCurrentChapter : book.inProgressCurrentPage!}
              type={itemType.BOOK}
              progressCounterType={ProgressCounterType.LONG}
              prefixText={`(${book.inProgress ? 'Currently' : 'Paused'} reading) ${book.inProgressCurrentChapter ? 'ch.' : 'p.'}`} /> :
            (!book.inProgress && bookGoal && book.numTowardGoal) &&
            <ProgressCounter
              goal={bookGoal}
              completed={book.numTowardGoal}
              type={itemType.BOOK}
              progressCounterType={ProgressCounterType.SHORT} />}
          {(!book.inProgress && !book.inProgressCurrentChapter && !book.inProgressCurrentPage && date) ?
            <div className="BookEvent-dateDiv">
              {datesToString(date, endDate)}
            </div> :
            ((book.inProgress && date) || (!book.inProgress && (book.inProgressCurrentChapter || book.inProgressCurrentPage) && date)) &&
            <>{datesToString(date)} — 🤷🏻‍♂️</>}
          <div className="BookEvent-titleDiv" onClick={() => {
            window.location.replace(`/${urlIdentifier}/post/${sanitize(book.title)}`)
          }}>
            <Marker marker={book.marker} type={book.type} />
            {book.title}&nbsp;
          </div>
          <div className="BookEvent-authorDiv">
            {book.author}
            <span className="BookEvent-yearDiv">, {book.year}</span>
          </div>
          {showCollapseText && book.text ?
          <>
              {textCollapsed ?
              <div className="BookEvent-collapseButtonDiv" onClick={() => setTextCollapsed(false)}>+</div> :
              <>
                <div className="BookEvent-collapseButtonDiv" onClick={() => setTextCollapsed(true)}>–</div>
                <MarkdownText text={book.text} />
              </>}
          </> :
          book.text && <MarkdownText text={book.text} />}
          {book.frames && book.frames.length > 0 && <MasonryPhotoGrid photos={book.frames} setPhoto={setPhoto} align="right" />}
        </div>
      </div>
      <div className="BookEvent-spacer" />
    </>
  );
}

export default BookEvent;
