import React, { Dispatch, useState } from 'react';
import { useParams } from 'react-router-dom';

import { film, itemType } from '../../../types';
import { datesToString, sanitize } from '../../../utils';
import MarkdownText from './MarkdownText';
import Marker from './Marker';
import MasonryPhotoGrid from './MasonryPhotoGrid';
import ProgressCounter, { ProgressCounterType } from './ProgressCounter';
import './FilmEvent.css';

type FilmEventProps = {
  date?: Date,
  endDate?: Date,
  film: film,
  showCollapseText?: boolean,
  setPhoto: Dispatch<React.SetStateAction<string | null>>,
  filmGoal?: number,
}

function FilmEvent({ date, endDate, film, showCollapseText, setPhoto, filmGoal }: FilmEventProps) {
  const { identifier: urlIdentifier } = useParams<{ identifier: string }>();

  const [ collapsed, setCollapsed ] = useState(true);

  return (
    <>
      <div className="FilmEvent">
        <div className="FilmEvent-subway" />
        <div className="FilmEvent-content">
          {filmGoal && film.numTowardGoal && 
          <ProgressCounter
            goal={filmGoal}
            completed={film.numTowardGoal}
            type={itemType.FILM}
            progressCounterType={ProgressCounterType.SHORT}/>} 
          {date && <div className="FilmEvent-dateDiv">
            {datesToString(date, endDate)}
          </div>}
          <div className="FilmEvent-titleDiv" onClick={() => {
              window.location.replace(`/${urlIdentifier}/post/${sanitize(film.title)}`)
            }}>
            <Marker marker={film.marker} type={film.type} />
            {film.title}&nbsp;
            <span className="FilmEvent-yearSpan">
              ({film.year})
            </span>
          </div>
          <div className="FilmEvent-directorDiv"><span className="FilmEvent-dirDotSpan">dir.</span> {film.director}</div>
          {film.frames && film.frames.length > 0 && <MasonryPhotoGrid photos={film.frames} setPhoto={setPhoto} align="right" />}
          {showCollapseText && film.text ?
            <>
              {collapsed ?
                <div className="FilmEvent-collapseButtonDiv" onClick={() => setCollapsed(false)}>+</div> :
                <>
                  <div className="FilmEvent-collapseButtonDiv" onClick={() => setCollapsed(true)}>–</div>
                  <MarkdownText text={film.text} />
                </>}
            </> :
            film.text && <MarkdownText text={film.text} />}
        </div>
      </div>
      <div className="FilmEvent-spacer" />
    </>
  );
}

export default FilmEvent;
