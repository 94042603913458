import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';

import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import './MarkdownTitle.css';

type MarkdownTitleProps = {
  text: string,
  useSerif?: boolean,
}

function MarkdownTitle({ text, useSerif }: MarkdownTitleProps) {
  return (
    <div className="MarkdownTitle">
      <ReactMarkdown 
        children={text}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        linkTarget="blank_" />
    </div>
  );
}

export default MarkdownTitle;
