import React, { Dispatch, useState } from 'react';
import { useParams } from 'react-router-dom';

import { post } from '../../../types';
import { datesToString, sanitize } from '../../../utils';
import MarkdownText from './MarkdownText';
import MarkdownTitle from './MarkdownTitle';
import MasonryPhotoGrid from './MasonryPhotoGrid';
import './PostEvent.css';

type PostEventProps = {
  date?: Date,
  endDate?: Date,
  post: post,
  useSerif?: boolean,
  showCollapseText?: boolean,
  textCollapsedOnInit?: boolean,
  showCollapseFrames?: boolean,
  framesCollapsedOnInit?: boolean,
  setPhoto: Dispatch<React.SetStateAction<string | null>>,
}

function PostEvent({ date, post, endDate, useSerif, showCollapseText, textCollapsedOnInit, showCollapseFrames, framesCollapsedOnInit, setPhoto }: PostEventProps) {
  const { identifier: urlIdentifier } = useParams<{ identifier: string }>();

  const [ textCollapsed, setTextCollapsed ] = useState(textCollapsedOnInit === true ? true : false);
  const [ framesCollapsed, setFramesCollapsed ] = useState(framesCollapsedOnInit === true ? true : false);

  return (
    <>
      <div className="PostEvent-spacer" />
      <div className="PostEvent">
        <div className="PostEvent-subway" />
        <div className="PostEvent-content">
          {date &&
          <div className="PostEvent-dateDiv">
            {datesToString(date, endDate)}
          </div>}
          <div className="PostEvent-titleDiv" onClick={() => {
              if (post.title) {
                window.location.replace(`/${urlIdentifier}/post/${sanitize(post.title)}`)
              }
            }}>
            {post.title &&
            <MarkdownTitle text={post.title} />}
          </div>

          {showCollapseText && post.text ?
          <>
            {textCollapsed ?
            <div className="PostEvent-collapseButtonDiv" onClick={() => setTextCollapsed(false)}>+</div> :
            <>
              <div className="PostEvent-collapseButtonDiv" onClick={() => setTextCollapsed(true)}>–</div>
              <MarkdownText text={post.text} useSerif={useSerif} />
            </>}
          </> :
          post.text && <MarkdownText text={post.text} useSerif={useSerif} />}

          {showCollapseFrames && post.frames && post.frames.length > 0 ?
          <>
            {framesCollapsed ?
            <div className="PostEvent-collapseButtonDiv" onClick={() => setFramesCollapsed(false)}>+</div> :
            <>
              <div className="PostEvent-collapseButtonDiv" onClick={() => setFramesCollapsed(true)}>–</div>
              <MasonryPhotoGrid photos={post.frames} setPhoto={setPhoto} align="left" />
            </>}
          </> :
          post.frames && post.frames.length > 0 && <MasonryPhotoGrid photos={post.frames} setPhoto={setPhoto} align="left" />}
        </div>
      </div>
    </>
  );
}

export default PostEvent;
