import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import data from '../../data';
import { filterCategory, person } from '../../types';
import Menu from './components/Menu';
import Content from './components/Content';
import './index.css';

function PersonScreen() {
  const navigate = useNavigate();

  const { identifier } = useParams<{ identifier: string }>();
  const { tag: urlTag } = useParams<{ tag: string }>();
  const { item: urlItem } = useParams<{ item: string }>();
  const { year: urlYear } = useParams<{ year: string }>();
  const { category: urlCategory } = useParams<{ category: string }>();
  const [ person, setPerson ] = useState<person | null>(null);
  const [ notFound, setNotFound ] = useState<boolean | null>(null);

  const [ category, setCategory ] = useState(filterCategory.ALL);
  const [ year, setYear ] = useState<number | null>(null);
  const [ tag, setTag ] = useState<string | null>(null);
  const [ item, setItem ] = useState<string | null>(null);

  useEffect(() => {
    if (identifier) {
      if (data[identifier]) {
        setPerson(data[identifier]);
      } else {
        setNotFound(true);
      }
    }
  }, [identifier]);

  useEffect(() => {
    if (urlTag) {
      setTag(urlTag);
    }
  }, [urlTag]);

  useEffect(() => {
    if (urlItem) {
      setItem(urlItem);
    }
  }, [urlItem]);

  useEffect(() => {
    if (urlYear) {
      setYear(parseInt(urlYear, 10));
    }
  }, [urlYear]);

  useEffect(() => {
    if (urlCategory) {
      switch (urlCategory) {
        case 'all': {
          setCategory(filterCategory.ALL);
          break;
        }
        case 'books': {
          setCategory(filterCategory.BOOKS);
          break;
        }
        case 'films': {
          setCategory(filterCategory.FILMS);
          break;
        }
        case 'climbs': {
          setCategory(filterCategory.CLIMBS);
          break;
        }
        case 'other': {
          setCategory(filterCategory.OTHER);
          break;
        }
      }
    }
  }, [urlCategory]);

  if (notFound) {
    navigate('/404', { replace: true });
  }

  return (
    <>
      {person && 
        (<div className="PersonScreen">
          <Menu
            person={person}
            category={category}
            year={year}
            tag={tag} />
          <Content
            person={person}
            category={category}
            year={year}
            tag={tag}
            item={item} />
        </div>)}
    </>
  );
}

export default PersonScreen;
