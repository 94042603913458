import React, { Dispatch } from 'react';
import './PhotoOverlay.css';

type PhotoOverlayProps = {
  photo: string,
  setPhoto: Dispatch<React.SetStateAction<string | null>>,
}

function PhotoOverlay({ photo, setPhoto }: PhotoOverlayProps) {
  return (
    <div
      className="PhotoOverlay"
      onClick={() => setPhoto(null)}>
      <img
        src={`${process.env.PUBLIC_URL}/img/${photo}-lg.jpg`} alt={photo}
        className="PhotoOverlay-img" 
        onContextMenu={(e) => e.preventDefault()}/>
    </div>
  );
}

export default PhotoOverlay;
