import { itemType, person } from '../types';
import { addCounts } from '../utils';

const v01p: person = {
  handle: 'v01p',
  eventLists: [{
    year: 2021,
    goals: {
      books: 15,
    },
    events: [
      {
        date: new Date('2021-09-16'),
        endDate: new Date('2021-11-01'),
        showCollapseFrames: true,
        framesCollapsedOnInit: false,
        filterTags: ['lego'],
        item: {
          type: itemType.POST,
          title: 'LEGO 42100 Technic Liebherr R 9800',
          text: `
<div style="font-size: 12px;">
  (4108 pieces · Released 2021) <p/>
  <b>Goals:</b> Gear train(s) deep dive. Make diagrams.
</div>

## Lower hub / Bag 1

The following motors/gear trains are connected to the lower 4-port PoweredUp hub.

* **Left track**: \`17.857:1\` gear ratio <span style="font-size: 12px;">(see render below)</span>
  * XL Motor connected to lower hub \`Port A\`. (red)
* **Right track**: \`17.857:1\` gear ratio <span style="font-size: 12px;">(see render below)</span>
  * XL Motor connected to lower hub \`Port B\`. (yellow)
* **Turntable**: \`9:1\` gear ratio  <span style="font-size: 12px;">(see render below)</span>
  * L Motor connected to lower hub \`Port D\`. (green)
  
## Upper hub / Bag 2

* **Lowest linear actuators**: \`5:3\` gear ratio  <span style="font-size: 12px;">(see render below)</span>
  * XL Motor connected to upper hub \`Port A\` (red)
  * These linear actuators articulate the long part of the arm (closest to the body).

## Upper hub / Bag 3

* In Bag 3, we hook up 3 more L motors to the upper hub. 
* 2 L motors (which live in the long part of the arm) power the middle pair of linear actuators that articulate the short part of the arm.
* 1 L motor  (which also lives in the long part of the arm) powers the top-most pair of linear actuators that articulate the shovel.

  No renders for this section b/c I had a hard time CAD-ing these in a way that makes their mechanisms apparent, in part b/c the gear trains
live on top of each other, and also because the trains are connected by u-joints in order to work
across the articulated arm, and bending u-joints seems impossible in Bricklink Studio. (I think Philo has mentioned
using LDraw to bend parts and then opening in Studio to render.) These arm also needs to be articulated at
arbitrary angles, which I don't know how to do without taking one million years.

---

Things learned:
* how to use bricklink [studio](https://www.bricklink.com/v3/studio/download.page)
* how to import open source parts from [LDraw](https://www.ldraw.org/)
  * specifically, the linear clutch ([46834.dat](https://www.ldraw.org/cgi-bin/ptdetail.cgi?s=46834), [46845.dat](https://www.ldraw.org/cgi-bin/ptdetail.cgi?s=46835)), and the PoweredUp XL motor ([22172.dat](https://www.ldraw.org/parts/official-part-lookup.html?folder=parts&partid=22172)).
   why are not in Studio default library i dunno 🤷🏻‍♂️. cannot be mad b/c legos are toys?
  * it took me forevs to figure out where to put the parts in the Studio application directory, in particular the sub \`s/\` parts and sub-sub \`p/\` parts.
  I only discovered a couple days ago that [Philo](https://www.philohome.com/studio/packs.htm) (who makes _all_ the parts and official models on LDraw 🤯) has [parts packs that are zipped](https://www.philohome.com/studio/packs.htm). (before, I was manually doing a tree traverse on the LDraw website 🤧).
  Haven't gotten the connectivity to work though; the motors cause a collision and don't sit exactly on the axle.
  
`,
          frames: [
            '2021-09-16-liebherr-drivetrain/42100_gear_train-02',
            '2021-09-16-liebherr-drivetrain/42100_gear_train-03',
            '2021-09-16-liebherr-drivetrain/42100_gear_train-01',
          ],
        },
      },
      {
        date: new Date('2021-10-03'),
        showCollapseFrames: true,
        framesCollapsedOnInit: false,
        filterTags: ['lego'],
        item: {
          type: itemType.POST,
          title: 'LEGO 42116 Skid Steer Loader',
          text: `
<div style="font-size: 12px;">
  (140 pieces · Released 2021) <p/>
  <b>Goals:</b> Document a smaller set's mechanics/functions. Simple mechanisms.
</div>
          `,
          frames: [
            '2021-10-03-skid-steer-loader/ss-02',
            '2021-10-03-skid-steer-loader/ss-01',
            '2021-10-03-skid-steer-loader/ss-functions-diagram-01',
            '2021-10-03-skid-steer-loader/ss-functions-diagram-02',
          ],
        },
      },
      // {
      //   date: new Date('2021-09-08'),
      //   item: {
      //     type: itemType.BOOK,
      //     title: 'The Unofficial LEGO Technic Builder\'s Guide, 2nd Edition',
      //     year: 2016,
      //     author: 'Pawel Sariel Kmiec',
      //     text: `<img src="https://images-na.ssl-images-amazon.com/images/I/81l9vYFS78L.jpg" width="100px">`,
      //   },
      // },
      {
        date: new Date('2021-08-26'),
        endDate: new Date('2021-08-30'),
        showCollapseFrames: true,
        framesCollapsedOnInit: false,
        filterTags: ['42110'],
        item: {
          type: itemType.POST,
          title: '[MOC: 42110 Pimp up my Land Rover v1.3](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover) by [jb70](https://www.eurobricks.com/forum/index.php?/profile/142957-jb70/)',
          text: `
_(On building the Land Rover modification, [MOC-31299 — Pimp up my Land Rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover)
 by [jb70](https://www.eurobricks.com/forum/index.php?/profile/142957-jb70/). Continued from Aug 12 post, 
 where we discussed how to obtain the additional parts required for this build.)_

In this MOC, the drivetrain has been rebuilt to fix the following problems in the original 42110:
  * gearbox and hi/lo ranges were reversed (shifting up lead to higher revolutions of the engine (while being driven by the wheels))
  * high torque in gear train / stick-slip caused by universal joints

Bonus features in v1.3:
  * ackermann steering
  * "easy body-removal" by Pattspatt

→ Extra details on mods in [post by jb70](https://www.eurobricks.com/forum/index.php?/forums/topic/173690-42110-land-rover-defender-mods-and-improvements/&page=8&tab=comments#comment-3200145) on eurobricks

→ [.mpd file and other diagrams by jb70](https://bricksafe.com/pages/jb70/42110-pimp-up-my-landrover) on bricksafe

→ featuring organizers from Daiso

→ a <a href="${process.env.PUBLIC_URL}/img/2021-08-24-pimp-my-land-rover/land-rover-stove.gif" target="_blank">gif</a> of land rover going over camp stove
`,
          frames: [
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-01',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-03',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-05',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-09',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-10',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-11',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-16',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-14',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-15',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-17',
            '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-25',
          ],
        },
      },
      {
        date: new Date('2021-08-14'),
        endDate: new Date('2021-08-22'),
        showCollapseFrames: true,
        framesCollapsedOnInit: false,
        filterTags: ['42110'],
        item: {
          type: itemType.POST,
          title: '[MOC: 42110 Forklift](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/) by [Pattspatt](https://www.eurobricks.com/forum/index.php?/profile/169165-pattspatt/)',
          text: `
_(On building the 42110 Land Rover alternate build, [MOC-55079: Forklift 42110 B-Model](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/)
by [Pattspatt](https://www.eurobricks.com/forum/index.php?/profile/169165-pattspatt/))_

While waiting for parts to arrive for [MOC-31299 Pimp my Land Rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover),
decided to build the [42110 Land Rover](https://www.lego.com/en-us/product/land-rover-defender-42110) alternate build, [MOC-55079 Forklift 42110 B-Model](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/)
because forklifts are cute and the steering on this design is cool.
* moc on [rebrickable](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/)
* [post by Pattspatt](https://www.eurobricks.com/forum/index.php?/forums/topic/180286-first-moc-42110-b-model-forklift) on eurobricks
`,
          frames: [
            '2021-08-14-lego-forklift-moc/lego-forklift-moc-21',
            '2021-08-14-lego-forklift-moc/lego-forklift-moc-23',
            '2021-08-14-lego-forklift-moc/lego-forklift-moc-25',
            '2021-08-14-lego-forklift-moc/lego-forklift-moc-27',
          ],
        },
      },
      {
        date: new Date('2021-08-13'),
        showCollapseFrames: true,
        filterTags: ['gearboxes'],
        item: {
          type: itemType.POST,
          title: 'LEGO RC Motorized Gearbox',
          text: `
<div style="font-size: 12px;">
<b>Goals:</b> Learn more about gearboxes and gear ratios, how to use the Powered Up RC components,
how to control the Powered Up components via PS4 controller.
</div>

Built the following motorized 4-speed gearbox by Sariel:
* [4/16-speed RC combinable transmission for Control+](http://sariel.pl/2020/04/4-16-speed-rc-combinable-transmission-for-control/), Sariel, 2020 — [PDF](http://sariel.pl/down/4speed-control+-combinable-transmission.pdf) instructions

RC components used:
* [Powered Up Technic L motor](https://www.bricklink.com/v2/catalog/catalogitem.page?P=bb0959c01) for shifting
* [Powered Up Technic XL motor](https://www.bricklink.com/v2/catalog/catalogitem.page?P=bb0960c01) for driving
* [Powered Up Technic Hub](https://www.bricklink.com/v2/catalog/catalogitem.page?P=bb0961c01) as the Bluetooth controller / battery box

    (Powered Up components are also referred to as Control+ components)

The instructions show how to set up custom controls in the [Lego Powered Up iOS/Android app](https://apps.apple.com/us/app/lego-powered-up/id1367846574)
to control the shifting and driving motors.  Custom controllers in the Powered Up app are configured using a drag-and-drop API called [code blocks](https://racingbrick.com/powered-up-code-block-guide/),
which are not officially documented (wut 🤷🏻‍♂️), but have been documented by RacingBrick.
I maybe want to check out the code blocks one day (strong maybe), but mostly, I really don't want
to have to use a smart phone as an RC controller (especially once it's an RC car and not just a gearbox).

Instead, I've opted to set up a PS4 controller to control the hub via the 3rd-party [BrickController2](https://apps.apple.com/us/app/brickcontroller2/id1441923224) iOS app
by following RacingBrick's custom control set up for the [Lego Technic 42114 6x6 Volvo Articulated Hauler](https://brickset.com/article/52649/review-42114-6x6-volvo-articulated-hauler)
which conveniently also has a motorized gearbox (that shifts via 90 degree rotations):
* [LEGO Technic 42114 6×6 Volvo Articulated Hauler review, automatic transmission upgrade & custom controls](https://racingbrick.com/2020/07/lego-technic-42114-6x6-volvo-articulated-hauler-review-automatic-transmission-upgrade-custom-controls/), RacingBrick, 2020

  _Note on gamepad compatiblity_ — in order to use a gamepad, the gamepad has to not only speak Bluetooth, but (because Apple is
incredibly annoying) must be MFi (made for iOS), or be an official PS4+ or Xbox One+ controller
(which work because they have been made in partnership w/ Apple 😒).

---
LEGO Powered Up:
* [LEGO Powered Up summary](https://racingbrick.com/lego-powered-up-summary/), RacingBrick

More on motors:
* [Lego Technic motors comparison](https://www.philohome.com/motors/motorcomp.htm), Philo

`,
          frames: [
            '2021-08-13-lego-rc/lego-sariel-rc-gearbox-01',
            '2021-08-13-lego-rc/gearbox_homework-01',
          ],
        },
      },
      {
        date: new Date('2021-08-12'),
        filterTags: ['42110'],
        item: {
          type: itemType.POST,
          title: 'Preparing to build 42110 drivetrain improvements',
          text: `
_(On building the Land Rover modification, [MOC-31299 — Pimp up my Land Rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover)
by [jb70](https://bricksafe.com/pages/jb70))_

<div style="font-size: 12px;">
<b>Goals:</b> "Fix" the Land Rover drive train. Learn how to get parts for MOCs using Rebrickable + BrickLink. Build an MOC.
</div>

The LEGO saga continues. This is my first time building an MOC ("my own creation");
[jb70](https://bricksafe.com/pages/jb70)'s highly recommended
redesign of the Land Rover drivetrain so that it doesn't get stuck in low gears plus some cool features
like [Ackermann steering](https://www.eurobricks.com/forum/index.php?/forums/topic/173690-42110-land-rover-defender-mods-and-improvements/&page=7&tab=comments#comment-3195420) and [easily-removable body](https://www.eurobricks.com/forum/index.php?/forums/topic/173690-42110-land-rover-defender-mods-and-improvements/&page=6&tab=comments#comment-3191624).

### MOCs on Rebrickable

On an official set's page on [Rebrickable](https://rebrickable.com/sets/42110-1/land-rover-defender),
there's a tab for "Alt builds" where _Alternate Builds_ and _Modifications_
are listed separately. The former are builds that can be made with only the pieces from the set, while
Modifications require additional parts.

This one is a mod, so gotta buy stuffs 🥴, but I think it's worth it
because of learning and also completionism of having a working Land Rover. (I also want to attach a
motor to the drivetrain to actually see the output speed difference while shifting through the gearbox
with constant input motor speed, which would be bad to do now b/c of the gear snapping.)

### Purchasing parts

Purchased the parts from [BrickLink](https://www.bricklink.com/) via the integration on Rebrickable
(which exports the parts list for a particular MOC). Was a bit confused at first about how to get
the pieces from the cheapest/reliable stores, especially re: shipping.

I eventually figured out that you've got to select Domestic stores only (for reasonable shipping cost)
and use the "Auto-select" button, which aggregates the pieces you need with the stores that are selling,
and places the cheapest order in your cart. It was a super smooth process / I am impressed with this
software.
(I also tried out BrickOwl but I couldn't find an "Auto-select" type of feature so am at a loss
for how to choose the optimal stores.) Both stores I purchased from on BrickLink shipped next day.

Order from first store:
\`\`\`
    Total Items: 67
    Unique Items (lots): 24

    Total: US $6.92
    Shipping: US $4.25
    California Sales Tax: US $0.71
    Grand Total: US $11.88
\`\`\`

Order from second store:
\`\`\`
    Total Items: 97
    Unique Items (lots): 30

    Total: US $6.94
    Shipping: US $3.75
    California Sales Tax: US $0.71
    Grand Total: US $11.40
\`\`\`
All-in-all, 164<sup>1</sup> parts (some new, some used) for $23.28.

(Sidenote: BrickLink also makes [Stud.io](https://www.bricklink.com/v3/studio/download.page),
one of a few popular Lego CAD programs. There's a whole Lego open-source community.
This rabbit hole is deep 🤷🏻‍♂️)

---

### 🚸 To be continued once parts arrive. Goodbye for now.

---

<div class="sidebar">
<sup>1</sup>The description on the MOC page on Rebrickable mentions that there are 166 extra parts in addition to the original
Land Rover parts. I purchased 164
based on the Rebrickable compare tool. I was worried about missing two parts, so did some investigation
and it looks like the MOC has listed 2 of part
<a href="https://www.bricklink.com/v2/catalog/catalogitem.page?P=6641" target="blank_">6641 - Technic Changeover Catch</a>,
used for the DNR and Hi/Lo selectors. On BrickLink, this part is searched interchangeably with
<a href="https://rebrickable.com/parts/51149/technic-changeover-catch-new-style/" target="blank_">51149 - Technic Changeover Catch - New Style</a>.
After changing my "Build settings" on Rebrickable to include "Consider alternate parts that can usually
be used as replacements, but are not always functionally compatible", the compare tool lists 162 additional parts,
because I already have the 2 51149 parts that came in the original Land Rover. (Still not super sure
about the 166 number, but I think it should be fine. Pretty sure I have two extra Technic Changeover Catch's,
and the only way to really verify beyond trusting the Rebrickable compare tool would be to go
through the build instructions and check all 2642 pieces 🙃.)
</div>
`
,
          frames: [],
        },
      },
      {
        date: new Date('2021-08-02'),
        endDate: new Date('2021-08-11'),
        showCollapseFrames: true,
        filterTags: ['42110'],
        item: {
          type: itemType.POST,
          title: '[LEGO 42110 Technic Land Rover Defender](https://brickset.com/article/45846)',
          text: `
<div style="font-size: 12px;">
<b>Goals:</b> How do gears work? (Learn math) Intro to Technic.
</div>

After careful consideration, procured 42110 as my first Technic set
b/c it has a complex drivetrain and I want to learn about gears; it has a 4-speed gearbox with high and
low gear ratios, drive/neutral/reverse (DNR) selector, and all-wheel drive w/ front, rear, and central
differentials.

Drivetrain overview / demo of the drivetrain being powered by a motor from the wheels (from the output):
* [Decoding the gearbox of the LEGO Technic 42110 Land Rover Defender](https://racingbrick.com/2019/09/decoding-the-gearbox-of-the-lego-technic-42110-land-rover-defender/)<sup>(YouTube)</sup>, Racing Brick, 2019
* [LEGO Technic 42110 Land Rover Defender ultimate repair guide](https://racingbrick.com/2020/04/lego-technic-42110-land-rover-defender-ultimate-repair-guide/)<sup>(YouTube)</sup>, Racing Brick, 2020

_Excellent_ drivetrain diagrams with transmission gear ratios for all 4 gears in hi and lo:
* [Lego 42110 Land Rover Defender Drivetrain](https://www.youtube.com/watch?v=R3TfZbrkDfY)<sup>(YouTube)</sup>, John Walt (TinkeringJohn), 2020  ⬇️

    <a href="https://youtu.be/R3TfZbrkDfY?t=42" target="blank_"><img src="${process.env.PUBLIC_URL}/img/2021-08-11-lego-land-rover/gears/landrover_drivetrain_johnwalt_tinkeringjohn_p1.png" width="85%" /></a>

Gears in general:
  * [Gears explained](https://www.youtube.com/watch?v=4ROtKKuSaBI)<sup>(YouTube)</sup>, The Engineering Mindset, 2021
    * Explains the driver gear and driven gear, how to calculate gear ratios, speed, and torque
  * [Lego Technic gears tutorial](http://sariel.pl/2009/09/gears-tutorial/)<sup>(blog)</sup>, Sariel, 2009-2010
    * Explains Lego gears, general gear mechanics (gear ratios, efficiency, backlash), and how these mechanics work in practice w/ the Lego gears

I wasn't able to eliminate the ["cracking" in the highest torqued gear](https://youtu.be/0p1kMtrvFvQ)
(4th gear in the official build, but 1st gear if reversed to be like a real car as explained by
RacingBrick [here](https://youtu.be/t9VQGoqhOFs?t=10)).
It seems like the universal joints don't turn well (even though they're "aligned"). There's a lot of resistance
in the low gears and everything gets jammed up; the cracking happens when there is too much resistance
from one gear against another and it jumps over a tooth.

Despite the cracking, I'm happy with the demonstration of the wide range of transmission ratios
(and the challenges of engineering a high torque off-roader, particularly with plastic parts):

<div style="padding-left: 40px;">
  <a href="https://youtu.be/R3TfZbrkDfY?t=281" target="blank_">
    <img src="${process.env.PUBLIC_URL}/img/2021-08-11-lego-land-rover/gears/landrover_drivetrain_johnwalt_tinkeringjohn_p13.png" width="85%" />
  </a>
</div>

Things have learned:
* how the 4-speed gearbox works (+ what happens when the output is then fed to the hi/lo selector)
* differentials: front, rear, and central
* steering (rack and pinion)

I'm curious how it will perform if the gear ratios are less extreme (e.g. replace the 8T/24T gearing
that's in the 1st and 2nd gear trains, with say 12T/24T).

**Next steps:** will build [jb70](https://bricksafe.com/pages/jb70)s alternate build [Pimp up my land rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover), which
has a rebuilt drivetrain, eliminating the u-joints, but also has been geared up using 16T/16T instead of 8T/24T.
Will try to recalculate the transmission ratios to [see if i know how to math and] see what the trade-off is.
(The MOC also has Ackermann steering; I have no idea what this is, but interested in learning.)
`
,
          frames: [
            '2021-08-11-lego-land-rover/lego-land-rover-02',
            '2021-08-11-lego-land-rover/lego-land-rover-07',
            '2021-08-11-lego-land-rover/lego-land-rover-08',
            '2021-08-11-lego-land-rover/lego-land-rover-20',
            '2021-08-11-lego-land-rover/lego-land-rover-23',
            '2021-08-11-lego-land-rover/lego-land-rover-24',
            '2021-08-11-lego-land-rover/lego-land-rover-28',
            '2021-08-11-lego-land-rover/lego-land-rover-26',
            '2021-08-11-lego-land-rover/lego-land-rover-30',
            '2021-08-11-lego-land-rover/lego-land-rover-33',
            '2021-08-11-lego-land-rover/lego-land-rover-35',
          ],
        },
      },
      {
        date: new Date('2021-08-01'),
        showCollapseFrames: true,
        filterTags: ['21303'],
        item: {
          type: itemType.POST,
          title: 'LEGO 21303 Ideas WALL•E',
          text: `became obsessed with lego after building the grand piano and M had a half completed
WALL•E around, so i made it and it was disappointing<sup>1</sup> (and this movie was terrible).
in related news / more recent flailing attempts to convince 8 billion people to forsake their true world
theories, the [IPCC Sixth Assessment Report (AR6)](https://www.ipcc.ch/report/ar6/wg1/#SPM) was released
on 6 Aug 2021. the septennial documents strategy is going to save us all.

<div class="sidebar">
<sup>1</sup> LEGO 21303 adverby review: based on my being a lego expert after having built two whole lego
sets: 21323 Ideas Grand Piano, and its corresponding free gift, 5006746 Swing Ship Ride, i am
now truly obsessed with lego b/c the builds are delightfully detailed and thoughtfully engineered.
WALL•E, however, was distressingly uninspired. the articulation of the arms and neck are fragile
(even after the official 'neck fix'), the tracks are clunky, and
the building process was dull and gave me a headache; it could be that i was dehydrated.
</div>
          `,
          frames: [
            '2021-08-01-lego-wall-e/wall-e-07',
            '2021-08-01-lego-wall-e/wall-e-10',
            '2021-08-01-lego-wall-e/wall-e-11',
          ],
        },
      },
      {
        date: new Date('2021-07-18'),
        endDate: new Date('2021-07-30'),
        showCollapseFrames: true,
        filterTags: ['21323'],
        item: {
          type: itemType.POST,
          title: 'LEGO 21323 Ideas Grand Piano',
          text: `
**post-post comment (Oct. 5, 2021):**

first lego set. was seriously impressed with LEGO mechanics and building techniques (learned about SNOT).
only complaint is the mobile app and use of the PoweredUp components was obviously an afterthought and
in my opinion, only makes the set worse. _however_, their use did allow me to discover that PoweredUp 
even exists, which lead me to discover Technic and now my life is forever changed.

now that I've learned a bit more about Technic, i think a nice mod will be to strip out the 
hub/motor/sensor, optionally add a manual crank for the pianola feature, and repurpose the smaller 
2-port hub for perhaps, a PoweredUp version of 
[piterx](https://www.eurobricks.com/forum/index.php?/profile/29201-piterx/)'s 
[RC motorbike](https://www.eurobricks.com/forum/index.php?/forums/topic/187382-rc-motocross-bike/) 😻.`,
          frames: [
            '2021-07-18-lego-grand-piano/lego-grand-piano-06',
            '2021-07-18-lego-grand-piano/lego-grand-piano-10',
            '2021-07-18-lego-grand-piano/lego-grand-piano-17',
            '2021-07-18-lego-grand-piano/lego-grand-piano-18',
            '2021-07-18-lego-grand-piano/lego-grand-piano-14',
            '2021-07-18-lego-grand-piano/lego-grand-piano-15',
            '2021-07-18-lego-grand-piano/lego-grand-piano-16',
          ],
        },
      },
    ],
  }],
}

export default addCounts(v01p);
