import React from 'react';
import './DateSpacer.css';

type DateSpacerProps = {
  date: Date,
  lastDate: Date,
  pixelsPerDay: number,
}

function DateSpacer({ date, lastDate, pixelsPerDay }: DateSpacerProps) {
  const height = pixelsPerDay * ((lastDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24));
  return (
    <>
      <div className="DateSpacer" style={{ height: `${height}px`}} />
    </>
  );
}

export default DateSpacer;
