import { itemType, person } from '../types';
import { addCounts } from '../utils';

const vplan: person = {
  handle: 'vplan',
  settings: {
    extendRightSide: false,
  },
  eventLists: [
    {
      year: 2023,
      goals: {
        books: 15,
      },
      events: [
        {
          date: new Date('2023-05-25'),
          filterTags: ['(socio|econo|political)-systems'],
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'to read',
            text: `
<span style="color:#EE352E;">&#10140;</span>  &#x2022;  _Molecular Biology of the Cell, 7th ed._ by Bruce Alberts, et al., 2022 <br/>
<span style="color:#EE352E;">&#10140;</span>   &#x2022;  _The Civil War in the United States_ by Karl Marx, 1937

* _War and Peace_ by Leo Tolstoy, 1867

* _Thinking in Systems_ by Donella H. Meadows, 2008
* _The Visual Display of Quantitative Information, 2nd Ed._ by Edward R. Tufte, 2001
* _18th Brumaire of Louis Bonaparte_ by Karl Marx, 1852

(updated 6/5)
`,
          },
        },
        //       {
        //         date: new Date('2023-05-24'),
        //         item: {
        //           type: itemType.BOOK,
        //           title: 'Thinking in Systems',
        //           year: 2008,
        //           author: 'Donella H. Meadows',
        //           inProgress: true,
        //           inProgressCurrentPage: 11,
        //           inProgressTotalPage: 185,
        //           text: `
        // # Part One: System Structure and Behavior
        // `,
        //         },
        //       },
        {
          date: new Date('2023-05-23'),
          endDate: new Date('2023-05-25'),
          filterTags: ['science'],
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'problemset.ai',
            text: `
problems: things that cause suffering, that stand in the way of human generativity...
assumes that we are first and foremost humanists.

the remaining problems facing humanity are those related to disease and scarcity (of resources, energy, etc).
these problems _can_ be solved with technology.

problem | solution
1. disease and fragility of the human body | immortality
2. scarcity of resources | excessively abundant (effectively infinite) resources for all human life
3. human-driven self-destruction and mutual destruction | humans only want to be generative for self and all humankind

the third tier of problem involves the human drive toward self-destruction and mutual destruction. 
some of this can be addressed with biological solutions such as curbing human addiction and aggression, however this solution
also requires society-building, philosophy, and figuring how to organize humans in a way that is
generative and not destructive, and solves for human-specific existential needs (something about...self-worth, 
belonging, pride, shame, ownership, jealousy, competition, winning, losing, etc.)

---

With DeepMind's AlphaFold, we were able to solve the protein-folding problem. We need to continue to
solve the protein-design problem. And innovate on genetic editing/repair/removal; the promise of CRISPER/Cas9
was the ability to edit genes, however, we have yet to figure out how to 
perform these edits in the live human body and be able to target the edit sites (e.g. locate the virus and remove it). 
Getting these things to work will also be the means to solving 
**(2) removal of self-infectious disease (cancers, auto-immune disease, genetic diseases)**.
With work on even existing AI models, these goals are well within reach.


# (1) Removal of infectious disease

Goal: Cure diseases caused by infection from foreign bodies such as **viruses, bacteria, and fungi**.

## Viruses

A LOT OF HUMAN DISEASE IS CAUSED BY VIRUSES, but we don't care much. 

We know that <mark>Epstein-Barr Virus (EBV)</mark>, also known as human herpesvirus 4 (HSV4) — which causes mononucleosis (commonly called mono) —
<mark>causes Multiple sclerosis (MS)</mark>, a disease of the brain and central nervous system. We know that this same virus
<mark>causes Hodgkin's lymphoma</mark>. According to the CDC, 
<mark>"most people get infected with EBV at some point in their lives."</mark>

* [Longitudinal analysis reveals high prevalence of Epstein-Barr virus associated with multiple sclerosis](https://www.science.org/doi/full/10.1126/science.abj8222?rfr_dat=cr_pub++0pubmed&url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org) — 13 Jan 2022
  > Multiple sclerosis (MS) is a chronic inflammatory demyelinating disease of the central nervous system of unknown etiology. We tested the hypothesis that MS is caused by Epstein-Barr virus (EBV) in a cohort comprising more than 10 million young adults on active duty in the US military, 955 of whom were diagnosed with MS during their period of service. Risk of MS increased 32-fold after infection with EBV but was not increased after infection with other viruses, including the similarly transmitted cytomegalovirus. Serum levels of neurofilament light chain, a biomarker of neuroaxonal degeneration, increased only after EBV seroconversion. These findings cannot be explained by any known risk factor for MS and suggest EBV as the leading cause of MS.
  * [Epstein-Barr virus and multiple sclerosis](https://www.science.org/doi/full/10.1126/science.abm7930) — 13 Jan 2022; "Perspective" that refers to paper above. has a diagram.
* [EBV in Hodgkin Lymphoma](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3033177/)
  > Up to 40% of Hodgkin lymphoma (HL) cases are associated with the Epstein-Barr virus (EBV). Clonal viral genomes can be found in the HL tumor cells, the Hodgkin Reed-Sternberg cells (HRS). 
 
We have yet to study (for some reason) what other degenerative diseases are definitely caused by viruses
that live in the body indefinitely.

We suspect that Alzheimer's disease (and other forms of dementia) are likely caused by viruses.
* _Please_ can someone sequence some Alzheimer's patients and check if they have HSV1 or HSV2
* HSV1 and HSV2 affect 1 out of 6 people in the U.S., aged 14-49. It's a neurotropic virus that lives basically only in the neurons and we have only been studying Alzheimer's
— because of the way that the history of neurobiology works — by looking at scans and looking at tissue sections
macroscopically; looking at protein plaques instead of looking at the micro level: DNA sequences, 
viruses being made, etc. which are invisible to the microscope. We are not sequencing people for viruses. 
(This is easy to do. 23-and-me does it every day...)

* [Herpes Simplex Virus 1 Induces Brain Inflammation and Multifocal Demyelination in the Cotton Rat Sigmodon hispidus](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6912097/#B28) — Jan 2020
  > Recurrent HSV-1 infection in mice was shown to contribute to neurodegeneration and cognitive deficits, providing additional evidence for a growing association between latent HSV infection and Alzheimer’s disease (AD)

  ^ Cites the following two papers for this:
  * [Recurrent herpes simplex virus-1 infection induces hallmarks of neurodegeneration and cognitive deficits in mice](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6417650/) — Mar 2019
  * [Herpes simplex virus type 1 and Alzheimer's disease: possible mechanisms and signposts](https://faseb.onlinelibrary.wiley.com/doi/abs/10.1096/fj.201700360) — Aug 2017

SARS-CoV-2 (COVID-19) will lead to large-scale disability across humankind.  As of May 5, 2023, 30% of people in the U.S. have been infected with COVID-19
* [SARS-CoV-2 is associated with changes in brain structure in UK Biobank](https://www.nature.com/articles/s41586-022-04569-5) — 07 Mar 2022
  * [Even Mild COVID-19 May Change the Brain](https://jamanetwork.com/journals/jama/fullarticle/2790595) — 23 Mar 2022; comments on above
* [Selective visuoconstructional impairment following mild COVID-19 with inflammatory and neuroimaging correlation findings](https://www.nature.com/articles/s41380-022-01632-5) — 14 June 2022
* [What SARS-CoV-2 does to our brains](https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9212726/) — 12 Jul 2022
* [SARS-CoV-2 infection and persistence in the human body and brain at autopsy](https://www.nature.com/articles/s41586-022-05542-y) — 14 December 2022

 
## Bacteria

* bacteria: will be resistant to antibiotics soon

## Fungi

---

# (2) removal of self-infectious disease

# cancers

There is one single mutation that causes 100% of human cancers.

# auto-immune diseases

# genetic diseases e.g. huntington's, parkinsons

# (3) general human longetivy
aging, "clean up" junk that accumulates in the human body

# (4) exoskeletons / body protection or regeneration
exoskeletons or pods to prevent accidents to the human body. or e.g., can scan brain and regenerate it.

# (5) more efficient capture of stellar energy
solar, or arrays in space closer to other stars. infinite energy exists.

# (6) energy storage and transport
a chemical thing. we're currently storing energy as electric potential. it can be stored
in other ways, e.g. mass

# (7) ability to control the weather (on earth)
carbon capture, controlling weather e.g. do air conditioning

# (8) terraforming
if we can control the weather (7), we can make all place inhabitable e.g. make mars warmer

# (9) fast space flight and use of relativity

# (10) quantum computing / physics
* quantum computing for faster than light-speed information travel (necessary if we have space flight)
* figure out what the actual particles in the universe are; finish physics

# (11) raw materials
stable alchemy; converting elements into other elements (be able to print whatever element we want out of whatever)
or find a way to detect elements; mining

# (12) toning down human addictions (self-destructive things)

# (13) toning down human aggression (mutually destructive things)

# (14) routes/ladders

in addition to scientific solutions for 12 and 13, we also need to build society with ladders
and things that make humans not destroy themselves and each other...

`,
          },
        },

        //       {
        //         date: new Date('2023-06-01'),
        //         endDate: new Date('2023-06-30'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: 'Bay Area Rocks',
        //           text: `
        // # Mount St. Helena

        // ## The Bubble
        //   * sport climbs until 11:30a

        // ## Silverado
        //   * go to top
        //   * practice rappelling both ways
        //   * practice ascending
        //   * practice belaying from top
        //   * regular toprope routes

        // ## The Bear
        //   * hard sport climbs after 2
        //   * meet up top/do them as follows
        //   * maybe try the multipitch

        // ## The Near Side
        //   * sport climbs after 3

        // ---

        // # South Lake Tahoe: Lover's Leap
        // ## Hogswild
        // * Knapsack Crack: Multipitch Trad 5.5
        // * Better with Bacon: Multipitch Trad 5.8-

        // ## Main Formation
        // * Dead Tree Direct trad 5.7
        // * Dragon Back 5.10b sport

        // `,
        //         },
        //       },
        //       {
        //         date: new Date('2023-06-20'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: 'South Lake Tahoe: Lover\'s Leap',
        //           text: `
        // _(Information below copy-and-pasted from Mountain Project)_

        // # [Lover's Leap](https://www.mountainproject.com/area/105733959/lovers-leap): [Hogsback North Face](https://www.mountainproject.com/area/109211055/north-face)
        // * [Knapsack Crack (T 5.5)](https://www.mountainproject.com/route/105812282/knapsack-crack) Trad, 300 ft (91m), 3 pitches
        //   * Start below a lone tree on the east side of Hogsback. 
        //   * P1: The first pitch ends at the tree and is very easy. 
        //   * P2: From the tree the second pitch contains some 5.5 moves, but due to the low angle of the rock it 
        //     doesn't feel that way. A 60 m rope gets you from the tree to the end of the second pitch. 
        //     Use a 50 m and you will be belaying from the crack itself. 
        //     Not that this is a bad thing as it helps to build confidence in belaying without a ledge.

        //   * P3: The third pitch is again very easy to climb and protect. 
        //   * Descent: Upon reaching the top, walk off to the left to return to more climbs or to head for climbs at the Leap.
        //   * Protection: Small to large cams and chocks; good protection on every pitch. I found an old piton on the second pitch that seemed fairly secure.

        // # Lover's Leap: [Hogwild](https://www.mountainproject.com/area/105969345/hogwild)
        // * [Ham and Cheese (S 5.6)](https://www.mountainproject.com/route/105976125/ham-and-cheese) — Sport, 330 ft (100m), 3 pitches, 2.5 stars
        //   * 1st pitch, fully bolted w/ two bolt anchor w/chains. full 98ft (30m) so watch the ends of your rope 
        //     when lowering or rapping. Possibly a good early lead for a 5.6 leader, but there is one section 
        //     where the bolts are 15' apart, but it is on easy climbing.
        //   * Need to place trad gear
        // * [Hogwild (T 5.7+)](https://www.mountainproject.com/route/106210176/hogwild) — Trad, 110 ft (33m), 2.5 stars
        //   * Description: Climb the shallow, right-facing corner and step right, following the bolts. Then climb the path of least resistance straight up to the top. A 70m rope or doubles / twins are needed to get down.
        //   * Location: On the right side of the Hogwild crag, take a left where the path meets the crag and walk uphill to where the path again meets the cliff. There should be a large, left-facing corner about 20 feet your right. Climb the shallow right-facing corner straight up the dikes to the chains above.
        //   * Protection: Mostly bolted, but small to medium sized cams work well at the top for extra protection.

        //   * Length of rope for rappel:
        //     * "70m rope is barely enough to rappel down. Watch your rope ends! Some 70m ropes are shorter, some are longer."
        //       * "My rope is about 64 meters since I cut it and I barely made it to the ground."
        //       * "We climbed with a 60m and had a pain in the ass rappel off to climbers right where there is an anchor in a tree. Super annoying getting there but it worked."

        //   * 6/2021: "Finished up the day last weekend with this route and it was the highlight of my day! I'm a very green trad climber with only a few routes under my belt and this was a fantastic "keep a cool head and you'll be fine" test piece! I didn't find any of the actual moves technically difficult - the real challenge was figuring out what tiny piece of gear fits in what tiny shallow crack for the first like 80 feet haha - good for practice picking gear! The few bolts that were on the route were just in the right spots, easy to clip, and looked fresh/recently replaced. Would recommend to anyone who is comfortable on slab. Also - the description of the route is "mostly bolted" - pretty sure I clipped just 4 bolts on a 115 foot route, so bring all your smallest trad gear if you don't like 30 foot runouts haha."
        //   * 7/2020: "Agree with the comments that this route is quite heads-up for a 5.7 (small, sometimes tricky gear and thin face climbing). However, I did not find it to be runout - I placed 11 pieces in addition to the bolts, including some placements between the bolts. Bring plenty of small gear (largest I placed was a #1 C4), offsets in particular worked well given some of the flaring placements."
        //   * 6/2018: "Very heads up, not for the fledgling 5.7 leader at all. The book says gear .5 to 1.5 but I thought it was more like 0.3 to 1, with some micro nuts thrown in for good measure. Way more committing (but because of that, also more fun) than I expected. My rope is about 64 meters since I cut it and I barely made it to the ground."
        //           `,
        //         },
        //       },
        //       {
        //         date: new Date('2023-06-21'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: 'South Lake Tahoe: Luther Spires',
        //           text: `
        // # [Luther Spires](https://www.mountainproject.com/area/109498727/luther-spires)

        // _(Information below copy-and-pasted from Mountain Project)_

        // ## Area

        // This area is in the Eldorado National Forest, so back country camping without a permit, and drilling bolts for climbing protection both seem to be free and legal here.

        // ## Parking

        // Upon entering Christmas Valley on Highway 89 from 50, drive a few miles until the road begins to curve as it approaches Luther Pass. There will be a sign to the Big Meadow Trailhead and a parking lot with a bathroom. There are also several turnouts along the highway near the lot.

        // ## Approach

        // New Approach Trail — Completed 6/6/2021: [Approach Map](https://www.mountainproject.com/photo/120882638)
        // * Approach no longer uses fallen log bridge or the steep slab that goes directly uphill near the top (marked by x's).
        //   New trail is obvious, very nicely done, and overall more sustainable. See approach beta for more.

        // From the parking area, walk back up the paved road (past where you drove in from the highway) and through the metal gate. There is a large area map on the right and the Tahoe Rim Trail continues behind it, then crosses a nicely built foot bridge (est 2019).

        // Head over the bridge and in just a minute or two depart the TRT taking a Left onto a trail marked by a cairn. The trail is small but maintained. - thanks Simon 

        // Once nearing the top, the trail will pass over a short section of slab and reappear (marked by cairns). No need to go up the slab anymore, easier to continue across and rejoin the trail.

        // After a few switchbacks you'll arrive at the base of the crag viewing Jane Spy 5.7

        // Overall the hike is moderately strenuous, lots of uphill, and takes about 30-40 minutes from the parking lot. ~1 mile.

        // (Old trail used a fallen log to cross the creek and went directly uphill on steepish slab near the end of the trail.
        // Old trail is closed off really nicely. The new (and much better) trail is quite obvious and intuitive unless you’ve made a strong habit of taking the log bridge and steep uphill slab.)

        // Elevation gain is about 500-550 feet from parking area to base of the crag (Jane Spy 5.7)

        // * [Jane Spy (S 5.7)](https://www.mountainproject.com/route/105736091/jane-spy) Sport, 60 ft (18 m)
        //   * MP suggests it's PG-13. Maybe can add small cam before 2nd bolt and larger cam between 3rd and 4th.
        //   * Jane Spy is the closest route to the approach trail as it comes to the Luther Spires, sitting just left of the corner that the trail runs into. [Note, as of 9/23/2020, there is a more established trail that turns right after the bridge from the highway. Though less direct, it is far more established & leads to the same place ending at Jane Spy.]
        //     The crux comes right as you leave the ground, and from there climbing eases to probably 5.5 through runout sections that will get you to the top anchors.
        //     This is often a busy route, and is the best warmup on the rock.
        //   * Protection: 4 bolts to a 2 bolt top anchor with mussies. 
        // * [A Beer and a Hot Dog, Two Bucks (S 5.8)](https://www.mountainproject.com/route/105736079/beer-and-a-hot-dog-two-bucks) Sport, TR, 30 ft (9 m)
        //   * Short (30ft) sustained 5.8
        //   * Protection: 4 bolts, mussy hook anchors
        // * [Hey Y\'all, Watch This (T 5.6)](https://www.mountainproject.com/route/107183614/heyyall-watch-this) Trad, 30 ft (9 m)
        //   * Short, but actually a good beginner trad lead. Goo dstances and gobbles up gear.
        //   * ST: "30 ft of mostly 5.5 crack climbing with a 5.7 move half way up."
        //   * Crack just left of the bolted arete, which I believe is Dog and GriGri, 2 bucks. Shares an anchor with this climb too.
        //     Starts out wider and narrows as you move up. Mostly easy 5th class with a 5.6ish move or two.
        //   * Protection: One 2" cam and a set of nuts is plenty. (ST says 'nuts, cams 1-2 ea to 2"')
        // * [Slab B (S/T 5.7)](https://www.mountainproject.com/route/107221157/slab-b) Sport or Trad, 40 ft (12m)
        //   * ST: "Cool slab climbing thru a roof. Good second lead for beginner climbers after Jane Spy 5.7"
        //   * The climb is on the next spire to the left of "Mixed Emotions". It will be easy to spot if you 
        //     look for the bolts to the left of a ledgy crack. There are four bolts with chains at the top 
        //     but you'll only be able to see three of the four and the chains are just out of sight even 
        //     though this is a short climb. The roof would be the crux, but it's a small roof. It's a good 
        //     option if you're working in the 5.7 range. Good challenge for beginners and a 
        //     good next step for those learning to place pro.
        //   * Follow around to the left and behind the spire with "Mixed Emotions". Lower from the chains at the top.
        //   * Protection: 4 draws with chains at the top. This route can also be trad climbed using a crack within 
        //     reach to the left. Nuts to cams 2".
        // * [Just Jerry (S 5.9)](https://www.mountainproject.com/route/105736061/just-jerry) — Sport/TR, 40 ft (12m)
        //   * Protection: 5 bolts, 2 bolt anchor
        // * [Mixed Emotions (S/T 5.10a)](https://www.mountainproject.com/route/105736073/mixed-emotions) Sport/Trad, 70 ft (21 m) — highly rated
        //   * Mixed Emotions ascends the downhill (south) face of a pretty skinny spire about 150 left and uphill 
        //     from where you first arrive at the crag. The climb is easily recognized by the crack system that gives way to bolts about 40 feet off the deck.  

        //     The crack goes at about 5.10a and there's also a distinct face crux up high, making Mixed Emotions a good mixture of 10a fun.
        //   * Protection: 6 bolts, gear to 1.25", mussy hook anchors.
        //     * 9/2020: "Is anyone concerned that the anchors of this route are on what looks like a detached block? It looked pretty precarious from one side, but if it hasn't moved in years...."
        //   * 8/2022: "Feels pretty full-on 10a at the crack if you want to avoid the face holds. I used #2 and #0.4 down low and then a Blue and Black Totem a little higher. Two Blue Totems/0.3 pieces would have been a little better. Didn't really notice any moves above that were as hard as the crack. Certainly worth getting on."
        //   * 8/2010: "Good route for a first time 10a trad leader (which was me!)There are convenient holds on the face (one of them is a total jug) so you can rest and place gear but not enough to avoid the crack. Bring several small cams in the 0.6 inch range (yellow C3 to grey C4) to really protect the crack well. Once the bolts start, it's a smooth climb."
        //   * 4/2013: "Fun climb with two distinct cruxes: the crack and where the face steepens at the third bolt. Great climbing in a great position!"
        //   * 8/2009: "I'd say that this is a must do if you find yourself at Luther Spires. Lower crack takes great gear, Top bolted portion is fun and mellow, a nice summit as well."
        // `,
        //         },
        //       },
        {
          date: new Date('2023-07-17'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Lassen National Park',
            text: `
[Lassen Peak Trail](https://www.alltrails.com/explore/trail/us/california/lassen-peak)
`,
          },
        },

        {
          date: new Date('2023-07-24'),
          filterTags: ['outside', 'climbing'],
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'North Cascades: Liberty Bell — Beckey Route (T 5.6)',
            text: `
# [Beckey Route / SW Face (T 5.6)](https://www.mountainproject.com/route/105797867/beckey-route-sw-face)
Trad, Alpine, 500 ft (152 m), 4 pitches, Grade II

_(Information below copy-and-pasted from Mountain Project)_

## Approach

To reach the climb (~2.5mi), hike up the [Blue Lake Trail](https://www.alltrails.com/trail/us/washington/blue-lake-trail--7) 
until it branches off left on a climbers trail that was completed in 2019. Stay on the trail as 
it climbs up, even if it feels like you are going too far climbers right. 
At the fork in the trail ~ .5 mile up and almost to the base of the west side of Concord, 
bear left to hike under Concord to the gully separating Concord from Liberty Bell, 
called the Beckey Gully.  A short hike up the gully takes you to the prominent notch between 
Liberty Bell and Concord. This gully is loose and unpleasant, but better if you stay to climber's 
left until about 2/3 height, then cross and hug the climber's right-side of the gully to the top.

The start of the Beckey route is about 30' below the notch. Look for a small ledge leading 
left and traverse it to the base of a blocky chimney. This is the start of the first pitch.

> Here's a map/track for the approach to help you avoid drifting off the main trail (easier 
than you think) as you're groggy in the morning: [Caltopo](https://caltopo.com/m/R71AL)
the main trail veers off to climber's right (towards SEWS) before it heads back towards the 
Beckey Gully making wonder if you've taken a wrong turn. There are many social trails to lure you 
off the beaten path, too. Rangers are trying hard to restore this area and it really helps 
if you stay on the main trail. 

— Will N, Aug 30, 2021

## P1

Climb the chimney or the face to its left reaching a large ledge with a tree. 5.3

## P2

Continue up the chimney for about 30m, climbing past chockstones and blocky slabs until 
reaching obvious 3rd/4th class terrain. I think this pitch is the hardest of the climb. 
Belay from a tree or from gear wherever you find it convenient. 5.5

## P3

Ascend the 4th class terrain to a 6 foot finger-crack traverse past a large roof (climbing past a 
fixed pin) (5.5), then follow a dihedral back to the right and up onto the summit shoulder. 
Belay from a tree or from gear, use long slings and watch the rope drag.

## P4

Shortly after leaving the belay on the ridge to the summit, you'll encounter a slabby 5.6 boulder 
problem ~10' high. After surmounting this obstacle it is easy 3rd/4th class terrain to the summit. 
If you belay all of this, it will be more than one pitch to the top.

## Descent

Downclimb the ridge and the 5.6 boulder problem. Below the boulder problem follow an obvious, 
tree filled gully down to the east until you encouter the first set of rappel bolts. From here it 
is possible to reach the ground in 2 single rope rappels. After the first rappel, scramble down and 
skier's right to reach a large ledge with another set of bolted anchors. We used a 60M rope, I can't
say if a 50 would suffice.

Note: There is a rappel station just below the summit that you can use to avoid the boulder problem 
down-climb described above. From the summit go back the way you came up, but veer to skiers' 
left and look for a groove in the rock. Take caution on slabby down climb and you'll see a couple 
slings wrapped around a tree with double rings. Note: A 60-meter rope will make it down to the large 
ledge below. The next rappel station is a skier's right down-climb scramble, leading you to a cliff; 
look right, you'll see a rock ledge and bolts on a wall. From those bolts on rappel trend skier's 
right towards a large tree with a small platform next to it. The last set of bolts will be on the 
wall skier's left of the tree. The 60-m rope is more than enough to drop you in the gully where you 
started.

## Relevant Links
* [mount stuart climb project — beckey route](http://cnmtstuartclimbproject.blogspot.com/2017/10/route-liberty-bell-beckey-route-sw-face.html)
`,
          },
        },

      ],
    }],
}

export default addCounts(vplan);
