import React, { ReactElement } from 'react';
import './App.css';

type AppProps = {
  children: ReactElement,
}

function App({ children }: AppProps) {
  return (
    <div className="App">
      {children}
    </div>
  );
}

export default App;
