import React from 'react';

import { itemType } from '../../../types';
import './ProgressCounter.css';

const DEFAULT_NUMBER_OF_MARKERS = 12;
const PROGRESS_MARKER = '.';

export enum ProgressCounterType {
  LONG = 1,
  SHORT = 2,
}

type ProgressCounterProps = {
  goal: number,
  completed: number,
  type: itemType,
  progressCounterType: ProgressCounterType,
  prefixText?: string,
}

function ProgressCounter({ goal, completed, type, progressCounterType, prefixText }: ProgressCounterProps) {
  const className = {
    [itemType.FILM]: 'ProgressCounter-film',
    [itemType.BOOK]: 'ProgressCounter-book',
    [itemType.POST]: '',
    [itemType.CLIMB]: '',
  }[type];

  const defaultNumberOfMarkers = {
    [ProgressCounterType.LONG]: DEFAULT_NUMBER_OF_MARKERS * 4,
    [ProgressCounterType.SHORT]: DEFAULT_NUMBER_OF_MARKERS,
  }[progressCounterType];

  // Render `defaultNumberOfMarkers` markers, unless the goal number is "close enough" to the
  // default that it makes sense to just render each item as one marker. ("Close enough" means that
  // the goal is between 50% and 170% of the default number (e.g. if DEFAULT_NUMBER_OF_MARKERS === 12,
  // between 6 and 20 inclusive).)
  const numTotalMarkers = ((goal / defaultNumberOfMarkers) < 1.7 && (goal / defaultNumberOfMarkers) > 0.5) ? goal : defaultNumberOfMarkers;

  // Calculate the number of markers that our actual progress computes to, rounding down if
  // fractional (solve for x in completed/goal = x/numTotalMarkers).
  const numCompletedMarkers = Math.floor(numTotalMarkers * completed / goal);

  return (
    <>
      <div className="ProgressCounter">
        {progressCounterType === ProgressCounterType.LONG ?
        <div>{prefixText && prefixText}{completed} of {goal}</div> :
        <div>
          {prefixText && prefixText}<span className={className}>{completed}</span> of <span className={className}>{goal}</span>
        </div>}
        {numCompletedMarkers > numTotalMarkers ?
        // If we've exceeded our goal, render progress marker something like ----------|---
        <>
          <span className={progressCounterType === ProgressCounterType.SHORT  ? className : undefined}>
            {PROGRESS_MARKER.repeat(numCompletedMarkers)}
          </span>
          |
          {[...Array(numCompletedMarkers - numTotalMarkers)].map((_, i) =>
            <span className={progressCounterType === ProgressCounterType.SHORT ? className : undefined}>
              {PROGRESS_MARKER}
            </span>
          )}
        </> :
        // If we haven't yet exceeded our goal, render progress marker something like -------   |
        <>
          {[...Array(numCompletedMarkers)].map((_, i) => 
            <span key={i} className={progressCounterType === ProgressCounterType.SHORT  ? className : undefined}>
              {PROGRESS_MARKER}
            </span>
          )}
          {[...Array(numTotalMarkers - numCompletedMarkers)].map((_, j) => <span key={j}>&nbsp;</span>)}
          |
        </>}
      </div>
    </>
  );
}

export default ProgressCounter;
