import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { person, event, eventList, filterCategory } from '../../../types';
import './Menu.css';

export const MOBILE_BREAKPOINT = 600;

type MenuProps = {
  person: person,
  category: filterCategory,
  year: number | null,
  tag: string | null,
}

function Menu({ person, category, year, tag }: MenuProps) {
  const { identifier: urlIdentifier } = useParams<{ identifier: string }>();

  const [ open, setOpen ] = useState(window.innerWidth >= MOBILE_BREAKPOINT);
  const [ width, setWidth ] = useState(window.innerWidth);
  const [ tagsExpanded, setTagsExpanded ] = useState(tag ? true : false);

  useEffect(() => {
    const listener = () => setWidth(window.innerWidth);
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    if (width >= MOBILE_BREAKPOINT) {
      setOpen(true);
    }
  }, [width]);

  const postTags = person.eventLists.reduce<string[]>((pts, list: eventList) => {
    list.events.forEach((ev: event) => {
      if (ev.filterTags) {
        pts = pts.concat(ev.filterTags);
      }
    });
    return pts;
  }, []).filter((v, i, a) => a.indexOf(v) === i).sort();

  return (
    <>
      <nav className="Menu">
        {!open &&
          <div className="Menu-hamburgerDiv" onClick={() => setOpen(true)}>
            —<br />
            —<br />
            —<br />
          </div>}
        {open && (
          <>
            {(width < MOBILE_BREAKPOINT) && <div className="Menu-closeHamburgerDiv" onClick={() => setOpen(false)}>×</div>}
            {postTags.length > 0 ?
              <>
                {tagsExpanded ?
                  <>
                    <div className={!tag ? 'Menu-selected' : 'Menu-deselected'}
                         onClick={() => window.location.replace(`/${urlIdentifier}`)}>all</div>
                    {postTags.map((postTag: string) => {
                      return (
                        <div
                          key={postTag}
                          className={tag === postTag ? 'Menu-selected' : 'Menu-deselected'}
                          onClick={() => {
                            if (tag === postTag) {
                              window.location.replace(`/${urlIdentifier}`);
                            } else {
                              window.location.replace(`/${urlIdentifier}/tag/${postTag}`);
                            }
                          }}>
                          {postTag}
                        </div>);
                    })}
                    <div className="Menu-expandTagsDiv" onClick={() => setTagsExpanded(false)}>↓</div>
                    <br />
                  </> :
                  <>
                    <div className="Menu-expandTagsDiv" onClick={() => setTagsExpanded(true)}>↑</div>
                    <br />
                  </>}
              </> :
              <>—<br /></>
            }
            <div
              className={category === filterCategory.ALL ? 'Menu-selected' : 'Menu-deselected'}
              onClick={() => window.location.replace(`/${urlIdentifier}/category/all`)}>all</div>
            <div
              className={category === filterCategory.BOOKS ? 'Menu-selected' : 'Menu-deselected'}
              onClick={() => window.location.replace(`/${urlIdentifier}/category/books`)}>
              <span className="Menu-bookDash">&#x2022;</span> books read</div>
            <div
              className={category === filterCategory.FILMS ? 'Menu-selected' : 'Menu-deselected'}
              onClick={() => window.location.replace(`/${urlIdentifier}/category/films`)}>
              <span className="Menu-filmDash">&#x2022;</span> films watched</div>
            <div
              className={category === filterCategory.CLIMBS ? 'Menu-selected' : 'Menu-deselected'}
              onClick={() => window.location.replace(`/${urlIdentifier}/category/climbs`)}>
              <span className="Menu-climbDash">&#x2022;</span> climbs climbed</div>
            <div
              className={category === filterCategory.OTHER ? 'Menu-selected' : 'Menu-deselected'}
              onClick={() => window.location.replace(`/${urlIdentifier}/category/other`)}>other</div>
            <br />
            —<br />
            {person.eventLists.map((list) => {
              return (
                <div
                  key={list.year}
                  className={year === list.year ? 'Menu-selected' : 'Menu-deselected'}
                  onClick={() => {
                    if (year === list.year) {
                      window.location.replace(`/${urlIdentifier}`);
                    } else {
                      window.location.replace(`/${urlIdentifier}/year/${list.year}`)
                    }
                  }}>
                  {list.year}
                </div>
              );
            })}
          </>)}
      </nav>
    </>
  );
}

export default Menu;
