import { climbType, itemType, person } from '../types';
import { addCounts } from '../utils';

const v: person = {
  handle: 'v',
  eventLists: [
    {
      year: 2024,
      goals: {
        books: 15,
      },
      events: [
        {
          date: new Date('2024-07-23'),
          endDate: new Date('2024-07-26'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Desolation Wilderness: Backpacking',
            text: `
<!--<img width="100%" src="/img/2024-07-23-desolation/actual-map.png" alt="desolation">-->

_4 days, 28.43 mi, +6225, -6227_

# Day 1 — Tuesday, July 23
* Start from Twin Lakes Trailhead (Wright's Lake)
* On-trail backpack past Twin Lakes to Island Lake. Off-trail around north side of Island Lake 
  to east side, just under the Island-Clyde pass.
  Took a long time to find a camp site;  set up camp by waterfall 8200'
* After setting up camp, went on off-trail day hike to find "Shangri-La"; located the photographed spot
  by going up to the ridge between Island Lake and Twin Lakes; continuing up this way 
  would get you to Mt. Price.
* _4.7 mi, +1588', -350'_

# Day 2 — Wednesday, July 24
* Backpacked off-trail over the ridge/pass east of Island Lake up a class 2 boulder gully _(+636 over 0.3 mi)_, down to Clyde Lake _(-810 over 0.6 mi)_
  (from west side of Crystal Range to east side).
  Originally planned to stay up close to the ridge line and traverse over to Mosquito Pass without dropping
  down, but the traverse looked too harrowing. Dropping down to Clyde was annoying and bad for ankles; slippery
  grass that obscured holes on the ground, boulder hopping.
* Met trail north of Clyde Lake that goes up Mosquito Pass over to Lake Aloha (very nice). 
  Met PCT and took that past Heather Lake to Susie Lake; camped at Susie Lake, 7800'.
* _4.86 mi, +1161', -1567'_

# Day 3 — Thursday, July 25
* Backpacked on-trail (PCT) from Susie Lake past Gilmore Lake (water stop), Half Moon Lake, 
  up through Dick's Pass (+1696' over 3.6 mi),
  down to Dicks Lake, Fontanillis Lake, Upper Velma Lake. Between Upper Velma and Middle Velma, got off PCT
  onto westward trail down to the 'valley' (_-2192' over 5.7 mi_)
* Then took southward trail that parallels the Rubicon River
  up towards Rockbound Pass. Camped at Lake Doris 8350'. (_+1175 over 3.6 mi_)
* _13.31 mi, +3022', -2481'_

# Day 4 — Friday, July 26
* Continued on-trail backpack up towards Rockbound Pass from Lake Doris (just +200), then
  down back to the west side of the Crystal Range, past Maud Lake (water), to meet short trail back to 
  Wright's Lake/Twin Lakes TH.
* _5.56 mi, +454', -1829'_
`,
            frames: [
              '2024-07-23-desolation/2024-07-23-desolation-01',
              '2024-07-23-desolation/2024-07-23-desolation-02',
              '2024-07-23-desolation/2024-07-23-desolation-03',
              '2024-07-23-desolation/2024-07-23-desolation-04',
              '2024-07-23-desolation/2024-07-23-desolation-06',
              '2024-07-23-desolation/2024-07-23-desolation-07',
              '2024-07-23-desolation/2024-07-23-desolation-08',
              '2024-07-23-desolation/2024-07-23-desolation-09',
              '2024-07-23-desolation/2024-07-23-desolation-10',
              '2024-07-23-desolation/2024-07-23-desolation-13',
            ],
          },
        },
        {
          date: new Date('2024-07-22'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.CLIMB,
            title: "Knapsack Crack",
            gradeYDS: '5.5',
            area: 'Lover\'s Leap',
            crag: 'Hogsback',
            climbType: climbType.TRAD,
            lengthFt: 300,
            numPitches: 3,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105812282/knapsack-crack',
            text: `
First time at Lover's Leap b/c scared of snakes (did not see any snakes).
Free parking down the road was pretty full when we arrived (got last spot). The actual paid parking
area (where they don't accept the National Park Pass) was quite empty.

Approach over/up to Hogsback wasn't bad except that it was _v hot_, but descent down to the base of the climb after
crossing over the top of Hogsback was talus-y. 
Not good for M's recovering ankle.

Good first route to get back into climbing. (This route is way better than 
Sunnyside Bench at the same grade; would be a much better first lead; not awkward, straightforward routefinding, still some non-trivial and 
interesting climbing.)

M led half of first pitch, but ankle was not doing well after the approach, so came down. V led
the rest, was very slow at building anchors (despite all tree anchors!) 
and transitioning (gloves, pulling rope, setting up belay device). Although slow, better at: (1) setting up based on 
which direction climber is coming/leaving from (2) making sure anchor is at the right height 
for comfortable belay w/ figure 9, and positioning for direction of pull from climber (3) making sure
to belay from the actual end of the last pitch b/c of what happened on Sunnyside Bench where there was some
unnecessary exposed scrambling at the end.

Had to conserve water b/c it was so hot. Stashed water bottles in car were very nice. We were the only
car left where we parked when we got back around early afternoon. General store did not have
lemonade.`,
            frames: [
              '2024-07-22-loversleap/knapsack-crack-00',
              '2024-07-22-loversleap/knapsack-crack-01',
              '2024-07-22-loversleap/knapsack-crack-02',
              '2024-07-22-loversleap/knapsack-crack-03',
              '2024-07-22-loversleap/knapsack-crack-04',
              '2024-07-22-loversleap/knapsack-crack-05',
            ],
          },
        },

        {
          date: new Date('2024-07-16'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'The Burnout Society',
            year: 2010,
            author: 'Byung-Chul Han',
            inProgress: true,
            inProgressCurrentPage: 191,
            inProgressTotalPage: 816,
            text:`
<div style="text-align:right;">
&#x2022; <i>Neuronal Power</i></br>
&#x2022; <i>Beyond Disciplinary Society</i><br/>
</div>`,
          },
        },
        // {
        //   date: new Date('2024-07-15'),
        //   showCollapseText: true,
        //   textCollapsedOnInit: true,
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Slouching Towards Bethlehem',
        //     year: 1968,
        //     author: 'Joan Didion',
        //     inProgress: true,
        //     inProgressCurrentPage: 6,
        //     inProgressTotalPage: 260,
        //   },
        // },
        {
          date: new Date('2024-07-12'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'In Search of Lost Time, Vol. 3: The Guermantes Way',
            year: 1920,
            author: 'Marcel Proust',
            inProgress: true,
            inProgressCurrentPage: 110,
            inProgressTotalPage: 819,
            // Note: this entry is duplicated at '2023-07-04' from when I began this book
            // but the year boundary makes this spaced out weirdly if I move it up here to where
            // actively being-read books are. Once book is finished, can add end date to that entry
            // if want, or just keep this one since this is almost a full re-read and the time
            // in between is mostly not reading this book. Before, only got started with Francoise at new house.
            text: `<div style="text-align:right;">
<sub>French: <i>Le Côté de Guermantes</i></br>
Translated by C.K. Scott Moncrieff and Terence Kilmartin. Revised by D.J. Enright; Modern Library, 2003</sub>
</div>`,
          },
        },
        {
          date: new Date('2024-07-06'),
          endDate: new Date('2024-07-10'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'Anti-Intellectualism in American Life',
            year: 1963,
            author: 'Richard Hofstadter',
            inProgress: false,
            inProgressCurrentChapter: 3,
            inProgressTotalChapter: 15,
            text: ``,
          },
        },
        {
          date: new Date('2024-07-05'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'The White Album',
            year: 1979,
            author: 'Joan Didion',
            text:`
<div style="text-align:right;">
&#x2022; <i>The White Album</i>, 1968-1978</br>
&#x2022; <i>The Getty</i>, 1977<br/>
&#x2022; <i>Notes Toward a Dreampolitik</i>, 1968-1970</br>
&#x2022; <i>At the Dam</i>, 1970</br>
&#x2022; <i>On the Morning After the Sixties</i>, 1970
</div>`,
          },
        },
//         {
//           date: new Date('2024-06-15'),
//           filterTags: ['philosophy'],
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           showCollapseFrames: true,
//           framesCollapsedOnInit: false,
//           useSerif: true,
//           item: {
//             type: itemType.POST,
//             title: '[nietzche] the gay science',
//             text: `
// ## Book I — Aphorism #2
// > _Intellectual conscience._ – I keep having the same experience and keep resisting it anew each time; 
// I do not want to believe it although I can grasp it as with my hands: the great majority lacks an 
// intellectual conscience – indeed, it has often seemed to me as if someone requiring such a conscience 
// would be as lonely in the most densely populated cities as he would be in the desert. 
// Everyone looks at you with strange eyes and goes on handling their scales, calling this good and that evil; 
// nobody as much as blushes when you notice that their weights are underweight – nor do they become 
// indignant with you; perhaps they laugh at your doubts. I mean: to the great majority it is not 
// contemptible to believe this or that and to live accordingly without first becoming aware of the 
// final and most certain reasons pro and con, and without even troubling themselves about such reasons afterwards: 
// the most gifted men and the noblest women still belong to this ‘great majority’. 
// But what are goodheartedness, refinement, and genius to me when the person possessing these virtues 
// tolerates slack feelings in his believing and judging and when he does not consider “craving and deepest need – 
// as that which separates the higher human beings from the lower! I discovered in certain pious 
// people a hatred of reason and I was well disposed towards them for that: at least this betrayed their 
// bad intellectual conscience! But to stand in the midst of this _rerum concordia discors_<sup>2</sup> and the 
// whole marvellous uncertainty and ambiguity of existence without questioning, without trembling with 
// the craving and rapture of questioning, without at least hating the person who questions, perhaps 
// even being faintly amused by him – that is what I feel to be contemptible, and this is the feeling 
// I look for first in anyone. Some folly keeps persuading me that every person has this feeling, 
// simply as human. That is my type of injustice.

// <sub><sup>2</sup> the discordant harmony of things’; from Horace, Epistles 1.12.19</sub>


// ## Book III — Aphorism #125

// <sub>Translation by Josephine Nauckhoff. Cambridge University Press, 2001, trans. Nauckhoff</sub>
// `,
//             frames: [],
//           },
//         },
        {
          date: new Date('2024-06-15'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'The Gay Science',
            year: 1887,
            author: 'Friedrich Nietzche',
            inProgress: true,
            inProgressCurrentPage: 92,
            inProgressTotalPage: 248,
            text: `
German: <b><i>Die fröhliche Wissenschaft</i></b></br>
Published in <b>1882</b>: Book I-IV</br>
Published in <b>1887</b>: 10-page preface, Book V, Appendix of Songs</br>

&#x2022; Cambridge University Press, 2001, translation by Josefine Nauckhoff</br>
&#x2022; Vintage, 1974, translation by Walter Kaufmann</br>
(Mostly reading Nauckhoff, but occasionally referring to Kaufmann esp for footnotes/commentary.)
`,
          },
        },
        {
          date: new Date('2024-05-21'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Munginella',
            gradeYDS: '5.6',
            // stars: 4,
            area: 'Yosemite National Park',
            crag: 'Five Opens Books',
            climbType: climbType.TRAD,
            lengthFt: 300,
            numPitches: 3,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105867008/munginella',
            text:`
* Approach: Nice that there's a climber's trail with switchbacks but I found it tiring. 
  Not a fan of the short scramble up to the start. Not a problem for M. V is scared of unroped 
  (and roped) exposure of any kind.
* V led P1 to the tree. Thought it was very nice esp. compared to Sunnyside Bench, which is mostly awkward.
  Slightly anxious but thought it was a nice pitch that built confidence.
* M led up P2; started w/ ease up pitch, then was lured by an 'unprotectable face' up/right 
  (as described in some beta) with a risky and unideally protected move (instead of a finger crack going up on climber's left.) 
  Resulted in a bad ledge fall: hit/scraped chin and face and fractured heel. 
  Was also flipped upside/sideways in harness due to new too-big harness.  M attempted to continue up the pitch, 
  which resulted in ligament tears on both sides of ankle. Decided to lower back down to
  belay tree, then rappel P1 from there. Thanks blue totem. More tearing on the way down. 
  At base of the climb, there arrived several
  parties that helped provide medical tape, ankle wrapping, ibuprofen, rigging to get down from the scrambly 
  part of the approach, and large sticks to use as crutches for the descent. M did a _very_ good descent
  down the climber's trail, then through the valley to the car. People were the nicest we've ever 
  witnessed in Yosemite NP. (V was not very helpful.)
* V lessons: I'm often paying attention to make sure not to short-rope especially 
  on low-angle terrain where climber is often moving really fast in bursts. 
  The move in question was kind of bouldery/mantle-y and I thought
  there needed to be ample slack in order to do a dynamic move up and over the boulder (which is prob still true?),
  but I wasn't paying attention to ledge fall at all. Should've been looking out for ledge fall and
  spotted/encouraged looking for an alt route. For a move that required "going for it", I should've
  been aware of the fall line. Also should be better/faster at feeding slack and being ready to pull in quickly.`,
            frames: [
              '2024-05-21-yosemite-munginella/munginella-01',
              '2024-05-21-yosemite-munginella/munginella-05', // start
              '2024-05-21-yosemite-munginella/munginella-06', // first pitch beginning
              '2024-05-21-yosemite-munginella/munginella-08', // second pitch beginning
              '2024-05-21-yosemite-munginella/munginella-09',
              '2024-05-21-yosemite-munginella/munginella-10',
              '2024-05-21-yosemite-munginella/munginella-11',
              '2024-05-21-yosemite-munginella/munginella-12', // waterfall photo
            ],

          },
        },
        {
          date: new Date('2024-05-20'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Sunnyside Bench Regular Route',
            gradeYDS: '5.5',
            // stars: 4,
            area: 'Yosemite National Park',
            crag: 'Sunnyside Bench',
            climbType: climbType.TRAD,
            lengthFt: 400,
            numPitches: 4,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106561511/sunnyside-bench-regular-route',
            text:`
Came to redeem ourselves after epic-ing on this route last year (Last time: V got off-course on P2/P3.
It was our first multipitch / first real trad lead besides doing pine line 3 times. Ended up rappelling down
with the help of some nice climbers, then M led P3 and P4 in one long pitch.)

* V led P1 to first tree. Took a long time to rig the tree belay; couldn't find great spot for backup piece and
  took a long time to get the length of the anchor right.
* M led short P2 to junction tree.
* V was supposed to lead P3 for redemption reasons, but chickened out (got lost last time and then 
  followed the rest in dazed state); M led P3.
* V led P4. Belayed from small Manzanita up and right from the left ledge with the big Pine Tree. 
  Should've kept going up onto the sandy terrace and belayed from the large tree up there right by the trail.
            `,
            frames: [
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-03',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-05',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-08',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-09',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-10',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-11',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-12',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-13',
            ],
          },
        },
        {
          date: new Date('2024-05-19'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Mockery",
            gradeYDS: '5.8',
            area: 'Yosemite National Park',
            crag: 'Five and Dime',
            climbType: climbType.SPORT,
            lengthFt: 70,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105974243/mockery',
            text: `
  * Stick-clipped first bolt. Too scary to reach 2nd bolt. Stick clipped second bolt.
  * Third bolt required going up and pulling on side pull up to a traverse with big knobs for feet. Scary! Decided to bolt-to-bolt stick-clip rest of route. 
  * After the traverse (pretty cool), there are two horizontal ledge thingys to pull/mantle over.
  * Then route goes left up a more regular-ish face. I think, stick-clipped the first bolt that was up and left. A bit of a getting-around-bulge move?
  * I think the climbing looked easier here (after bulge) so decided to lead to one of the bolts without stick-clipping; got the draw on, but felt insecure while
    clipping the rope b/c the bolt was off the left and my stance was not great (and it took a while to get the rope on). 
    Too scared to do another bolt; stick-clipped the rest...two more bolts maybe? before reaching mussys.
  * Forgot the top-rope sling anchor. Set up top-rope using two quickdraws.
  * SO MANY Mosquitoes 
            `,
            frames: [
              '2024-05-19-yosemite-mockery/mockery-01',
              '2024-05-19-yosemite-mockery/mockery-02',
              '2024-05-19-yosemite-mockery/mockery-03',
              '2024-05-19-yosemite-mockery/mockery-04',
            ],
          },
        },
        {
          date: new Date('2024-05-19'),
          endDate: new Date('2024-05-21'),
          filterTags: ['outside', 'yosemite'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Yosemite National Park: Sunnyside Bench, Munginella',
            text: `
# Day 1 — Sunday, May 19
Arrived from LA. Got to McDonald's 20 min before lunch. Downloaded the app; it had a free Big 
Mac for lunch; waited for lunch. Waited in line at the Yosemite entrance for 2 hours.
Set up tent in the hot at [Upper Pines Campground](https://www.recreation.gov/camping/campgrounds/232447).
Went to climb [Mockery](https://www.mountainproject.com/route/105974243/mockery). There were a lot
of Mosquitoes. The climb was too hard; stick-clipped it.

  
# Day 2 — Monday, May 20
Packed up camp. Climbed [Sunnyside Bench](https://www.mountainproject.com/route/106561511/sunnyside-bench-regular-route).
No issues this time (except V was chicken). Set up camp at Upper Pines again (at a different site).
Hiked to [Sentinel Dome](https://www.alltrails.com/trail/us/california/sentinel-dome-trail) where it was chilly.

# Day 3 - Tuesday, May 21
Climbed [Munginella](https://www.mountainproject.com/route/105867008/munginella). 
Tragedy ensued but very good self-rescue by M. Pack up camp to go home.
`,
            frames: [
              '2024-05-20-yosemite/yosemite-01',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-01',
              '2024-05-20-yosemite-sunnysidebench/sunnyside-bench-02',
              '2024-05-20-yosemite/yosemite-02',
              '2024-05-20-yosemite/yosemite-03',
              '2024-05-20-yosemite/yosemite-04',
              '2024-05-20-yosemite/yosemite-05',
              '2024-05-21-yosemite-munginella/munginella-12',
            ],
          },
        },
        {
          date: new Date('2023-07-16'),
          endDate: new Date('2024-05-17'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'War and Peace',
            year: 1869,
            author: 'Leo Tolstoy',
            // inProgress: true,
            // inProgressCurrentPage: 1317,
            // inProgressTotalPage: 1358,
            text: `<div style="text-align:right;">
<sub>Translated by Anthony Briggs. Penguin Classics, 2017</sub>
</div>

### War and Peace

* [YT:TED-Ed — Why should you read Tolstoy's "War and Peace"?](https://www.youtube.com/watch?v=4dn7TEjnbPY)

### Epilogue 2

Got to Epilogue 2 late April 2024 but got stuck on it, re-reading/skimming it continuously, for the whole month.
It's repetitive and ranty and mostly reiterates the interspersed treatises in the novel portion; Napoleon and Alexander's role
in the history books is overblown and that historians attribute things to a few powerful individuals when in actuality,
things happen as a culmination of many actions of many individuals that all come together in a sort of random way,
and it is only hindsight that gives us false cause-and-effect analyses of events.

<details>

<summary><strong>Prompt (GPT-4o)</strong>: <i>Can you please summarize Epilogue 2 of War and Peace for me? It is so confusing!</i></summary>
<div style="margin-left: 30px">

Certainly! Epilogue 2 of **"War and Peace"** by Leo Tolstoy is a philosophical conclusion to the novel, distinct from the main storyline's resolution. Here’s a summary:

### Themes Explored:
1. **Historical Determinism**: Tolstoy delves into the nature of history and the forces that drive historical events. He challenges the idea that history is shaped solely by the actions of great individuals (such as Napoleon or Alexander I), arguing instead that history is the result of many interconnected factors and the collective actions of people.

2. **The Role of Free Will**: He questions the concept of free will, suggesting that while individuals may feel they are making independent choices, their actions are influenced by a multitude of external forces and circumstances beyond their control.

3. **The Inevitability of Events**: Tolstoy proposes that events in history are inevitable and are part of a larger, incomprehensible scheme. He uses mathematical and scientific analogies to illustrate how individual actions combine to produce historical outcomes.

### Key Points:
- **Historiography**: Tolstoy criticizes historians who attribute historical events to the will and actions of a few key figures, arguing that this oversimplifies the complexity of historical developments.
- **Philosophical Reflections**: He reflects on the nature of power and leadership, suggesting that those in positions of power are often carried along by the tide of events rather than shaping them.
- **Human Experience**: The epilogue emphasizes the interconnectedness of human experiences and the idea that individual lives and decisions are part of a larger, deterministic flow of history.

### Conclusion:
Epilogue 2 serves as a meditation on the philosophy of history and the limitations of human understanding. Tolstoy seeks to provide a broader perspective on the events described in the novel, emphasizing the complexity and unpredictability of life and history. The epilogue underscores the novel’s overarching themes of fate, free will, and the human condition.

In essence, while the main story of "War and Peace" concludes with the characters' personal resolutions, Epilogue 2 shifts focus to a broader, more philosophical discussion, leaving readers with a deeper reflection on the nature of history and human existence.

</div>
</details>

### Chris McCandless / Pierre Bezukhov
`,
          },
        },
        {
          date: new Date('2024-04-04'),
          endDate: new Date('2024-05-16'),
          item: {
            type: itemType.BOOK,
            title: 'Into the Wild',
            year: 1996,
            author: 'Jon Krakauer',
          },
        },
        {
          date: new Date('2024-05-11'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Sea of Troubles",
            gradeYDS: '5.9',
            area: 'Red Rock Canyon',
            crag: 'Calico I > Hamlet',
            climbType: climbType.SPORT,
            lengthFt: 60,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106608213/sea-of-troubles',
            text: `
Red Rock Canyon > Calico I (1st pullout) > Hamlet

* V started leading 7am
* 7:20am, just below fall (took 20 min to get from start to just before)
* Fell around 7:22a onto 3rd bolt from above the 4th bolt
  * Almost crashed into ledge. Hard catch. Foot hit ledge mildy-hard but safe. Very good catch by M. M saw stars. Decided another lead fall soon is not okay. A good big fall and no danger.
  * Stick clipped up the rest, trouble untying knot (1h 15)
  * Lowered and left the quick draws as back-up
* M top-roped (30 min to climb and clean (quick draws were making rope rub against rock) and re-rig anchor)
* V top-roped (15 min to climb, 10 min to remove quickdraw anchor)
`,
            frames: [
              '2024-05-08-red-rocks/redrocks-16',
              '2024-05-08-red-rocks/redrocks-17',
            ],
          },
        },
        {
          date: new Date('2024-05-10'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Sacred Undergarment Squeeze Job",
            gradeYDS: '5.8',
            area: 'Red Rock Canyon',
            crag: 'Calico I > Panty Wall',
            climbType: climbType.SPORT,
            lengthFt: 70,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105903232/sacred-undergarment-squeeze-job',
            text: `
Similar but different to [Brief Encounter](https://www.mountainproject.com/route/105813275/brief-encounter) (yesterday)
**M** led: _22 min b2b_
**V** led: _24 min b2b_
`,
            frames: [
              '2024-05-08-red-rocks/redrocks-12',
              '2024-05-08-red-rocks/redrocks-13',
              '2024-05-08-red-rocks/redrocks-14',
              '2024-05-08-red-rocks/redrocks-15',
            ],
          },
        },
        {
          date: new Date('2024-05-09'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Haunted House",
            gradeYDS: '5.7',
            area: 'Red Rock Canyon',
            crag: 'Calico I > Amusement Park',
            climbType: climbType.SPORT,
            lengthFt: 35,
            mountainProjectUrl: 'https://www.mountainproject.com/route/108563805/haunted-house',
            text: `
**Approach:** Hamlet plus ramp toward Panty Wall part way, but cut right and down to white section that goes all the way down. 25 min.

Haunted House 5.7

M climbed to the first bolt, overheating, not feeling good, asked me to go first.
**V** led: _24 min b2b_
**M** led: _18 min b2b_
                    
            `,
            frames: [
              '2024-05-08-red-rocks/redrocks-08',
              '2024-05-08-red-rocks/redrocks-09',
              '2024-05-08-red-rocks/redrocks-10',
            ],
          },
        },
        {
          date: new Date('2024-05-09'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Brief Encounter",
            gradeYDS: '5.8',
            area: 'Red Rock Canyon',
            crag: 'Calico I > Panty Wall',
            climbType: climbType.SPORT,
            lengthFt: 60,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105813275/brief-encounter',
            text: `
Red Rock Canyon > Calico I (1st pullout) > Panty Wall

* Good exposure practice. First time we've started so high and more exposed than other stuff we've done.
* M went first b/c V whined. M had fun and now understands that going first is way more fun.
* Getting shoes on, stick clipping, cloving belayer in to first bolt: 15 min.
* M led: 15 min bottom to bottom.
* Change shoes: 10-15min, re-stick-clip first bolt
* V toproped: 18 min, bottom to bottom.
* V led: 17 min bottom to bottom.
`,
            frames: [
              '2024-05-08-red-rocks/redrocks-05-brief-encounter',
              '2024-05-08-red-rocks/redrocks-06',
            ],
          },
        },
        {
          date: new Date('2024-05-08'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Crack of Noon",
            gradeYDS: '5.8',
            area: 'Red Rock Canyon',
            crag: 'Sandstone Quarry > Wake Up Wall',
            climbType: climbType.SPORT,
            lengthFt: 40,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105885143/crack-of-noon',
            text: `
Red Rock Canyon > Calico Hills - Sandstone Quarry (3rd pull out) > Wake Up Wall

**Protection**: Guidebook says this is a trad route, but it's since been [tighty] bolted. Mussy hooks.

Stick-clipped first bolt.

Easier than Poundcake b/c it didn't have the more vertical slab section at the bottom. Was easy slab the whole way up.

**V**: Clean lead, 22 min bottom to bottom.

**M**: Clean lead, 13 min bottom to bottom.

Started getting more windy, which was uncomfortable/scary; decided to leave Wake Up Wall`,
          },
        },
        {
          date: new Date('2024-05-08'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Poundcake",
            gradeYDS: '5.8',
            area: 'Red Rock Canyon',
            crag: 'Sandstone Quarry > Wake Up Wall',
            climbType: climbType.SPORT,
            lengthFt: 40,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106538886/poundcake',
            text: `
Red Rock Canyon > Calico Hills - Sandstone Quarry (3rd pull out) > Wake Up Wall

**Approach**: Easy. Follows the main trail (Calico Tanks and Turtlehead Peak), turn left onto Turtlehead Peak Trail,
when Turtlehead Peak Trail bends right, just keep going left and then you're there.

**Protection**: Guidebook says 3 bolts, but there are at least 4 (maybe 5, not sure); tightly bolted either way. 
Mussy hooks.

Stick-clipped high first bolt.

**V**: I climbed first, hung 3 or 4 times. First time climbing in a while. Slabs scary.
Not really in the mindset of sending, just getting to the top. 
Pulled on first and second quick draws. _39 minutes from bottom to bottom._

**M:** Was scared of leading so kept top-roping section between 0 and 2nd bolt, until some nice Canadians came by
and said 'don't be scared', then stopped being scared and sent the whole thing clean. _14 minutes from bottom to bottom._

Started getting windy.`,
            frames: [
              '2024-05-08-red-rocks/redrocks-01-poundcake',
            ],
          },
        },
        {
          date: new Date('2024-05-08'),
          endDate: new Date('2024-05-11'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Red Rock Canyon',
            text: `

<details>
<summary>Red Rock Canyon — Scenic Drive Map</summary>
<a href="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/scenic_drive_map.png"><img src="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/scenic_drive_map.png" width="95%" /></a>
</details>

# Day 1 — Wednesday, May 8
* [Sandstone Quarry](https://www.mountainproject.com/area/105731956/sandstone-quarry) (3rd pull out)
  * Approached [Man's Best Friend](https://www.mountainproject.com/route/106070440/mans-best-friend) 5.7 S, 2 pitches
    * **Approach:** Somewhat easy to spot, but requires a rappel into the route. 
      Can see the wall easily from the parking lot. 
      Climbed up onto the formation to right of the parking lot. 
      Had to climb up and found the hidden rappel rings that let you rappel down _into_ the route.
      It was not obvious how you would climb back up; possibly just scramble up close to the rappel rings.

      Didn't have enough time to climb before it got sunny (in an hour); suspected we couldn't do two pitches in that time.
  * Approached and climbed single-pitch sport at [Wake Up Wall](https://www.mountainproject.com/area/122157961/wake-up-wall-area)

    **Approach:** Easy. Follows the main trail (Calico Tanks and Turtlehead Peak), turn left onto Turtlehead Peak Trail, when Turtlehead Peak Trail bends right, just keep going left and then you're there.

    * [Poundcake](https://www.mountainproject.com/route/106538886/poundcake) 5.8 S
    * [Crack of Noon](https://www.mountainproject.com/route/105885143/crack-of-noon) 5.8 S
* Set up camp at [Red Rock Canyon Campground](https://www.recreation.gov/camping/campgrounds/250877)
* [Calico Basin](https://www.mountainproject.com/area/105731977/calico-basin) - Red Springs Picnic Area (outside of scenic loop)
  * Approached [Riding Hood Wall](https://www.mountainproject.com/area/105732012/riding-hood-wall) and scoped [Big Bad Wolf](https://www.mountainproject.com/route/107339211/big-bad-wolf) (5.9 S, 4 pitches) and [Physical Graffiti](https://www.mountainproject.com/route/105732266/physical-graffiti) (5.6 T, 2 pitches)
    * **Approach**: Calico Basin Trail past Cannibal Crag (was pretty busy) about .6 miles, then cut left onto a set of ramps/gullys to get to Riding Hood Wall. Route-finding was non-trivial (but the scrambling was easy.)
    * There were two parties on Big Bad Wolf at 5:40pm. We touched Physical Graffiti but didn't learn about the descent, because everybody rappelled off of Big Bad Wolf.

      <a href="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-02-lg.jpg"><img src="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-02-sm.jpg" width="95%" /></a>
      <a href="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-03-lg.jpg"><img src="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-03-sm.jpg" width="95%" /></a>
      <a href="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-04-lg.jpg"><img src="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-04-sm.jpg" width="95%" /></a>
* Went back to windy camp and had Mama Ramen.
   
# Day 2 — Thursday, May 9
  * Dunkin Donuts
  * [Calico I](https://www.mountainproject.com/area/105731953/first-pullout-calico-i) (first pullout): entered park early before reservations needed.
  * Approached [Panty Wall](https://www.mountainproject.com/area/105732045/panty-wall). Took 1 hour to find (including [Hamlet](https://www.mountainproject.com/area/106118838/the-hamlet) approach)
    * We were scared by the ramp belay for easier climbs.
    * [Brief Encounter](https://www.mountainproject.com/route/105813275/brief-encounter) 5.8, 2 stars in guidebook
      * Good exposure practice. First time we've started so high and more exposed than other stuff we've done. 
      * M went first b/c V whined. M had fun and now understands that going first is way more fun.
      * Getting shoes on, stick clipping, cloving belayer in to first bolt: 15 min.
      * M led: 15 min bottom to bottom.
      * Change shoes: 10-15min, re-stick-clip first bolt
      * V toproped: 18 min, bottom to bottom.
      * V led: 17 min bottom to bottom.
    * Sat and watched sun come over the Panty Wall. Watched to see if Amusement Park would become shady and decided it would not.
  * Raising Cane's for lunch. Lemonade very refreshing. Yummy.
  * [Calico Basin](https://www.mountainproject.com/area/105731977/calico-basin): Red Springs Parking Lot
    * Attempted to approached [Jabba The Hut](https://www.mountainproject.com/area/105944075/red-springs-rock-lower-tier-aka-jabba-the-hutt-rock). 
      Took boardwalk, went off boardwalk (possibly not allowed, possibly into private territory). Saw boulderer who was also lost. 
      Saw tortoise. Identified Jaba The Hutt rock but it looked very far away and difficult to appraoch despite is purported approach time of 5 minutes.
      Very hot outside.
      Total non-approach time: 1h 15m
  * Went to Walmart for air conditioning. no LEGOs, all behind glass at full price. Inspected all frozen items, but not very cold. Bought 3 haagen daz ice cream bars.
        <a href="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-07-lg.jpg"><img src="${process.env.PUBLIC_URL}/img/2024-05-08-red-rocks/redrocks-07-sm.jpg" width="85%" /></a>
  * Calico I: approached and climbed at [Amusement Park](https://www.mountainproject.com/area/108563769/amusement-park)
    * **Approach**: Hamlet plus ramp toward Panty Wall part way, but cut right and down to white section that goes all the way down. 25 min. 
    * [Haunted House](https://www.mountainproject.com/route/108563805/haunted-house) 5.7
      * M climbed to the first bolt, overheating, not feeling good, asked me to go first.
      * V led: 24 min b2b
      * M led: 18 min b2b
  * Camp: Buldak Carbonara Ramen w/ Parmesan and Bok Choy

# Day 3 - Friday, May 10
  * Dunkin Donuts; saw grandmas meet up.
  * [Calico I](https://www.mountainproject.com/area/105731953/first-pullout-calico-i): [Panty Wall](https://www.mountainproject.com/area/105732045/panty-wall)
    * Approaching wall: 50 min
    * [Sacred Undergarment Squeeze Job](https://www.mountainproject.com/route/105903232/sacred-undergarment-squeeze-job) 5.8, 2 stars in guidebook
      * Similar but different to [Brief Encounter](https://www.mountainproject.com/route/105813275/brief-encounter) (yesterday)
      * M led: 22 min b2b
      * V led: 24 min b2b
  * M saw spider eat a caterpillar
  * Ate pb&j at the car
  * Started approaching [Civilization Crag](https://www.mountainproject.com/area/106686385/civilization-crag) but then there were sprinkles/so we decided to be good climbers and went back to the car. 
  * [Pine Creek](https://www.mountainproject.com/area/105731962/pine-creek-canyon) Parking Lot: sat there for a while and looked at [Mescalito](https://www.mountainproject.com/area/105732159/mescalito)/Rainbow Mountain. Not hot b/c it's sprinkling.
  * Approached [Dark Shadows](https://www.mountainproject.com/route/105732380/dark-shadows): [Pine Creek Canyon hike](https://www.redrockcanyonlv.org/pine-creek-canyon/) 3 miles
    * Cut left and crossed the creek too early and had to go through brush/bushwacking
    * Found Dark Shadows.
    * Touched the 5.10 P1, which looked v scary and the 5.5. P1, which seemed easy compared to the 5.10. 2 bolts but stick-clippable.
    * Saw people on Dark Shadows and Risky Business
    
    * Took the trail on the other side of the creek on the way back. It goes all the way to Dark Shadows.
    * The correct **approach**: Follow Pine Creek Canyon trail until 2 white boulders that are high 
      and to the right. Cut right _right_ in-front of boulders onto an upward slab that takes you up to the boulders via 2 switchbacks until the boulders are on your left. 
    Follow that trail all the way to Dark Shadows.
  * Raising Cane's - Lemonade is good!
  * Camp: Sleep early to climb early next morning. Walked around campground to observe other setups. Went to bed as soon as it got dark ~8pm.

# Day 4 - Saturday, May 11
  * Left camp 5:40pm
  * Dunkin Donuts; saw climbing group meeting up
    * We were concerned about rain dry time but saw climbers at Dunkin. (It sprinkled a little at night, and a couple of times the day before, but very very very lightly)
    * M found giant bug inside pants
  * Got to scenic drive close to opening ~6:20am
  * Approached [Hamlet](https://www.mountainproject.com/area/106118838/the-hamlet) and checked sand. It was powdery. Approach took 15 min.
  * Took a long time to set up. 30 min to get first bolt clipped and change shoes.
  * [Sea of Troubles](https://www.mountainproject.com/route/106608213/sea-of-troubles) 5.9 S
    * V started leading 7am
    * 7:20am, just below fall (took 20 min to get from start to just before)
    * Fell around 7:22a onto 3rd bolt from above the 4th bolt
      * Almost crashed into ledge. Hard catch. Foot hit ledge mildy-hard but safe. Very good catch by M.
        M saw stars. Decided another lead fall soon is not okay.
        A good big fall and no danger.
      * Stick clipped up the rest, trouble untying knot (1h 15)
      * Lowered and left the quick draws as back-up
    * M top-roped (30 min to climb and clean (quick draws were making rope rub against rock) and re-rig anchor)
    * V top-roped (15 min to climb, 10 min to remove quickdraw anchor)
  * Saw Dan Young guiding group of women, second Saturdays at Hamlet 
  * White Castle: 14 sliders, small fry, coke accidentally
  * Drove to LA
`,
            frames: [

            ],
          },
        },
//         {
//           date: new Date('2024-05-05'),
//           showCollapseText: true,
//           textCollapsedOnInit: true,
//           item: {
//             type: itemType.BOOK,
//             title: 'Designing Data-Intensive Applications',
//             year: 2017,
//             author: 'Martin Kleppmann',
//             inProgress: true,
//             text: `
// &#x2022; <b>Ch 10:</b> Batch Processing</br>
// &#x2022; <b>Ch 11:</b> Stream Processing
// `
//           },
//         },
        {
          date: new Date('2024-05-04'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Mount Tamalpais State Park',
            text: `
[Mount Tamalpais State Park](https://www.parks.ca.gov/pages/471/files/MountTamalpaisSPFinalWebLayout110816.pdf) (pdf w/ good map)

<!--<img src="${process.env.PUBLIC_URL}/img/2024-05-04-mt-tam/mt-tam-sp.png" width="55%" />-->

**Pantoll Campground to Stinson Beach** via Steep Ravine Trail to Dipsea Trail (out and back)

* sort of like [this](https://kindly-kaitlin.com/2021/02/18/dipsea-matt-davis-steep-ravine-and-pantoll-trailheads-coastal-day-hikes/) or [this](https://www.alltrails.com/trail/us/california/dipsea-and-steep-ravine-loop-from-stinson-beach) but without the loop
* post-rain hike w/ M and C

  <a href="${process.env.PUBLIC_URL}/img/2024-05-04-mt-tam/mt-tam-hike.jpg"><img src="${process.env.PUBLIC_URL}/img/2024-05-04-mt-tam/mt-tam-hike.jpg" width="95%" /></a>
_Route shown in highlighter orange._

Then drove up to Visitor Center / did tiny walk up to the <a href="${process.env.PUBLIC_URL}/img/2024-05-04-mt-tam/mt-tam-fire-lookout.jpg">fire lookout</a> for quick view. Windy.
            `,
            frames: [
              '2024-05-04-mt-tam/mt-tam-02',
              '2024-05-04-mt-tam/mt-tam-03',
              '2024-05-04-mt-tam/mt-tam-04',
              '2024-05-04-mt-tam/mt-tam-05',
              '2024-05-04-mt-tam/mt-tam-06',
              '2024-05-04-mt-tam/mt-tam-07',
              '2024-05-04-mt-tam/mt-tam-08',
              '2024-05-04-mt-tam/mt-tam-09',
              '2024-05-04-mt-tam/mt-tam-10',
              '2024-05-04-mt-tam/mt-tam-11',
              '2024-05-04-mt-tam/mt-tam-12',
              '2024-05-04-mt-tam/mt-tam-13',
              '2024-05-04-mt-tam/mt-tam-14',
              '2024-05-04-mt-tam/mt-tam-15',
            ],
          },
        },
        {
          date: new Date('2024-04-16'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'The Unknown Country ',
            year: 2022,
            director: 'Morrisa Maltz',
            text: `
<div style="text-align:right;">
  (Lily Gladstone. Non-actors/semi-documentary-like. Good vignettes of [real] small shop owners  at diner (Jan), motel, gas station. Good smoking.)
</div>`,
          },
        },
        {
          date: new Date('2024-04-16'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'The Taste of Things',
            year: 2023,
            director: 'Trần Anh Hùng',
            text: `
<div style="text-align:right;">
  French: <i>La Passion de Dodin Bouffant</i></br>
  (Art of food movie w/ Juliette Binoche and ex-husband Benoît Magimel)
</div>`,
          },
        },
        {
          date: new Date('2024-04-15'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'Fallen Leaves',
            year: 2023,
            director: 'Aki Kaurismäki',
            text: `
<div style="text-align:right;">
  Finnish: <i>Kuolleet lehdet</i></br>
  (Finnish movie. Depressed people, love-story. Smoking, Alcohol. Little Wes Anderson-like vignettes 
    of random people at bars that are slightly comical but mostly human (more human that WA).)
</div>`,
          },
        },
        {
          date: new Date('2024-04-14'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'Revoir Paris',
            year: 2022,
            director: 'Alice Winocour',
            text: `
<div style="text-align:right;">
  (French movie. PTSD re: terrorist attack/mass shootings in Paris. Trauma connections w/ strangers. 
  Life after trauma/breakdown of existing relationships. Immigration.)
</div>`,

          },
        },
        {
          date: new Date('2024-04-12'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'You Hurt My Feelings',
            year: 2023,
            director: 'Nicole Holofcener',
            text: `
<div style="text-align:right;">
  (Family, marriage, telling the truth vs. being supportive. Julia Louis-Dreyfus (aka Elaine from Seinfeld).)
</div>`,
          },
        },
        // then watched the west wing b/c star wars was so bad
        // 3/9 tried to watch - the mandalorian. it was so terrible
        // 3/3, 3/4 brave new world
        // 2/27 empire strikes back, return of the jedi
        // 2/26 rogue 1, a new hope
        // {
        //   date: new Date('2024-02-21'),
        //   endDate: new Date('2024-02-25'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Rebels',
        //     // year: ,
        //     director: '',
        //     frames: [],
        //     text: `tried to watch rebels on 21st and the 25th. then gave up.`,
        //   },
        // },
        {
          date: new Date('2024-02-18'),
          item: {
            type: itemType.FILM,
            title: 'Obi-Wan Kenobi',
            year: 2022,
            director: 'Deborah Chow',
            frames: [
            ],
            text: `
<div style="text-align:right;">
  <sub>(6-episode Limited Series)</sub><br />
</div>
            `,
          },
        },
        {
          date: new Date('2024-02-17'),
          item: {
            type: itemType.FILM,
            title: 'Solo: A Star Wars Story',
            year: 2018,
            director: 'Ron Howard',
            frames: [
            ],
            text: ``,
          },
        },
        {
          date: new Date('2024-02-07'),
          endDate: new Date('2024-02-16'),
          item: {
            type: itemType.FILM,
            title: 'The Bad Batch (s1, s2)',
            year: 2021,
            director: 'Brad Rau (supervising)',
            frames: [
            ],
            text: `
<div style="text-align:right;">
  <sub>
    Created by: Dave Filoni<br/>
    Head writer: Jennifer Corbett<br/><br/>
    Seasons 1: 16 episodes<br/>
    Seasons 2: 16 episodes<br/>
    Season 3: Releasing Feb 21, 2024
  </sub><br />
</div>
            `,
          },
        },
        {
          date: new Date('2024-02-06'),
          item: {
            type: itemType.FILM,
            title: 'The Polar Express',
            year: 2004,
            director: 'Robert Zemeckis',
            frames: [
            ],
            text: ``,
          },
        },
        {
          date: new Date('2024-01-29'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'Episode III: Revenge of the Sith',
            year: 2005,
            director: 'George Lucas',
            frames: [
            ],
            text: `
* Obi-Wan and Anakin lead mission to rescue Chancellor Palpatine from Separtist commander General Grievous
* Obi-Wan and Anakin fight Count Dooku. Anakin decapitates Dooku at urging of Palpatine (Obi-Wan is unconscious).
* Anakin sees Padme on Coruscant // she's pregnant.

* Palpatine appoints Anakin to Jedi Council
* Council tells him to spy on Palpatine // Anakin is unhappy
* Palpatine keeps telling Anakin he's too good for the Jedi // they don't see his worth

* Anakin has dreams that Padme will die in childbirth
* Palpatine tells Anakin he knows how to save people from death
* Eventually Palpatine reveals that he's Darth Sidious // Anakin tells the Jedi Council

* Master Windu comes to kill Palpatine, Palpatine becomes deformed in the battle.
* Palpatine tells Anakin that only he knows how to save Padme // Anakin kills Windu to save Palpatine (to save Padme)
* Anakin pledges himself to the Sith; becomes Darth Vader
* Palpatine issues Order 66 ordering the clone troopers to kill all Jedi.
* Vader and some clone troopers kill all the Jedi in teh Jedi Temple including the younglings.
* Vader goes to Mustafar to kill the Separatist leaders
* Palpatine declares himself Emperor of the Galactic Senate (he claims the Jedi traitors attacked the Republic
and that he needs more power to protect the Republic.)

* Obi-Wan finds Padme to figure out where Vader is; tells her he killed younglings; Padme goes to Mustafar to find Anakin; Obi-Wan has snuck board her ship.
* When Obi-Wan shows up, Anakin thinks Padme has betrayed him and brought Obi-Wan to kill him; he strangles Padme
* Obi-Wan and Vader fight; Obi-Wan cuts of Anakin's arms and legs and Anakin is burned all over.

* On Coruscant, Yoda battles Palpatine; nobody wins.

* Yoda flees with Senator Organa and meets Obi-Wan and Padme on Polis Massa. Padme gives birth to twins she names Luke and Leia.
* Padme dies

* Palpatine recovers Vader and gives him a suit.
* Anakin asks about Padme and Palpatine says Anakin killed her; Anakin is sad.

* Obi-Wan and Yoda go into exile.
* Bail Organa adopts Leia to Alderaan.
* Luke taken to Owen and Beru Lars (step-uncle/aunt) on Tatooine. Obi-Wan settles nearby.
            `,
          },
        },
        {
          date: new Date('2024-01-27'),
          endDate: new Date('2024-02-05'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'The Clone Wars (series)',
            year: 2008,
            director: 'Dave Filoni, et al',
            frames: [
            ],
            text: `

**TLDR**

Was interested in the psychology of the clone troopers (existentialism,
internal dissonance, individualism v collectivism) from a brief peek at a
potential for such themes in
116 Hidden Enemy and 301 Clone Cadets. Tried to watch the series in a skimming way
because it's mostly boring fight-the-bad-guy action/adventure, but there are hints of
deeper things (which is much more apparent in The Bad Batch).

Psychology of Anakin is also interesting, of course. His character
in the show to be pretty different from how he's portrayed in the prequel movies; he's
a respectable, competent, empathetic leader in the show; mostly a brat in the movies.
Given the show, it makes his turn much more heartbreaking; esp re: Ahsoka.
His maturity seems like a retrofit (it literally is) that doesn't really match how
he is in Episode III very well.

I tried to find a 'critical episodes'
list on the Internet but the Star Wars fans always say to watch everything; so ended
up watching a lot, unfortunately. In conclusion, the critical episodes list on the
sole criteria of rich-depiction-of-the-human-condition:
* 301-302 Clone Cadets / ARC Troopers
* 520 The Wrong Jedi (Ahsoka leaves)
* 709-712 Siege of Mandalore Arc

It was mentioned in a blog post somewhere that Siege of Mandalore should've been the clone wars movie; agree.
It's an excellent companion to Episode III.

I didn't really like the Season 4 Umbara arc b/c there's too much action, but I do really
like Captain Rex because of it; which is helpful for The Bad Batch series
(which is _way_ better than The Clone Wars).

---
**The Clone Wars  (_2008-2020 TV Series_), 7 Seasons**

The Clone Wars series takes place between Episode II and Episode III, during "the Clone Wars", between:
* Separatists and their drone army (led by Darth Sidious and Count Dooku)
* The Republic, supported by the Jedi, and their clone army.

Chancellor Palpatine of the Republic
is Darth Sidious, but we don't find that out until the end of Episode III.
The last season of the show takes place at the same time as Episode III and ends with
Palpatine's executive Order 66, which orders the clones to kill all Jedi
(and Anakin becomes Darth Vader).

---

Attempting to watch all of Star Wars chronologically:

* 216 Cat and Mouse
* 116 Hidden Enemy
* The Clone Wars (2008) dir. Dave Filoni (theatrical release)
* 301 Clone Cadets (about Domino Squad, Kamino arc)
* 303 Supply Lines

Clone Cadets was good (interested in psychology and philosphy re: clones) but the episodes
that are strictly battles are unbearably uninteresting. Going forward, looking for arcs
that are good only. The [clone wars wiki](https://clonewars.fandom.com) has good
documentation of the arcs.

---
Season 2

* 220 Death Trap (about Boba Fett)

Wanted to learn about Boba Fett. He tries to kill Master Windu.
Wasn't an amazing episode, but some insight into the kid clones was interesting.

---
Season 3

* 302 ARC Troopers (follow-up to Domino Squad, Kamino arc)

Liked this one; Domino Squad's graduation / teamwork / 99 / basis for why The Bad Batch is called Clone Force 99.

* 315 Overlords

This is the first episode in the Secrets of Mortis Arc (315-317) which was
recommended on Reddit; didn't continue the arc b/c this episode was stupid.

Also recommended on Reddit was the Citadel Arc (318-320; didn't watch b/c the
synopsis seems like it's battle heavy (and not necessarily character-depth heavy).
And also, I don't trust Reddit recs after 315. Events from here are referenced
later though; they thought Echo died at this time. Later, in the Bad Batch arc,
they rescue Echo. It's possible that the battle stuff is instrumental in clone psychology
later on, but trying to not waste time / this cartoon is inconsistent in goodness.)

---
Prior to this, I was searching for best arcs per season. Instead switched
to looking at best arcs overall in the entire series, to better narrow down which arcs to watch.

Season 4

* 407 Darkness of Umbara (beginning of Umbara Arc)
* 410 Carnage of Krell (end of Umbara Arc)

Accidentally skipped 408-409 because of file error,
but it's for the best since 407/410 had good synopsis of the psychology of clones
without having to watch all the battles. Good profiles on Rex and Fives. Very
uninterested in any of the actual clone wars fighting.

---

Season 5

Maul's Revenge / Fall of Mandalore arc 510,514-516a

* 510 Revival
* 514 Eminence
* 515 Shades of Reason
* 516 The Lawless

Not great. The basic political strategy is mildly interesting // how to start fascism.
Death Watch and Maul organize fake crime so that they can take over and be a
hard-ass military state / lock up democratic leadership (Duchess Satine).

Was supposed to be heartbreaking (probably b/c Satine dies and Obi-Wan is sad) but it really wasn't.
Introduced to Bo-Katan, which may be helpful for later in the saga. Darth Maul is also kind of cool
because he does philosophical monologues and kind of looks cool (esp. compared to in Episode I),
but overall is only deep for a children's cartoon (which this is).

* 520 [The Wrong Jedi](https://clonewars.fandom.com/wiki/The_Wrong_Jedi)

Ahsoka is falsely accused of treason and chooses to leave the Jedi order b/c of self-respect // they don't believe in her.
Most notable episode of the series b/c of the last scene. Rounded out with Season 7, concluded
w/ Siege of Mandalore arc.

---

Season 6

Clone Protocol 66

* 601 The Unknown
* 602 Conspiracy
* 603 Fugitive
* 604 Orders

Tup has a malfunction with his inhibitor chip (before Order 66) and kills a Jedi.
He's sent to Kamino. Fives finds out about the chip w/ the help of AZI-3, best droid.
The Siths don't want their Order 66 plan ruined;
Fives becomes killed (but told Rex and Anakin about the chip, who didn't believe him).
Later (in Episode 711), when Order 66 goes out, Rex resists the order for enough time to warn Ahsoka
to check Fives' report.

---

Season 7

Ahsoka's Journey (Ahsoka has nowhere to be. She meets the Martez sisters on Coruscant and does adventures with them.)

* 705 Gone with a Trace
* 706 Deal No Deal
* 707 Dangerous Debt
* 708 Together Again


The Bad Batch

* 701 The Bad Batch
* 702 A Distant Echo

**Siege of Mandalore** (A four-part film. Takes place at the same time as Episode III // when Anakin is becoming evil and Order 66 is executed.)

* 709 [Old Friends Not Forgotten](https://clonewars.fandom.com/wiki/Old_Friends_Not_Forgotten)

  Anakin gives Ahsoka her lightsabers that she had left behind.
  Rex and 332nd Company of the 501st paint their helmets to honor Ahsoka.
  They go to Mandalore under Ahsoka's unofficial command (Rex promoted to Commander) to fight Darth Maul,
  while the other half of the 501st goes with Obi-Wan and Anakin to rescue Chancellor Palpatine from General Grievous (Episode III).

* 710 The Phantom Apprentice
* 711 Shattered

  Rex warns Ahsoka about Order 66 before he and other clones try to kill her; she takes his chip out.

* 712 Victory and Death

  Rex and Ahsoka survive cruiser crash with R7. They make a clone trooper graveyard.
  Ahsoka leaves her lightsaber. Years later, Darth Vader finds it.

            `,
          },
        },
        {
          date: new Date('2024-01-27'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'The Clone Wars (theatrical)',
            year: 2008,
            director: 'Dave Filoni',
            frames: [
            ],
            text: `This is a very bad action/adventure/plot-driven movie that does not help with understanding canon really at all.
I believe it's been described as a pilot (and maybe literally is) for The Clone Wars series. It is like _a random episode_
of the series and is not necessary to watch.
            `,
          },
        },
        {
          date: new Date('2024-01-27'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'Episode II: Attack of the Clones',
            year: 2002,
            director: 'George Lucas',
            frames: [
            ],
            text: `
Hayden Christensen is not _that_ bad, 22 years later. He is really annoying but he's probably
sort of supposed to be like that? He's much more likeable in The Clone Wars 2008 animated series.
This movie is mostly a love story (Anakin and Padmé) and culminates in the start of The Clone Wars.

* The Sith lords (Darth Sidious and Count Dooku) have been making the Separatists attack planets
so that the republic will eventually approve a Republic army. The Droid army is unleashed on the Jedi and
kills them all. The Jedi and the Republic are forced to come in with their new clone army.

* Kamino: where they make the clones from Jango Fett's DNA.
* Geonosis: where the Separtist droid factory is.

* Final battle: Count Dooku injures Obi-Wan and cuts off Anakin's arm. Yoda saves them.
* Anakin gets a droid arm // Anakin and Padmé are secretly married.
`,
          },
        },
        {
          date: new Date('2024-01-27'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'Episode I: The Phantom Menace',
            year: 1999,
            director: 'George Lucas',
            frames: [
            ],
            text: `We have set out to understand the Star Wars universe for some reason.
Watching the YouTube recaps of the entire Star Wars canon did not prove educational because
it's an overwhelming firehose of facts that we have no reason to care about.

So we begin the watching of Star Wars, chronologically.

---

tldr; Darth Sidious makes the Trade Federation do bad stuff so that he can get promoted
to Chancellor of the Republic. Anakin Skywalker is discovered.

* The Trade Federation is blockading Naboo. Darth Sidious is making them do bad stuff.
* Qui-Gon Jinn and Obi-Wan Kenobi go to negotiate but are attacked. They rescue Queen Amidala from Naboo but have to land
in Tatooine for ship repairs.
* They find Anakin. He wins his freedom in pod race. Leaves his mom behind.
* Jeid council says Anakin is too old to be trained, but Qui-Gon thinks he's the chosen one.
* Darth Maul kills Qui-Gon Jinn. Before he dies, Qui-Gon makes Obi-Wan promise to train Anakin.
* Darth Maul is cut in half by Obi-Wan.
* Senator Palpatine is elected Chancellor of the Republic (by persuading Queen Amidala to call for a vote of no confidence.)
* Anakin becomes Obi-Wan's apprentice.
`,
          },
        },
        {
          date: new Date('2024-01-21'),
          item: {
            type: itemType.FILM,
            title: 'Fair Play',
            year: 2023,
            director: 'Chloe Domont',
            frames: [
            ],
            text: ``
          },
        },
        {
          date: new Date('2024-01-11'),
          item: {
            type: itemType.FILM,
            title: 'May December',
            year: 2023,
            director: 'Todd Haynes',
            frames: [
            ],
            text: ``
          },
        },
        {
          date: new Date('2024-01-05'),
          item: {
            type: itemType.FILM,
            title: 'Leave the World Behind',
            year: 2023,
            director: 'Sam Esmail',
            frames: [
            ],
            text: ``
          },
        },

        {
          date: new Date('2024-01-05'),
          item: {
            type: itemType.FILM,
            title: 'Killers of the Flower Moon',
            year: 2023,
            director: 'Martin Scorsese',
            frames: [
            ],
            text: ``
          },
        },

      ]
    },
    {
      year: 2023,
      goals: {
        books: 15,
      },
      events: [
//         {
//           date: new Date('2023-07-16'),
//           item: {
//             type: itemType.BOOK,
//             title: 'War and Peace',
//             year: 1869,
//             author: 'Leo Tolstoy',
//             inProgress: true,
//             inProgressCurrentPage: 595,
//             inProgressTotalPage: 1358,
//             text: `<div style="text-align:right;">
// <sub>Translated by Anthony Briggs. Penguin Classics, 2017</sub>
// </div>`,
//           },
//         },
//         {
//           date: new Date('2023-12-20'),
//           item: {
//             type: itemType.POST,
//             title: 'train station (look at modulars)',
//             text: `
// ### 10182 cafe corner 2007
// - no interior
// - brickset review from 2022 (newer photos) with release of boutique hotel: [10182 cafe corner](https://brickset.com/article/68609/review-10182-caf%C3%A9-corner)

//     <img src="https://images.brickset.com/news/68609_P1064616.JPG" width="60%">
//     <img src="https://images.brickset.com/news/68609_P1064612.JPG" width="60%">

// ### 10185 green grocer 2008

// - [eurobricks review](https://www.eurobricks.com/forum/index.php?/forums/topic/98039-lego-10185-green-grocer-new-build-review/)
// - grocery store / apartment lobby: floor tiling patterns/colors

//     <img src="https://farm3.staticflickr.com/2906/14697106056_cb9f29e61a_c.jpg" width="60%">
//     <img src="https://farm4.staticflickr.com/3864/14697098986_d4374c9e92_c.jpg" width="60%">
//     <img src="http://www.brickshelf.com/gallery/dunamis/10185/10185-26.jpg" width="60%">

// - angled bay door
// - open apartment lobby goes through to back

//     <img src="https://farm4.staticflickr.com/3871/14720088615_5ef029c7b2_c.jpg" width="60%">
//     <img src="https://farm6.staticflickr.com/5584/14718319624_198da6c909_c.jpg" width="60%">

// - upper floor exteriors: bay window and triangular roofs

// ### 10197 fire brigade 2009
// ### 10211 grand emporium 2010
// ### 10218 pet shop 2011
// ### 10224 town hall 2012
// ### 10232 palace cinema 2013
// ### 10243 parisian restaurant 2014
// ### 10246 detective's office 2015
// ### 10251 brick bank 2016
// ### 10255 assembly square 2017
// ### 10260 downtown diner 2018
// ### 10264 corner garage 2019
// ### 10270 bookshop 2020

// - floor tiling

//     <img src="https://brickarchitect.com/wp-content/uploads/2020/06/JB2A1617-2048x1365.jpg" width="80%">

// ### 10278 police station 2021

// - floor tiling

//   <img src="https://brickarchitect.com/wp-content/uploads/2020/12/JB2A0256-1536x1024.jpg" width="80%">
//   <img src="https://brickarchitect.com/wp-content/uploads/2020/12/Brick_Architect-review_10278_LEGO_Police_Station-Bag_4-2048x1024.jpg" width="80%">

// ### 10297 boutiue hotel 2022
// ### 10312 jazz club 2023
// ### 10326 natural history museum 2023
// tips and bricks review:
// * [tipsandbricks.co.uk/bookshop](https://www.tipsandbricks.co.uk/post/1470-set-review-10270-bookshop)

// `,
//           },
//         },
//         {
//           date: new Date('2023-12-05'),
//           item: {
//             type: itemType.POST,
//             title: 'Notes for LEGO blog',
//             text: `
// <div style="width:fit-content;border-radius:200px;background-color:#EE352E;color:#fff;padding:3px 15px 3px 15px;margin:5px 0 5px 0;font-size:16px;font-weight:600;">
//   set roast
// </div>
// <div style="width:fit-content;border-radius:200px;background-color:#FF6433;color:#fff;padding:3px 15px 3px 15px;margin:5px 0 5px 0;font-size:16px;font-weight:600;">
//   [rebrickable] set roast
// </div>
// <div style="width:fit-content;border-radius:200px;background-color:#0039A6;color:#fff;padding:3px 15px 3px 15px;margin:5px 0 5px 0;font-size:16px;font-weight:600;">
//   techniques
// </div>
// <div style="width:fit-content;border-radius:200px;background-color:#18b616;color:#000;padding:3px 15px 3px 15px;margin:5px 0 5px 0;font-size:16px;font-weight:600;">
//   deep dive
// </div>
// <div style="width:fit-content;border-radius:200px;background-color:#FCCC0A;color:#000;padding:3px 15px 3px 15px;margin:5px 0 5px 0;font-size:16px;font-weight:600;">
//   strong opinion
// </div>
// `,
//           },
//         },
//         {
//           date: new Date('2023-11-20'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: 'LEGO workbench',
// // &#x2611; ballot box with check
// // &#x2612; ballot box with x
// // &#x2610; ballot box
//             text: `
// # Sorting and Parting
// Continuing to (1) part sets, (2) sort pieces, (3) label bins, (4) put pieces into bins.

// An end where there are no un-binned, un-labeled parts seems in sight (until we part more sets.)

// ### Done &#x2705;
// &#x2611; 10305 Lion Nights' Castle</br>
// &#x2611; 42123 McLaren Senna GTR</br>

// ### In Progress:
// &#x2610; 71799 Ninjago City Markets</br>

// ### Todo:
// &#x2610; Heavy Duty Tow Truck</br>

// ## City
// Working on city series. Starting with infra: roads, tunnel, stairs/elevator, bike and bus infra,
// train and train infra, pedestrian walkways. Then modulars! It will be cool.

// ## Sets

// Got a bunch of sets on sale (some around 50% off) in November:

// * [75301 Luke Skywalker’s X-Wing Fighter](https://brickset.com/sets/75301-1/Luke-Skywalker-s-X-wing-Fighter) (2021), 474 Pieces, 9+, RRP $49.99
//   * The Technic submodule for the X wings is very cool (the two upper wings move together and the two lower wings move together).
//     Extremely impressive model at 474 piece count. And surprisingly sizeable too.  One complaint: when the model sits on the table,
//     its inevitably fold down and are unable to stay in X position.
// * [75300 Imperial TIE Fighter](https://brickset.com/sets/75300-1/Imperial-TIE-Fighter) (2021), 432 Pieces, 8+, RRP $44.99
//   * Mostly got this to go with the X-Wing since it was also on sale for a lot. Comes with our first Storm Trooper fig.
//     The two wings are repeated but both the wing and the center sphere were fairly pleasant builds; nothing very notable,
//     but nice. Like the X-Wing, the model is also a lot larger than expected.
//     Looks pleasantly accurate to source material with fairly low piece count. Both the X-Wing and the TIE Fighter
//     were a really nice size + accuracy to source material made for really pleasant building experiences.
// * [60378 Arctic Explorer Truck and Mobile Lab](https://brickset.com/sets/60378-1/Arctic-Explorer-Truck-and-Mobile-Lab), 489 Pieces, 6+, RRP $74.99
//   * This build was extremely well done. The detailing/shaping for the mobile lab is really nice (with the fender piece), especially
//     for a 6+ set. And the snow groomer is an excellent build; the shaping of the cab is so good and the functions;
//     much more delightful than the technic snowgroomer ([42148](https://www.lego.com/en-us/product/snow-groomer-42148)) at similar scale.

//     Designed by [Robert Heim](https://brickset.com/sets/designer-Robert-Heim):
//     > My 13th CITY Set. And my first Explorer set. I really enjoyed playing around with the shaping of both the snow groomer an the mobile lab, to create a more modern aesthetic. The new wheel arch piece from the ICONS Landrover Defender helped me to make an interesting window design for the lab. All the new minifigure prints and stickers were created by my colleague Cristina and look wonderful! But I'm also especially happy with the box art! It looks so exciting and dramatic! And in case you haven't noticed: We have a bear cub now!
// * [60385 Construction Digger](https://brickset.com/sets/60385-1/Construction-Digger) (2023), 148 Pieces, 5+, RRP $19.99
//   * We got this and 60287 Tractor in a Walmart-exclusive 2-pack.
//     Both are designed by Robert Heim, who we are now fans of. He is a very thoughtful builder and tries
//     to build excellence into even small sets while keeping them delightful for kids.
//     The movement of the bucket (lift and tilt motions) is delightfully playable yet simple in technique.
//     > My 11th CITY set. Even though it's a fairly small set, they often come with the toughest design challenges, like how can this back hoe loader use all his tools to build something that includes solar cells!?!? Second biggest challenge: making something look cool and realistic, but keep the build as easy as possible, so 5 year olds have an enjoyable time building it. :-)

// * [60287 Tractor](https://brickset.com/sets/60287-1/Tractor) (2021), 148 Pieces, 5+, $19.99 <sup>todo</sup>
//   * Designed by Robert Heim
//     > This is my 5th City Set, that I designed so far and one, that I really like. When I proposed a concept model of a tractor to my colleague Erik, who is leading the development of the great vehicles, he smiled at me saying he already has a brief for one. Woohoo! Finally we get a tractor back to LEGO City! Erik then put my name on the brief and I finally had the chance to design the set. We even had the chance to make those new tyres for the back wheels. Another new element in the set is the 2x2 / 2-plate tall grill element. The purpose of the element is to avoid the stacking of two identical plates, because young builders often overlook the second plate. It also reduces unnecessary complexity of a model, which helps 5-year olds as well. To make the element easy to decode and different from a 2x2 plate or a 2x2 brick we had to give it some texture and also added a cross axle hole. Now the element has a grill pattern, which makes it also usable as a decorative element. The tractor model demonstrates the use of that element very well. I used it eight times. Four in black and four in tan.
//     You are wondering why I used that many technic elements for the arms of the frontloader and not just the same element like in 60219? It's the weight of the bucket and its load. The way it's designed now uses 4 LEGO-modules of friction to always keep the shovel up. It's much better for the play experience. :-)
// * [60324 Mobile Crane](https://brickset.com/sets/60324-1/Mobile-Crane), 340 Pieces, 7+, RRP $39.99 <sup>todo</sup>
//   * Designed by Robert Heim
//     > My 8th CITY Set. And it’s a mobile crane!! I love mobile cranes! So I was super excited to be able to design one and I’m pretty happy with the result. I am especially proud of the stabilizers, that actually slide out like on real cranes and are actually strong enough to support the weight of the crane. I also love the fact, that I was able to use a linear actuator from LEGO Technic. It’s probably the first System based set that uses this element. It just makes the play experience so much better in my opinion, when you are able to control the crane boom smoothly. And have you noticed the color scheme? It’s not just the standard yellow! ;-)
// * [76916 Porsche 963](https://brickset.com/sets/76916-1/Porsche-963) (2023), 280 Pieces, 9+, RRP $24.99 (Speed Champions)
//   * Designed by [Marin Stipkovic](https://brickset.com/sets/designer-Marin-Stipkovic)
// * [60383 Electric Sports Car](https://brickset.com/sets/60383-1/Electric-Sports-Car) (2023), 95 Pieces, 5+, RRP $9.99 <sup>todo</sup>
// * [76832 XL-15 Spaceship](https://brickset.com/sets/76832-1/XL-15-Spaceship) (2022), 497 Pieces, 8+, RRP $49.99 (Disney/Lightyear) <sup>todo</sup>
//   * Designer: [Chris Perron](https://brickset.com/sets/designer-Chris-Perron/)

// And previously:

// * [10325 Alpine Lodge](https://brickset.com/sets/10325-1/Alpine-Lodge) (2023), 1517 Pieces, '18+', RRP $99.99 (Winter Village)
//   * Designers: Antica Bracanov, Chris McVeigh (out house)
//     > My second LEGO Icons set.
//     > An absolute joy to design and work on.
//     >
//     > My second favorite part was figuring out how to interlock the wooden logs and what the building flow would be for those sections of the lodge.
//     > My favorite part was working with Chris McVeigh who was my building buddy on this, helping me with
//     > advice on little story-telling details and some challenges along the way. He also designed the outhouse. :)
// * [40595 Tribute to Galileo Galilei](https://brickset.com/sets/40595-1/Tribute-to-Galileo-Galilei) (2023), 307 Pieces, '18+', GWP
//   * Based on LEGO Ideas [Tribute to Galileo Galilei](https://ideas.lego.com/challenges/91c1198d-ec7e-4cfa-9724-1c039739d8ff/application/a3814919-dde6-4b9e-8c85-35611423ccfe) by [Firecracker_](https://ideas.lego.com/profile/26869174-877b-4bba-b608-c51413e02f01/entries?query=&sort=top)
//   * LEGO Designer: [Leonard Bahro](https://brickset.com/sets/designer-Leonard-Bahro/)
// * [21343 Viking Village](https://brickset.com/sets/21343-1/Viking-Village) (2023), 2103 Pieces, '18+', RRP $129.99 (Ideas) <sup>todo</sup>
//   * Based on LEGO Ideas [Viking Village](https://ideas.lego.com/projects/57a97b5a-2236-45ec-80fd-cc49d958b20a) by [BrickHammer](https://ideas.lego.com/profile/4c7cee03-96f7-49ea-a715-d8f790949e45/entries?query=&sort=top)
//   * The project wasn't actually approved from LEGO Ideas (both times it was submitted) but won a
//     fan vote from a LEGO x Target partnership.
//     LEGO Ideas is [hard](https://ideas.lego.com/blogs/a4ae09b6-0d4c-4307-9da8-3ee9f3d368d6/post/3ba52cbb-e0d5-4f0b-a43f-12147480ad06).

// Advent Calendars:
// * [60381 City Advent Calendar 2023](https://brickset.com/sets/60381-1/LEGO-City-Advent-Calendar-2023) <sup>todo</sup>
// * [75366 Star Wars Advent Calendar 2023](https://brickset.com/sets/75366-1/LEGO-Star-Wars-Advent-Calendar) <sup>todo</sup>

// Retired sets designed by **Jamie Berard** that were reasonably priced on Bricklink:
// * [4993](https://brickset.com/sets/4993-1/Cool-Convertible) Cool Convertible (2008), 648 Pieces, RRP $49.99 (3-in-1) <sup>todo</sup>
//   * Have built build 1 of 3; the convertible. Very cool convertible roof function!
//   Doors open without gap by putting the axis of rotation a bit back on a 2x2 turntable.
//   Openable hood. Rolls goodly. Builds 2 and 3 still <sup>todo</sup>.
//   > A cool roof function became amazing with some help from my Technic friend Anders Gaasedal.
//   > It was fun 'hiding' black arches in the car to make the bucket of the front-end loader.
//   > Curious fact, the B-model Flat-front truck uses almost every brick in the box and also has more building instructions than the main model.
// * [4953](https://brickset.com/sets/4953-1/Fast-Flyers) Fast Flyers (2007) 312 Pieces, RRP $19.99 (3-in-1) <sup>todo</sup>
//   > My first LEGO design (all three models). The hovercraft was made because of a dare from my colleague Nathanaël Kuipers. I really loved the challenge.

// Retired set that seller of the above had at a good price:
// * [60033](https://brickset.com/sets/60033-1/Arctic-Ice-Crawler) Arctic Ice Crawler (2014), 113 Pieces, RRP $14.99 (Arctic) <sup>todo</sup>
// * [5865](https://brickset.com/sets/5865-1/Mini-Dumper) Mini Dumper (2010), 60 Pieces, $5.99 (3-in-1) <sup>todo</sup>

// ## Alternates
// (all alternates be low are <sup>todo</sup>)
// * Out of 42108 Mobile Crane:
//   * [American Tow Truck](https://rebrickable.com/mocs/MOC-43434/timtimgo/42108-american-tow-truck-alternate-build/#details) by **timtimgo**, €8.90
// * Out of 42151 Bugatti Bolide:
//   * [Corvette C3](https://rebrickable.com/mocs/MOC-140469/ale0794/42151-corvette-c3/#details) by **ale0794**, €3.50
 
//     Note: designer includes warning that build is complicated.
//   * [1989 Batmobile](https://rebrickable.com/mocs/MOC-138271/ale0794/1989-batmobile-42151/#details) by **ale0794**, €5

//     Note: comments say it's a clever build and the designer includes a warning that it's complicated.
// * Out of 42127 The Batman Batmobile:
//   * [1989 Batmobile](https://rebrickable.com/mocs/MOC-115452/Dyens%20Creations/1989-batmobile-42127-alternate-model/#details) by **Dyens**, €12
//   * [1963 Corvette Stingray](https://rebrickable.com/mocs/MOC-99666/timtimgo/42127-corvette-sting-ray-1963-alternate-build/#details) by **timtimgo**, €9
  
//     (but i hate batman jfc)
// * Out of 42100 Liebherr R 9800 Excavator
//   * [Liebherr PR 776 Dozer](https://rebrickable.com/mocs/MOC-51120/M_longer/42100-liebherr-pr-776-dozer/#details) by **Eric Trax**, €15
  
//     (Before parting, verifying pieces, and packing up to sell.)
// `,
//           },
//         },
        {
          date: new Date('2023-11-18'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'The Killer',
            year: 2023,
            director: 'David Fincher',
            frames: [
            ],
            text: `interesting opening scene. seriously terrible movie`,
          },
        },
        {
          date: new Date('2023-11-17'),
          item: {
            type: itemType.FILM,
            title: 'Seven Psychopaths',
            year: 2012,
            director: 'Martin McDonagh',
            frames: [
            ],
            text: ``,
          },
        },
//         {
//           date: new Date('2023-10-30'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: 'LEGO 42127 The Batman — Batmobile™',
//             text: `
// tldr; don't buy legos<sup>1</sup> that have a ™ on them.

// \`\`\`
// Set number: 42127
// Set name: The Batman — Batmobile™
// Pieces: 1,360
// Retail Price: US $99.99
// Theme: Technic
// Release Date: 2022
// \`\`\`

// Purchased on sale for $81. It's a pretty terrible set (no functions, bad build experience)
// although its shaping does look accurate.
// To be fair, I'm also extra disappointed because the movie was really terrible; we watched it to
// enhance the building experience. At least we got to see the source material and the scale modeling, again, _is_ good.

// * No functions: no suspension, no working steering
// * Build is all body shaping; very repetitive
// * It does look a lot like the real vehicle though
// * Never buy licensed / superhero sets e.g. 76178 Daily Bugle. They are for "Batman fans" or "Marvel fans", not Technic or LEGO fans.
// * Possibly never buy large-scale technic sets with no functions (display sets) e.g. 42141 McLaren F1, and probably the 1:8 supercars (despite the draw of the gearbox.)

// Anyways, the _purpose_ of buying this set was to build the alternates by [Dyens Creations](https://www.eurobricks.com/forum/index.php?/profile/161634-dyen/):
// * [1989 Batmobile](https://www.eurobricks.com/forum/index.php?/forums/topic/191176-1989-batmobile-42127-alternate-model/&tab=comments#comment-3538212) ([Rebrickable](https://rebrickable.com/mocs/MOC-115452/Dyens%20Creations/1989-batmobile-42127-alternate-model/#details): €12.00)
// * Batmobile Tumbler (which he teased in his post on his [42154 F-150](https://www.eurobricks.com/forum/index.php?/forums/topic/196474-ford-raptor-f150-42154-alternate-build/)) ([Rebrickable](https://rebrickable.com/mocs/MOC-163158/Dyens%20Creations/the-tumbler-42127-alternate-model/#details): €11.00)
// * ([Both for €20.00](https://rebrickable.com/mocs/MOC-163541/Dyens%20Creations/2-in-1-batmobiles-lego-42127-alternate-builds/#details) (€3 off))

// Also interested in timtimgo's 1963 Corvette Stingray:
// * [Rebrickable](https://rebrickable.com/mocs/MOC-99666/timtimgo/42127-corvette-sting-ray-1963-alternate-build/#details): €9.00

// Leaning toward only getting the 1989 Batmobile and the Corvette. Tumblr looks a little disappointing and is expensive.

// <sub><sup>1</sup> lego baked snacked crackers</sub>
// `,
//           },
//         },
//         {
//           date: new Date('2023-10-27'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: 'LEGO 42159 Yamaha MT-10 SP',
//             text: `
// \`\`\`
// Set Number: 42159
// Released: 2023
// Piece Count: 1478
// Price: $239.99
// Designer: Lars Thygesen
// Scale: 1:5
// \`\`\`

// Don't actually own this set b/c it's too expensive and has a very bad price per piece.
// If it goes on sale, would like to get it to check out the new gearbox.
// Started building it in Studio but gave up after about 15 steps.


// Brickset review: [42159 Yamaha MT-10 SP Review](https://brickset.com/article/97917/review-42159-yamaha-mt-10-sp)

// <img src="https://www.lego.com/cdn/cs/set/assets/blt844b0720877c5fc2/42159_alt2.png?format=webply&fit=bounds&quality=60&width=800&height=800&dpr=2" width="70%">

// ## New Pieces:

// ### 1. Technic Changeover Cylinder ([4158](https://www.bricklink.com/v2/catalog/catalogitem.page?P=4158&name=Technic%20Changeover%20Cylinder&category=%5BTechnic%5D#T=C&C=156))

//   <img src="https://img.bricklink.com/ItemImage/PN/156/4158.png" width="90px" style="padding-left:30px;">

//   * Ldraw part: [parts/4158.dat](https://library.ldraw.org/tracker/32581)
//   * Appears only in this set (2x medium azure).
//   * Functionally similar to the orange Changeover Rotary Catch ([35188](https://www.bricklink.com/v2/catalog/catalogitem.page?P=35188&idColor=4#T=C&C=4)).
//     Engages towball pins and has 8 letters, A-H.

// ### 2. Technic, Changeover Stepper Gear / Ratchet 8 Tooth ([2474](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2474#T=C&C=3))

//   <img src="https://img.bricklink.com/ItemImage/PN/3/2474.png" width="90px" style="padding-left:30px;">

//   * LDraw part:
//   * Appears only in this set (2x yellow).

// ### 3. Technic Transmission Driving Ring Guide / Changeover Catch Double (Fork) ([4159](https://www.bricklink.com/v2/catalog/catalogitem.page?P=4159&name=Technic%20Changeover%20Catch%20Double%20(Fork)&category=%5BTechnic%5D#T=C&C=4))
 
//   <img src="https://img.bricklink.com/ItemImage/PN/4/4159.png" width="100px" style="padding-left:30px;">

//   * LDraw part: [parts/4159.dat](https://library.ldraw.org/tracker/32584)
//   * Appears only in this set (2x orange).
//   * "Hold[s] the transmission driving ring and slide, moving it, but allowing it to rotate." <sup><a href="#dagger">†</a></sup>

// ### 4. Technic Transmission Driving Ring 2L with 8 Teeth ([2473](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2473&idColor=42#T=C&C=42))
 
//   <img src="https://img.bricklink.com/ItemImage/PL/2473.png" width="90px" style="padding-left:30px;">

//   * LDraw part:
//   * Appears only in this set (2x dbg).
//   * "This is a new type of driving ring, 2 studs long, just like the older ring that came before the 3L one, but with 8
//     teeth instead of 4 and modified overall shape.  This ring is compatible with the same
//     gears as the 3L ring." <sup><a href="#dagger">†</a></sup>

// ### 5. Technic, Gear 24 Tooth with Clutch on Both Sides ([2471](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2471&name=Technic,%20Gear%2024%20Tooth%20with%20Clutch%20on%20Both%20Sides&category=%5BTechnic,%20Gear%5D#T=C&C=5))
 
//   <img src="https://img.bricklink.com/ItemImage/PN/5/2471.png" width="90px" style="padding-left:30px;">

//   * LDraw part: [parts/2471.dat](https://library.ldraw.org/tracker/32409)
//   * Appears only in this set (1x red).
//   * 24T double-sided clutch gear. "You can finally put a 24T gear in a LEGO transimssion without having to cut it out from an old LEGO differential first." <sup><a href="#dagger">†</a></sup>
    
// ### 6. Technic, Shock Absorber 6.5L - Soft Spring, Tight Coils ([731c08](https://www.bricklink.com/v2/catalog/catalogitem.page?P=731c08&name=Technic,%20Shock%20Absorber%206.5L%20-%20Soft%20Spring,%20Tight%20Coils&category=%5BTechnic,%20Shock%20Absorber%5D#T=C))

//   <img src="https://cdn.toypro.com/media/cache/ks_product_detail_normal/uploads/images/custom/46823-src.jpg" width="200px" style="padding-left:30px;">

//   * Appears in 2 sets this year with Soft Spring and "Tight Coils":
//     * 42159 Yamaha MT-10 SP (2023)
//     * [42152 Firefighter Aircraft](https://brickset.com/sets/42152-1/Firefighter-Aircraft) (2023)
//   * Probably the 6.5L Soft Spring has been replaced with "Tight Coils" and is no longer produced with "Standard Coils"; [731c06](https://www.bricklink.com/v2/catalog/catalogitem.page?P=731c06#T=C) last appeared in [42139 All-Terrain Vehicle](https://brickset.com/sets/42139-1/All-Terrain-Vehicle) and [42140 App-Controlled Transformation Vehicle](https://brickset.com/sets/42140-1/App-Controlled-Transformation-Vehicle), both 2022 sets.
    
//     <img src="https://cdn.toypro.com/media/cache/ks_product_detail_normal/uploads/images/custom/1438-src.jpg" width="200px">

//   * The closest LDraw part is _Shock Absorber 6.5L Soft Spring, Standard Coils_: [parts/41838.dat](https://library.ldraw.org/official/8118). There isn't an updated
//     one w/ Tight Coils yet in the LDraw library.

// ## Significant new recolored parts:

// ### 7. Technic, Shock Absorber 9L with Black and Light Bluish Gray Ends ([79717c01](https://www.bricklink.com/v2/catalog/catalogitem.page?P=79717c01&name=Technic,%20Shock%20Absorber%209L%20with%20Black%20and%20Light%20Bluish%20Gray%20Ends&category=%5BTechnic,%20Shock%20Absorber%5D#T=C))

//   <img src="https://img.bricklink.com/ItemImage/PN/3/79717c01.png" style="padding-left:30px;">

//   * LDraw part: [parts/79717p01-f1.dat](https://library.ldraw.org/tracker/32582)
//   * Appears in 4 sets:
//     * 1x yellow in [42159 Yamaha MT-10 SP](https://brickset.com/sets/42159-1/Yamaha-MT-10-SP) (2023), 1478 pieces, $239.99
//     * 4x red in [42143 Ferrari Daytona SP3](https://brickset.com/sets/42143-1/Ferrari-Daytona-SP3) (2022), 3778 pieces, $449.99
//     * 2x blue in [42156 Peugeot 9X8 24H Le Mans Hybrid Hypercar](https://brickset.com/sets/42156-1/PEUGEOT-9X8-24H-Le-Mans-Hybrid-Hypercar) (2023), 1775, $199.99
//     * 1x blue in [42130 BMW M 1000 RR](https://brickset.com/sets/42130-1/BMW-M-1000-RR) (2022), 1920 pieces, $249.99

// ### 8. Wheel 107.1mm D. x 24mm Motorcycle ([71720](https://www.bricklink.com/v2/catalog/catalogitem.page?P=71720&idColor=7#T=C&C=7))
  
//   <img src="https://img.bricklink.com/ItemImage/PN/7/71720.png" width="120px" style="padding-left:30px;">

//   * LDraw part:
//   * Appears in 2 sets:
//     * 2x blue in [42159 Yamaha MT-10 SP](https://brickset.com/sets/42159-1/Yamaha-MT-10-SP) (2023), 1478 pieces, $239.99
//     * 2x black in [42130 BMW M 1000 RR](https://brickset.com/sets/42130-1/BMW-M-1000-RR) (2022), 1920 pieces, $249.99

// ### 9. Technic, Shock Absorber 18L with Internal Spring and Flat Silver Shaft ([69633c01](https://www.bricklink.com/v2/catalog/catalogitem.page?P=69633c01&idColor=115#T=C&C=115))
  
//   <img src="https://img.bricklink.com/ItemImage/PN/115/69633c01.png" width="120px" style="padding-left:30px;">

//   * LDraw part:
//   * Appears in 3 sets:
//     * 2x pearl gold in [42159 Yamaha MT-10 SP](https://brickset.com/sets/42159-1/Yamaha-MT-10-SP) (2023), 1478 pieces, $239.99
//     * 2x pearl dark gray in [42130 BMW M 1000 RR](https://brickset.com/sets/42130-1/BMW-M-1000-RR) (2022), 1920 pieces, $249.99
//     * 2x pearl dark gray in [Liebherr Crawler Crane LR 13000](https://brickset.com/sets/42146-1/Liebherr-Crawler-Crane-LR-13000) (2023), 2883 pieces, $699.99

// (There are other parts that are new recolors in this set that aren't included below. The full list of new parts is [here](https://www.bricklink.com/catalogItemInv.asp?S=42159-1&bt=5&sortBy=0&sortAsc=A).  )

// ## Demonstration of Gearbox
// <iframe width="560" height="315" src="https://www.youtube.com/embed/wZgsic3nkfI?si=_PxD2rQvnBg1cXSY&amp;start=258" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

// <p>
// <sub>
// <sup><a name="dagger"></a>†</sup>
// New pieces are described in the "New Pieces" section of
// <a href="https://youtu.be/wZgsic3nkfI?si=xqmIvfpEd0c4tfdI&t=177" target="_blank">42159 Yamaha MT-10 SP Review</a> by Sariel's Bricks & Pets. (Video is linked at the correct timestamp.)
// </sub>
// `,
//           },
//         },
//         {
//           date: new Date('2023-10-27'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: 'camper van reviews',
//             text: `
// 1. [City] Holiday Camper Van (60283) 2021, 190 pcs

// This is a great little set for the price. From our travels,
// we've confirmed that it looks just like a real camper.
// Delightful storytelling (bolstered by a cute propane stove mini-build) in a 6-wide vehicle
// that fits on our city streets.

// Amazon: https://amzn.to/4793fva $15.99 (20% off)
// LEGO: https://bit.ly/lego-holiday-camper-60283 $19.99

// 2. [Creator 3-in-1] Vacation Getaways (31052), 2016-2018, 792 pcs (retired)

// One of our favorite Creator 3-in-1s because each of the 3 builds could not only be the A-model, but
// be a superb A-model.
// Unfortunately, this set is retired, but feel free to go back and check out our more in-depth and nerdy
// look at this set in this video: https://www.youtube.com/watch?v=YoKvdnTbgwM

// 3. [DreamZzz] Mrs. Castillo's Turtle Van (71456), 434 pcs

// We were so excited to try the new DreamZzz theme, but we have to say,
// we were pretty disappointed in this set.
// The graphic design and concept of the set are really compelling and creative, which is why we were excited about this
// theme to begin with, but, the execution of this as a LEGO set is quite lacking, especially given the nearly $50 price tag.

// The 2-in-1 experience is lackluster and mainly involves rotating the down-facing turtle legs to be back-facing
// (thus turning a land turtle into a sea/air turtle).
// One of our biggest complaints is that the turtle legs don't articulate. Given that the legs are attached using technic axles,
// a few extra parts and time could have made for an easy solution to this and added a lot to the playability of the set.

// Several of the technic axles are too short and/or not held in place. This causes the turtle legs and the big yellow flame thingy to fall off.

// General lack of thoughtfulness and craftsmanship.

// To be fair, here are the things we liked:
// - the shape of the van is very cute, modeled after a stumpy VW camper van
// - the minifigs are excellent (including the two little goops)

// Overall, it just seemed like we didn't get much given the cost of the set. Other LEGO sets at this price point usually
// offer quite a lot of value. The design of this set seems like an afterthought that is relying solely on
// the graphic design from the television series.
// The playability is strictly worse than the Holiday Camper Van at 2.4x the price.
// That said, it's possible this van gets the most screen time out of all of our campers in the video, so maybe it was playable after all.

// LEGO: https://bit.ly/lego-castillo-turtle-van-71456 $47.99
// Amazon: https://amzn.to/3ShrbIf $47.95 + $8.56 off coupon = $39.39 (17.9% off)

// 4. [Creator 3-in-1] Beach Camper Van (31138), 556 pcs

// Amazon: https://amzn.to/40e9fjM $44.99 (10% off)
// LEGO: https://bit.ly/lego-beach-camper-31138 $49.99

// We didn't buy this set right away because there were so many 3-in-1s released at the same time.
// Much care put into this set! The van looks excellent. The set was thoughtfully made. Sculpting of the van,
// well-designed functions (opening door, engine cover, camper).

// Would recommend over Downtown Noodle Shop and Surfer Beach House, in case you're considering similarly priced 3-in-1s.

// 5. [Icons] Volkswagen T2 Camper Van (10279), 2021-2022, 2207 pcs (retired)

// Looks absolutely stunning, has lots of ambitious functions...and also the most fragile LEGO set of all time.
// Beware: this set will fall apart when touched.

// 6. [Technic] 4x4 Mercedes-Benz Zetros Trial Truck (42129) 2021-2023, 2108 pcs (retired)


// 7. [Technic] Land Rover Defender (42110) 2019-2022, 2573 pcs (retired, but still available)

// Our favorite Technic set. We love the complex gearbox, the 4x4 drivetrain, and the overall
// playability of this set. We also appreciate the use of just a few system bricks to finish off the look of the model.
// While this set is retired, we recently discovered that it's still available on the Land Rover website:
// https://shop.landroverusa.com/lego-technic-land-rover-defender

// `,
// // for /$224, which is a bit over original retail of $199.99:
//           },
//         },
//         {
//           date: new Date('2023-10-25'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: '[sec] Unimog U1400 Snowplow',
//             text: `

// # Unimog U1400 Snowplow

// 1:17 Unimog U1400 Snowplow by [timtimgo](https://rebrickable.com/users/timtimgo/mocs/) (1266 parts): [Eurobricks](https://www.eurobricks.com/forum/index.php?/forums/topic/188294-42126-unimog-u1400-snowplow-alternate-build/) / [Rebrickable](https://rebrickable.com/mocs/MOC-92700/timtimgo/42126-unimog-u1400-snowplow-alternate-build/#details) (€9.90)

// (An alternate build of [42126 Ford F-150 Raptor](https://rebrickable.com/sets/42126-1/ford-f-150-raptor/))

// <img src="https://tim-application.online/promo/ttl21c/r_img3.png" width="70%" style="padding-left:40px;">

// Modeled after:

// <img src="https://tim-application.online/promo/ttl21c/real1.jpg" width="70%" style="padding-left:40px;">


// #  The original model: 42126 F-150 Raptor

// [Jim from EB's review](https://www.eurobricks.com/forum/index.php?/forums/topic/187275-review-42126-ford-f-150-raptor/) has good photos/discussion of functional components:

// * Front independent suspension with yellow hard springs
// </br></br><img src="https://live.staticflickr.com/65535/51409818773_665495e528_b.jpg" width="70%">
// * Rear live axle suspension w/ grey softer springs
// </br></br><img src="https://live.staticflickr.com/65535/51409551371_4ce7aa880d_b.jpg" width="70%">

// fogeyman's [Studio review](https://fogeyman.tistory.com/1305) includes a render of 42126's gear trains and suspension (and as always, helpfully includes
// the \`.io\` file):

// <img src="https://img1.daumcdn.net/thumb/R1280x0/?scode=mtistory2&fname=https%3A%2F%2Fblog.kakaocdn.net%2Fdn%2FxDYRH%2FbtriKyygLVz%2F4g2PkP9WaVFAwuD9GrgB20%2Fimg.jpg" width="70%" style="padding-left:40px;">

// # Thoughts on Unimog (as alternate build)

// The gear trains in the Unimog are similar to those in the F-150, but the functions are reversed.
// Specifically, the steering gear train goes _through_ the red 16T clutch gear ([18946](https://www.bricklink.com/v2/catalog/catalogitem.page?P=18946#T=C)), and the drive train (connecting the wheels to the
// fake engine) uses the vertically aligned group of three (grey/red/grey) 16T gears. In the original F-150, it's the other way around;
// the drive train (shown in purple) goes _through_ the red gear and the steering (shown in red) uses the vertically aligned group of three gears.

// The description for this model says that it includes independent front and rear suspension, but I _think_
// the rear suspension is still live/solid axle (like the original model). This seems like the correct suspension
// choice for a Unimog, but not sure why the discrepancy.

// The scaling of this model is impressively accurate. And it uses 91% of the original's parts; all of the panels except for 3 of the fender pieces.
// The build experience was more interesting than the original set even though it's an alternate. The only alternate-driven trade-off was the
// use of different brick-built elements for the black accent to the front of each of the doors — which is extremely minor.
// Extremely high-quality build, instructions, and final model.

// Will certainly build more timtimgo models in the future. Some on the list:
// * [42108 American Tow Truck](https://rebrickable.com/mocs/MOC-43434/timtimgo/42108-american-tow-truck-alternate-build/#details)
// * [42127 Corvette Sting Ray](https://rebrickable.com/mocs/MOC-99666/timtimgo/42127-corvette-sting-ray-1963-alternate-build/#details)

// And of course the famous 1:8 alternates:
// * [42143 Ferrari F40](https://rebrickable.com/mocs/MOC-140404/timtimgo/42143-ferrari-f40-18-alternate-build/#details)
// * [42115 Lamborghini Miura Concept](https://rebrickable.com/mocs/MOC-119000/timtimgo/42115-lamborghini-miura-concept-alternate-build/#details)

// `,
//           },
//         },
//         {
//           date: new Date('2023-10-23'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: 'LEGO 10315 Tranquil Garden',
//             text: `
// \`\`\`
// Set number: 10315
// Set name: Tranquil Garden
// Pieces: 1,363
// Retail Price: US $109.99
// Theme: LEGO Icons
// LEGO Designers: Mike Psiaki, Carl Merriam
// Release Date: 1 August 2023
// \`\`\`

// On sale at Costco for $89.99. The build [before the trees] is tranquil. A similar relaxing build experience to Starry Night.
// Laying the foundation and water pieces was nice.
// Makes me appreciate Icons a bit more; the adult sets can have
// a nice sophisticated build experience that the kids sets lack e.g. even compared to laying the base
// of Ninjago City for example, which is very similar to this. Although, I think Mike Psiaki and Carl Merriam
// added to the quality of this set for sure and attributing this elegance to all Icons sets would be a terrible mistake.
// I didn't really enjoy building the trees and I think the overall look is also worsened by their addition.
// The teahouse is very nice and cute but the trees clutter the scene and make it feel pretty kind of woodsy and dirty
// rather than clean and carefully/intentionally landscaped as a Japanese garden is.
// `,
//           },
//         },
        {
          date: new Date('2023-10-22'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.FILM,
            title: 'Past Lives',
            year: 2012,
            director: 'Celine Song',
            frames: [
            ],
            text: `good cinematography. terribly disappointing screenwriting, acting.
            critical acclaim for this one is confounding. cinematography is really good though.`,
          },
        },
//         {
//           date: new Date('2023-10-19'),
//           showCollapseText: true,
//           textCollapsedOnInit: false,
//           filterTags: ['lego'],
//           item: {
//             type: itemType.POST,
//             title: 'LEGO 31109 Pirate Ship (C Model): Skull Island',
//             text: `
// This is the third build in the Creator 3-in-1: Skull Island:

// <img width="70%" src="/img/2023-10-19-lego-pirate-ship/31109-pirate-ship-skull-island-06-sm.jpg" alt="">

// It has a nice mechanism to move the skull up and down:

// <img width="70%" src="/img/2023-10-19-lego-pirate-ship/31109-pirate-ship-skull-island-01-sm.jpg" alt="">
// <img width="70%" src="/img/2023-10-19-lego-pirate-ship/31109-pirate-ship-skull-island-04-sm.jpg" alt="">

// Also nice expressions on the minifigs:

// <img width="70%" src="/img/2023-10-19-lego-pirate-ship/31109-pirate-ship-skull-island-02-sm.jpg" alt="">

// ^ The second fig in this render has the wrong head b/c the correct one
// isn't in Studio. The correct head:

// <img width="10%" src="https://img.bricklink.com/ItemImage/PN/3/3626cpb1335.png">

// The mini boat builds are also really delightful.

// In particular the steering rod and SNOT log profile bricks
// for the raft:

// <img width="70%" src="/img/2023-10-19-lego-pirate-ship/31109-pirate-ship-skull-island-03-sm.jpg" alt="">

// And the very clean and simple SNOT shaping of the
// small boat using curved slopes [11477](https://www.bricklink.com/v2/catalog/catalogitem.page?P=11477&idColor=88) and [61678](https://www.bricklink.com/v2/catalog/catalogitem.page?P=61678&idColor=88),
// and the modified plate with 2 studs [92593](https://www.bricklink.com/v2/catalog/catalogitem.page?P=92593#T=C&C=88) for detailing.
// The little additions like the lamp and map bring the boat to life. It's clear that the designer took a lot of care in making this set.

// This set was designed by [Jonathan Robson](https://brickset.com/sets/designer-Jonathan-Robson).
// The concept for the Skull Island model was made by [Eric Dubois Y](https://brickset.com/sets/designer-Eric-Dubois-Y).

// (Used Studio files by [fogeyman](https://fogeyman-tistory-com.translate.goog/1095?_x_tr_sl=auto&_x_tr_tl=en&_x_tr_hl=en&_x_tr_pto=wapp) to make the renders.)
// `,
//           },
//         },
        {
          date: new Date('2023-10-16'),
          endDate: new Date('2023-10-17'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Red Rock Canyon',
            text: `Stopped by Calico I before setting up camp at [Red Rock Canyon Campground](https://www.recreation.gov/camping/campgrounds/250877)
            to film T2 Camper Van. Stopped by Calico I again in the morning before timed entry to film Zetros and Turtle Van, before heading home.`,
            frames: [
              '2023-10-13-capitol-reef/red-rock-canyon-26',
              '2023-10-13-capitol-reef/red-rock-canyon-27',
              '2023-10-13-capitol-reef/red-rock-canyon-28',
              // '2023-10-13-capitol-reef/red-rock-canyon-29',
              '2023-10-13-capitol-reef/red-rock-canyon-30',
            ],
          },
        },
        {
          date: new Date('2023-10-13'),
          endDate: new Date('2023-10-15'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Capitol Reef National Park: Backpacking + Eclipse',
            text: `
<img width="100%" src="/img/2023-10-13-capitol-reef/fruita-map-backpacking-1.png" alt="capitol reef backpack route">
<div style="text-align:right;">
  <a href="https://www.nps.gov/care/planyourvisit/upload/Fruita-Area-Map-and-Guide-2021.pdf" target="_blank">Capitol Reef: Fruita Area Map and Guide</a>
</div>

# Day 1 — Friday, October 13
* Cache water off the road (1) at the end of Lower Spring Canyon Trail, and (2) at the end of Cohab Canyon Trail
* Park car at Chimney Rock
* Hike Chimney Rock Trail to Lower Spring Canyon Trail
* Camp close to the end of the canyon; plan to start at dawn on next day to make it out of Grand Wash in time to see the annular solar eclipse at 10:30am

# Day 2 — Saturday, October 14
* Clean up camp and start hiking before the sun comes up (plan to cross the river in the sun to avoid too cold)
* Cross/ford the river at the end of Lower Spring Canyon Trail
* Pick up first cache
* Walk along road briefly to Grand Wash Trailhead
* Hike Grand Wash Trail to Cassidy Arch Trail
* Find spot to watch annular solar eclipse (and shoot LEGO camper vans).
* Continue on Frying Pan Trail and camp just before Cohab Canyon

# Day 3 — Sunday, October 15
* Finish Cohab Canyon Trail and pick up cache
* Stop by Gifford House for strawberry rubarb pie and ice cream
* Walk along road (there's a parallel-ing dirt trail) briefly to Visitor Center
* Hike Sulphur Creek Route (narrows water hike, followed by dry wash) upstream back to Chimney Rock
  * Film camper vans in the creek behind the Visitor Center, before we enter canyon and get too wet
  * Had to climb over the first waterfall, then some light climbing later at different falls
  * Had a mishap in the dry wash / resulted in visit to hospital in Cedar City
`,
            frames: [
              '2023-10-13-capitol-reef/capitol-reef-04',
              '2023-10-13-capitol-reef/capitol-reef-05',
              '2023-10-13-capitol-reef/capitol-reef-06',
              '2023-10-13-capitol-reef/capitol-reef-07',
              '2023-10-13-capitol-reef/capitol-reef-08',
              '2023-10-13-capitol-reef/capitol-reef-09',
              '2023-10-13-capitol-reef/capitol-reef-10',
              // '2023-10-13-capitol-reef/capitol-reef-11',
              '2023-10-13-capitol-reef/capitol-reef-12',
              '2023-10-13-capitol-reef/capitol-reef-13',
              '2023-10-13-capitol-reef/capitol-reef-14',
              '2023-10-13-capitol-reef/capitol-reef-15',
              '2023-10-13-capitol-reef/capitol-reef-16',
              '2023-10-13-capitol-reef/capitol-reef-17',
              '2023-10-13-capitol-reef/capitol-reef-18',
              '2023-10-13-capitol-reef/capitol-reef-19',
              '2023-10-13-capitol-reef/capitol-reef-21',
              // '2023-10-13-capitol-reef/capitol-reef-22',
              '2023-10-13-capitol-reef/capitol-reef-26',
              '2023-10-13-capitol-reef/capitol-reef-23',
              // '2023-10-13-capitol-reef/capitol-reef-24',
              // '2023-10-13-capitol-reef/capitol-reef-25',
            ],
          },
        },
        {
          date: new Date('2023-10-12'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Sand Mountain Recreation Area',
            text: `
Stopped by [Sand Mountain Recreation Area](https://www.blm.gov/visit/sand-mountain-recreation-area) on the way to Capitol Reef
to film camper vans. It's like an ATV play area on BLM land. Lots of RVs.
`,
            frames: [
              '2023-10-13-capitol-reef/sand-mountain-01',
              '2023-10-13-capitol-reef/sand-mountain-03',
              '2023-10-13-capitol-reef/sand-mountain-02',
            ],
          },
        },
        {
          date: new Date('2023-09-28'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Commitment',
            gradeYDS: '5.9',
            area: 'Yosemite National Park',
            crag: 'Five Open Books',
            climbType: climbType.TRAD,
            lengthFt: 320,
            numPitches: 3,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105867013/commitment',
            text: `
Had a bit of trouble finding the climb. Located Munginella and kept going along Five Open Books. Spotted the roof then confirmed
with some nearby climbers that the start of Commitment was a scrample up at 'the tree.'

Climbed the first pitch (5.8) then rappelled. It was challenging / had to rest. There was
a wide section that was scary; got past it on a hope, but probably it would've been fine to fall on gear there. Traverse across flakes at the top
of the pitch was also scary even though the climbing was easy b/c hard to protect.
I was too slow so no time for M to climb; had to go home.
            
Took 1 hour 50 min to lead the single pitch.
Not including rappel and cleaning (which also took a long time; M walked me through the rappel setup over walkie, and orange cam was stuck.)
`,
          },
        },
        {
          date: new Date('2023-09-27'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Highway Star',
            gradeYDS: '5.10a',
            area: 'Yosemite National Park',
            crag: 'Highway Star',
            climbType: climbType.TOPROPE,
            lengthFt: 60,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106009915/highway-star',
            text: `Found Highway Star off the highway (by locating the boulder with a 25 mph sign before it). M set up a very serious top rope anchor for us and was attacked by a log. I climbed once on top rope. Then M climbed and discovered/determined that it's all flakes and danger!
            Decided to go relax and do tourist things — visited Glacier Point and ate Mama ramens.`,
          },
        },
        {
          date: new Date('2023-09-26'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Pine Line',
            gradeYDS: '5.7',
            area: 'Yosemite National Park',
            crag: 'El Capitan Base',
            climbType: climbType.TRAD,
            lengthFt: 70,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105841118/pine-line',
            text: `M led Pine Line. We then went to check out [Claude's Delight](https://www.mountainproject.com/route/106653590/claudes-delight) and [Lena's Lieback](https://www.mountainproject.com/route/105867066/lenas-lieback).
            I was too depleted from yesterday's 'epic' to manage.
            Observed some guides at Swan Slab; apparently to get down from Swan Slab you just have to sling trees and rocks and things; not sure
            what is the deal with leaving gear.
            Since we didn't want to climb, we approached [Jamcrack](https://www.mountainproject.com/route/105866367/jamcrack) to check it out. Looks challenging but at least you can see the whole line; no route-finding issues.
            Witnessed someone toprope solo-ing a route next to Jamcrack (maybe [Bummer](https://www.mountainproject.com/route/105879421/bummer) or [Lazy Bum](https://www.mountainproject.com/route/105878833/lazy-bum)) with a micro-traxion and a backpack as a counterweight.`,
          },
        },
        {
          date: new Date('2023-09-25'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Bishop's Terrace",
            gradeYDS: '5.8',
            area: 'Yosemite National Park',
            crag: 'Church Bowl',
            climbType: climbType.TRAD,
            lengthFt: 150,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105872317/bishops-terrace',
            text: `
Climbed it in two pitches because ran out of gear. And also there were several parties behind us and I was slow. Mostly straight-forward climbing except for the small wide section, but much anxiety from people passing and waiting.
M sprained ankle up the second pitch because I didn't pull the rope stretch out; still need to figure out how to do this without using the 3:1.
Rappelled to a terrace close to the ground w/ our 80m, but then had to do a small rappel from the tree to make it to the ground. (Probably should've used the intermediate rappel station, but we needed to get down quick
to give gear to the party that came down before us.).

Took 3.5 hours to lead to the first stopping point (just before the wide section). Not entirely sure what took up so much of the time.
I don't remember actually being _stuck_, so probably just slow deliberation about placing and route-finding. (The following parties didn't
pass until after I had belayed M up to this point, so the time wasn't from that.)

I also did a bad on the first rappel. M had gone down first and I was at the top with the guy who had just come up.
I was anxious setting up the rappel b/c of the guy and ended up adjusting my autoblock while weighting the rappel after
my backup had already been removed. I must've been holding the brake strand the whole time, because it was fine, but
I was not being very deliberate b/c of anxiety.`,
          },
        },
        {
          date: new Date('2023-09-19'),
          item: {
            type: itemType.FILM,
            title: 'Temple Grandin',
            year: 2010,
            director: 'Mick Jackson',
            frames: [
            ],
            text: ``,
          },
        },
        {
          date: new Date('2023-08-21'),
          item: {
            type: itemType.BOOK,
            title: 'Climbing Anchors',
            year: 2013,
            author: 'John Long, Bob Gaines',
            inProgress: true,
            inProgressCurrentChapter: 3,
            inProgressTotalChapter: 10,
          },
        },
        {
          date: new Date('2023-08-06'),
          // filterTags: [''],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: '_War and Peace_ by Tolstoy: Historical Context',
            text: `
> The novel spans the period from 1805 to 1820. The era of [Catherine the Great](https://en.wikipedia.org/wiki/Catherine_the_Great) was still fresh in
> the minds of older people. Catherine had made French the language of her royal court.
> For the next 100 years, it became a social requirement for the Russian nobility to speak French and understand French culture.
>
> The historical context of the novel begins with the execution of [Louis Antoine, Duke of Enghien](https://en.wikipedia.org/wiki/Louis_Antoine,_Duke_of_Enghien) in 1805,
> while Russia is ruled by [Alexander I](https://en.wikipedia.org/wiki/Alexander_I_of_Russia) during the [Napoleonic Wars](https://en.wikipedia.org/wiki/Napoleonic_Wars). Key historical events woven into the
> novel include the [Ulm Campaign](https://en.wikipedia.org/wiki/Ulm_campaign), the [Battle of Austerlitz](https://en.wikipedia.org/wiki/Battle_of_Austerlitz),
> the [Treaties of Tilsit](https://en.wikipedia.org/wiki/Treaties_of_Tilsit), and the [Congress of Erfurt](https://en.wikipedia.org/wiki/Congress_of_Erfurt). Tolstoy also references the [Great Comet of 1811](https://en.wikipedia.org/wiki/Great_Comet_of_1811) just before the [French invasion of Russia](https://en.wikipedia.org/wiki/French_invasion_of_Russia).
>
> Tolstoy then uses the [Battle of Ostrovno](https://en.wikipedia.org/wiki/Battle_of_Ostrovno) and the [Battle of Shevardino Redoubt](https://en.wikipedia.org/wiki/Battle_of_Borodino#Battle_of_Shevardino) in his novel, before
> the occupation of Moscow and the subsequent [fire](https://en.wikipedia.org/wiki/Fire_of_Moscow_(1812)).
> The novel continues with the [Battle of Tarutino](https://en.wikipedia.org/wiki/Battle_of_Tarutino),
> the [Battle of Maloyaroslavets](https://en.wikipedia.org/wiki/Battle_of_Maloyaroslavets), the [Battle of Vyazma](https://en.wikipedia.org/wiki/Battle_of_Vyazma),
>  and the [Battle of Krasnoi](https://en.wikipedia.org/wiki/Battle_of_Krasnoi). The final battle
> cited is the [Battle of Berezina](https://en.wikipedia.org/wiki/Battle_of_Berezina), after which the characters move on with rebuilding Moscow and their lives.

<div style="text-align:right;">
<a href="https://en.wikipedia.org/wiki/War_and_Peace#Background_and_historical_context">"War and Peace: Background and historical context", Wikipedia</a>
</div>

# French Revolutionary Wars

<details style="margin-left: 30px">
<summary><b>[1792 – 1797]</b> War of the First Coalition</summary>

**Background:** This war began as a response to the French Revolution, especially after the execution of Louis XVI. Many European monarchies were concerned about the revolutionary ideas spreading and threatening their own regimes.

**Major Coalition Members:** Austria, Prussia, Great Britain, Spain, and several others.

**Major Events:**

  1. **Valmy (1792):** French forces stopped a Prussian advance, solidifying the new revolutionary regime.
  2. **Toulon (1793):** A significant siege where a young Napoleon Bonaparte first made a name for himself by recapturing the city from a British-Spanish force.
  3. **Flanders Campaign (1792-1795):** A series of battles in the Austrian Netherlands (Belgium).
  4. **Italian Campaigns (1796-1797):** Led by Napoleon, the French Army of Italy defeated Austrian and Sardinian forces, showcasing Napoleon's military genius.

**Outcome:** The war ended with the Treaty of Campo Formio in 1797. France annexed the Austrian Netherlands (present-day Belgium) and established satellite republics in northern Italy.

</details>

</br>

<details style="margin-left: 30px">
<summary><b>[1798 - 1802]</b> War of the Second Coalition</summary>

**Background:** Dissatisfaction with the results of the First Coalition and concerns over French expansion and revolutionary ideals led to the formation of the Second Coalition.

**Major Coalition Members:** Austria, Russia, Ottoman Empire, Great Britain, Portugal, and several Italian states.

**Major Events:**

  1. **French invasion of Egypt (1798-1801):** Led by Napoleon, this was an attempt to undermine British influence in India. However, the campaign is most famously associated with the Battle of the Nile, a major naval defeat for the French against the British under Admiral Nelson.
  2. **Italian and Swiss Campaigns (1799-1800):** Battles raged across Italy and Switzerland. The French faced defeats but then rebounded with victories like the Battle of Marengo where Napoleon, having returned from Egypt, reasserted control.
  3. **Russian Involvement:** Tsar Paul I of Russia was initially enthusiastic about fighting the French but after some Russian setbacks and diplomatic issues, he withdrew his support.

**Outcome:** The war concluded with the Treaty of Amiens in 1802, which was a temporary peace agreement between France and Great Britain. The uneasy peace set the stage for the subsequent Napoleonic Wars.

Both wars saw a Revolutionary France, often under-resourced and facing internal strife, managing to resist and often defeat the combined efforts of multiple established European powers. These wars set the stage for Napoleon Bonaparte's rise to power as the leading figure in France and subsequently, its Emperor.

</details>


# Napoleonic Wars

* **[November 9, 1799]** — coup d'état of 18th Brumaire; Napoleon Bonaparte seized power

* **[December 2, 1804]** — Napoleon crowned himself Emperor Napoleon I

<details style="margin-left: 30px">
<summary><b>[1805 - 1806]</b> War of the Third Coalition</summary>

**Background:** The War of the Third Coalition was precipitated by Napoleon's expansionist ambitions,
notably his self-coronation as Emperor of the French in 1804 and subsequent annexations in Europe.
The short-lived peace established by the Treaty of Amiens in 1802 collapsed by 1803 due to British
concerns over French influence in Italy and Switzerland, as well as growing continental unease with
France's power. By 1805, Britain had forged alliances with Russia and Austria, setting the stage
for a united front against Napoleon's evident intention to invade Britain and his increasing dominance in Central Europe.

**Major Coalition Members:** Austria, Great Britain, Russia, Sweden, Naples

**Major Events:**

  1. **The Battle of Ulm (October 1805)**: This was a key French victory. Napoleon’s forces enveloped Austrian General Karl Mack’s army near Ulm, resulting in a massive Austrian loss.

  2. **The Battle of Trafalgar (October 1805)**: This naval battle saw the British Royal Navy, under Admiral Horatio Nelson, achieve a significant victory over the combined fleets of the French and Spanish Navies. Although Nelson was killed in the battle, the British prevented an invasion and confirmed their naval supremacy.

  3. **The Battle of Austerlitz (December 2, 1805)**: Known as the "Battle of the Three Emperors," Napoleon scored a decisive victory
  against the Austrians and Russians. The Russian army, under the command of Tsar Alexander I
  and his general Mikhail Kutuzov, combined with Austrian forces under Holy Roman Emperor Francis II,
  faced Napoleon in what is now the Czech Republic. Despite initially holding a strong position,
  the Russo-Austrian forces were outmaneuvered by Napoleon in what is often considered his greatest victory.
  4. **Campaigns in Italy and the Adriatic:** French forces repelled Austrian and Russian advances in Italy and along the Adriatic coast.

**Outcome:**

* The war concluded with the dissolution of the Holy Roman Empire in 1806. Napoleon established the Confederation of the Rhine, a group of German states under French influence.
* Austria signed the Treaty of Pressburg in December 1805 after its defeat, leading to considerable territorial concessions to France and its allies.
* Despite Napoleon's continental successes, the Battle of Trafalgar had significant implications. The British naval dominance resulting from this battle severely impacted French trade and set the stage for the Continental System, Napoleon's effort to economically isolate Britain.
</details>
</br>

<details style="margin-left: 30px">
<summary><b>[1806 - 1807]</b> War of the Fourth Coalition</summary>
  
**Background:**
The continued territorial expansion of Napoleon's French Empire, his creation of satellite states, and the dissolution of the Holy Roman Empire to form the Confederation of the Rhine alarmed various European powers. Particularly, Prussia was perturbed by the French influence on the German states and the Continental System Napoleon imposed, which was detrimental to Prussian trade.

**Major Coalition Members:** Great Britain, Prussia, Russia, Saxony (initially, though it later became a part of the Confederation of the Rhine), Sweden

**Major Events and Campaigns:**

  1. **Battle of Jena-Auerstedt (October 14, 1806):** Two significant battles fought on the same day. At Jena, Napoleon's forces defeated a Prussian army led by Prince Hohenlohe. Meanwhile, at Auerstedt, a French corps under Marshal Davout defeated the main Prussian army commanded by the Duke of Brunswick, who was killed. These victories led to the rapid occupation of most of Prussia by French forces.

  2. **Siege of Danzig (March-May 1807):** A key siege during which French forces captured the fortressed city of Danzig (modern-day Gdańsk in Poland).

  3. **Battle of Eylau (February 7-8, 1807):** Fought between French forces and the combined armies of Russia and Prussia, the battle was indecisive but bloody. Both sides claimed victory, but neither could achieve a decisive advantage.

  4. **Battle of Friedland (June 14, 1807):** Napoleon decisively defeated the Russian army, forcing Tsar Alexander I to seek peace.

**Outcome:**

The war ended with the Treaties of Tilsit in July 1807. These treaties were signed between Napoleon and Tsar Alexander I of Russia and King Frederick William III of Prussia.

  * **Treaty with Russia:** Russia had to join the Continental System against British trade. The two emperors, Napoleon and Alexander, formed a Franco-Russian alliance. Russia also acknowledged the Grand Duchy of Warsaw, a French client state created from Polish lands previously partitioned by Prussia and Austria.

  * **Treaty with Prussia:** Prussia suffered significant territorial losses, including territories west of the Elbe that went to the newly created Kingdom of Westphalia, a French satellite. The lands taken from Prussia in western Poland became part of the Grand Duchy of Warsaw. In total, Prussia lost about half of its population due to these territorial changes.

The War of the Fourth Coalition saw a continuation of French dominance on the continent. The Treaties of Tilsit represented a high point in Napoleon's power, but they sowed the seeds for future conflicts, especially with the establishment of the Duchy of Warsaw, which threatened to rekindle Polish nationalism and drew the attention and concern of Austria and Russia.
</details>
</br>

<details style="margin-left: 30px">
<summary><b>[1807 - 1814]</b> Peninsular War</summary>

The Peninsular War (1807-1814) was a major conflict during the Napoleonic Wars,
fought primarily in the Iberian Peninsula (Spain and Portugal). It pitted the French Empire against a
fluctuating alliance of Britain, Spain, and Portugal.

**Background:** The Peninsular War began when Napoleon attempted to enforce the Continental System in
Portugal and then placed his brother Joseph Bonaparte on the Spanish throne in June 1808, which led to widespread resistance.

**Major Events:**
  * **Popular Uprising:** Spanish popular resistance against the French started with the Dos de Mayo Uprising in Madrid in 1808 and spread throughout the country.
  * **British Involvement:** In August 1808, a British expeditionary force under Sir Arthur Wellesley (later Duke of Wellington) landed in Portugal to assist the Portuguese and Spanish in their fight against the French.
  * **Guerilla Warfare:** In addition to conventional battles, much of the Peninsular War consisted of Spanish guerilla warfare against French occupiers. These irregular forces greatly harassed the French, making long-term occupation difficult.
  * **Major Battles:** Over the course of the war, significant battles included Vimiero (1808), Talavera (1809), Salamanca (1812), and Vitoria (1813), among others.
  * **French Expulsion:** By 1813, the combined efforts of the British, Portuguese, and Spanish forces had successfully driven the French out of the Iberian Peninsula.

**Outcome:**
The Peninsular War concluded in April 1814, shortly after Napoleon's first abdication.
The French were expelled from Spain, and Joseph Bonaparte was removed from the Spanish throne.
The war drained French resources and proved that the French Empire could be challenged through
guerilla warfare and a determined conventional defense. The Peninsular War,
combined with the French invasion of Russia in 1812, stretched Napoleon's resources thin.
</details>
</br>

<details style="margin-left: 30px">
<summary><b>[1809]</b> War of the Fifth Coalition</summary>

**Background:** Austria sought to reclaim lost territories and diminish French influence, leveraging French distractions in Spain.

**Major Coalition Members:** Austria, Great Britain

**Major Events:**

  1. **Battle of Aspern-Essling:** Napoleon suffered his first significant tactical defeat.
  2. **Battle of Wagram:** A massive French victory.

**Outcome:**
* **The Treaty of Schönbrunn:** Austria ceded territory to France and its allies, further weakening the Habsburg power.
</details>
</br>

<details style="margin-left: 30px">
<summary><b>[1812]</b> French Invasion of Russia (Russia Campaign of 1812)</summary>

**Background:**

* **Continental System:** Napoleon's Continental System sought to cripple the British economy by banning European nations from trading with Britain. Russia initially agreed to this but later began to break from it, leading to tensions.
* **Diplomatic Strains:** Despite the Treaty of Tilsit (1807) that had established friendly relations between France and Russia, various disagreements arose, particularly regarding the Duchy of Warsaw (a French satellite state) and Russian ambitions in the Balkans against the Ottoman Empire.

**Invasion:**

* **Grand Armée:** Napoleon assembled a massive army, the Grand Armée, consisting of over 600,000 troops from various European nations under French control or influence.
* **Beginning of the Campaign:** In June 1812, Napoleon's forces crossed the Neman River, marking the start of the invasion.
* **Russian Strategy:** The Russian armies, rather than giving battle, kept retreating deeper into Russia, adopting a scorched earth policy: they burned crops, villages, and towns to deprive the advancing French army of supplies.

**Major Battles and Events:**

* **Battle of Smolensk (August 1812):** While it was a French victory, it caused significant casualties and slowed Napoleon's advance.
* **Battle of Borodino (September 1812):** The bloodiest single-day action of the campaign. Though the French claimed a tactical victory, the Russians did not break, and their army remained a threat.
* **Moscow (September 14, 1812):** Napoleon entered Moscow expecting the Russians to sue for peace, but instead, he found the city abandoned and set aflame. With winter approaching and no sign of a Russian surrender, he decided to retreat.

**Retreat:**

* **Winter Onset:** As the harsh Russian winter set in, the retreating Grand Armée faced immense hardships, including cold, starvation, disease, and attacks from Russian forces and partisans.
* **Crossing the Berezina River (November 1812):** In a desperate battle, the French fought Russian troops while attempting to cross the Berezina River, leading to heavy French casualties.
* **Return to Poland (December 1812):** By December, the remnants of the Grand Armée, now a fraction of its original size, retreated to Poland.

**Consequences:**

* **Casualties:** The campaign resulted in catastrophic losses for the French, with estimates suggesting that only about 20,000 of the original troops returned.
* **Shift in European Power Dynamics:** The disaster in Russia marked the beginning of the end for Napoleon's dominance in Europe. Sensing his vulnerability, many nations of Europe formed a new coalition against him in 1813.
* **Cultural Impact:** The invasion has been immortalized in various art forms, most notably in Leo Tolstoy's "War and Peace" and Tchaikovsky's "1812 Overture."

The campaign was a turning point in the Napoleonic Wars and showcased the risks of long supply lines, the challenges of diverse multinational forces, and the deadly impact of harsh environmental conditions on military campaigns.

</details>
</br>

<details style="margin-left: 30px">
<summary><b>[1813 - 1814]</b> War of the Sixth Coalition</summary>

**Background:** After Napoleon's Russian campaign disaster, a new coalition formed against him, including Britain, Russia, Spain, Portugal, Prussia, Austria, Sweden, and several German states.

**Major Coalition Members:** Russia, Prussia, Austria (from August 1813), Great Britain, Spain, Portugal, Sweden, several German states

**Major Events:**

* Battles across Germany and France, including the Battle of Leipzig (1813), also known as the "Battle of Nations," which was a severe defeat for Napoleon.

**Outcome:**
Paris was captured in 1814, forcing Napoleon's abdication. He was exiled to the island of Elba, and the Bourbon monarchy was restored in France with Louis XVIII as king.

</details>
</br>

<details style="margin-left: 30px">
<summary><b>[1815]</b> War of the Seventh Coalition (Hundred Days)</summary>

**Background:** Napoleon escaped from Elba and returned to France, leading to "The Hundred Days" where he regained power.

**Major Coalition Members:** Great Britain, Prussia, Russia, Austria, Netherlands, Hanover, Nassau, Brunswick

**Major Event:**

  * **Battle of Waterloo (June 18, 1815):** Napoleon's final defeat against a British-led coalition under Wellington and a Prussian army under Gebhard Leberecht von Blücher.

**Outcome:** Napoleon was permanently exiled to the remote island of Saint Helena in the South Atlantic, where he died in 1821. The Congress of Vienna followed, which aimed to restore stability and balance in Europe.

</details>
</br>

---

Organized by Volume and Part in _War and Peace_:

# War and Peace

## VOLUME I

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px; width: 35%;">Part I: July-August 1805</td>
        <td style="border: 1px solid #ddd; padding:10px;">Society people are talking about Napoleon. He has recently (December 4, 1804) declared himself Emperor.</td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part II: October-November 1805</td>
        <td style="border: 1px solid #ddd; padding:10px;">&#x2022; The Battle of Ulm seems to be taking place; Austria is going to lose.</br>
        &#x2022; The Battle of Schöngrabern; Nikolay is injured.
        </td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part III: November 1805</td>
        <td style="border: 1px solid #ddd; padding:10px;">The Battle of Austerlitz begins; Andrey is captured.</td>
      </tr>
    </tbody>
  </table>
</div>

## VOLUME II

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px; width: 35%;">Part I: 1805-6</td>
        <td style="border: 1px solid #ddd; padding:10px;">The Battle of Austerlitz (December 2, 1805)</td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part II: 1807</td>
        <td style="border: 1px solid #ddd; padding:10px;">
          &#x2022; The War of the Fourth Coalition</br>
          &#x2022; July 1807: Treaty of Tilsit: Russia had to join the Continental System against British trade.
          The two emperors, Napoleon and Alexander, formed a Franco-Russian alliance. Russia also acknowledged
          the Grand Duchy of Warsaw, a French client state created from Polish lands previously partitioned by Prussia and Austria.
        </td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part III: 1808-10</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part IV: 1810-11</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part V: 1811-12</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
    </tbody>
  </table>
</div>

## VOLUME III

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px; width: 35%;">Part I: May-July 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;">June 1812: French Invasion of Russia begins (despite Treaty of Tilsit)</td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part II: August 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;">Battle of Smolensk (August 1812)</td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part III: September 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;">
          &#x2022; Battle of Borodino (September 1812)</br>
          &#x2022; September 14, 1812: Napoleon enters Moscow to find city largely abandoned.
        </td>
      </tr>
    </tbody>
  </table>
</div>

## VOLUME IV

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px; width: 40%;">Part I: August 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part II: October 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part III: October-November 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part IV: November-December 1812</td>
        <td style="border: 1px solid #ddd; padding:10px;">
          &#x2022; Crossing the Berezina River (November 1812): French fought Russian troops while attempting to cross Berezina River, leading to heavy French casualties
          </br>
          &#x2022; December: French army retreated to Poland
        </td>
      </tr>
    </tbody>
  </table>
</div>

## EPILOGUE

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px; width: 40%;">Part I: 1813-20</td>
        <td style="border: 1px solid #ddd; padding:10px;">
&#x2022; Paris captured in 1814; Napoleon exiled to Elba</br>
&#x2022; 1815: Napoleon returned for "The Hundred Days"</br>
&#x2022; June 18, 1815: Napoleon defeated at Battle of Waterloo and permanently exiled.
        </td>
      </tr>
      <tr>
        <th style="border: 1px solid #ddd; padding:10px;">Part II</td>
        <td style="border: 1px solid #ddd; padding:10px;"></td>
      </tr>
    </tbody>
  </table>
</div>

`,
            frames: [],
          },
        },
        {
          date: new Date('2023-08-04'),
          endDate: new Date('2023-09-03'),
          item: {
            type: itemType.FILM,
            title: 'Dekalog',
            marker: '+',
            year: 1989,
            director: 'Krzysztof Kieślowski',
            frames: [
            ],
            text: ``,
          },
        },
        {
          date: new Date('2023-07-31'),
          item: {
            type: itemType.FILM,
            title: 'Honnold 3.0',
            year: 2012,
            director: 'Josh Lowell, Peter Mortimer',
            frames: [
            ],
            text: ``,
          },
        },
        {
          date: new Date('2023-07-26'),
          endDate: new Date('2023-07-28'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Olympic National Park + Mount Rainier National Park',
            text: `
# Olympic National Park
Wednesday, July 26
* [Hurricane Ridge to Mt. Angeles via Klahhane Ridge Trail](https://www.alltrails.com/trail/us/washington/sunrise-ridge-trail-to-mount-angeles), 5.5 mi
  * Up-close deer. Trail seemed to hit some Class 4, so we went back to take a different fork in the trail that went around to climber's left;
    did some off-trail bushwacking/scrambling to get up to Mt. Angeles. On the way down, caught the first trail we had turned back on and 
    discovered that there had only been a short safe scramble up at the point where we had turned back. Tried
    to leave some cairns sort of. This trail is now currently (Aug 6) closed because of [cougar activity](https://www.nps.gov/olym/learn/news/8-year-old-attacked-by-cougar-at-lake-angeles-avoids-serious-injuries.htm).
* Camped at [Mora Campground](https://www.recreation.gov/camping/campgrounds/247591)
  * Lesson: apparently wood needs to be dry in order to burn.

Thursday, July 27
* [Rialto Beach to Hole-in-the-Wall](https://www.alltrails.com/trail/us/washington/hole-in-the-wall-trail), 3.3 mi
  * Walked along beach. Easiest where the water has receded and the sand is packed down. Did pretty fun scramble/climb
    to get over the water to the "hole-in-the-wall".
* [Hoh Rainforest: Hoh River Trail to Tom's Creek](https://www.alltrails.com/trail/us/washington/hoh-river-trail-to-mineral-creek-falls--4), 5.6 mi
  * It is very green. Nice that it was dry/sunny in the rainforest.
# Mount Rainier National Park
Friday, July 28
* [Skyline Trail Loop](https://www.alltrails.com/trail/us/washington/skyline-trail), 5.6 mi
  * Full of chipmunks that are unafraid to jump on to your actual body.
  * Uphill was semi-strenuous for a trail (e.g. it has steps) but then the rest is all 
    downhill which makes the loop very mentally easy (compared to up and down and up and down etc.)
    There are alpine climbers who are also taking this trail up to Mount Rainier.
    And quite a bit of people in general. By the downhill part, however, there were actually quite few people,
    so not sure why that is. Maybe people go out and back instead of taking the loop. 
    The end of the loop has a wild botanical garden with signs identifying the flora.
  * People on AllTrails like to prescribe about whether to go clockwise or counter-clockwise. I don't remember
    which way we went — the way that has Mount Rainier in view the whole time. And again, the uphill-to-downhill-ness
    was very pleasant (although the same would be true the other way, but I'm not sure about steepness 
    on the up vs the down.)
            `,
            frames: [],
          },
        },
        {
          date: new Date('2023-07-24'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'North Cascades NP: Liberty Bell',
            text: `
Did the approach for [Beckey Route (SW Face) (T 5.6)](https://www.mountainproject.com/route/105797867/beckey-route-sw-face), 500 ft, 4 pitches — which took forever and 
involved quite a lot of scrambling. There were clouds we were a-watching. Once we got to the base of the route,
there was one party on Pitch 1 and another waiting. We were setting up to wait, but then felt rain drops, so decided to bail. The scramble
down was pretty involved. Got to see some mountain goats extremely up close though (mostly on the way up, but they were around later too).
`,
            frames: [],
          },
        },
        {
          date: new Date('2023-07-23'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Where's Maddie?",
            gradeYDS: '5.9',
            location: 'Squamish',
            area: 'Murrin Park',
            crag: 'Commune',
            climbType: climbType.SPORT,
            lengthFt: 104,
            mountainProjectUrl: 'https://www.mountainproject.com/route/119405401/wheres-maddie',
            text: `
Figuring out how to get to first bolt was hard; skipped the move (clipped it w/ clip stick). The rest of the 
route was fun. Mostly easy climbing, but had to figure out the best route(sometimes over and around). 
Hung a couple of times to rest.

First experience at outside crag with folks on nearby routes. Use belayer's name before commands.
We should've brought rocky talkies, but didn't. (Other people just shout, but we would prefer talkies) .
Asking neighbors when it's good to pull the rope.`,
          },
        },
        {
          date: new Date('2023-07-22'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: "Nubile Woman",
            gradeYDS: '5.10a',
            location: 'Squamish',
            area: 'Smoke Bluffs',
            crag: 'Nubile Woman',
            climbType: climbType.TRAD,
            lengthFt: 50,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106018921/nubile-woman',
            text: `Fun finger crack. Takes various sizes. Hung on gear to rest. Getting over the top 
            was non-trivial; was expecting good jugs, but holds were slopey, but well-marked with chalk.`,
          },
        },
        {
          date: new Date('2023-07-21'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Definitely Not The Dawn Wall',
            gradeYDS: '5.6',
            location: 'Squamish',
            area: 'Smoke Bluffs',
            crag: 'Parking Lot Wall',
            climbType: climbType.TOPROPE,
            lengthFt: 32,
            mountainProjectUrl: 'https://www.mountainproject.com/route/114561844/definitely-not-the-dawn-wall',
            text: `K50 activity. First hand crack. Led by M (with two cams). Followed on toprope. 
            Tried to practice hand jams, 
            but only did a couple since there are other features around to pull on.`,
          },
        },
        {
          date: new Date('2023-07-21'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Frontside 180',
            gradeYDS: '5.8',
            location: 'Squamish',
            area: 'Chek',
            crag: 'Electric Avenue',
            climbType: climbType.SPORT,
            lengthFt: 720,
            numPitches: 10,
            mountainProjectUrl: 'https://www.mountainproject.com/route/117387778/frontside-180',
            text: `Practiced rope work. Very nice to have rocky talkies. (Learned later we should always bring them for single pitch too).
            Route was very easy, bordering on boring toward the end, but good practice and did indeed make several mistakes
            with belaying from the top (1. i'm stupid and positioned the anchor in a useless position, 
            2. didn't know how to deal with rope drag), so it's good the climbing was easy. We let a party pass us
            at the top of the first pitch, which made the rest of the climb less stressful without someone
            directly behind. (A party of 3 ended up behind, but they weren't always on our tail.) Swapped leads (M on Pitch 1). I don't
            remember if maybe we linked pitches 9 and 10.
            `,
          },
        },
        {
          date: new Date('2023-07-20'),
          endDate: new Date('2023-07-23'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Squamish, BC',
            text: `
## Thursday, July 20
* Hike: [Joffre Lakes](https://www.alltrails.com/trail/canada/british-columbia/joffre-lakes?u=i) (almost to second lake)

## Friday, July 21
* Climb: Chek > Electric Avenue: [Frontside 180 (S 5.8), 10 pitches](https://www.mountainproject.com/route/117387778/frontside-180)
* Hello: [Alice Lake](https://bcparks.ca/alice-lake-park/)
* Climb: Smoke Bluffs > Parking Lot Wall: [Definitely Not The Dawn Wall (T 5.6)](https://www.mountainproject.com/route/114561844/definitely-not-the-dawn-wall)

## Saturday, July 22
* Climb: Smoke Bluffs > Nubile Woman: [Nubile Woman (T 5.10a)](https://www.mountainproject.com/route/106018921/nubile-woman)

## Sunday, July 23
* Climb: Murrin Park > Commune: [Where's Maddie? (S 5.9)](https://www.mountainproject.com/route/119405401/wheres-maddie)
* Climb [M]: Murrin Park > Betazoid: [Jugs, Not Drugs (S 5.8)](https://www.mountainproject.com/route/108303905/jugs-not-drugs)
`,
            frames: [],
          },
        },
        {
          date: new Date('2023-07-19'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Mount Rainier NP: Third Burroughs to Skyscraper Mountain',
            text: `[Third Burroughs and Skyscraper Traverse](https://www.mountaineers.org/activities/routes-places/third-burroughs-skyscraper-mountain-traverse), 9.0 mi, 6400' to 7400' (4 summits)
* Sunrise to First, Second, and Third Burrough.
* Third Burrough had the best view — of Mount Rainier on one side and a big valley on the other side.
* Then did the off-trail traverse to Skyscraper Mountain. Tried to aim for the ridgeline and follow it, but got stuck because it becomes Class 5. 
  Went back and down and around (through some trees and down a big rock gully), then up a trail to Skyscraper Mountain. 
  Tippy top of Skyscraper was kind of scary, but okay enough to eat a snack.
* Took the Wonderland Trail to get back to Sunrise.
* Overall, was like 14 mi?
            `,
            frames: [],
          },
        },
        {
          date: new Date('2023-07-17'),
          filterTags: ['outside'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Lassen National Park: Lassen Peak',
            text: `[Lassen Peak Trail](https://www.alltrails.com/trail/us/california/lassen-peak), 4.9 mi, 8500' to 10,457'

I only remember the very beginning of this, where there was some snow and we filmed some microspikes.
And the very end, where there was a lot of uphill to a peak where a kid set up his telescope. Then there was
another traverse to Lassen Peak, where there was a round plaque to mark it (although it wasn't at the highest point).
There were some travelers talking loudly about their accomplishments. And another group of young people having a good time.
There was a peak in the distance, but I don't remember which one. Shasta?
`,
            frames: [],
          },
        },
        {
          date: new Date('2023-07-10'),
          item: {
            type: itemType.FILM,
            title: 'Showing Up',
            year: 2022,
            director: 'Kelly Reichardt',
            frames: [
            ],
            text: ``,
          },
        },
        {
          date: new Date('2023-07-06'),
          filterTags: ['science'],
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.BOOK,
            title: 'Molecular Biology: Principles of Genome Function, 3rd Edition',
            year: 2021,
            author: 'Nancy L. Craig et al.',
            inProgress: true,
            inProgressCurrentChapter: 5, // p.117 [page-number]-116 = 117-116 = 1
            inProgressTotalChapter: 19, // p.160 [page-number]-116 = 160-116 = 44 (44 pages total in chapter 4)
            // red right arrow: <span style="color:#EE352E;">&#10140;</span> 
            // &#x2022; <b>Ch 6:</b> DNA replication<br/>
            // &#x2022; <b>Ch 7:</b> Chromosome segregation<br/>
            // &#x2022; <b>Ch 8:</b> Transcription<br/>
            // &#x2022; <b>Ch 9:</b> Regulation of transcription<br/>
            // &#x2022; <b>Ch 10:</b> RNA processing<br/>
            // &#x2022; <b>Ch 11:</b> Translation<br/>
            // &#x2022; <b>Ch 12:</b> Regulation of translation<br/>
            // ...

            text: `
&#x2022; <b>Ch 1:</b> Genomes and the flow of biological information<br/>
&#x2022; <b>Ch 2:</b> Biological molecules<br/>
&#x2022; <b>Ch 3:</b> The chemical basis of life<br/>
&#x2022; <b>Ch 4:</b> Chromosome structure and function<br/>
&#x2022; <b>Ch 5:</b> The cell cycle<br/>
`,
          },
        },
        {
          date: new Date('2023-07-04'),
          item: {
            type: itemType.BOOK,
            title: 'In Search of Lost Time, Vol. 3: The Guermantes Way',
            year: 1920,
            author: 'Marcel Proust',
            inProgress: true,
            inProgressCurrentPage: 17,
            inProgressTotalPage: 819,
            text: `<div style="text-align:right;">
<sub>French: <i>Le Côté de Guermantes</i></br>
Translated by C.K. Scott Moncrieff and Terence Kilmartin. Revised by D.J. Enright; Modern Library, 2003</sub>
</div>`,
          },
        },
        {
          date: new Date('2023-05-28'),
          endDate: new Date('2023-07-05'),
          filterTags: ['science'],
          item: {
            type: itemType.BOOK,
            title: 'Molecular Biology of the Cell, 7th Edition',
            year: 2022,
            author: 'Bruce Alberts et al.',
            // inProgress: true,
            // inProgressCurrentPage:164,
            // inProgressTotalPage: 320,
            // <span style="color:#EE352E;">&#10140;</span> &#x2022; <b>Ch 4:</b> DNA, Chromosomes, and Genomes<br/>
            // &#x2022; <b>Ch 5:</b> DNA Replication, Repiar, and Recombination<br/>
            text: `
Reviewing the following chapters (read before in 3rd ed. in 3/2022):<br/>
  &#x2022; <b>Ch 2:</b> Cell Chemistry and Bioenergetics<br/>
  &#x2022; <b>Ch 3:</b> Proteins<br/>
`,
          },
        },
        {
          date: new Date('2022-12-31'),
          endDate: new Date('2023-06-29'),
          item: {
            type: itemType.BOOK,
            title: 'In Search of Lost Time, Vol. 2: Within a Budding Grove',
            year: 1919,
            author: 'Marcel Proust',
            // inProgress: true,
            // inProgressCurrentPage: 637,
            // inProgressTotalPage: 730,
            text: `<div style="text-align:right;">
<sub>French: <i>À l'ombre des jeunes filles en fleur</i></br>
(Also translated as <i>In the Shadow of Young Girls in Flower</i>)</br>
Translated by C.K. Scott Moncrieff and Terence Kilmartin. Revised by D.J. Enright; Modern Library, 2003</sub>
</div>`,
          },
        },
        {
          date: new Date('2023-06-23'),
          endDate: new Date('2023-06-24'),
          filterTags: ['outside', 'boviscopophobia', 'yosemite'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Yosemite National Park: Half Dome',
            // <img width="100%" style="clip-path: inset(0 2px 0 0);" src="/img/2023-06-21-yosemite/yosemite-trail-our-hike.jpg" alt="yosemite-half-dome">
            // <div style="text-align:right; font-size:14px;">
            // <i>Hike to Half Dome via Mist Trail and Little Yosemite Valley</i>
            // </div>
            text: `
<details style="margin-left: 20px">
  <summary>Map: Hike to Half Dome via Mist Trail and Little Yosemite Valley</summary>
  <br/>
  <a href="/img/2023-06-21-yosemite/yosemite-trail-half-dome.jpg" target="_blank">
    <img style="border: 3px solid #555" width="100%" src="/img/2023-06-21-yosemite/yosemite-trail-half-dome.jpg">
  </a>
</details>

* Backpacked to [Little Yosemite Valley Campground](https://www.nps.gov/yose/planyourvisit/lyv.htm). Started out
  late because of the late night the day before, so there was no parking at [Trailhead Parking](https://goo.gl/maps/7N7zPYkCoJtUEKpi9) (we had to
  park at El Cap Meadow (shuttle stop #9) and take the shuttle to Happy Isles (shuttle stop #16)), 
  and there was tons of traffic on the [Mist Trail](https://www.nps.gov/yose/planyourvisit/vernalnevadatrail.htm).
  The campground was nice (and unexpectedly v. quiet); there are numbered sites, sometimes shared bear lockers, 
  and individual-stall vault toilets w/ an excess of paper and hand sanitizer.

* Woke up at 5am to hike [Half Dome](https://www.nps.gov/yose/planyourvisit/halfdome.htm) early 
before the day-hikers arrive. Was very good decision. There were tons of people at subdome
when we got down. Going up the cables was indeed scary but not _that_ bad. Having sticky gloves was definitely helpful. 
The ranger caught us on our way up the cables and gave us some tips that were very helpful (e.g. only use one cable instead of two).
I was anxious anticipating coming down, but it ended up being quite smooth and easy descending feet-first, facing toward mountain, 
using [/leaning into] one-cable, body position similar to rappelling.

* Went back to camp and ate some ramen for lunch. Then packed up for the backpack out.
We took the John Muir Trail this time to go around the wet and packed part of the Mist Trail. (We had planned
  to catch the John Muir Trail earlier, before Nevada Falls, but that section of the JMT was closed.) 
`,
            frames: [
              '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-01',
              '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-02',
              '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-03',
              '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-06',
              // '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-07',
              '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-08',
              '2023-06-21-yosemite/mist-trail-to-little-yosemite-valley-09',
              '2023-06-21-yosemite/half-dome-01',
              '2023-06-21-yosemite/half-dome-02',
              // '2023-06-21-yosemite/half-dome-03',
              '2023-06-21-yosemite/half-dome-04',
              // '2023-06-21-yosemite/half-dome-05',
              '2023-06-21-yosemite/half-dome-06',
            ],
          },
        },
        {
          date: new Date('2023-06-22'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Sunnyside Bench Regular Route',
            gradeYDS: '5.5',
            // stars: 4,
            area: 'Yosemite National Park',
            crag: 'Sunnyside Bench',
            climbType: climbType.TRAD,
            lengthFt: 400,
            numPitches: 4,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106561511/sunnyside-bench-regular-route',
            // text:``,
          },
        },
        {
          date: new Date('2023-06-21'),
          endDate: new Date('2023-06-22'),
          filterTags: ['outside', 'climbing', 'yosemite'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Yosemite National Park: Sunnyside Bench',
            // * M led the first pitch. Then she became out-of-sight. People arrived on the route,
            //   and it became impossible to hear. Eventually she came back into sight after setting up the anchor at the tree belay
            //   and then coming back down so that she could see me.

            //   (Lessons: did not foresee the struggle of not being able to see the climber/belayer — sucks. Very difficult for me to hear
            //   when there are people having conversations close to me. This also happens when we're just hiking and people are just behind,
            //   but on climb, the stakes are life/death and it is extremely frustrating.) 

            //   The first move is v. awkward; had to do horsey. 
            // * I led the beginning of second pitch and was confused because the route I scoped was very difficult, so brought M up to that point
            //   and she found the right way. I then continued on the second pitch and went the totally wrong way. Reached a short wall that went
            //   straight up (maybe like a 5.11) but had 2 pitons in it; at the bottom of the wall there were two anchor bolts with chain links. 
            //   Set up an anchor on this and was going to bring M up to help me assess the situation. M asked the party behind us to help me 
            //   and we simul-rappelled back down to the belay station.

            // * M then led the rest of the route in a single pitch (the rest of the second pitch, and the whole third pitch, which we were originally
            //   planning to split into two pitches because of visibility and rope drag.) As a result, there was a TON of rope drag and she
            //   had to lead while carrying 100lbs of rope, which she did apparently by pulling it up w/ one hand and climbing w/ the other?
            //   To belay me up she had to create some type of pully system. Meanwhile I was at the belay station not knowing what was going on
            //   for a long time. It was cold and I had to emergency pee at one point. When M finally called me back on the phone,
            //   I told her I needed to remove my harness to pee. I assumed she had set up the anchor but apparently she had not and
            //   was also carrying all the rope, but she didn't inform me of this and anchored herself so that I could go. Even though I was
            //   pretty fried from the getting lost part, following the rest of the route was pretty fun, I guess. It was
            //   a pretty non-trivial climb even though it's a 5.5. There was a tricky traverse.
            //   A free solo-er arrived, so I let him pass. He was hanging out w/ M when I arrived at the belay.
            text: `
# Pine Line

Arrived Wednesday afternoon and climbed [Pine Line](https://www.mountainproject.com/route/105841118/pine-line) 
again. M led, then I led. Finally did it clean this time (3rd attempt). Our first experience with 
waiting in line for a climb (something I was nervous about.)

After returning to El Cap Meadow, we locked the car keys in the trunk w/ cell phones and wallets.
Nice people in the meadow helped us and let us use their phones. Apparently, calling 911 in Yosemite
automatically goes to [YOSAR](https://www.nps.gov/yose/getinvolved/sar_jobs.htm), and they were super helpful and sent rangers to help. Were able
to get in before dark.

Camped at [Wawona Campground](https://www.nps.gov/yose/planyourvisit/wawonacamp.htm) in Loop C; a very pleasant campground.

# Sunnyside Bench

Thursday morning, packed up relatively early to head to [Sunnyside Bench Regular Route (5.5)](https://www.mountainproject.com/route/106561511/sunnyside-bench-regular-route) — _Trad, 400 ft (121 m), 3 pitches_ — for our first multi-pitch.
Got off-route, it was harrowing. Some nice folks on the route helped us rappel and get back on route. 
M used superhero strength/tactics to lead the entire rest of the route in one pitch w/ serious 
rope drag before we ran out of sunlight. Took us 10 hours including the long and 
also route-findingly-difficult descent.  Got back to the Valley Loop Trail in twilight.

Found this route image after the fact which shows the correct route in green:

<details style="margin-left: 20px">
  <summary>Photo Topo: Sunnyside Bench Regular Route</summary>
  <br/>
  <a href="https://i.imgur.com/ITUQATO.jpg" target="_blank">
    <img style="border: 3px solid #555" width="100%" src="https://i.imgur.com/ITUQATO.jpg">
  </a>
  <div style="text-align:right; font-size:14px;">
  <i>Linked in <a href="https://www.mountainproject.com/route/106561511/sunnyside-bench-regular-route#Comment-120686969">MP comment by Ted Bradley</a> (source possibly Steph Abegg)</i>
  </div>
</details>

* Instead of sticking right on Pitch 2, went into/toward the mountain on leftward up-ramp — wrong.

Approach trail starts [here](https://goo.gl/maps/EMBPjBPnTkDapqUE6) off of Valley Loop Trail. 
(The trail veers left toward the crag, and goes to the bottom of Sunnyside Bench Regular Route. 
The rest of Sunnyside Bench crag routes
approach via a separate trail that veers right toward the crag, from about this same point off of 
Valley Loop Trail, and includes some switchbacks.).

Descent: stay left/high until the talus field behind the stables as shown [here](https://themtsarecalling.com/sunnyside/).
There's another "gully" of rocks before the talus field that shows up earlier on the descent; stay up at this part.

Camped at Wawona Campground again, but in Loop A.`,
            frames: [
              '2023-06-21-yosemite/sunnyside-bench-01',
              '2023-06-21-yosemite/sunnyside-bench-02',
              '2023-06-21-yosemite/sunnyside-bench-03',
              '2023-06-21-yosemite/sunnyside-bench-04',
              '2023-06-21-yosemite/sunnyside-bench-06',
              '2023-06-21-yosemite/sunnyside-bench-10',
            ],
          },
        },
        {
          date: new Date('2023-06-07'),
          filterTags: ['climbing'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Mount St. Helena: Down Under, The Bear, The Bubble',
            text: `
Did all the approaches. Tried to find [The Near Side](https://www.mountainproject.com/area/120888822/near-side) (from The Bubble)
but ended up at 
[Down Under](https://www.mountainproject.com/area/120912959/down-under) by accident. [The map in the guide-book under Near Side
does not include Down Under on it, so we didn't know it existed.] This map includes everything:


<details>
  <summary>Map: Mount St. Helena Crags from Bay Area Rocks</summary>
  <br/>
  <img width="100%" style="clip-path: inset(0 2px 0 0);" src="/img/2023-05-31-climbing/mt-st-helena-map.png" alt="mt-st-helena-map">
  <div style="text-align:right; font-size:14px;">
  <i>From Bay Area Rocks</i>
  </div>
</details>


[Down Under](https://www.mountainproject.com/area/120912959/down-under) approach:
* The trail off the fire road is brushy and spiky and slippery and down hill. Eventually,
you get to a slab of rock where there's no brush on it (the crag is still lower than this point.). We went down
to the bottom of the slab where it wasn't brushy and scrambled down, but it turns out there's a trail 
to skier's left of the slab that goes down and around to the right, to the crag.

We decided to pass on The Near Side because Down Under was kind of dirty and we expected Near Side would have similar
landscape. There were people at the Bubble still, so we continued to [The Bear](https://www.mountainproject.com/area/105734042/the-bear).
The intersection between the trail-to-the-Bear and the fire road is not marked to scale on the map.
The "sweet new trail" turns off the fire road to the left where the hill is the least steep (further than where it's
marked on the map if coming from The Bubble) and 
then goes back/left toward The Bear. It was also dirty here.
While we were at the Bear, the party that was at the Bubble arrived, so we decided to go back
to the Bubble and re-climb the routes we tried last time.

[The Bubble](https://www.mountainproject.com/area/105734213/the-bubble):
* Right Slab (5.7) — tried to lead but did v poorly; had to rest and use bolts.
* The Slab (5.9+) — tried to top rope without stopping for rests; successful.
  * Messed up the order of operations while undoing the anchor, so had to untie the quad. 
    (Put the rope into the mussy hooks first, then removed the quad's two TR carabiners from the rope, 
    and then the quad ended up between the rope and the wall, looped in a way where it needed to be untied, or the rope
    needed to be removed from the mussy hooks. Not really sure what happened; must've unhooked the carabiners
    from the wrong place. Lesson: Need to pay attention
    to the order in which things are placed and removed.)
`,
            frames: [
            ],
          },
        },
        {
          date: new Date('2023-05-31'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'The "Old" Ladder',
            gradeYDS: '5.10a',
            // stars: 4,
            area: 'Mount St. Helena',
            crag: 'The Bubble',
            climbType: climbType.SPORT,
            lengthFt: 70,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105735071/old-ladder-route',
            text:`
"A fun, steep line of burly jugs."

Attempted first overhanging route. Used stick clip to clip the first bolt. Didn't make it past the first bolt.
Also unable to see all the bolts at the top, or see the anchor, so pretty scary. 
Can try to set up a top rope on this next time.
  `,
          },
        },
        {
          date: new Date('2023-05-31'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'The Slab',
            // stars: 3,
            gradeYDS: '5.9+',
            area: 'Mount St. Helena',
            crag: 'The Bubble',
            climbType: climbType.SPORT,
            lengthFt: 60,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105734870/59-face',
            text:`
"An excellent and testy intro to St Helena pocket climbing."

Top roped it after setting up anchor by climbing Right Slab. 
Very fun pockets; would like to come back and try leading it sometime.`,
          },
        },
        {
          date: new Date('2023-05-31'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'The Right Slab',
            gradeYDS: '5.7',
            // stars: 3,
            area: 'Mount St. Helena',
            crag: 'The Bubble',
            climbType: climbType.SPORT,
            lengthFt: 60,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106288015/bubble-slab-right',
            text: `
"Avoid the crack to the right. Nice pockets!"

Led with resting at some bolts.
  `,
          },
        },
        {
          date: new Date('2023-05-31'),
          filterTags: ['climbing'],
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Mount St. Helena: The Bubble',
            text: `
Visited [The Bubble](https://www.mountainproject.com/area/105734213/the-bubble) for Wednesday workout. Arrived at a leisurely pace (around 12:30p maybe), 
so The Bubble was in the sun, although by the afternoon when we were packing up, the slab was in the shade. 
The approach was pretty tiring (uphill and hot); took us about 50 minutes.
`,
            frames: [
              '2023-05-31-climbing/2023-05-31-climbing-05',
              '2023-05-31-climbing/2023-05-31-climbing-07',
              '2023-05-31-climbing/2023-05-31-climbing-08',
              '2023-05-31-climbing/2023-05-31-climbing-09',
            ],
          },
        },
        {
          date: new Date('2023-05-29'),
          filterTags: ['climbing'],
          showCollapseText: true,
          textCollapsedOnInit: true,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: '[mountain practice] rappelling/abseiling and ascending/prusiking',
            text: `
* rappelling [with ATC](https://www.vdiffclimbing.com/abseil/)
  * [autoblock](https://www.vdiffclimbing.com/prusik-types/#autoblock): 5 loops with orange cordelette when rappelling on double strand of blue rope
* rappelling with GRIGRI (on a single strand)
  * tie figure-8 on bight on strand on the opposite side of the rappel rings (from the rappel strand),
attach carabiner to bight and to the rappel strand. this holds the single strand in place.
  * rappel down single strand with GRIGRI.
  * [autoblock](https://www.vdiffclimbing.com/prusik-types/#autoblock): need to double check how many loops w/ orange cordellete for rappelling down single-strand of blue rope
* [ascending with prusik and klemheist](https://www.vdiffclimbing.com/prusik-rope/)
  * tie back-up knot in the rope and retie it frequently as you're ascending (tie new knot before removing old knot)
  * [classic](https://www.vdiffclimbing.com/prusik-types/#classic) prusik on top w/ 4 loops. 
  * [klemheist](https://www.vdiffclimbing.com/prusik-types/#klemheist) on bottom w/ 4 loops - foot loop attached
`,
            frames: [
              '2023-05-31-climbing/2023-05-29-climbing-01',
              '2023-05-31-climbing/2023-05-29-climbing-03',
              '2023-05-31-climbing/2023-05-29-climbing-04',
              '2023-05-31-climbing/2023-05-29-climbing-02',
            ],
          },
        },

        //       {
        //         date: new Date('2023-07-24'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: 'problemset.ai',
        //           text: `
        // The lift coefficient ($C_L$) is a dimensionless coefficient.

        // problems: things that cause suffering, that stand in the way of human generativity...
        // assumes that we are first and foremost humanists.

        // the remaining problems facing humanity are those related to disease and scarcity (of resources, energy, etc).
        // these problems _can_ be solved with technology.

        // problem | solution
        // 1. disease and fragility of the human body | immortality
        // 2. scarcity of resources | excessively abundant resources for all human life and potential
        // 3. human-driven self-destruction and mutual destruction | humans only want to be generative for self and all humankind

        // # 1. removal of infectious disease
        // * viruses: viruses are likely responsible for... 
        // EBV is responsible for MS and Hodgkins lymphoma.

        // * bacteria: will be resistant to antibiotics soon
        // * fungi

        // # 2. removal of self-infectious disease
        // * cancers
        // * auto-immune diseases
        // * genetic diseases e.g. huntington's, parkinsons

        // # 3. general human longetivy
        // aging, "clean up" junk that accumulates in the human body

        // # 4. exoskeletons / body protection or regeneration
        // exoskeletons or pods to prevent accidents to the human body. or e.g., can scan brain and regenerate it.

        // # 5. more efficient capture of stellar energy
        // solar, or arrays in space closer to other stars. infinite energy exists.

        // # 6. energy storage and transport
        // a chemical thing. we're currently storing energy as electric potential. it can be stored
        // in other ways, e.g. mass

        // # 7. ability to control the weather (on earth)
        // carbon capture, controlling weather e.g. do air conditioning

        // # 8. terraforming
        // if we can control the weather (7), we can make all place inhabitable e.g. make mars warmer

        // # 9. fast space flight and use of relativity

        // # 10. quantum computing / physics
        // * quantum computing for faster than light-speed information travel (necessary if we have space flight)
        // * figure out what the actual particles in the universe are; finish physics

        // # 11. raw materials
        // stable alchemy; converting elements into other elements (be able to print whatever element we want out of whatever)
        // or find a way to detect elements; mining

        // # 12. toning down human addictions (self-destructive things)


        // # 13. toning down human aggression (mutually destructive things)

        // # 14. routes/ladders

        // in addition to scientific solutions for 12 and 13, we also need to build society with ladders
        // and things that make humans not destroy themselves and each other...

        // `,
        //         },
        //       },

        //       {
        //         date: new Date('2023-05-23'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: '[research] North Cascades: Liberty Bell — Beckey Route (T 5.6)',
        //           text: `
        // (Planning to climb on July 24, 2023)

        // # [Beckey Route / SW Face (T 5.6)](https://www.mountainproject.com/route/105797867/beckey-route-sw-face)
        // Trad, Alpine, 500 ft (152 m), 4 pitches, Grade II

        // _(Information below copy-and-pasted from Mountain Project)_

        // The Beckey route is a very moderate route to the spectacular summit of Liberty Bell. The summit is 
        // the best part of the route, offering panoramic views of the peaks of the North Cascades, the Wine 
        // Spires, and the Early Winter Spires.

        // ## Approach

        // To reach the climb (~2.5mi), hike up the [Blue Lake Trail](https://www.alltrails.com/trail/us/washington/blue-lake-trail--7) 
        // until it branches off left on a climbers trail that was completed in 2019. Stay on the trail as 
        // it climbs up, even if it feels like you are going too far climbers right. 
        // At the fork in the trail ~ .5 mile up and almost to the base of the west side of Concord, 
        // bear left to hike under Concord to the gully separating Concord from Liberty Bell, 
        // called the Beckey Gully.  A short hike up the gully takes you to the prominent notch between 
        // Liberty Bell and Concord. This gully is loose and unpleasant, but better if you stay to climber's 
        // left until about 2/3 height, then cross and hug the climber's right-side of the gully to the top.

        // The start of the Beckey route is about 30' below the notch. Look for a small ledge leading 
        // left and traverse it to the base of a blocky chimney. This is the start of the first pitch.

        // > Here's a map/track for the approach to help you avoid drifting off the main trail (easier 
        // than you think) as you're groggy in the morning: [Caltopo](https://caltopo.com/m/R71AL)
        // the main trail veers off to climber's right (towards SEWS) before it heads back towards the 
        // Beckey Gully making wonder if you've taken a wrong turn. There are many social trails to lure you 
        // off the beaten path, too. Rangers are trying hard to restore this area and it really helps 
        // if you stay on the main trail. 

        // — Will N, Aug 30, 2021

        // ## P1

        // Climb the chimney or the face to its left reaching a large ledge with a tree. 5.3

        // ## P2

        // Continue up the chimney for about 30m, climbing past chockstones and blocky slabs until 
        // reaching obvious 3rd/4th class terrain. I think this pitch is the hardest of the climb. 
        // Belay from a tree or from gear wherever you find it convenient. 5.5

        // ## P3

        // Ascend the 4th class terrain to a 6 foot finger-crack traverse past a large roof (climbing past a 
        // fixed pin) (5.5), then follow a dihedral back to the right and up onto the summit shoulder. 
        // Belay from a tree or from gear, use long slings and watch the rope drag.

        // ## P4

        // Shortly after leaving the belay on the ridge to the summit, you'll encounter a slabby 5.6 boulder 
        // problem ~10' high. After surmounting this obstacle it is easy 3rd/4th class terrain to the summit. 
        // If you belay all of this, it will be more than one pitch to the top.

        // ## Descent

        // Downclimb the ridge and the 5.6 boulder problem. Below the boulder problem follow an obvious, 
        // tree filled gully down to the east until you encouter the first set of rappel bolts. From here it 
        // is possible to reach the ground in 2 single rope rappels. After the first rappel, scramble down and 
        // skier's right to reach a large ledge with another set of bolted anchors. We used a 60M rope, I can't
        // say if a 50 would suffice.

        // Note: There is a rappel station just below the summit that you can use to avoid the boulder problem 
        // down-climb described above. From the summit go back the way you came up, but veer to skiers' 
        // left and look for a groove in the rock. Take caution on slabby down climb and you'll see a couple 
        // slings wrapped around a tree with double rings. Note: A 60-meter rope will make it down to the large 
        // ledge below. The next rappel station is a skier's right down-climb scramble, leading you to a cliff; 
        // look right, you'll see a rock ledge and bolts on a wall. From those bolts on rappel trend skier's 
        // right towards a large tree with a small platform next to it. The last set of bolts will be on the 
        // wall skier's left of the tree. The 60-m rope is more than enough to drop you in the gully where you 
        // started.
        // `,
        //         },
        //       },

        //       {
        //         date: new Date('2023-06-01'),
        //         endDate: new Date('2023-06-30'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: 'Mount St. Helena',
        //           text: `
        // (The Bubble: sport climbs until 11:30a)

        // # Silverado
        //   * go to top
        //   * practice rappelling both ways
        //   * practice ascending
        //   * practice belaying from top
        //   * regular toprope routes

        // # The Bear
        //   * hard sport climbs after 2
        //   * meet up top/do them as follows
        //   * maybe try the multipitch

        // # The Near Side
        //   * sport climbs after 3
        // `,
        //         },
        //       },
        //       {
        //         date: new Date('2023-06-01'),
        //         endDate: new Date('2023-06-30'),
        //         filterTags: ['outside', 'climbing'],
        //         showCollapseFrames: true,
        //         framesCollapsedOnInit: false,
        //         item: {
        //           type: itemType.POST,
        //           title: 'South Lake Tahoe: Lover\'s Leap',
        //           text: `
        // # Hogswild
        // ## Knapsack Crack
        // Multipitch Trad 5.5
        // ## Better with Bacon
        // Multipitch Trad 5.8-

        // # Main Formation
        // ## Dead Tree Direct trad 5.7
        // ## Dragon Back 5.10b sport
        // `,
        //         },
        //       },

        // example of bullet points using svg
        //       {
        //         date: new Date('2023-03-28'),
        //         item: {
        //           type: itemType.POST,
        //           title: 'today',
        //           text: `
        // # LEGO

        // <div style="display:flex; margin-bottom:15px;">
        //   <img src="/icon/circle.svg" style="padding-right:5px"> clean up LEGO sets on shelves</br>
        // </div>
        // <div style="display:flex; margin-bottom:15px;">
        //   <img src="/icon/circle.svg" style="padding-right:5px"> clean up LEGO bags and parts (LEGO room)</br>
        // </div>
        // <div style="display:flex; margin-bottom:15px;">
        //   <img src="/icon/circle.svg" style="padding-right:5px"> organization minifigs</br>
        // </div>
        // <div style="display:flex; margin-bottom:15px;">
        //   <img src="/icon/circle.svg" style="padding-right:5px"> rebuild LEGO city</br>
        // </div>
        // <div style="display:flex; margin-bottom:15px;">
        //   <img src="/icon/circle.svg" style="padding-right:5px"> build Ninjago City</br>
        // </div>

        // # eggs
        // <div style="display:flex; margin-bottom:15px;">
        //   <img src="/icon/circle.svg" style="padding-right:5px">DALL-E integration</br>
        // </div>
        // `,
        //   },
        // },
        {
          date: new Date('2023-05-18'),
          item: {
            type: itemType.BOOK,
            title: 'What I Want to Talk About: How Autistic Special Interests Shape a Life',
            year: 2022,
            author: 'Pete Wharmby',
          },
        },
        {
          date: new Date('2023-05-17'),
          item: {
            type: itemType.BOOK,
            title: 'Untypical: How the World Isn\'t Built for Autistic People and What We Should All Do About It',
            year: 2023,
            author: 'Pete Wharmby',
          },
        },
        {
          date: new Date('2023-05-15'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            title: 'Meditations',
            year: 180,
            author: 'Marcus Aurelius',
          },
        },
//         {
//           date: new Date('2023-05-13'),
//           showCollapseText: true,
//           textCollapsedOnInit: true,
//           item: {
//             type: itemType.CLIMB,
//             title: 'Pine Line',
//             gradeYDS: '5.7',
//             area: 'Yosemite National Park',
//             crag: 'El Capitan Base',
//             climbType: climbType.TRAD,
//             lengthFt: 70,
//             mountainProjectUrl: 'https://www.mountainproject.com/route/105841118/pine-line',

//             attempt: 2,
//             sent: true,
//             text:`
// * Second attempt trad lead. Finished not cleanly but made it to the top.
// * This time, in addition to wallnuts (1-11, offset 7-11, peenut 4-5), we also had 3 totem cams: blue (0.65), yellow (0.80), and green (1.25).
// Placed small nuts during the first half of the route, then a blue totem at [what felt like] the crux, the yellow totem,
// and then the green for safety at the very top of the route. Some nuts were better placed than others;
// for about two of them, placed what was immediately _okay_ in the downward direction and just tried to get through quickly.
// * Pulled on a couple of pieces after placing them to assist with 'resting'; tried
// to do this minimally, but it was scary sometimes. Fell on the blue totem, which was still above me at the time (😪).
// At one of the ledges, had to stop and do breathing for a while to stop left calf from shaking.
// * Set up the [quad top rope anchor](https://www.vdiffclimbing.com/quad-anchor/) (made by M on cordelette, and already set up with 4 lockers 
// in the right places: 1 locker on each end + 2 opposing lockers (into which the rope goes) 
// on the master point — each of the opposing lockers around 2 of the 4 master point strands) 
// on the two fixed bolt-hangers at top of the route, and lowered off top rope. 
// M cleaned protection on the way up on top rope, cleaned the anchor, and lowered on rings.
// (Mountain-project mentioned the right bolt is a 'spinner'; the hanger spins around the bolt,
// but the bolt was secure. The chains and rings looked good.)
//             `,
//           },
//         },
        {
          date: new Date('2023-05-11'),
          endDate: new Date('2023-05-14'),
          filterTags: ['outside', 'climbing', 'yosemite'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Yosemite NP: Swan Slab, Pine Line',
            text: `
Trip with M parents, but secretly a send-Pine-Line trip. Thursday/Friday in the Valley not very crowded.
Saturday very bad around Curry Village / Yosemite Village, but nobody around El Cap Meadow.

* Swan Slab
  * Assessed Swan Slab. Mostly we are confused about how you're supposed to descend from any of these 
    without leaving gear if you're only climbing one pitch.
    
    Wanted to practice slab climbing and making top-rope anchors on 
    [West Slabs (TR 5.6)](https://www.mountainproject.com/route/106852654/west-slabs) since you can walk up to the top, 
    but there were people on it.

    [Patio Cracks (5.7-5.9)](https://www.mountainproject.com/route/106844762/patio-cracks-center) looks like
    maybe you can get to the top / descend from the top by scrambling the 5.3 on the left side. Spotted Beth Rodden belaying
    her 9-yr-old son leading one of these!

    Considered [Claude's Delight (T 5.7)](https://www.mountainproject.com/route/106653590/claudes-delight) 
    to top rope of [Lena's Lieback (T 5.9)](https://www.mountainproject.com/route/105867066/lenas-lieback) because there are
    supposedly anchor bolts on this route, but Claude's Delight looked scary/tall (150ft) and also dirty; thought it'd
    be an unlikely send and didn't want to have to bail and leave gear again. (These climbs are in a weird corner all
    the way to the right of Swan Slab, away from the other climbs, that feels dirty/wet/claustrophobic to be in.)

    When we're ready for multi-pitch, 
    we should do [Swan Slab Gully (T 5.6, 3 pitches)](https://www.mountainproject.com/route/105889783/swan-slab-gully) maybe.
    (There wasn't anyone on it on a Thursday.)

* El Capitan Base          
  * [Pine Line (5.7)](https://www.mountainproject.com/route/105841118/pine-line) — Trad, 70 ft (21 m) — ~✅ Sat, May 13
    * Second attempt trad lead. Finished not cleanly but made it to the top.
    * This time, in addition to wallnuts (1-11, offset 7-11, peenut 4-5), we also had 3 totem cams: blue (0.65), yellow (0.80), and green (1.25).
      Placed small nuts during the first half of the route, then a blue totem at [what felt like] the crux, the yellow totem,
      and then the green for safety at the very top of the route. Some nuts were better placed than others;
      for about two of them, placed what was immediately _okay_ in the downward direction and just tried to get through quickly.
    * Pulled on a couple of pieces after placing them to assist with 'resting'; tried
      to do this minimally, but it was scary sometimes. Fell on the blue totem, which was still above me at the time (😪).
      At one of the ledges, had to stop and do breathing for a while to stop left calf from shaking.
    * Set up the [quad top rope anchor](https://www.vdiffclimbing.com/quad-anchor/) (made by M on cordelette, and already set up with 4 lockers 
      in the right places: 1 locker on each end + 2 opposing lockers (into which the rope goes) 
      on the master point — each of the opposing lockers around 2 of the 4 master point strands) 
      on the two fixed bolt-hangers at top of the route, and lowered off top rope. 
      M cleaned protection on the way up on top rope, cleaned the anchor, and lowered on rings.
      (Mountain-project mentioned the right bolt is a 'spinner'; the hanger spins around the bolt,
      but the bolt was secure. The chains and rings looked good.)`,
          },
        },
        {
          date: new Date('2023-05-10'),
          filterTags: ['climbing'],
          item: {
            type: itemType.BOOK,
            title: 'Crack Climbing: The Definitive Guide',
            year: 2019,
            author: 'Pete Whittaker',
            text: `
<div style="text-align:right;">
&#x2022; Chapter 5: Finger Cracks
</div>`,
          },
        },
        {
          date: new Date('2023-05-03'),
          filterTags: ['climbing'],
          item: {
            type: itemType.FILM,
            title: 'The Dawn Wall',
            year: 2017,
            director: 'Peter Moritmer, Josh Lowell',
            frames: [],
            text: '',
          },
        },
        {
          date: new Date('2023-05-02'),
          filterTags: ['climbing'],
          item: {
            type: itemType.FILM,
            title: 'Valley Uprising',
            year: 2014,
            director: 'Peter Moritmer, Nick Rosen',
            frames: [],
            text: '',
          },
        },
        {
          date: new Date('2023-04-30'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'Pine Line',
            gradeYDS: '5.7',
            area: 'Yosemite National Park',
            crag: 'El Capitan Base',
            climbType: climbType.TRAD,
            lengthFt: 70,
            mountainProjectUrl: 'https://www.mountainproject.com/route/105841118/pine-line',

            attempt: 2,
            sent: true,
            text:`
  First trad/trad-lead. Got about halfway up route 
  (to first ledge) with aid (pulling on protection) and weighting/resting on protection. Bailed 
  and donated 2 wallnuts (#4 gold, #7 gray) and a carabiner.
            `,
          },
        },
        {
          date: new Date('2023-04-29'),
          endDate: new Date('2023-04-30'),
          filterTags: ['outside', 'climbing', 'yosemite'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Yosemite NP: Pohono Trail, Lower Chilnualna Falls, Pine Line',
            text: `
Quick weekend trip with C. 
Someone cancelled a campsite reservation at Wawona, so M grabbed it.
Just before the weekend, NPS announced the Valley would be closed due to forecasted flooding 
(from a heat wave following the heavy snow season). We decided to go anyways since the camp site 
was outside of the Valley. And we wanted to try to climb Pine Line on El Capitan which was _just_ 
outside of the closure area (everything east of El Capitan Bridge). We were originally
going to hike to [Eagle Peak](https://www.alltrails.com/trail/us/california/eagle-peak--6) via 
Yosemite Falls (11.5 mi), but because of the closure, 
hiked [Pohono Trail](https://www.alltrails.com/trail/us/california/dewey-crocker-and-stanford-point-via-the-pohono-trail) instead.

<a href="https://www.yosemite.ca.us/maps/yosemite_valley_map_2008.jpg" target="_blank">
<img width="100%" style="clip-path: inset(0 5px 0 0);" src="https://www.yosemite.ca.us/maps/yosemite_valley_map_2008.jpg" alt="yosemite crop right side">
</a>
<div style="text-align:right; font-size:14px;">
<i>Yosemite Valley. Closures included everything east of El Capitan and El Capitan Bridge.</i>
</div>

# Saturday, April 29

* Hiked [Pohono Trail from Tunnel View to Stanford Point](https://www.alltrails.com/trail/us/california/dewey-crocker-and-stanford-point-via-the-pohono-trail) — 8.5 mi
  * Hiked through snow using microspikes; made wet boots. All-day hike.

* Camped at [Wawona Campground](https://www.nps.gov/yose/planyourvisit/wawonacamp.htm)

  <details style="margin-left: 20px">
    <summary>Yosemite Camprounds Map</summary>
    <img width="100%" src="https://www.nps.gov/yose/planyourvisit/upload/campgroundmap2013.jpg">
  </details>

# Sunday, April 30

Flooding didn't occur as expected, so they opened the Valley unexpectedly!
Easy hiking due to very strenuous snow hike yesterday + now our boots are wet / hiking in flips.

* Hiked [Lower Chilnualna Falls Trail](https://www.alltrails.com/trail/us/california/lower-chilnualna-falls-trail) — 0.6 mi
  * Close to our campground. So much water fall.
* Walked Camp 4 → [Lower Yosemite Fall Trail](https://www.alltrails.com/trail/us/california/lower-yosemite-falls-trail) — 2 mi
  * Chill w/ nobody in the valley. Took a look at Camp 4 and Swan Slab.

Approach for Pine Line: From El Cap Meadow, there's a trail that starts 300 feet west of El Capitan Bridge. It reaches a fork that 
has signs for either the left/west or right/east side of the base of The Nose. Taking the left fork will 
lead to a large clearing where there's a climber's trail that goes up to Pine Line. (Taking the right fork
also works, but you end up on the east side of The Nose, and you have to go downhill and uphill a little to get to the west side.)

* Climbed [Pine Line (5.7)](https://www.mountainproject.com/route/105841118/pine-line) — Trad, 70 ft (21 m) — ❌
  * M led trad class. We learned how to set passive protection.
  * First trad / trad-lead. Got about halfway up route 
    (to first ledge) with aid (pulling on protection) and weighting/resting on protection. Bailed 
    and donated 2 wallnuts (#4 gold, #7 gray) and a carabiner.
  * They say it's a "Yosemite 5.7" aka harder than typical 5.7 and polished, so we shouldn't feel too bad.
  * Amazing view and very comfy ledge.`,
            frames: [
              '2023-04-29-yosemite/2023-04-yosemite-08', // binocs
              '2023-04-29-yosemite/2023-04-yosemite-18',
              '2023-04-29-yosemite/2023-04-yosemite-17',
              '2023-04-29-yosemite/2023-04-yosemite-19',
            ],
          },
        },
        {
          date: new Date('2023-04-17'),
          item: {
            type: itemType.BOOK,
            title: 'My Year of Rest and Relaxation',
            year: 2018,
            author: 'Ottessa Moshfegh',
          },
        },
        {
          date: new Date('2023-04-12'),
          showCollapseText: true,
          textCollapsedOnInit: true,
          item: {
            type: itemType.CLIMB,
            title: 'The Play\'s the Thing',
            gradeYDS: '5.4',
            area: 'Red Rocks',
            crag: 'The Hamlet',
            climbType: climbType.SPORT,
            lengthFt: 50,
            mountainProjectUrl: 'https://www.mountainproject.com/route/106337029/the-plays-the-thing',

            sent: true,
            text: `
First sport/lead climb — technically easy but still surprisingly scary (slab).
Learned how to safely lower from bolted chain rings (without ever untying from the rope).
          `,
          },
        },
        {
          date: new Date('2023-04-11'),
          endDate: new Date('2023-04-12'),
          filterTags: ['outside', 'climbing'],
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          item: {
            type: itemType.POST,
            title: 'Red Rock Canyon National Conservation Area',
            text: `
# Tuesday, April 11

Arrived from Joshua Tree. Requires a Scenic Loop entry-time reservation.
We stopped at Calico I to climb at The Hamlet but it was scorching hot. Headed to Ice Box Canyon 
because it's called "Ice Box" for potentially shady sramble.

* [Ice Box Canyon Scramble](https://www.alltrails.com/trail/us/nevada/ice-box-canyon-trail--3) — [technically] Class 3 (~3 miles)
  * It was very hot outside / not that ice-boxy, but there were plenty of places to stop for shade once
    we were in the canyon/river part. Very easy for Class 3. First part follows a trail, then you follow the
    river up until whenever, I guess.

Then we headed to Calico I when it was cooler to try to find The Hamlet. Followed approach directions
from guidebook/mountain-project, which were not the clearest, but got us there eventually. The approach
was certainly a project on its own. Eventually discovered that there's a very distinct and easy trail that
gets you most of the way, followed by some scrambling // hard to describe // but now we know.

# Wednesday, April 12

Were looking for something easy for our first sport/lead/outside climb. 
Top rope also okay if there's anything accessible.

* Calico I: [The Hamlet](https://www.mountainproject.com/area/106118838/the-hamlet)
  * Climbed [The Play's the Thing (S 5.4)](https://www.mountainproject.com/route/106337029/the-plays-the-thing) — Sport, 50 ft (15 m) — ✅
    * First sport/lead climb — technically easy but still surprisingly scary
    * Learned: How to lower from bolted chain rings (without ever untying from the rope):
      1. Attach personal anchor to one of the bolts
      2. Feed rope bight through chain rings, tie figure-8 on bight, and clip to extra locking carabiner on harness
      3. Untie climbing figure-8 tie-in knot from harness, pull end of rope through to other side of chain rings,
        retie climbing figure-8 tie-in knot through harness
      4. Remove figure-8 on bight that's attached to the locking carabiner and untie it, then have belayer pull in all the slack ("tension")
      6. Weight the rope and make sure it's good
      7. When ready, remove personal anchor and "lower"
  * Took a look at
    [Contageous Blastments (S 5.5)](https://www.mountainproject.com/route/118289745/contageous-blastments),
    [Passing Through Nature to Eternity (S 5.6)](https://www.mountainproject.com/route/118289751/passing-through-nature-to-eternity), and
    [Some Strange Eruption (S 5.6)](https://www.mountainproject.com/route/118289780/some-strange-eruption); newish routes 
    to the left of [Rosencrantz (S 5.6)](https://www.mountainproject.com/route/107102936/rosencrantz) and [Guildenstern (S 5.5)](https://www.mountainproject.com/route/107102950/guildenstern),
    but they were on a scary-ass ledge, and also there were loose rocks and belayers below. Mostly 
    _much_ scarier than the 5.4 we had just done, which was already scary. The top rope anchors are also on this ledge
    for the routes below, which would've been try-able but there was a party on them. (The anchors look scary to set up though
    because the ledge was v scary to be on, but M seemed less phased.)

    <img width="100%" src="https://mountainproject.com/assets/photos/climb/106703750_medium_1494133622.jpg">
    <div style="text-align:right; font-size:14px;">
    <i>The Hamlet. Rosencrantz and Guildenstern are to the right of 5, but not drawn here because they're newer.</i>
    </div>

* Calico I: [The Amusement Park](https://www.mountainproject.com/area/108563769/amusement-park)

  We were looking for Amusement Park because it has all 5.7s, but we were never able to find it.
  The approach directions rely on you knowing how to find [Panty Wall](https://www.mountainproject.com/area/105732045/panty-wall),
  which proved impossible for us to find. 
  
  Meta lesson: I made M ask for directions from some people at the Hamlet. Turns out that [especially] when there are
  peer group dynamics involved, people will often give you bad information instead of admitting not knowing things.
  M foresaw such dynamics and predicted that having bad information is more confusing than having none at all,
  which proved (as always) to be true. We still don't know how to get to the Panty Wall.

Lessons/Conclusions:
* Approach at Red Rocks is non-trivial. Glad we found The Hamlet and became a little bit familiar
  with Calico I. Next time will be easier. (Finding the Hamlet approach the day before was helpful.)
* Learned about how sport leading works (climbing, clipping, lowering, belaying, voice commands). And observed people top-roping 
  routes 6-9 off the anchors; helpful to see how one sets that up from above.
`,
            frames: [
              // '2023-04-11-red-rock-canyon/2023-04-11-red-rock-canyon-09',
              '2023-04-11-red-rock-canyon/2023-04-11-red-rock-canyon-17',
              '2023-04-11-red-rock-canyon/2023-04-11-red-rock-canyon-15',
              '2023-04-11-red-rock-canyon/2023-04-11-red-rock-canyon-11',
              // '2023-04-11-red-rock-canyon/2023-04-11-red-rock-canyon-12',
            ],
          },
        },
        {
          date: new Date('2023-04-10'),
          item: {
            type: itemType.CLIMB,
            title: 'Chitlin',
            gradeYDS: '.5',
            area: 'Joshua Tree National Park',
            crag: 'Mel\'s Diner',
            climbType: climbType.BOULDER,
            lengthFt: 15,
            mountainProjectUrl: 'https://www.mountainproject.com/area/116558551/mels-diner-boulder',

            sent: true,
          },
        },
        {
          date: new Date('2023-04-10'),
          item: {
            type: itemType.CLIMB,
            title: 'Corned Beef Hash',
            gradeYDS: '.6',
            area: 'Joshua Tree National Park',
            crag: 'Mel\'s Diner',
            climbType: climbType.BOULDER,
            lengthFt: 13,
            mountainProjectUrl: 'https://www.mountainproject.com/route/116558592/corned-beef-hash',

            sent: true,
          },
        },
        {
          date: new Date('2023-04-10'),
          item: {
            type: itemType.CLIMB,
            title: 'Blue Plate Special',
            gradeYDS: '.6',
            area: 'Joshua Tree National Park',
            crag: 'Mel\'s Diner',
            climbType: climbType.BOULDER,
            lengthFt: 20,
            mountainProjectUrl: 'https://www.mountainproject.com/route/116558603/blue-plate-special',

            sent: true,
          },
        },
        {
          date: new Date('2023-04-10'),
          item: {
            type: itemType.CLIMB,
            title: 'Mel\'s Traverse',
            gradeYDS: '.7',
            area: 'Joshua Tree National Park',
            crag: 'Mel\'s Diner',
            climbType: climbType.BOULDER,
            lengthFt: 15,
            mountainProjectUrl: 'https://www.mountainproject.com/route/116558576/mels-traverse',

            sent: true,
          },
        },
        {
          date: new Date('2023-04-09'),
          endDate: new Date('2023-04-11'),
          filterTags: ['outside', 'climbing'],
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          item: {
            type: itemType.POST,
            title: 'Joshua Tree National Park',
            text: `
Camped at [Jumbo Rocks Campground](https://www.recreation.gov/camping/campgrounds/272300) — 2 nights

First thing in the morning, did a pretty cool rock scramble. 
Non-trivial route-finding; over and under big rocks. Did about half of it, then
became stucked by water (~2 miles).

* Indian Cove: Gunsight Loop | [Sierra Club Wilderness Travel Course Trail](https://www.alltrails.com/trail/us/california/sierra-club-wtc-scramble-route) — Class 2-3

Then did easy bouldering at Mel's Diner — it was hot. First time climbing outside, really. 
(Attempted at Joshua Tree 2020, but those V0 boulders were way too hard; this time we knew to look for 
decimal point grades.)

* Lost Horse Road: [Mel's Diner](https://www.mountainproject.com/area/116558551/mels-diner-boulder)
  * [Chitlins](https://www.mountainproject.com/route/116558598/chitlins) .5, 15 ft. — ✅
  * [Corned Beef Hash](https://www.mountainproject.com/route/116558592/corned-beef-hash) .6, 13 ft. — ✅
  * [Blue Plate Special](https://www.mountainproject.com/route/116558603/blue-plate-special) .6, 20 ft. — ~✅
    * Did an early-escape variation and went to the left instead of over the top 
  * [Mel's Traverse](https://www.mountainproject.com/route/116558576/mels-traverse) .7, 15 ft. — ✅

Was fun to successfully climb outside for the first time, but boulders are scary/dangerous!

Went searching for [Conan's Corridor](https://www.mountainproject.com/area/105720780/conans-corridor) to 
practice trad placements (at Jumbo Rocks, close to camp), but couldn't find it, so completed
the Skull Rock Nature Trail at sunset; chill and pleasant. Learned that approach-finding is hard.

  * Jumbo Rocks: [Skull Rock Nature Trail](https://www.alltrails.com/trail/us/california/skull-rock-nature-trail) — 1.7 mi
`,
            frames: [
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-15',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-02',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-01',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-05',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-07',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-08',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-09',
              '2023-04-10-joshua-tree/2023-04-10-joshua-tree-10', // ok
            ],
          },
        },
        //       {
        //         date: new Date('2023-03-28'),
        //         filterTags: ['computers'],
        //         item: {
        //           type: itemType.POST,
        //           title: '[email egg] private beta',
        //           text: `
        // <div style="padding:56.25% 0 0 0;position:relative;">
        //   <iframe
        //     src="https://www.youtube.com/embed/9MMlDmtifMY?controls=0"
        //     title="YouTube video player"
        //     frameborder="0"
        //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        //     allowfullscreen
        //     style="position:absolute;top:0;left:0;width:100%;height:100%;" 
        //   >
        //   </iframe>
        // </div>`,
        //         },
        //       },
        {
          date: new Date('2023-03-25'),
          item: {
            type: itemType.FILM,
            title: 'Aftersun',
            year: 2022,
            director: 'Charlotte Wells',
            frames: [],
            text: '',
          },
        },
        // {
        //   date: new Date('2023-03-21'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'This Is Where I Leave You',
        //     year: 2014,
        //     director: 'Shawn Levy',
        //     frames: [],
        //     text: '',
        //   },
        // },
        // {
        //   date: new Date('2023-03-10'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'The Whale',
        //     year: 2022,
        //     director: 'Darren Aronofsky',
        //     frames: [],
        //     text: '',
        //   },
        // },
        {
          date: new Date('2023-03-08'),
          item: {
            type: itemType.FILM,
            title: 'The Fabelmans',
            year: 2022,
            director: 'Steven Spielberg',
            frames: [],
            text: '',
          },
        },
        // {
        //   date: new Date('2023-03-01'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Her',
        //     year: 2013,
        //     director: 'Spike Jonze',
        //     frames: [],
        //     text: 'in preparation for the next chat-gpt',
        //   },
        // },
        {
          date: new Date('2023-02-26'),
          item: {
            type: itemType.FILM,
            title: 'Women Talking',
            year: 2022,
            director: 'Sarah Polley',
            frames: [],
            text: '',
          },
        },
        // {
        //   date: new Date('2023-02-24'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Ammonite',
        //     year: 2020,
        //     director: 'Francis Lee',
        //     frames: [],
        //     text: '',
        //   },
        // },
        // {
        //   date: new Date('2023-02-22'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Private Life',
        //     year: 2018,
        //     director: 'Tamara Jenkins',
        //     frames: [],
        //     text: '',
        //   },
        // },
        // {
        //   date: new Date('2023-02-16'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'The Land of Steady Habits',
        //     year: 2018,
        //     director: 'Nicole Holofcener',
        //     frames: [],
        //   },
        // },
        {
          date: new Date('2023-02-15'),
          item: {
            type: itemType.FILM,
            title: 'The Banshees of Inisherin',
            year: 2022,
            director: 'Martin McDonagh',
            frames: [],
          },
        },
        // {
        //   date: new Date('2023-02-14'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Tallulah',
        //     year: 2016,
        //     director: 'Sian Heder',
        //     frames: [],
        //   },
        // },
        // jonah hill 2/8
        {
          date: new Date('2023-02-07'),
          item: {
            type: itemType.FILM,
            title: 'All Quiet on the Western Front',
            year: 2022,
            director: 'Edward Berger',
            frames: [],
          },
        },
        {
          date: new Date('2023-02-03'),
          item: {
            type: itemType.FILM,
            title: 'The Umbrellas of Cherbourg',
            year: 1964,
            director: 'Jacques Demy',
            frames: [],
          },
        },
        {
          date: new Date('2023-02-02'),
          item: {
            type: itemType.FILM,
            title: 'Paris, Texas',
            year: 1984,
            director: 'Wim Wenders',
            frames: [],
          },
        },
        {
          date: new Date('2023-02-01'),
          item: {
            type: itemType.FILM,
            title: 'Clouds of Sils Maria',
            year: 2014,
            director: 'Olivier Assayas',
            frames: [],
          },
        },
        // {
        //   date: new Date('2023-01-31'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Joan Didion: The Center Will Not Hold',
        //     year: 2017,
        //     director: 'Griffin Dunne',
        //     frames: [],
        //   },
        // },
        // {
        //   date: new Date('2023-01-30'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Tár',
        //     year: 2022,
        //     director: 'Todd Field',
        //     frames: [],
        //   },
        // },
        // {
        //   date: new Date('2023-01-29'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Elvis',
        //     year: 2022,
        //     director: 'Baz Luhrmann',
        //     frames: [],
        //   },
        // },
        // {
        //   date: new Date('2023-01-25'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Still Alice',
        //     year: 2014,
        //     director: 'Richard Glatzer, Wash Westmoreland',
        //     frames: [],
        //   },
        // },
        {
          date: new Date('2023-01-15'),
          item: {
            type: itemType.FILM,
            title: 'Sound of the Night',
            year: 2021,
            director: 'Chanrado Sok, Kongkea Vann',
            frames: [],
            text: `<div style="text-align:right;">
20 min | Cambodia
</div>`,

          },
        },
        {
          date: new Date('2023-01-15'),
          item: {
            type: itemType.FILM,
            title: 'Man Push Cart',
            year: 2005,
            director: 'Ramin Bahrani',
            frames: [],
          },
        },
        // {
        //   date: new Date('2023-01-12'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'My Policeman',
        //     year: 2022,
        //     director: 'Michael Grandage',
        //     frames: [],
        //   },
        // },
        // the woman king jan 1
        // gattica 12/25 
        {
          date: new Date('2023-01-02'),
          showCollapseText: true,
          textCollapsedOnInit: false,
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: '[moc] nyc modern coffee',
            text: `

<img width="80%" src="/img/2023-01-02-central-perk-modern/central-perk-modern-08-sm.jpg" alt="nyc moc">

# Concept
Modern coffee shop w/ co-working space on the 2nd floor.  New York City architecture, but serves
<code>8oz</code> cappuccinos. The two adjacent buildings are a set with this one and are also NYC
style, but are thin; one is dark red and one is tan; they serve as eggy hq, and should have
technology and science things.

# References

The NYC theme comes from the Central Perk [21319](https://brickset.com/sets/21319-1/Central-Perk) 
set because we wanted to be economical about sourcing pieces.
So the design of Version 1 is largely inspired by Brick Artisan's
[Sit-Complex](https://rebrickable.com/mocs/MOC-79570/Brick%20Artisan/central-perk-friends-apartment/#details)
and other variations of the official Central Perk set. (There are many alts of this set, but most 
don't follow the angled section onto the upper floors.)

Some references for the adjacent red & white MOC (for which there is not a post yet) include:

  <details style="margin-left: 30px">
    <summary>ref.1</summary>
    <img width="50%" src="https://www.ozmoving.com/sites/default/files/colorbld.jpg">
  </details>
  <br />
  <details style="margin-left: 30px">
    <summary>ref.2</summary>
    <img width="50%" src="https://www.brickunderground.com/sites/default/files/styles/blog_primary_image/public/blog/images/iStock-1370044173.jpg">
  </details>
  <br />
  <details style="margin-left: 30px">
    <summary>ref.3</summary>
    <img width="50%" src="https://cdn.vox-cdn.com/thumbor/M4k6CEfHNf10BBiCgxhkXHQOo8U=/0x0:1004x1338/1820x1213/filters:focal(422x589:582x749):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/65893013/new_york_city_house_2009.0.jpg">
  </details>

These were not very carefully chosen, and were the first photos that came up on Google for "New York City apartments".

# Structure

This building is intended to inhabit the left inverted corner in the upstairs-city (above-and-to-the-left of the train station).

Version 1 uses most of the fascade from Central Perk 
[21319](https://brickset.com/sets/21319-1/Central-Perk) and some of the pieces from 
The Friends Apartments [10292](https://brickset.com/sets/10292-1/The-Friends-Apartments) (mostly medium nougat
plates, profile bricks, and black 2x2 windows).

Footprint: **24 studs wide x 16 studs deep** (with 8x8 missing from the front-right corner)

The angled corner is close-ish to the angle of the 2x2 wedge plate ([24299](https://www.bricklink.com/v2/catalog/catalogitem.page?P=24299)), but not really.
(This is the angle of the diagonal walking path in front of the building.)
  * The exact angle is made from the following conditions:
    1. 8-wide double doors for the diagonal
    2. Needs to spans the 8-stud depth (let's call depth the <code>y-axis</code>) of our inverted corner.
    3. Doors need to attach at integer studs in the depth-direction (<code>y</code>) and width-direction (<code>x</code>) using hinge plates 
    ([2429](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2429)/[2430](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2430)) on both sides
        of the diaginal door.

  * The tri ends up being 4-studs wide (<code>x</code>) with the hinge attaching at the 
    stud to the direct left and right of the 4-stud width, and only 7 studs deep because the hinge-corner
    rests at the corner between the 7th and 8th y-axis stud (kinda makes no sense), making a <code>4x7x8</code> triangle;
    the hypotenuse _should_ be &nbsp;<code>sqrt(4</code><sup>2</sup><code> + 7</code><sup>2</sup><code>) = 8.06</code>&nbsp;, but there's enough
    give in the hinge-plates that this works, I guess ☠️

    <img width="80%" src="/img/2023-01-02-central-perk-modern/central-perk-modern-09-sm.jpg" alt="book 2 page 14">

While Version 1 is 3-stories tall, we've decided that the city doesn't look right (in a perspective way)
if the upstairs-city is that tall. So this needs to be revised to be only 2 stories tall. 
I like the balcony, so may try to turn that into the "roof", and maybe sneak in the shape of the 
3rd floor as a short attic/crawl-space.

# Exterior Detail

After making Version 1, we've decided that keeping the Central Perk remnants
only make the MOC worse. We can start by replacing the dark green with black, and changing
the awning colors. And replacing the green windows on the right side with black ones,
and in-setting them using jumpers like the other windows. (Those are currently not inset because
they follow the design of the original set.)

Also, I should look up more real-life references to figure out exactly how we want to change
this building to a 2-story buiding. While sticking with Central Perk/ Brick Artisan was
safe for Version 1, I now feel more confident to change things up more for Version 2.

# Interior Detail

Both floors are supposed to be a clean well-lighted place, where you can put your papers down
on the tables and they won't get sticky. And everybody uses a coaster not because of any rules or social
pressure, but because they want to make the right choice for all mankind [sic].

## Floor 1

The coffee-making area has a dark-red La Marzocco espresso machine, which is maybe a pretentious.
I wanted the cups on top of the machine to be downside-up so as not to collect dust and human skin,
but alas, the studs of the cup are on the bottom. There is, for now, a cash-register, but mainly just because
that build was in Central Perk. Our city doesn't buy or sell things, so that should be removed
in Version 2. The little coffee-goods on the left
wall were inspired by AFOL TV from their [Starbucks Reserve MOC](https://afolstore.com/products/lego%C2%AE-coffee-shop-starbricks-reserve-interior-instructions).

## Floor 2

TBD but it will likely follow the same color-scheme as the tables on Floor 1. Will think about
good co-working ergonomics. Probably we need well-interior-decorated indoor plants.

I'm considering putting stairs in the right-side 8x8 space. It will block the nice back windows on 
Floor 1, though; will it still be clean and well-lighted?


`,
            frames: [
              '2023-01-02-central-perk-modern/central-perk-modern-01',
              '2023-01-02-central-perk-modern/central-perk-modern-02',
              '2023-01-02-central-perk-modern/central-perk-modern-04',
              '2023-01-02-central-perk-modern/central-perk-modern-03',
              '2023-01-02-central-perk-modern/central-perk-modern-05',
              '2023-01-02-central-perk-modern/central-perk-modern-06',
            ],
          },
        },
      ],
    },
    {
      year: 2022,
      goals: {
        books: 20,
      },
      events: [
        {
          date: new Date('2022-12-02'),
          endDate: new Date('2022-12-30'),
          item: {
            type: itemType.BOOK,
            title: 'In Search of Lost Time, Vol. 1: Swann’s Way',
            year: 1913,
            author: 'Marcel Proust',
            text: `<div style="text-align:right;">
<sub>French: <i>À la recherche du temps perdu: Du côté de chez Swann</i></br>
Translated by C.K. Scott Moncrieff and Terence Kilmartin. Revised by D.J. Enright; Modern Library, 2003</sub><br/>
<br/>
Along with: <b><i>Paintings in Proust: A Visual Companion to In Search of Lost Time</i></b>
by Eric Karpeles, 2017
</div>`,
          },
        },
        {
          date: new Date('2022-12-13'),
          item: {
            type: itemType.FILM,
            title: 'Happening',
            year: 2021,
            director: 'Audrey Diwan',
            frames: [],
            text: `<div style="text-align:right;">
French: <i>L'Événement</i>
</div>`,
          },
        },
        {
          date: new Date('2022-12-11'),
          item: {
            type: itemType.FILM,
            title: 'On the Count of Three',
            year: 2021,
            director: 'Jerrod Carmichael',
            frames: [],
          },
        },
        // she said 12/11
        {
          date: new Date('2022-12-06'),
          item: {
            type: itemType.FILM,
            title: 'The Worst Person in the World',
            year: 2021,
            director: 'Joachim Trier',
            frames: [],
            text: `<div style="text-align:right;">
Norwegian: <i>Verdens verste menneske</i>
</div>`,
          },
        },
        {
          date: new Date('2022-12-04'),
          item: {
            type: itemType.FILM,
            title: 'Eyes Wide Shut',
            year: 1999,
            director: 'Stanley Kubrick',
            frames: [],
          },
        },
        {
          date: new Date('2022-11-28'),
          endDate: new Date('2022-12-11'),
          item: {
            type: itemType.FILM,
            title: "The White Lotus (Season 2)",
            year: 2022,
            director: 'Mike White',
            text: `
<div style="text-align:right;">
  <sub>(7-episode HBO no-longer-limited Series)</sub><br />
</div>
          `,
          },
        },
        {
          date: new Date('2022-10-30'),
          item: {
            type: itemType.FILM,
            title: 'Old Joy',
            year: 2006,
            director: 'Kelly Reichardt',
            frames: [],
          },
        },
        {
          date: new Date('2022-10-29'),
          item: {
            type: itemType.FILM,
            title: 'The Good Nurse',
            year: 2022,
            director: 'Tobias Lindholm',
            frames: [],
          },
        },
        {
          date: new Date('2022-10-27'),
          item: {
            type: itemType.FILM,
            title: 'Certain Women',
            year: 2016,
            director: 'Kelly Reichardt',
            frames: [],
          },
        },
        {
          date: new Date('2022-10-27'),
          item: {
            type: itemType.FILM,
            title: 'Asako I & II',
            year: 2018,
            director: 'Ryusuke Hamaguchi',
            frames: [],
            text: `<div style="text-align:right;">
Japanese: 寝ても覚めても (<i>Netemo Sametemo</i>)</br>
"whether asleep or awake"
</div>`,
          },
        },
        {
          date: new Date('2022-10-26'),
          item: {
            type: itemType.FILM,
            title: 'Happy Hour',
            year: 2022,
            director: 'Ryusuke Hamaguchi',
            frames: [],
            text: `<div style="text-align:right;">
Japanese: ハッピーアワー (<i>Happī Awā</i>)
</div>`,
          },
        },
        {
          date: new Date('2022-10-25'),
          item: {
            type: itemType.FILM,
            title: 'August: Osage County',
            year: 2013,
            director: 'John Wells',
            frames: [],
          },
        },
        {
          date: new Date('2022-10-16'),
          showCollapseText: true,
          textCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: '[techniques] flipping studs',
            text: `
**01**<span style="padding-right: 30px"/><code>75317</code><span style="padding-right: 10px"/>
The Mandalorian & the Child
<!-- book 2 page 14 -->

<img width="50%" src="/img/2022-10-16-lego-techniques/2022-10-16-lego-techniques-01-sm.jpg" alt="book 2 page 14">

**02**<span style="padding-right: 30px"/><code>10497</code><span style="padding-right: 10px"/>
<span width="250">Galaxy Explorer</span>
<!-- book 2 page 129 -->

<img width="50%" src="/img/2022-10-16-lego-techniques/2022-10-16-lego-techniques-02-sm.jpg" alt="page 129">
`,
            // also noteable in galaxy explorer:
            // * sub-build p122-124 on slider door rails
            // * angled base e.g. p61-62
          },
        },
        {
          date: new Date('2022-10-08'),
          item: {
            type: itemType.FILM,
            title: 'Both Sides of the Blade',
            year: 2022,
            director: 'Claire Denis',
            frames: [],
            text: `<div style="text-align:right;">
French: <i>Avec amour et acharnement</i></br>
"with love and fury"</br>
<sub>(the one where Juliette Binoche is married to Jean and obsessed with François)</sub>
</div>`,
          },
        },
        {
          date: new Date('2022-07-24'),
          item: {
            type: itemType.FILM,
            title: 'Brad’s Status',
            year: 2017,
            director: 'Mike White',
            frames: [],
          },
        },
        //       {
        //         date: new Date('2022-06-09'),
        //         endDate: new Date('2022-07-22'),
        //         showCollapseText: true,
        //         textCollapsedOnInit: true,
        //         item: {
        //           type: itemType.FILM,
        //           title: 'Investigation of January 6 Attack on the U.S. Capitol',
        //           year: 2022,
        //           director: 'U.S. House Select Committee',
        //           frames: [],
        //           text: `
        // <div style="text-align:right;">
        //   (8-session <a href="https://www.c-span.org/organization/?139816/Select-Committee-Investigate-January-6th-Attack-United-States-Capitol" target="_blank">CSPAN Limited Series</a>) </br></br>
        // </div>
        //   <a href="https://www.c-span.org/video/?520944-1/president-trumps-campaign-influence-vice-president-pence#" target="_blank">Session 3</a> was particularly exciting
        //   especially Eric Herschmann re John Eastman from <code>2:25:25 — 2:28:19</code>, and my personal favorite, Mr. Jacob re Babylon at <code>2:23:56</code>. </br></br>
        //   <sub>(<code>c-span.org</code>'s searchable transcript-to-video integration is impressive.)</sub></br>`,
        //         },
        //       },
        {
          date: new Date('2022-07-20'),
          item: {
            type: itemType.FILM,
            title: 'Who You Think I Am',
            year: 2019,
            director: 'Safy Nebbou',
            text: `
<div style="text-align:right;">
  French: <i>Celle que vous croyez</i></br>
<sub>(the one where Juliette Binoche does catfish; "Clara" and Alex)</sub>
</div>`,
          },
        },
        // {
        //   date: new Date('2022-07-19'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Words on Bathroom Walls',
        //     year: 2020,
        //     director: 'Thor Freudenthal',
        //   },
        // },
        // {
        //   date: new Date('2022-07-18'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'The Talented Mr. Ripley',
        //     year: 1999,
        //     director: 'Anthony Minghella',
        //   },
        // },
        //       {
        //         date: new Date('2022-04-27'),
        //         item: {
        //           type: itemType.FILM,
        //           title: 'Phoenix Rising',
        //           year: 2022,
        //           director: 'Amy Berg',
        //           text: `
        // <div style="text-align:right;"><sub>Evan Rachel Wood (two-part docuseries)</sub></br>
        // </div>`,
        //         },
        //       },
        // {
        //   date: new Date('2022-04-25'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'King Richard',
        //     year: 2021,
        //     director: 'Reinaldo Marcus Green',
        //   },
        // },
        // {
        //   date: new Date('2022-06-03'),
        //   item: {
        //     type: itemType.FILM,
        //     title: 'Free Willy',
        //     year: 1993,
        //     director: 'Simon Wincer',
        //   },
        // },

        {
          date: new Date('2022-07-16'),
          showCollapseText: true,
          textCollapsedOnInit: false,
          useSerif: true,
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.POST,
            title: '[marx] 1. the commodity',
            text: `<i>Notes on "Capital Volume I" (1867) by Karl Marx — Chapter 1, Sections 1—2 </i>

# Chapter 1: The Commodity
## Section 1: The Two Factors of the Commodity: Use-value and Value (Substance of Value, Magnitude of Value)

**Commodity**: an external object/thing that satisfies a human need of whatever kind

A commodity has:
1. **Use value**: the usefulness of the thing
2. **Exchange value**: quantitative relation in which use-values of one kind exchange for use-values of another kind.
    * For example, "a quarter of wheat...is exchanged for _x_ boot-polish, _y_ silk or _z_ gold etc."
    * This exchange ratio presupposes that these items of different use value have _something in common_ that make them exchangeable.

This _something in common_ (what Marx calls **value**) is congealed quantities of homogeneous human labor.
The magnitude of value is exclusively determined by the amount of labor [time] socially necessary for its production.

**Value (socially necessary labor time)**: labor time required to produce any use-value under the conditions of production normal
for a given society and with average degree of skill and intensity of labor prevalent in that society.

As _exchange values_, all commodities are merely definite quantities of congealed labor time.
* As productivity of labor increases, labor time required to produce the article decreases, the labor time crystallized _in_ the article decreases, and therefore the value of the article decreases.
<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; productivity of labor</td>
        <td style="border: 1px solid #ddd; padding:10px;">&darr; labor time required to produce the item</td>
        <td style="border: 1px solid #ddd; padding:10px;">&darr; labor time crystallized in the article</td>
        <td style="border: 1px solid #ddd; padding:10px;">&darr; value of the item</td>
      </tr>
    </tbody>
  </table>
</div>

* Alternatively, As productivity of labor decreases, labor time increases, and value increases.

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px;">&darr; productivity of labor</td>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; labor time required to produce the item</td>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; labor time crystallized in the article</td>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; value of the item</td>
      </tr>
    </tbody>
  </table>
</div>

The _substance_ of value is _labor_. The _magnitude_ of value is _labor time_. (The _form_ which stamps _value_ as _exchange-value_ will be covered later.)

A _commodity_ must be a _use-value_ _produced for others_ through the medium of _exchange_.

<details style="margin-left: 30px">
  <summary>More elaboration</summary>

  * A thing can have use-value without being a value (if it doesn't need labor)
    * e.g. air, natural meadows, unplanted forests, etc.

  * A thing can have use-value and be a product of human labor without being a commodity
    * e.g. something that is used (satisfies the maker's own need) but is not exchanged

  * Nothing can be a value without being useful; If it's useless, so is the labor contained in it; labor doesn't count as labor; creates no value.
</details>

## Section 2: The Dual Character of the Labour Embodied in Commodities

A commmodity _as an object of utility_ (use-value) requires concrete labor to make.
  * Different use-values require different types of skills
  * Making a coat and making linen require different forms of useful labor ("a social division of labor")

A commodity as a something to be exchanged (exchange-value) boils labor down to "simple average labor"
  * 10 yards of linen = W. coat = 2W.
  * The coat is worth 2x linen because linen has half the labor time (labor is expended twice as long to make the coat)
    * If labor takes 2x as long, the value of the coat doubles
    * If labor takes 1/2 as long, the value of the coat is halved

The more use-values you have, the more material wealth you have. (eg. 2 coats can clothe 2 people)
<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; quantity of <i>use-values</i></td>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; material wealth</td>
      </tr>
    </tbody>
  </table>
</div>

However, this may correspond to a decrease in the _value_ (socially-necessary labor time) of the item
<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; quantity of <i>use-values</i></td>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; material wealth</td>
        <td style="border: 1px solid #ddd; padding:10px;">&darr; magnitude of item's <i>value</i></td>
      </tr>
    </tbody>
  </table>
</div>
<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; productivity</td>
        <td style="border: 1px solid #ddd; padding:10px;">&uarr; quantity of use-values produced</td>
        <td style="border: 1px solid #ddd; padding:10px;">&darr; value of this increased total amount</td>
      </tr>
    </tbody>
  </table>
</div>

This contradiction exists because of the two-fold character of labor.
* Labor as _equal_ and _abstract_ labor forms the _value of commodities_
* Labor in a _concrete, particular_ form, with _definite aims_, produces _use-values_

<span style="margin-left: 15px">&rarr;</span> Making something a commodity (with exchange value) compromises its use-value.
`
          },
        },
        {
          date: new Date('2022-07-09'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: '[moc] µ castle',
            text: `
trying to win some sets via [lego ideas contest](https://ideas.lego.com/challenges/c7291324-89fa-4ec3-83bd-0de5a4f16619) [submission](https://ideas.lego.com/challenges/c7291324-89fa-4ec3-83bd-0de5a4f16619/application/5719396b-5e51-4777-bd85-92c187dd9279)
`,
            frames: [
              '2022-07-09-castle-375/castle-375-03',
              '2022-07-09-castle-375/castle-375-01',
              '2022-07-09-castle-375/castle-375-02',
              '2022-07-09-castle-375/castle-375-04',
            ],
          },
        },
        // {
        //   date: new Date('2022-06-07'),
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Crafting Interpreters',
        //     year: 2021,
        //     author: 'Robert Nystrom',
        //     inProgress: true,
        //   },
        // },
        {
          date: new Date('2022-07-04'),
          showCollapseText: true,
          textCollapsedOnInit: false,
          useSerif: true,
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.POST,
            title: '[wainwright, mann] climate leviathan',
            text: `
_Notes on "[Climate Leviathan: A Political Theory of Our Planetary Future](https://www.versobooks.com/books/3138-climate-leviathan)" (2018)
by Joel Wainwright and Geoff Mann_. With reference to the [Red Menace](https://redmenace.libsyn.com/climate-leviathan) podcast episode on this book.

## Introduction

While we try to mitigate rapid climage change, we also need to think about its political consequences. The world is going to be
vastly different and it will change how human life on Earth is organized. (Preface XI)

Quote from Mike Davis, [_Who Will Build the Ark?_](https://irows.ucr.edu/cd/courses/10/reader/New%20Left%20Review%20-%20Mike%20Davis%20%20Who%20Will%20Build%20the%20Ark.htm), 2010:
> In other words, instead of galvanizing heroic innovation and international cooperation, <mark>growing 
> environmental and socio-economic turbulence may simply drive elite publics into more frenzied 
> attempts to wall themselves off from the rest of humanity</mark>. Global mitigation, in this unexplored 
> but not improbable scenario, would be tacitly abandoned—as, to some extent, it already has been—in 
> favour of accelerated investment in selective adaptation for Earth’s first-class passengers. 
> The goal would be the creation of green and gated oases of permanent affluence on an otherwise stricken planet.
>
> Of course, <mark>there would still be treaties, carbon credits, famine relief, humanitarian acrobatics</mark>,
> and perhaps the full-scale conversion of some European cities and small countries to alternative energy. 
> <mark>But worldwide adaptation to climate change, which presupposes trillions of dollars of investment
> in the urban and rural infrastructures of poor and medium-income countries, as well as the assisted 
> migration of tens of millions of people from Africa and Asia, would necessarily command a revolution 
> of almost mythic magnitude in the redistribution of income and power.</mark> Meanwhile we are speeding 
> toward a fateful rendezvous around 2030, or even earlier, when the convergent impacts of 
> climate change, peak oil, peak water, and an additional 1.5 billion people on the planet will 
> produce negative synergies probably beyond our imagination.

Wainwright and Mann explore four potential global political responses to this future based on a pair of dichotomies:
1) capitalist or non-capitalist
2) one led by a planetary sovereign or not (anti-planetary sovereign)

<div style="margin-left: 30px; margin-top: 30px; margin-bottom: 30px;">
  <table style="border-collapse: collapse; font-family: Inter; font-size: 0.95rem;">
    <thead>
      <tr>
        <th></th>
        <th style="border: 1px solid #ddd; padding:10px; background-color: #eee">planetary sovereign</th>
        <th style="border: 1px solid #ddd; padding:10px; background-color: #eee">anti-planetary sovereign</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px; background-color: #eee"><b>capitalist</b></td>
        <td style="border: 1px solid #ddd; padding:10px;">climate leviathan</td>
        <td style="border: 1px solid #ddd; padding:10px;">climate behemoth</td>
      </tr>
      <tr>
        <td style="border: 1px solid #ddd; padding:10px; background-color: #eee"><b>anti-capitalist</b></td>
        <td style="border: 1px solid #ddd; padding:10px;">climate mao</td>
        <td style="border: 1px solid #ddd; padding:10px;">climate x</td>
      </tr>
    </tbody>
  </table>
</div>

## Note on the names
  The names given for the 4 political potentialities by Wainwright and Mann (Climate Leviathan,
    Climate Mao, Climate Behemoth, and Climate X) are a bit confusing.

  The authors use Hobbes' concept of a  <i>Leviathan</i> to describe both imaginations led 
  by a "planetary sovereign" in the left column. They use Hobbes' concept of a <i>Behemoth</i>,
  to describe both imaginations that are <i>not</i> led by a planetary-sovereign in the right column.
  
  * Climate Leviathan is a capitalist 'leviathan'/planetary-sovereign
  * Climate Mao is an anti-capitalist 'leviathan'/planetary-sovereign
  * Climate Behemoth is a capitalist 'behemoth'/anti-planetary-sovereign
  * Climate X is an anti-capitalist 'behemoth'/anti-planetary-sovereign

<br/>
First, a quick explanation of Hobbes' <i>Leviathan</i>, a term / concept which will be used to describe 
the two planetary-sovereign solutions (Climate Leviathan and Climate Mao):

## Hobbes' _Leviathan_
* TLDR; The Leviathan metaphor comes from the Leviathan monster from the book of Job.  Hobbes uses _Leviathan_  to represent
a strong state/government; one tending toward totality of control. The Leviathan has power because of the agreement
of the people. In Hobbes' view, the Leviathan is good and necessary in order to keep society from descending into chaos.
* Where does a sovereign or governing body get its legitimacy? 
  From <i>"social contract theory"</i>; from people agreeing that the state has sovereignty. 
* The <i>"state of nature"</i> is the state in which humans
exist before entering into contract with the government where they are only bound by natural laws 
and can do whatever they want. (Hobbes describes this as life that is "nasty, brutish, and short".)
* Then emerges the necessity of a sovereign to mediate
human affairs; <i>a Leviathan</i>. The sovereignty of the Leviathan comes from people entering into contract with
the state; willing to give up some freedoms in exchange for safety and security. It is the solution to chaos and violence.
* The Leviathan arises in moments of exception or crisis (e.g. in the U.S., 9/11, COVID-19; we are 
willing to extend the usual powers of the state in a moment of exception/crisis/emergency).
  * Liberal states can take various forms of dictatorship; Leviathan represents the trend toward totalizing control.
* The notable cover art of Hobbes' _Leviathan_ has a crowned giant with a sword (earthly power) and 
crosier (Church power). Its torso and arms are composed of 300 humans, together
representing their contracted leader who draws strength from collective agreement. ([British Library](https://www.bl.uk/collection-items/hobbess-leviathan))

  <details>
    <summary>Expand image</summary>
    <br/>
    <img src="https://www.college.columbia.edu/core/sites/core/files/styles/large/public/images/Leviathan.jpg?itok=VTL9wcVC">
  </details>

## Climate Leviathan: capitalist planetary sovereignty
  * A global planetary sovereign emerges and ensures that capitalism continues. Wainwright and Mann believe this
    to be the most likely trajectory. We live in a global capitalist world order that serves fundamentally the interests of capitalism.
  * The dream of a sustainable capitalist status quo
    > the waning, US-led, liberal capitalist block will collaborate with China to create a planetary regime...The pattern of mobilization
      will likely be familiar, in which the United Nations or other international fora serve as a means of legitimizing aggressive means of surveillance 
      and discipline. This could make the construction of Climate Leviathan a key means by which to salvage US hegemony — a prospect that 
      only increaes the likelihood of its consolidation. (32)
  * Given that combating climate change is inherently at odds with the goals of capitalism, a capitalist
    solution would require a state solution (a Leviathan); a regulatory body.
  * The seeds for this path exist
    * While pretty ineffectual, the annual meeting of the United Nations Conference of the Parties (COP) to advance
      the United Nations Framework Convention on Climate Change(UNFCCC) is the first institutional manifestation 
      of this dream; "a process that the dominant capitalist nation-states will consolidate as climate-induced disruptions of
      accumulation and political stability become more urgent."
    
    * John Holdren, senior advisor to Obama, co-authored a plan called "Toward a Planetary Regime" in 1977:
      <details>
      <summary>
        Expand
      </summary>
        </br>
        <blockquote>
        <i>Toward a Planetary Regime:</i> . . . Perhaps those agencies, combined with [the United Nations Environment Programme] 
        and the United Nations population agencies, 
        might eventually be developed into a Planetary Regime—sort of an international superagency for 
        population, resources, and environment. Such a comprehensive Planetary Regime could control the 
        development, administration, conservation, and distribution of all natural resources . . . 
        Thus the Regime could have the power to control pollution not only in the atmosphere and oceans, 
        but also in such freshwater bodies as rivers and lakes that cross international boundaries or 
        that discharge into the oceans. The Regime might also be a logical central agency for 
        regulating all international trade, perhaps including assistance from DCs to LDCs, and 
        including all food on the international market. The Planetary Regime might be given responsibility 
        for determining the optimum population for the world and for each region and for arbitrating 
        various countries’ shares within their regional limits. Control of population size might remain 
        the responsibility of each government, but the Regime would have some power to enforce the agreed limits.
        </blockquote>
        </br>
        <div class="markdown-quotee">Paul Erhlich, Anne Ehrlich, John Holdren, 1977</div>
      </details>
    
      The "Regime" described is [Carl] Schmitt-like (and Erhlichs are neo-Malthusians), but the _capitalist_ nature of Climate Leviathan
      will make it more Keynesian (like the effort to save capitalist civilization after 1929), 
      than like Nazi-ism.
  
  * The capitalist Leviathan would likely take a Keynesian form (33)
    >  a concentration of political power at the national scale in combination with international coordinating institutions that attempt to render liberal hegemony immutable — allowing, as with the United Nations, for specific constraints on capital's dominion. 
    * Based in belief that capitalist growth can be managed, e.g. Edward Barbier's "Global Green New Deal"; one of several plans for a "green Keynesianism"
    * We can already see the current transition to global green capitalism ("greenwashing"); 
     there would be continued opportunities for capital accumulation via green business.
    * "Climate Leviathan will be the fundamental regulary ideal motivating elites in the near future." (34)
  * There are obvious problems with this
    * While the state might be strong, it will be contested. It will be "threatened within by the
      usual burdens of any state-capitalist project divided by multiple accumulation strategies, and 
      <mark>it almost impossible to imagine that it will actually reverse climate change</mark>."
      * Capitalism requires <mark>"the drive for incessantly expanded accumulation"; "the constant conversion 
        of the planet into means of production". The energy required to operate capitalism is hitting our planetary limits.</mark> (34)
    * Capitalism deepens inequalities of wealth and power; it will be hard to form trans-class coalitions around "shared sacrifice".
         Even if "Climate Leviathan can come into being — through a global consolidation of ecological and economic sovereignty and
         some combination of coercion and consent — it is unlikely to secure confident hegemony."
        * Wainwright/Mann say that we shouldn't assume that this means it will die; "its advocates desparately seek a containment strategy for its foes."
    * A political-economic response to climate change in liberal, capitalist societies has a "fundamentally contradictory character" (38).
      * Wainwright and Mann point to the <mark>ineffectiveness of the Paris Agreement</mark> of December 2015 (which included no agreement to keep fossil
       fuels in the ground) <mark>as an example that this task is fundamentally contradictory to capitalist interests.</mark>
      * However, Wainwright and Mann seem optimistic at the ineffectiveness by the Paris Agreement b/c at least the agreement
       is not _pretending_ that it is effective when it is not; it admits that it does not solve the problem.
        > The Paris Agreement admits to its own failures. So it would be more accurate to say (as Hegel might have) that 
        the Paris Agreement is an entirely 'rational' manifestation of the world's reason — a world 
        and reason wrought with deep contradictions.
        The world's elites recognize these contradictions and — although they are by no means agreed on what to do — are trying
        to address them within limiting conditions, conditions that cause them to "fail". The principle failure is that the
        Paris Agreement does not keep fossil fuels in the ground, but this does not mean it will
        not set the foundation for adaptation on a burning planet. On the contrary, the so-called "failures"
        of Paris are enabling, and part of, a crucial adaptation, the adaptation of the political. Notwithstanding
        inadequacies on the carbon question, the Paris agreement constitutes an important step toward the emergence of planetary
        sovereignty.

## Climate Mao: anti-capitalist planetary sovereignty
  * Also led by a planetary sovereign or Leviathan, but alternatively, anti-capitalist.
    > As the climate justice movement struggles to be heard, most campaigns in the global North are premised on an unspoken faith in a lop-sided
    elite-biased, liberal proceduralism doomed to failure given the scale and scope of the changes required. If climate science is
    even half-right in its forecasts, the liberal model of democracy is at best too slow, at worst a devastating distraction. <mark>Climate Mao reflects the
    demand for rapid, revolutionary, state-led transformation today.</mark>
  * Calls abound on the Left for Climate Mao as an alternative to the capitalist Climate Leviathan (e.g. Mike Davis, Giovanni Arrighi, Minqi Li). 
  * A Climate Mao regime would require China to return to, or push forward to, a Mao-ist China; while this seems unlikely today, 
  "if an anticapitalist, planetary sovereign is to emerge that could change the world's climate trajectory, it is most likely to emerge in China."

    Minqi Li argues that Climate Mao offers the only way forward: 
    > [U]nless China takes seriously and meaningful actions to fulfill its obligations of emissions reduction,
    there is little hope that global climate stabilization can be achieved. However, it is very unlikely
    that the [present] Chinese government will voluntarily take the necessary actions to reduce
    emissions. The sharp fall of economic growth that would be required is something that the Chinese
    government will not accept and cannot afford politically. Does this mean that humanity is doomed?
    That depends on the struggle within China and in the world as a whole.

    Li says a re-energization of the Maoist political trandition / a new revolution in China, could transform China and save humanity from doom.
  
  *  "Even today, with an increasingly non-Maoist Chinese state invokes its full regulatory authority, it can achieve political feats
  unimaginable in liberal democracy":
      * Beijing's air quality was re-engineered for the 2008 Olympics; "flowers potted all over the city,
  traffic barred, trees planted in the desert, and factories and power plants closed — all to successfully blue the skies for the Games." (40)
      * Chinese state effectively killed General Motor's Hummer in 2010
      * The "Great Green Wall" if completed will give China 42% forest cover by 2050.
      * Since vowing to reduce emissions in Summer 2010, the CCP closed more than 2,000 steel mills and other 
      carbon-emitting factories by March 2011
      * In mid-2016, the government released dietary guidelines encouraging people to consume no more than 75g of meat per day
      > Such policies foretell the possibility of a Climate Mao, were China to become a global hegemon and also change under revolutionary pressures.
      > 
      > To be clear, that is a very big "if." 
      > . . . The Communist Party of China appears committed, at least today, to building a capitalist Climate Leviathan. The centrality of China to the Paris Agreement only proves the point.

  * This imagination is specifically Mao-ist (and not say, Robespierre-ist or Lenin-ist).
      * Mao emphatically denied that only a fully proletarianized class could serve as the basis of revolution; 
      that even "poor peasants" and "semi-proletariat" could achieve class consciousness in the Marxist sense.
      * This is crucial today "in an era with large and growing social groups that, to put it mildly, do not 
        fit neatly into the bourgeois-proletariat distinction." (41)
  * This imagination is also specifically Asian
    > Only in Asia — and only with some revolutionary leadership from China — do we find the combination of factors that make Climate Mao realizable:
    massive and marginalized peasantries and proletariats, historical experience and revolutionary ideology,
    and powerful states governing large economies. 

    >  In the imminent confrontation of Asia's historical-geographic conditions with catastrophic 
    climate change, too many people have too much to lose, too quickly — a formula for revolution.

    Wainwright/Mann expect Asian (Indian and Chinese) peasants and recently urbanized former peasants, 
    not to respond to carbon emissions per se but to state failures in response to material crises 
    (shortages of water, food, shelter, etc.).
    China is currently building toward Climate Leviathan. How we get to Climate Mao will depend on the Chinese proletariat and peasantry.
    China's emissions are growing daily and the economic growth associated with those emissions "is the basis of much of the legitimacy enjoyed
    by the Chinese state and ruling elites. If the Chinese working class responds to massive climate-change-induced
    disruptions in growth, the possibilities for an energetic Climate Mao are substantial."

    * Maoist tradition exists not only in China, but also in India, Nepal, North Korea (not exactly Maoist).

  * What makes Climate Mao a plausible basis for global transformation?

    <details>
      <summary> Toggle this map between "Emissions" and "People at risk"</summary></br>
      <iframe style="margin-left: 20px;" src="https://www.carbonmap.org/#Emissions" height="400px" width="460px"></iframe>
    </details>
    </br>

    > First, most rich countries (the US, Canada, Western Europe and some oil-producing states) are home to very
    > few people who are directly at risk of the negative ffects of climate change.
    >
    > Second, there is an extraordinary geographical unevenness to the world's at-risk population. They live
    mainly in South and East Asia, betwen Pakistan and North Korea, a belt of potentially revolutionary change.
    >
    > Asia is not only home to the majority of humanity, but also the center of capital's economic
    geography: the world's hub of commodity production and consumption (and carbon emissions). We might expect,
    therefore, climate-induced social turbulence to combine a region with an enormous, growing capacity to reshape
    the consumption and distribution of all the world's resources.

## Climate Behemoth: capitalist anti-planetary sovereignty
  * In Hobbes' work, behemoth is a figure of the masses (plural of Aramaic _behema_: ordinary cattle or beast). 
    Schmitt casts Behemoth in the revolutionary role,
    in opposition to the Leviathan. However, as Wainwright/Mann discuss, a 'revolution' of the masses 
    can take different forms.
    
    > Behemoth provides at least two possible, mass-based responses to Leviathan:
    > 1) reactionary population (Climate Behemoth)
    > 2) revolutionary anti-state democracy (Climate X)
    > </br>

  * Reactionary populism (Climate Behemoth)
    * Conservative, populist rally to capital; reaction to Climate Leviathan (e.g. Donald Trump, Narendra Modi, Brexit)
      * Epitomized in climate change denial in mainstream political discourse, especially in the United States.
      * Imprevious to reason; disproportionate influence of this proudly unreasonable minority
    * Class basis for Climate Behemoth
      * Leadership/funding from a small fraction of the capitalist class w/ ties to fossil fuels. 
        Outsized role in shaping ideology, but too small in number to win elections in democratic societies; 
        they need allies from lower social classes.
      * In the capitalist core, esp. where fossil-fuel sector is large (U.S., Canada, Australia), 
        most allies are among the proletariat who think climate change response threatens their 
        jobs and cheap energy. Also think climate change response empowers elites and hinders national 
        sovereignty. (But there are variations in class composition within capitalist societies — 
        across nation-states and globally; Trump voters in Ohio/Michigan different
        from those in Texas; similarly for supporters of Modi, or Brexit proponents.)
    * Identifiable broad trends
        * "Right-wing movements have grown steadily since the 2000s, mobilized around ethno-religious-nationalist
        (and often hyper-masculinist) ideologies, leading to momentous political victories for authoritarian, neoliberal
        leadership."
        * Signature issue around immigration, "security" for privileged racial and/or religious groups.
        * In most cases, political shift accompanied by a rejection of international collaboration to address climate change. (e.g. post-Brexit England, U.S. under Trump)
        * Variegation of social classes supporting Behemoth is one of its strengths; Trump and Modi
        voters come from different social groups and classes, mobilized around forms of racial/national/gendered prejudice, but
        almost unanimously oppose legitimacy of a distinctly international political sphere, especially if it has
        the capacity to discipline (national) capital.
    * Behemoth's inability to provide a coherent alternative to liberal capitalism's crisis will limit its long term viability.
        * Today, Behemoth substitutes rhetoric for explanation; it is truly reactionary.
        * Its myriad of goals are contradictory and unstable
          > In the United States, the signature affiliations of the reactionary right — market fetishism, cheap energy, white nationalism,
        firearms, evangelical faith — buttress reactionary Behemoth. The result is an opportunistic, but contradictor
        and unstable, blend of fundamentalisms: the security of the homeland, the freedom of the market, and the justice of God.
    * "[T]he nightmare outcome of reactionary Behemoth" was realized in the Nazi state.

## Climate X: anti-capitalist anti-planetary sovereignty
  * Revolutionary anti-state democracy (the second type of Behemoth) — we search for a nonreactionary opposition to Climate Leviathan.
    * Fredric Jameson: Today, "it is easier to imagine the end of the world than to imagine the end of capitalism." ("Future City", 2003)
    * In reponse the authors urge us to remember:
      > First, although imagination is of course not enough on its own, and it is indeed "easier" to imagine the end
      of the world , it is not only possible but imperative that we imagine the end of capitalism. We must
      try to assemble effecgtive conceptions of such a world, alternative rallying points and revolutionary
      strategies for cliamte justice.
      >
      > Second, despite their novel apperance through atmospheric chemistry and glacial melt rates, the problems
      posed at present are not new. The basic questions which have tormented the Left for centureis —
      the relations between sovereignty, democracy, and liberty; the political possibilites of a mode of human
      life that produces not exchange values but social wealth and dignity for all — are still the ones that matter.
      The defining characteristic of their present inensity is that they have an ecological deadline. The urgency
      that global warming imposes does not cut us off from the past, but only reignites the past in the present.
`
            ,
            //
            // * <sub>lol</sub> the authors seem a bit self-satisfied with their "Hegelian" argument. 
            // The Paris Agreement (which admittedly doesn't address the carbon question) is not a failure because it is _rationally consistent_ with 
            // the fact that capitalist interests and climate interests are fundamentally contradictory. But at least they're...consolidating capitalist power... 
            // I think their point fails to acknowledge that they are choosing capitalist interests _over_ climate interests.
            // And yes, they will likely adapt _capitalist interests_ to a burning planet (e.g. green business), but that
            // does not inherently align with making the world livable.
            //
            // what if climate crisis doens't meet end of the world?
            // hobbes
            // 
            // where do sovereins get power from; feudalism—divine right of kings
            // hobbes disrupts this idea
            // foundational idea in contemporary liberalism
            // emerence of capitalism; revolutions in europe
            // where doew governing body get its legitimacy?
            // "social contract theory"_ soveriegny of state comes from bunch of people agreeing that that state has sovereignty
            // state -> leviathan
            // state of nature: state where every human being exists before entering into contract w/ government. only bound by natural laws; can do whatever they want
            //   nasty, brutish, and short
            // emerges necessity fo sovereign to mediate; sovereign is leviathan
            // sovereignty of leviathan is people entering into contract w/ state; give up some freedoms in exchange for safety
            // solution to chaos and violence

            // leviathan arises in moments of exception, crisis
            // (pull back some civil or democratic rights in name of order due to unprecedented threat/moment of exception/state of emergency, e.g. 9/11, covid)
            // liberal states can take various forms of dictatorship
            // leviathian represents trend toward totalizing control

            // global planetary sovereign order that allows capitalism to continue; our most likely trajectory
            // we live in global capitalist world order; serve fundamentally the interests of captialism

            // global capitalist ruling class wants to stabilize their wealth and power; climate change threatens their hegemony.

            // - whether value for MCM will continue to shape
            // - planetary soveriegnty or not

            // Climate Leviathan
            // - capitalist planetary sovereignty
            // - open opportutiesy for capital accumulation; green business
            // - planetary sovereign; 
            // - seeds of this are already there; p. 33 _Towrad a Planetary Regime_ by former obama adviser
            // - liberal authoritarian
            // - likely a Keynesian form (33)
            //   - believes capitalist growth can be managed, Green New Deal-like form (33)
            // - not stable; it would be contested all the way through (35)

            // Climate Mao
            // - non-capitalist planetary sovereignty
            // - mass mobilization
            // - rapid revolutionary state led transformation of society to green, sustainable, anti-capitalist (39)
            // - would require China to return to or push forward to Mao-ist China.
            // - even today, non-Maoist Chinese state can accomplish great feats (e.g. olympics air quality cleaned up, 
            //   blue the skies for the games, killed Hummer) (40)
            // - china could continue down its capitalist road; would need neo-Maoist revolution within china's communist party (does exist)
            // - will of the people

            // Behemoth
            // - anti-soverign, capitalist
            // - fascist
            // - Trump, Modi, etc.
            // - climate denialism
            // - don't have any solutions 
            // - (end of 45-46)

            // Climate X
            // - 173-
            // 3 guiding principles
            // 1. equality
            // 2. the inclusion and dignity of all
            // 3. solidarity in composinga a world of many worlds
            // anti-colonialism as a starting point
            // unrealistic (180)
            // ways to potentially get there:
            // 1) Marx
            // 2) indigenous
            // ---
            // given that capitalism is inherently at odds with combating the climate crisis; aka 
            // given that solving the climate crisis is inherently antithetical to the goals of capitalism, a capitalist
            // society can only combat the crisis by enacting some "rules" // planetary sovereign, that go against its own 
            // interests but can be made despite that for the sake of the survival of humanity.

            // climate leviathan is a 'solution' led by a capitalist planetary sovereign.
            // `,
          },
        },
        {
          date: new Date('2022-04-29'),
          endDate: new Date('2022-07-04'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            title: 'Existentialism Is a Humanism',
            marker: '+',
            year: 1946,
            author: 'Jean-Paul Sartre',
          },
        },
        {
          date: new Date('2022-06-06'),
          endDate: new Date('2022-06-09'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            title: 'The Antichrist',
            year: 1895,
            author: 'Friedrich Nietzsche',
          },
        },
        {
          date: new Date('2022-06-29'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'Train platform',
            text: `
Work-in-progress of train platform that goes above the tunnel. Track will run 60337 Express Passenger Train.
Will render something with better lighting later.

Current version has white tiles only on the back wall instead of multi-colored (as shown in the renders). 
I think the clean look is better.

Inspiration:
* Denver airport train platform
* Mini-builds (departure signs, maybe ticket machines, etc.): dtomsen's Odense Banegård on [EB](https://www.eurobricks.com/forum/index.php?%2Fforums%2Ftopic%2F170731-moc-odense-baneg%C3%A5rd-modular-platforms%2F)
* Tiled back wall: Robin Hood Bricks: Subway station MOC [Part 4] on [YT](https://www.youtube.com/watch?v=n_oYSjd48ZI&list=PLugym_Bo2Fi8qbr7D5JZkLibdG6nOUIjn)
  
`,
            frames: [
              '2022-07-06-mocs/2022-07-06-mocs-18',
              '2022-07-06-mocs/2022-07-06-mocs-19',
            ],
          },
        },
        {
          date: new Date('2022-06-28'),
          item: {
            type: itemType.FILM,
            title: 'Dunkirk',
            year: 2017,
            director: 'Christopher Nolan',
          },
        },
        {
          date: new Date('2022-06-25'),
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            inProgress: false,
            inProgressCurrentChapter: 5,
            inProgressTotalChapter: 13,
            title: 'The Origins of Totalitarianism',
            year: 2021,
            author: 'Hannah Arendt',
          },
        },
        {
          date: new Date('2022-06-24'),
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            inProgress: false,
            inProgressCurrentChapter: 3,
            inProgressTotalChapter: 33,
            title: 'Capital: Volume I: A Critique of Political Economy',
            year: 1867,
            author: 'Karl Marx',
            text: `<div style="text-align:right;">
<sub>(Translation by Ben Fowkes; Penguin Classics, 1992)</sub><br/>
<br/>
Along with: <b><i>A Companion to Marx's Capital, Volume I</i></b><br/>
by David Harvey, 2010
</div>`,
          },
        },
        //       {
        //         showCollapseText: true,
        //         date: new Date('2022-06-22'),
        //         item: {
        //           type: itemType.FILM,
        //           title: 'Doctor Strange in the Multiverse of Madness',
        //           year: 2022,
        //           director: 'Sam Raimi',
        //           text: `<div style="text-align:right;">
        // exceptionally terrible 🥵<br/>
        // <sub>budget $200 million | box office $954.7 million </sub>
        // </div>`,
        //         },
        //       },
        {
          date: new Date('2022-06-19'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'City Tram',
            text: `Recoloring the tram from 60097, potentially to run along the road.
          (Inspired by AFOL TV.)`,
            frames: [
              '2022-07-06-mocs/2022-07-06-mocs-20',
              '2022-07-06-mocs/2022-07-06-mocs-21',
            ],
          },
        },
        {
          date: new Date('2022-06-14'),
          endDate: new Date('2022-06-21'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            inProgress: false,
            inProgressCurrentPage: 41,
            inProgressTotalPage: 160,
            title: 'The Subversive Simone Weil: A Life in Five Ideas',
            year: 2021,
            author: 'Robert Zaretsky',
          },
        },
        {
          date: new Date('2022-06-09'),
          endDate: new Date('2022-06-19'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            title: 'The Rebel',
            year: 1951,
            author: 'Albert Camus',
          },
        },
        {
          date: new Date('2022-06-06'),
          endDate: new Date('2022-06-10'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            title: 'Thus Spoke Zarathustra',
            year: 1883,
            author: 'Friedrich Nietzsche',
          },
        },
        {
          date: new Date('2022-06-03'),
          item: {
            type: itemType.FILM,
            title: 'Never Let Me Go',
            year: 2010,
            director: 'Mark Romanek',
          },
        },
        {
          date: new Date('2022-04-28'),
          endDate: new Date('2022-06-02'),
          item: {
            type: itemType.FILM,
            title: 'Under the Banner of Heaven',
            year: 2022,
            director: 'Dustin Lance Black',
            text: `
<div style="text-align:right;"><sub>🖤 (7-episode miniseries)</sub></br>
</div>`,
          },
        },
        // {
        //   date: new Date('2022-06-03'),
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Infinite Jest',
        //     year: 1996,
        //     author: 'David Foster Wallace',
        //   },
        // },
        {
          date: new Date('2022-06-02'),
          showCollapseText: false,
          item: {
            type: itemType.BOOK,
            title: 'A Supposedly Fun Thing I\'ll Never Do Again: Essays and Arguments',
            year: 1998,
            author: 'David Foster Wallace',
            text: `
<div style="text-align:right;">
&#x2022; <i>Derivative Sport in Tornado Alley</i>, 1991<br/>
&#x2022; <i>E Unibus Pluram: Television and U.S. Fiction</i>, 1993<br/>
&#x2022; <i>Getting Away from Already Being Pretty Much Away from It All</i>, 1993
</div>`,
          },
        },
        {
          date: new Date('2022-05-10'),
          showCollapseText: false,
          filterTags: ['computers'],
          item: {
            type: itemType.BOOK,
            title: 'Interactive Data Visualization for the Web: An Introduction to Designing with D3',
            year: 2017,
            author: 'Scott Murray',
            text: `
<div style="text-align:right;">
&#x2022; <b>Ch. 7:</b> Scales<br/>
&#x2022; <b>Ch. 8:</b> Axes
</div>`
          },
        },

        // {
        //   date: new Date('2022-04-30'),
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'The Ethics of Ambiguity',
        //     year: 1947,
        //     author: 'Simone de Beauvoir',
        //   },
        // },
        //       {
        //         date: new Date('2022-04-20'),
        //         showCollapseText: false,
        //         item: {
        //           type: itemType.BOOK,
        //           title: 'Between Past and Future',
        //           year: 1968,
        //           author: 'Hannah Arendt',
        //           text:`
        // <div style="text-align:right;">
        // &#x2022; <i>Truth and Politics</i>, originally published in <i>The New Yorker</i>, 1967
        // </div>`,
        //         },
        // },
        {
          date: new Date('2022-05-03'),
          item: {
            type: itemType.BOOK,
            title: 'Franny',
            year: 1961,
            author: 'J.D. Salinger',
          },
        },
        //       {
        //         showCollapseText: true,
        //         date: new Date('2022-05-03'),
        //         item: {
        //           type: itemType.FILM,
        //           title: 'Everything Everywhere All at Once',
        //           year: 2022,
        //           director: 'Daniel Kwan, Daniel Scheinert',
        //           text: `<div style="text-align:right;">
        //           wut.
        // </div>`,
        //         },
        //       },
        {
          date: new Date('2022-04-30'),
          showCollapseText: false,
          item: {
            type: itemType.BOOK,
            title: 'Consider the Lobster and Other Essays',
            year: 2005,
            author: 'David Foster Wallace',
            text: `
<div style="text-align:right;">
&#x2022; <i>Consider the Lobster</i>, 2004
</div>`
          },
        },
        {
          date: new Date('2022-04-29'),
          item: {
            type: itemType.FILM,
            title: 'Phantom Thread',
            year: 2017,
            director: 'Paul Thomas Anderson',
          },
        },
        // {
        //   date: new Date('2022-04-04'),
        //   showCollapseText: true,
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Escape From Evil',
        //     year: 1985,
        //     author: 'Ernest Becker',
        //     text:`<div style="text-align:right;">(follows <i>Denial of Death</i>, 1973)</div>`,
        //   },
        // },
        {
          date: new Date('2022-03-29'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'Tunnel',
            text: `
Work-in-progress tunnel that goes above the city's T-intersection, and holds a train track and platform above it.
Above the train, there will be another level for small modulars / foot traffic, etc.
This is a hilarious render (mostly to get the supports right and to get a starter parts list); will make another one sometime.
`,
            frames: [
              '2022-07-06-mocs/2022-07-06-mocs-17',
            ],
          },
        },
        {
          date: new Date('2022-03-27'),
          endDate: new Date('2022-03-28'),
          item: {
            type: itemType.FILM,
            title: 'Wheel of Fortune and Fantasy',
            year: 2021,
            director: 'Ryusuke Hamaguchi',
            text: `
<div style="text-align:right;">
  Japanese: 偶然と想像, <i>Chance and Imagination</i>
</div>`,
          },
        },
        {
          date: new Date('2022-03-26'),
          item: {
            type: itemType.FILM,
            title: 'Drive My Car',
            year: 2021,
            director: 'Ryusuke Hamaguchi',
          },
        },
        {
          date: new Date('2022-03-17'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'House interior',
            text: `Render of the 2nd floor kitchen and stairs.
Other rooms not included because the renders were taken at terrible angles.
Will make better renders / take pictures of the real thing!

Learnings:
* The stairs were probably the hardest part; used very basic building techniques. (Maybe could've been simpler
if built SNOT into the walls.) Mostly it was very difficult to see all of the structural layers in 
Stud.io (and sometimes there would be gaps with a missing 1x2 or 1x1, for example).
* At the time, I didn't know that the 1x5 white plate (which exists in Stud.io) is a rare part. So had to go back
  and switch that out. Also there are many parts in Stud.io that look the same but one is retired or more expensive.
    * <mark>Trick for find-and-replacing parts:</mark> Export the \`.io\` file as a \`.ldr\` (which is text-based). Then
     find-and-replace the part number.
* While modeling, built the exterior first, then the interior, but when making instructions, should
leave exterior walls off while building interiors so you can reach! I also like the ideas of making the outer
walls removable for a much better view and for better photos.

Furniture builds based on / inspired by:
* Many furniture builds by [brickdesigned](https://www.youtube.com/c/brickdesigned/): refrigerator, shower, toilet  
* Kitchen build by [AFOL TV](https://www.youtube.com/watch?v=mTl-PHp9m2E)
* Bookcases by [Tiago Catarino](https://www.youtube.com/watch?v=GclPbxx5DUs)
* Couch by [TD bricks](https://www.youtube.com/watch?v=mv5dvx3FGEM)
`,
            frames: [
              '2022-07-06-mocs/2022-07-06-mocs-16',
            ],
          },
        },

        {
          date: new Date('2022-03-07'),
          // endDate: new Date('2022-06-07'),
          filterTags: ['science'],
          item: {
            type: itemType.BOOK,
            title: 'Molecular Biology of the Cell, 5th Edition',
            year: 2007,
            author: 'Bruce Alberts et al.',
            inProgress: true,
            inProgressCurrentChapter: 5,
            inProgressTotalChapter: 25,
            text: `
<div style="text-align:right;">
Part I: Introduction to the Cell<br/>
<hr style="width:50%;margin-right:0;"> 
  &#x2022; <b>Ch 1:</b> Cells and Genomes<br/>
  &#x2022; <b>Ch 2:</b> Cell Chemistry and Biosynthesis<br/>
  &#x2022; <b>Ch 3:</b> Proteins<br/><br/>
Part II: Basic Genetic Mechanisms<br/>
<hr style="width:50%;margin-right:0;"> 
  &#x2022; <b>Ch 4:</b> DNA, Chromosomes, and Genomes<br/>
  <span style="color:#EE352E;">&#10140</span> &#x2022; <b>Ch 5:</b> DNA Replication, Repair, and Recombination<br/>
  &#x2022; <b>Ch 6:</b> How Cells Read the Genome: From DNA to Protein<br/>
  ...
</div>`,
          },
        },
        {
          date: new Date('2021-12-26'),
          endDate: new Date('2022-02-27'),
          filterTags: ['science'],
          item: {
            type: itemType.BOOK,
            title: 'Principles of Modern Chemistry, 3rd Edition',
            year: 1998,
            author: 'David W. Oxtoby et al.',
            text: `
<div style="text-align:right;">
  &#x2022; <b>Ch 1:</b> Stoichiometry and the Atomic Theory of Matter<br/>
  &#x2022; <b>Ch 2:</b> Chemical Periodicity and Inorganic Reactions<br/>
  &#x2022; <b>Ch 5:</b> Chemical Equilibrium<br/>
  &#x2022; <b>Ch 11:</b> Chemical Kinetics<br/>
  &#x2022; <b>Ch 14:</b> Chemical Bonding<br/>
  <br/>
  &#x2022; (7e) <b>Ch 20.6:</b> Atomospheric Chemistry — (3e) Ch. 15.4<br/>
  &#x2022; (7e) <b>Ch 20.7:</b> Photosynthesis — (3e) Ch. 15.3<br/>
  &#x2022; (7e) <b>Ch 20.5:</b> Electronic Spectroscopy and Excited State Relaxation Processes — (3e) Ch. 15.2-15.2<br/>
  <br/>
  &#x2022; <b>Ch 23:</b> Organic Chemistry and Polymers<br/>
</div>`,
          },
        },
        {
          date: new Date('2022-03-04'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'House exterior',
            text: `
First MOC Learnings and trade-offs:

* Chose to use the 1x4x5 window frames/glass ([2493](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2493)/[2494](https://www.bricklink.com/v2/catalog/catalogitem.page?P=2494))
  for the 3rd floor windows for realistic scale — a part retired in 2005. I tried to make it work with the 1x4x6 but
  it would've made the 3rd floor too tall.


  <details>
  <summary>The saga of obtaining retired 1x4x5 windows</summary>

    * These parts are only available used (and are not very available). Had a bunch of trouble
    ordering them (ended up placing orders with two different stores that didn't actually send the part.)

      Once we did get them, turned out the glass for all the pieces were
    _super_ scratched. Purchased Novus #2 Fine Scratch Remover as well as Novus #3 Heavy Scratch Remover on 
    Robin Hood Bricks' recommendation, but the examples he showed were for just a single scratch, as opposed to these
    windows, which were really in terrible shape; the scratch removers didn't really work.

      I've since discovered that there was a bulk bricks set released in 2000 with 10 each of windows and glass 
    ([3448](https://www.bricklink.com/v2/catalog/catalogitem.page?S=3448-1)), so there are a handful of these
    available new. They are quite expensive compared to retail (of course 😐) but I think maybe this 
    is...the life of an AFOL. There is only one set available domestically; the other ones are about &#8364;30 
    just for shipping
  </details>

  In hindsight, I would try harder during the design phase to avoid retired parts. In the end,
  if this was still the best solution, it's not the _worst_ price. Also, would <mark>be very wary of used glass.</mark>

* Ended up making the garage door smaller than in real-life so that we can use the 
official [4218](https://www.bricklink.com/v2/catalog/catalogitem.page?P=4218) 
"Garage Roller Door". Mainly because there was not enough room for me to make a custom mechanism for opening/closing
a custom door. (Originally tried something like [this](https://www.youtube.com/watch?v=GlGDzwQlSqE).)
  * This corresponded with shortening the width of the dark-bluish-gray section above the garage from 
    4 windows across to 3 windows across, which does not match the real house, 
    but does have similar proportions given that
    the LEGO windows are much wider than those in real life.
* Process for making the base for each floor:
  * Each base is 2 plates high, the perimeter of which will be visible (for which color matters).
  * First, make the footprint/area of the level with (presumably large) plates (this is the top layer)
  * Then, under this layer of plates, a layer of 1x plates is required around the entire perimeter that can sit on the wall of the
    floor beneath it. And the rest of this layer should be filled in with stability/connecting plates that 
    binds the top layer together.
* Digital (Stud.io) workflow is not my favorite (hard to see / gives me a headache); grohl says he builds 
  digitally and in real life at the same time; of course, this is not realistic without infinite pieces. 
  For now that is what I've come up with: 
    * While designing the model, use the submodels in a way that makes it easy
  to hide sections for better visibility while designing. 
    * Once the _entire_ model is done, [copy the file to save the original,]
  release all of the submodels. _Then_ while making instructions, create submodels that reflect easy 
  brick building (which is a totally different use-case for submodels than during designing/modeling.)
* On the brick-building process (after designing digitally):
  * Making instructions is very time consuming; once you get to building, if there is a small mistake
in the instructions (like a missing/floating piece), the rest of the build process is really hard to deduce.
You then have to go back to fix the instructions, which is pretty annoying (e.g. locating the missing or floating pieces
  and figuring which step they need to go into.)
  * Building directly from the computer model without instructions at all is _really_ annoying 
    and headache-inducing, but it seems like people do that. It's impossible to see which pieces are being
    used without clicking each of them, while continuously hiding those that block the view.
  * Conclusion: unless I am able to do digital and physical design at the same time 
    (aka have most of the pieces already),
    if I am building from a digital design, it's worth it to take the time to make the instructions very good.`,
            frames: [
              '2022-07-06-mocs/2022-07-06-mocs-09',
              '2022-07-06-mocs/2022-07-06-mocs-08',
              '2022-07-06-mocs/2022-07-06-mocs-07',
              '2022-07-06-mocs/2022-07-06-mocs-10',
            ],
          },
        },
        {
          date: new Date('2022-02-05'),
          item: {
            type: itemType.FILM,
            title: 'Bergman Island',
            year: 2021,
            director: 'Mia Hansen-Løve',
          },
        },
        {
          date: new Date('2022-01-29'),
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            title: 'Climate Leviathan: A Political Theory of Our Planetary Future',
            year: 2018,
            author: 'Joel Wainwright, Geoff Mann',
          },
        },
        {
          date: new Date('2022-01-20'),
          item: {
            type: itemType.FILM,
            title: "The Power of the Dog",
            year: 2021,
            director: 'Jane Campion',
          },
        },

        {
          date: new Date('2022-01-13'),
          endDate: new Date('2022-01-17'),
          item: {
            type: itemType.FILM,
            title: 'Station Eleven',
            year: 2021,
            director: 'Patrick Somerville',
            frames: [],
            text: `
<div style="text-align:right;">
  <sub>(10-episode HBO miniseries)</sub><br />
</div>`,
          },
        },


        // {
        //   date: new Date('2022-02-14'),
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Ending Fossil Fuels: Why Net Zero is Not Enough',
        //     year: 2021,
        //     author: 'Holly Jean Buck',
        //   },
        // },
        // {
        //   date: new Date('2022-02-13'),
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'The Death and Life of Great American Cities',
        //     year: 1989,
        //     author: 'Jane Jacobs',
        //   },
        // },
        // {
        //   date: new Date('2022-01-01'),
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Agency',
        //     year: 2020,
        //     author: 'William Gibson',
        //   },
        // },
        {
          date: new Date('2022-01-10'),
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO brick geometry',
            text: `
* [Brick Geometry: Half-plate offsets, SNOT, Angles, & more](https://www.youtube.com/watch?v=WYFZqhYvR58) - 2021 BrickCon presentation by [Bill Ward](https://www.brickpile.com/articles/brick-geometry-presentations/)
* [LEGOmetry – the geometry of LEGO bricks](https://www.holgermatthes.de/bricks/en/geometry.php)

* 5 plates = 2 studs; if multipled by 3, 15 plates = 6 studs &#10142; 5 bricks = 6 studs
`,

          },
        },
      ],
    },
    {
      year: 2021,
      goals: {
        books: 15,
      },
      events: [
        {
          date: new Date('2021-12-21'),
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'Resources for LEGO MILS roads',
            text: `
* [Brick-Built Roads](http://vlc.ca/2021/05/mils-part-3-brickcan-presentation/) - May 2021 BrickCan presentation by Neil Snowball
* [MILS standard](https://www.abellon.net/MILS/).
* [L-Gauge Modular standards](http://l-gauge.org/wiki/index.php?title=Modular_Standards)
* [Brick train depot instructions](https://bricktraindepot.com/resources/free-layout/)
`,
          },
        },
        {
          date: new Date('2021-12-02'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'Starting a LEGO city',
            text: `
* Work-in-progress of house modular MOC; based on real-life.
* MILS road layout w/ extended-width road plates (extension shown in 2nd image); build from [AFOL TV](https://www.youtube.com/watch?v=KiROobJq9ao)
* Border around the city inspired by [LionBricks](https://www.youtube.com/watch?v=uZmVmLYfhMM)
          `,
            frames: [
              '2022-07-06-mocs/2022-07-06-mocs-01',
              '2022-07-06-mocs/2022-07-06-mocs-02',
            ],
          },
        },
        {
          date: new Date('2021-09-16'),
          endDate: new Date('2021-11-01'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42100 Technic Liebherr R 9800',
            text: `
<div style="font-size: 12px;">
  (4108 pieces · Released 2021) <p/>
  <b>Goals:</b> Gear train(s) deep dive. Make diagrams.
</div>

## Lower hub / Bag 1

The following motors/gear trains are connected to the lower 4-port PoweredUp hub.

* **Left track**: \`17.857:1\` gear ratio <span style="font-size: 12px;">(see render below)</span>
  * XL Motor connected to lower hub \`Port A\`. (red)
* **Right track**: \`17.857:1\` gear ratio <span style="font-size: 12px;">(see render below)</span>
  * XL Motor connected to lower hub \`Port B\`. (yellow)
* **Turntable**: \`9:1\` gear ratio  <span style="font-size: 12px;">(see render below)</span>
  * L Motor connected to lower hub \`Port D\`. (green)
  
## Upper hub / Bag 2

* **Lowest linear actuators**: \`5:3\` gear ratio  <span style="font-size: 12px;">(see render below)</span>
  * XL Motor connected to upper hub \`Port A\` (red)
  * These linear actuators articulate the long part of the arm (closest to the body).

## Upper hub / Bag 3

* In Bag 3, we hook up 3 more L motors to the upper hub. 
* 2 L motors (which live in the long part of the arm) power the middle pair of linear actuators that articulate the short part of the arm.
* 1 L motor  (which also lives in the long part of the arm) powers the top-most pair of linear actuators that articulate the shovel.

  No renders for this section b/c I had a hard time CAD-ing these in a way that makes their mechanisms apparent, in part b/c the gear trains
live on top of each other, and also because the trains are connected by u-joints in order to work
across the articulated arm, and bending u-joints seems impossible in Bricklink Studio. (I think Philo has mentioned
using LDraw to bend parts and then opening in Studio to render.) These arm also needs to be articulated at
arbitrary angles, which I don't know how to do without taking one million years.

---

Things learned:
* how to use bricklink [studio](https://www.bricklink.com/v3/studio/download.page)
* how to import open source parts from [LDraw](https://www.ldraw.org/)
  * specifically, the linear clutch ([46834.dat](https://www.ldraw.org/cgi-bin/ptdetail.cgi?s=46834), [46845.dat](https://www.ldraw.org/cgi-bin/ptdetail.cgi?s=46835)), and the PoweredUp XL motor ([22172.dat](https://www.ldraw.org/parts/official-part-lookup.html?folder=parts&partid=22172)).
   why are not in Studio default library i dunno 🤷🏻‍♂️. cannot be mad b/c legos are toys?
  * it took me forevs to figure out where to put the parts in the Studio application directory, in particular the sub \`s/\` parts and sub-sub \`p/\` parts.
  I only discovered a couple days ago that [Philo](https://www.philohome.com/studio/packs.htm) (who makes _all_ the parts and official models on LDraw 🤯) has [parts packs that are zipped](https://www.philohome.com/studio/packs.htm). (before, I was manually doing a tree traverse on the LDraw website 🤧).
  Haven't gotten the connectivity to work though; the motors cause a collision and don't sit exactly on the axle.
  
`,
            frames: [
              '2021-09-16-liebherr-drivetrain/42100_gear_train-02',
              '2021-09-16-liebherr-drivetrain/42100_gear_train-03',
              '2021-09-16-liebherr-drivetrain/42100_gear_train-01',
            ],
          },
        },
        {
          date: new Date('2021-10-20'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 21056 Architecture Taj Mahal',
            text: `<div style="font-size: 12px;">(2022 pieces · Released 2021)</div>

→ brothers brick [review](https://www.brothers-brick.com/2021/05/03/lego-architecture-21056-taj-mahal-third-times-a-charm-review/).

first architecture set. very repetitive build, but you know, the Taj Mahal is symmetrical.
looks great. good tomb. i think i like brick building.
`,
            frames: [
              '2021-10-20-taj/taj-mahal-01',
              '2021-10-20-taj/taj-mahal-03',
            ],
          },
        },
        {
          date: new Date('2021-10-15'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42120 Rescue Hovercraft',
            text: `<div style="font-size: 12px;">(457 pieces · Released 2021)</div>

Looks cooler than expected. HOG steering works well going backwards, but not enough downward pressure when going forwards
unless you hold the back down with your other hand. (The wheels are really skinny and don't have much traction since 
they're hidden to make it look like a hovercraft.)

The sticker sheet was misaligned during printing so there was a white edge on the orange stickers.
LEGO customer support got it sorted efficiently as always and sent me a new one 😍.

### Modifications attempt:

Attempted to motorize this set with the 2-port PoweredUp hub<sup>1</sup>.
It sort of worked? Was able to drive the wheels. I didn't try continue with actually making it structural
because I wasn't sure there would be enough space to hold the hub and the motor, and almost certainly not
another motor for steering as well. Was a good beginner experiment.

At this point I had disassembled the whole model, so went ahead and took it apart and built the B-model plane.
Was cool but the wingspan is very large, so not as cute as the hovercraft. Planning to rebuild the hovercraft
and replace the misprinted stickers with the new ones (someday soon — so many legos so little time).

---

<div class="sidebar">
<sup>1</sup> bb0892c01/28738 Hub/Battery Box. Comes with the 60197 Passenger Train, 60198 Cargo Train, 
71044 Disney Train and Station, 76112 App-Controlled Batmobile, and my favorite [gateway] set, the 21323 Grand Piano.
</div>

          `,
            frames: [
              '2021-10-15-rescue-hovercraft/rescue-hovercraft-02',
              '2021-10-15-rescue-hovercraft/rescue-hovercraft-03',
              '2021-10-15-rescue-hovercraft/rescue-hovercraft-04',
              '2021-10-15-rescue-hovercraft/rescue-hovercraft-05',
              '2021-10-15-rescue-hovercraft/rescue-hovercraft-08',
              '2021-10-15-rescue-hovercraft/rescue-hovercraft-09',
            ],
          },
        },
        {
          date: new Date('2021-10-15'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42102 Mini CLAAS XERION',
            text: `<div style="font-size: 12px;">(130 pieces · Released 2020)</div>

These small [construction] sets are fantastic. Simple mechanisms that work perfectly.
This one's got nice rear wheel steering and the rotary cutter (two 36T double bevel gears) is connected by a gear train to the front wheels.
          `,
            frames: [
              '2021-10-15-miniclaasxerion/miniclaasxerion-02',
              '2021-10-15-miniclaasxerion/miniclaasxerion-01',
            ],
          },
        },
        {
          date: new Date('2021-10-06'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42122 Jeep Wrangler',
            text: `<div style="font-size: 12px;">(665 pieces · Released 2021)</div>

Good review by [brothers bricks](https://www.brothers-brick.com/2020/12/04/lego-technic-42122-jeep-wrangler-the-ultimate-4x4-to-fit-your-wallet-review/).

Notable mechanisms:
* Solid front and rear axles

Missing front suspension makes the car feel clunky and fragile / not fun to play with.
Obviously wanting compared to 42123.

## Modifications report:
The Rubicon seems like a good candidate for beginner mods.

Notably it's missing:
  * 4-wheel drive
    * I created a chassis base with a front, rear, and central differential 
      but had trouble getting everything to fit within the existing wheelbase.
    * For steering, I added a steering rack, but it was difficult to fit with the front differential.
      Tried a few times to improve rigidity. Ended up with something _okay_, but again, it didn't fit 
      in the Jeep's wheelbase.
  * front suspension
  * could also try to motorize it with the 2-port PoweredUp hub
    * Removed the 2-port hub from the 21323 Grand Piano. Didn't get much further / focusing on 4x4 first.

Things learned:
  * Modding is hard. This will take more time than I thought.
          `,
            frames: [
            ],
          },
        },
        {
          date: new Date('2021-10-04'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 76900 Speed Champions Koenigsegg Jesko',
            text: `<div style="font-size: 12px;">(280 pieces · Released 2021)</div><br/>
          `,
            frames: [
              '2021-10-04-speed-champs-koenigsegg/speedchamps-03',
              '2021-10-04-speed-champs-koenigsegg/speedchamps-04',
              '2021-10-04-speed-champs-koenigsegg/speedchamps-05',
              '2021-10-04-speed-champs-koenigsegg/speedchamps-06',
              '2021-10-04-speed-champs-koenigsegg/speedchamps-07',
              '2021-10-04-speed-champs-koenigsegg/speedchamps-08',
            ],
          },
        },
        {
          date: new Date('2021-10-03'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42116 Skid Steer Loader',
            text: `
<div style="font-size: 12px;">
  (140 pieces · Released 2021) <p/>
  <b>Goals:</b> Document a smaller set's mechanics/functions. Simple mechanisms.
</div>

Best set. Love worm gear. Made some diagrams.
          `,
            frames: [
              '2021-10-03-skid-steer-loader/ss-02',
              '2021-10-03-skid-steer-loader/ss-01',
              '2021-10-03-skid-steer-loader/ss-functions-diagram-01',
              '2021-10-03-skid-steer-loader/ss-functions-diagram-02',
            ],
          },
        },
        {
          date: new Date('2021-10-03'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42117 Race Plane',
            text: `<div style="font-size: 12px;">(145 pieces · Released 2021)</div>

Most notable thing about Race Plane is the official designer (Milan Reindl aka [grohl](https://www.eurobricks.com/forum/index.php?/profile/11368-grohl/)) has created 14 alternate models to date, and will
very likely release more. ([He says]((https://www.eurobricks.com/forum/index.php?/forums/topic/44159-grohls-creations/&page=24&tab=comments#comment-3463334)) he wants to beat his personal record of 31.)

          `,
            frames: [
              '2021-10-03-race-plane/race-plane-03',
              '2021-10-03-race-plane/race-plane-04',
            ],
          },
        },
        {
          date: new Date('2021-09-30'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42123 McLaren Senna GTR',
            text: `<div style="font-size: 12px;">(830 pieces · Released 2021)</div>

Road LEGOs continued. Built at sunken-place™ train station in St. Louis during Strangeloop. Really odd covid travels.
Sorry, digression.

This set has excellent functions (and piece count) for the price ($50 USD), especially compared to the 42122 Rubicon (released at the same time at the same price):
* V8 mid-engine
* butterfly doors

Not really a fan of the look but I think that's just because supercars look bad in Technic. The back looks pretty cool. 
I left off the stickers b/c I think they're ugly but I think they're a reasonable effort to make it look like the real car.
Excellent value.
          `,
            frames: [
              '2021-09-30-mclaren-senna/senna-01',
              '2021-09-30-mclaren-senna/senna-02',
              '2021-09-30-mclaren-senna/senna-03',
              '2021-09-30-mclaren-senna/senna-04',
            ],
          },
        },
        {
          date: new Date('2021-09-26'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42119 Monster Jam Max-D',
            text: `<div style="font-size: 12px;">(230 pieces · Released 2021)</div>

More road LEGOs. First pullback set. The pullback motor is cool but I mostly thought this set was ridiculous looking 🤷🏻‍♂️ (and slow and bad at jumps).

Ended up disassembling and turning this into [42119 Rhino Buggy](https://rebrickable.com/mocs/MOC-64897/Keep%20On%20Bricking/42119-rhino-buggy/#details) 
by [Keep On Bricking](https://rebrickable.com/users/Keep%20On%20Bricking/mocs/). <span style="font-size: 12px;">(Last 2 photos)</span>
  * It's noticeably speedier and looks great.
  * Note: Keep On Bricking has [11 alternates](https://rebrickable.com/mocs/MOC-71600/Keep%20On%20Bricking/42119-11in1/#details) for this set
    and at least 7 for [42118 Monster Jam Grave Digger](https://rebrickable.com/sets/42118-1/monster-jam-grave-digger/#alt_builds) which I
    was (am?) tempted to get just to make an alt.
          `,
            frames: [
              '2021-09-26-monster-jam/monster-01',
              '2021-09-26-monster-jam/monster-02',
              '2021-09-26-monster-jam/monster-03',
              '2021-09-26-monster-jam/monster-04',
              '2021-09-26-monster-jam/monster-alt-01',
              '2021-09-26-monster-jam/monster-alt-02',
            ],
          },
        },
        {
          date: new Date('2021-09-21'),
          endDate: new Date('2021-09-23'),
          showCollapseText: true,
          textCollapsedOnInit: false,
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Canyonlands National Park',
            text: `
<img src="https://blog.melindalu.com/media/2021-10-04-needlesm2-0921.jpg" width="65%">
`,
          },
        },
        {
          date: new Date('2021-09-18'),
          endDate: new Date('2021-09-26'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 76895 Speed Champions Ferrari F8 Tributo',
            text: `<div style="font-size: 12px;">(275 pieces · Released 2020)</div>

Road LEGOs. Procured from Walmart in Las Vegas while gathering camp/road supplies. Assembled slowly in UT, AZ, and NV.
          `,
            frames: [
              '2021-09-18-speed-champs-ferrari/speedchamps-02',
              '2021-09-18-speed-champs-ferrari/speedchamps-07',
              '2021-09-18-speed-champs-ferrari/speedchamps-08',
              '2021-09-18-speed-champs-ferrari/speedchamps-09',
            ],
          },
        },
        {
          date: new Date('2021-09-10'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42124 Off-Road Buggy',
            text: `<div style="font-size: 12px;">(374 pieces · Released 2021)</div>

Notable features:
* Simple build of a speedy car
  * Some attempts to make it faster on [eurobricks](https://www.eurobricks.com/forum/index.php?/forums/topic/182007-42124-off-road-buggy-mods-fixes/)
* Front and rear suspension
* Easily controlled w/ BrickController2
          `,
            frames: [
              '2021-09-10-buggy/buggy-01',
              '2021-09-10-buggy/buggy-02',
              '2021-09-10-buggy/buggy-03',
              '2021-09-10-buggy/buggy-04',
            ],
          },
        },
        {
          date: new Date('2021-09-01'),
          endDate: new Date('2021-09-09'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42114 6x6 Volvo Articulated Hauler',
            text: `<div style="font-size: 12px;">(2193 pieces · Released 2020)</div>

Notable mechanisms:
* 4-speed gearbox:
  * 1st, 2nd, and 3rd gear are used for driving
  * 4th gear is used to actuate the bed. In 4th gear, a linear clutch piece is used as a brake so that we can't push the wheels
      while moving the bed.
* 1 motor for driving (and bed actuation). 1 motor for shifting.
* Since we're shiftig with the Control+ motor, the 90° turns are done via software. In the Control+ app, there is also an "automatic" gear box
  that will shift up by it self. Unfortunately, it does not down-shift.
    * RacingBrick wrote a custom automatic controller that down-shifts when there's resistance in the motor: [YT: Real automatic transmission for LEGO Technic 42114 6x6 Volvo + custom controls with BC2](https://www.youtube.com/watch?v=_B0Vumvy9Rs)
      * This video also shows a BrickController2 profile for controlling the Volvo, and therefore any generic 4 speed gearbox. (This is what we used to control the RC gearbox from Aug 13).
      * Unfortunately, RacingBrick didn't release the PoweredUp code for the automatic gearbox. I'm interested in how to detect motor resistance. Will have to investigate the code blocks. 
        Supposedly, [TLG is working on documentation](https://ramblingbrick.com/2021/05/28/the-road-map-for-legos-powered-up-system-unfolds/). ← This post also mentions that a PoweredUp VM
        is on the roadmap (which would allow for storing programs on the hub aka eliminate the need to connect via the smart phone.)

Useful resources:
* [Understanding LEGO 4 speed sequential gearboxes](https://www.eurobricks.com/forum/index.php?/forums/topic/174240-understanding-lego-4-speed-sequential-gearboxes/)
          `,
            frames: [
              '2021-09-01-volvo/volvo-01',
              '2021-09-01-volvo/volvo-02',
              '2021-09-01-volvo/volvo-03',
              '2021-09-01-volvo/volvo-04',
            ],
          },
        },
        {
          date: new Date('2021-09-07'),
          showCollapseFrames: true,
          framesCollapsedOnInit: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 30343 Speed Champions McLaren Elva Polybag',
            text: `<div style="font-size: 12px;">(86 pieces · Released 2021)</div>

We discovered the existence of polybags while in the checkout aisle of Target.
They're $4.99 and come in a plastic (poly-) bag.
          `,
            frames: [
              '2021-09-07-mclaren-polybag/mclaren-elva-02',
              '2021-09-07-mclaren-polybag/mclaren-elva-04',
            ],
          },
        },
        {
          date: new Date('2021-09-08'),
          filterTags: ['lego'],
          item: {
            type: itemType.BOOK,
            title: 'The Unofficial LEGO Technic Builder\'s Guide, 2nd Edition',
            year: 2016,
            author: 'Pawel Sariel Kmiec',
          },
        },
        {
          date: new Date('2021-08-30'),
          filterTags: ['climbing'],
          item: {
            type: itemType.POST,
            title: '[climbing wall] [the climbing surface](https://blog.melindalu.com/posts/2021-08-30-climbing-cube-part2/)',
            text: `<div style="font-size: 12px;">
^ blog post (part 2) by M on our climbing cube
</div>
<br />
<img src="https://blog.melindalu.com/media/2021-08-26-finishedwall-only-b0804.jpg" width="60%">
<br />`,
            frames: [],
          },
        },
        {
          date: new Date('2021-08-26'),
          endDate: new Date('2021-08-30'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42110 Mod: [Pimp up my Land Rover v1.3](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover)',
            text: `
_(On building the Land Rover modification, [MOC-31299 — Pimp up my Land Rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover) 
 by [jb70](https://www.eurobricks.com/forum/index.php?/profile/142957-jb70/). Continued from Aug 12 post, where we discussed how to obtain the additional parts required for this build.)_

In this MOC, the drivetrain has been rebuilt to fix the following problems in the original 42110:
  * gearbox and hi/lo ranges were reversed (shifting up lead to higher revolutions of the engine (while being driven by the wheels))
  * high torque in gear train / stick-slip caused by universal joints
   
Bonus features in v1.3:
  * ackermann steering
  * "easy body-removal" by Pattspatt

→ Extra details on mods in [post by jb70](https://www.eurobricks.com/forum/index.php?/forums/topic/173690-42110-land-rover-defender-mods-and-improvements/&page=8&tab=comments#comment-3200145) on eurobricks

→ featuring organizers from Daiso

→ a <a href="${process.env.PUBLIC_URL}/img/2021-08-24-pimp-my-land-rover/land-rover-stove.gif" target="_blank">gif</a> of land rover going over camp stove
`,
            frames: [
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-01',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-03',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-09',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-10',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-11',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-16',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-14',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-15',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-17',
              '2021-08-24-pimp-my-land-rover/pimp-my-land-rover-25',
            ],
          },
        },
        {
          date: new Date('2021-08-26'),
          filterTags: ['climbing'],
          item: {
            type: itemType.POST,
            title: '[climbing wall] [design, foundation, framing](https://blog.melindalu.com/posts/2021-08-26-climbing-cube-part1/)',
            text: `<div style="font-size: 12px;">
^ blog post (part 1) by M on our climbing cube 🎉
</div>
<br />
<img src="https://blog.melindalu.com/media/2021-08-30-framing-b0326.jpg" width="60%">
<br />`,
            frames: [],
          },
        },
        {
          date: new Date('2021-07-11'),
          endDate: new Date('2021-08-15'),
          item: {
            type: itemType.FILM,
            title: "The White Lotus",
            year: 2021,
            director: 'Mike White',
            marker: '+',
            text: `
<div style="text-align:right;">
  <sub>(6-episode HBO Limited Series)</sub><br />
</div>
          `,
            // stills: jennifer coolidge after rejected;  brittany o'grady when she's on the boat knowing
            // that boy is going to get caught, murray bartlett when answering the phones
            // round table at dinner when steve zahn is talking about how white men have nothing now
          },
        },
        // {
        //   date: new Date('2021-08-19'),
        //   endDate: new Date('2021-08-22'),
        //   showCollapseFrames: true,
        //   framesCollapsedOnInit: false,
        //     filterTags: ['outside'],
        //   item: {
        //     type: itemType.POST,
        //     title: 'Russian Gulch State Park',
        //     text: ``,
        //     frames: [
        //       '2021-08-19-russian-gulch/russian-gulch-01',
        //       '2021-08-19-russian-gulch/russian-gulch-02',
        //       '2021-08-19-russian-gulch/russian-gulch-07',
        //       '2021-08-19-russian-gulch/russian-gulch-08',
        //       '2021-08-19-russian-gulch/russian-gulch-10',
        //       '2021-08-19-russian-gulch/russian-gulch-21',
        //     ],
        //   },
        // },
        {
          date: new Date('2021-08-14'),
          endDate: new Date('2021-08-22'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO [42110 Forklift](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/) by [Pattspatt](https://www.eurobricks.com/forum/index.php?/profile/169165-pattspatt/)',
            text: `
_(On building the 42110 Land Rover alternate build, [MOC-55079: Forklift 42110 B-Model](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/)
by [Pattspatt](https://www.eurobricks.com/forum/index.php?/profile/169165-pattspatt/))_

While waiting for parts to arrive for [MOC-31299 Pimp my Land Rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover),
decided to build the [42110 Land Rover](https://www.lego.com/en-us/product/land-rover-defender-42110) alternate build, [MOC-55079 Forklift 42110 B-Model](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/) 
because forklifts are cute and the steering on this design is cool. 
* moc on [rebrickable](https://rebrickable.com/mocs/MOC-55079/Pattspatt/forklift-42110-b-model/)
* [post by Pattspatt](https://www.eurobricks.com/forum/index.php?/forums/topic/180286-first-moc-42110-b-model-forklift) on eurobricks
`,
            frames: [
              '2021-08-14-lego-forklift-moc/lego-forklift-moc-11',
              '2021-08-14-lego-forklift-moc/lego-forklift-moc-21',
              '2021-08-14-lego-forklift-moc/lego-forklift-moc-20',
              '2021-08-14-lego-forklift-moc/lego-forklift-moc-23',
              '2021-08-14-lego-forklift-moc/lego-forklift-moc-25',
              '2021-08-14-lego-forklift-moc/lego-forklift-moc-27',
            ],
          },
        },
        {
          date: new Date('2021-08-13'),
          showCollapseFrames: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO RC Motorized Gearbox',
            text: `
<div style="font-size: 12px;">
<b>Goals:</b> Learn more about gearboxes and gear ratios, how to use the Powered Up RC components, 
how to control the Powered Up components via PS4 controller.
</div>

Built the following motorized 4-speed gearbox by Sariel:
* [4/16-speed RC combinable transmission for Control+](http://sariel.pl/2020/04/4-16-speed-rc-combinable-transmission-for-control/), Sariel, 2020 — [PDF](http://sariel.pl/down/4speed-control+-combinable-transmission.pdf) instructions

RC components used:
* [Powered Up Technic L motor](https://www.bricklink.com/v2/catalog/catalogitem.page?P=bb0959c01) for shifting
* [Powered Up Technic XL motor](https://www.bricklink.com/v2/catalog/catalogitem.page?P=bb0960c01) for driving
* [Powered Up Technic Hub](https://www.bricklink.com/v2/catalog/catalogitem.page?P=bb0961c01) as the Bluetooth controller / battery box

    (Powered Up components are also referred to as Control+ components)

The instructions show how to set up custom controls in the [Lego Powered Up iOS/Android app](https://apps.apple.com/us/app/lego-powered-up/id1367846574) 
to control the shifting and driving motors.  Custom controllers in the Powered Up app are configured using a drag-and-drop API called [code blocks](https://racingbrick.com/powered-up-code-block-guide/),
which are not officially documented (wut 🤷🏻‍♂️), but have been documented by RacingBrick. 
I maybe want to check out the code blocks one day (strong maybe), but mostly, I really don't want 
to have to use a smart phone as an RC controller (especially once it's an RC car and not just a gearbox).

Instead, I've opted to set up a PS4 controller to control the hub via the 3rd-party [BrickController2](https://apps.apple.com/us/app/brickcontroller2/id1441923224) iOS app
by following RacingBrick's custom control set up for the [Lego Technic 42114 6x6 Volvo Articulated Hauler](https://brickset.com/article/52649/review-42114-6x6-volvo-articulated-hauler)
which conveniently also has a motorized gearbox (that shifts via 90 degree rotations):
* [LEGO Technic 42114 6×6 Volvo Articulated Hauler review, automatic transmission upgrade & custom controls](https://racingbrick.com/2020/07/lego-technic-42114-6x6-volvo-articulated-hauler-review-automatic-transmission-upgrade-custom-controls/), RacingBrick, 2020

  _Note on gamepad compatiblity_ — in order to use a gamepad, the gamepad has to not only speak Bluetooth, but (because Apple is 
incredibly annoying) must be MFi (made for iOS), or be an official PS4+ or Xbox One+ controller 
(which work because they have been made in partnership w/ Apple 😒).

---
LEGO Powered Up:
* [LEGO Powered Up summary](https://racingbrick.com/lego-powered-up-summary/), RacingBrick

More on motors:
* [Lego Technic motors comparison](https://www.philohome.com/motors/motorcomp.htm), Philo

`,
            frames: [
              '2021-08-13-lego-rc/lego-sariel-rc-gearbox-01',
              '2021-08-13-lego-rc/lego-sariel-rc-gearbox-02',
              '2021-08-13-lego-rc/lego-sariel-rc-gearbox-07',
              '2021-08-13-lego-rc/gearbox_homework-01',
            ],
          },
        },
        {
          date: new Date('2021-08-12'),
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'Preparing to build [MOC-31299](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover): Drivetrain improvements for [42110 Land Rover Defender](https://brickset.com/article/45846)',
            text: `
_(On building the Land Rover modification, [MOC-31299 — Pimp up my Land Rover](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover) 
by [jb70](https://bricksafe.com/pages/jb70))_

<div style="font-size: 12px;">
<b>Goals:</b> "Fix" the Land Rover drive train. Learn how to get parts for MOCs using Rebrickable + BrickLink. Build an MOC.
</div>

The LEGO saga continues. This is my first time building an MOC ("my own creation"); 
[jb70](https://bricksafe.com/pages/jb70)'s highly recommended
redesign of the Land Rover drivetrain so that it doesn't get stuck in low gears plus some cool features
like [Ackermann steering](https://www.eurobricks.com/forum/index.php?/forums/topic/173690-42110-land-rover-defender-mods-and-improvements/&page=7&tab=comments#comment-3195420) and [easily-removable body](https://www.eurobricks.com/forum/index.php?/forums/topic/173690-42110-land-rover-defender-mods-and-improvements/&page=6&tab=comments#comment-3191624).

### MOCs on Rebrickable

On an official set's page on [Rebrickable](https://rebrickable.com/sets/42110-1/land-rover-defender), 
there's a tab for "Alt builds" where _Alternate Builds_ and _Modifications_
are listed separately. The former are builds that can be made with only the pieces from the set, while 
Modifications require additional parts. 

This one is a mod, so gotta buy stuffs 🥴, but I think it's worth it
because of learning and also completionism of having a working Land Rover. (I also want to attach a 
motor to the drivetrain to actually see the output speed difference while shifting through the gearbox
with constant input motor speed, which would be bad to do now b/c of the gear snapping.)

### Purchasing parts

Purchased the parts from [BrickLink](https://www.bricklink.com/) via the integration on Rebrickable 
(which exports the parts list for a particular MOC). Was a bit confused at first about how to get 
the pieces from the cheapest/reliable stores, especially re: shipping. 

I eventually figured out that you've got to select Domestic stores only (for reasonable shipping cost) 
and use the "Auto-select" button, which aggregates the pieces you need with the stores that are selling, 
and places the cheapest order in your cart. It was a super smooth process / I am impressed with this 
software.
(I also tried out BrickOwl but I couldn't find an "Auto-select" type of feature so am at a loss 
for how to choose the optimal stores.) Both stores I purchased from on BrickLink shipped next day.

Order from first store:
\`\`\`
    Total Items: 67
    Unique Items (lots): 24

    Total: US $6.92
    Shipping: US $4.25
    California Sales Tax: US $0.71
    Grand Total: US $11.88
\`\`\`

Order from second store:
\`\`\`
    Total Items: 97
    Unique Items (lots): 30

    Total: US $6.94
    Shipping: US $3.75
    California Sales Tax: US $0.71
    Grand Total: US $11.40
\`\`\`
All-in-all, 164<sup>1</sup> parts (some new, some used) for $23.28.

(Sidenote: BrickLink also makes [Stud.io](https://www.bricklink.com/v3/studio/download.page), 
one of a few popular Lego CAD programs. There's a whole Lego open-source community. 
This rabbit hole is deep 🤷🏻‍♂️)

---

### 🚸 To be continued once parts arrive. Goodbye for now.

---

<div class="sidebar">
<sup>1</sup>The description on the MOC page on Rebrickable mentions that there are 166 extra parts in addition to the original
Land Rover parts. I purchased 164
based on the Rebrickable compare tool. I was worried about missing two parts, so did some investigation
and it looks like the MOC has listed 2 of part 
<a href="https://www.bricklink.com/v2/catalog/catalogitem.page?P=6641" target="blank_">6641 - Technic Changeover Catch</a>,
used for the DNR and Hi/Lo selectors. On BrickLink, this part is searched interchangeably with
<a href="https://rebrickable.com/parts/51149/technic-changeover-catch-new-style/" target="blank_">51149 - Technic Changeover Catch - New Style</a>.
After changing my "Build settings" on Rebrickable to include "Consider alternate parts that can usually 
be used as replacements, but are not always functionally compatible", the compare tool lists 162 additional parts,
because I already have the 2 51149 parts that came in the original Land Rover. (Still not super sure 
about the 166 number, but I think it should be fine. Pretty sure I have two extra Technic Changeover Catch's,
and the only way to really verify beyond trusting the Rebrickable compare tool would be to go
through the build instructions and check all 2642 pieces 🙃.)
</div>
`
            ,
            frames: [],
          },
        },
        {
          date: new Date('2021-08-02'),
          endDate: new Date('2021-08-11'),
          showCollapseFrames: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 42110 Land Rover Defender',
            text: `
<div style="font-size: 12px;">
<b>Goals:</b> How do gears work? (Learn math) Intro to Technic.

(2573 pieces · Released 2019)
</div>

After careful consideration, procured 42110 as my first Technic set
b/c it has a complex drivetrain and I want to learn about gears; it has a 4-speed gearbox with high and 
low gear ratios, drive/neutral/reverse (DNR) selector, and all-wheel drive w/ front, rear, and central 
differentials.

Drivetrain overview / demo of the drivetrain being powered by a motor from the wheels (from the output):
* [Decoding the gearbox of the LEGO Technic 42110 Land Rover Defender](https://racingbrick.com/2019/09/decoding-the-gearbox-of-the-lego-technic-42110-land-rover-defender/)<sup>(YouTube)</sup>, Racing Brick, 2019
* [LEGO Technic 42110 Land Rover Defender ultimate repair guide](https://racingbrick.com/2020/04/lego-technic-42110-land-rover-defender-ultimate-repair-guide/)<sup>(YouTube)</sup>, Racing Brick, 2020

_Excellent_ drivetrain diagrams with transmission gear ratios for all 4 gears in hi and lo:
* [Lego 42110 Land Rover Defender Drivetrain](https://www.youtube.com/watch?v=R3TfZbrkDfY)<sup>(YouTube)</sup>, John Walt (TinkeringJohn), 2020  ⬇️
    
    <a href="https://youtu.be/R3TfZbrkDfY?t=42" target="blank_"><img src="${process.env.PUBLIC_URL}/img/2021-08-11-lego-land-rover/gears/landrover_drivetrain_johnwalt_tinkeringjohn_p1.png" width="85%" /></a>

Gears in general:
  * [Gears explained](https://www.youtube.com/watch?v=4ROtKKuSaBI)<sup>(YouTube)</sup>, The Engineering Mindset, 2021
    * Explains the driver gear and driven gear, how to calculate gear ratios, speed, and torque
  * [Lego Technic gears tutorial](http://sariel.pl/2009/09/gears-tutorial/)<sup>(blog)</sup>, Sariel, 2009-2010
    * Explains Lego gears, general gear mechanics (gear ratios, efficiency, backlash), and how these mechanics work in practice w/ the Lego gears

I wasn't able to eliminate the ["cracking" in the highest torqued gear](https://youtu.be/0p1kMtrvFvQ) 
(4th gear in the official build, but 1st gear if reversed to be like a real car as explained by 
RacingBrick [here](https://youtu.be/t9VQGoqhOFs?t=10)).
It seems like the universal joints don't turn well (even though they're "aligned"). There's a lot of resistance
in the low gears and everything gets jammed up; the cracking happens when there is too much resistance 
from one gear against another and it jumps over a tooth.

Despite the cracking, I'm happy with the demonstration of the wide range of transmission ratios 
(and the challenges of engineering a high torque off-roader, particularly with plastic parts):

<div style="padding-left: 40px;">
  <a href="https://youtu.be/R3TfZbrkDfY?t=281" target="blank_">
    <img src="${process.env.PUBLIC_URL}/img/2021-08-11-lego-land-rover/gears/landrover_drivetrain_johnwalt_tinkeringjohn_p13.png" width="85%" />
  </a>
</div>

Things have learned:
* how the 4-speed gearbox works (+ what happens when the output is then fed to the hi/lo selector)
* differentials: front, rear, and central
* steering (rack and pinion)

I'm curious how it will perform if the gear ratios are less extreme (e.g. replace the 8T/24T gearing 
that's in the 1st and 2nd gear trains, with say 12T/24T).

**Next steps:** will build alternate build [MOC-31299](https://rebrickable.com/mocs/MOC-31299/jb70/42110-pimp-up-my-land-rover) 
by [jb70](https://bricksafe.com/pages/jb70), which
has a rebuilt drivetrain, eliminating the u-joints, but also has been geared up using 16T/16T instead of 8T/24T.
Will try to recalculate the transmission ratios to [see if i know how to math and] see what the trade-off is.
(The MOC also has Ackermann steering; I have no idea what this is, but interested in learning.)
`
            ,
            frames: [
              '2021-08-11-lego-land-rover/lego-land-rover-02',
              '2021-08-11-lego-land-rover/lego-land-rover-05',
              '2021-08-11-lego-land-rover/lego-land-rover-07',
              '2021-08-11-lego-land-rover/lego-land-rover-08',
              '2021-08-11-lego-land-rover/lego-land-rover-10',
              '2021-08-11-lego-land-rover/lego-land-rover-15',
              '2021-08-11-lego-land-rover/lego-land-rover-20',
              '2021-08-11-lego-land-rover/lego-land-rover-23',
              '2021-08-11-lego-land-rover/lego-land-rover-24',
              '2021-08-11-lego-land-rover/lego-land-rover-28',
              '2021-08-11-lego-land-rover/lego-land-rover-26',
              '2021-08-11-lego-land-rover/lego-land-rover-30',
              '2021-08-11-lego-land-rover/lego-land-rover-33',
              '2021-08-11-lego-land-rover/lego-land-rover-35',
            ],
          },
        },
        // {
        //   date: new Date('2021-08-03'),
        //   item: {
        //     type: itemType.POST,
        //     title: 'the internet',
        //     text: `is still the worst polis`,
        //   },
        // },
        {
          date: new Date('2021-08-01'),
          showCollapseFrames: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 21303 Ideas WALL•E',
            text: `became obsessed with lego after building the grand piano and M had a half completed 
WALL•E around, so i made it and it was disappointing<sup>1</sup> (and this movie was terrible).
in related news / more recent flailing attempts to convince 8 billion people to forsake their true world 
theories, the [IPCC Sixth Assessment Report (AR6)](https://www.ipcc.ch/report/ar6/wg1/#SPM) was released
on 6 Aug 2021. the septennial documents strategy is going to save us all.

<div class="sidebar">
<sup>1</sup> LEGO 21303 adverby review: based on my being a lego expert after having built two whole lego 
sets: 21323 Ideas Grand Piano, and its corresponding free gift, 5006746 Swing Ship Ride, i am 
now truly obsessed with lego b/c the builds are delightfully detailed and thoughtfully engineered. 
WALL•E, however, was distressingly uninspired. the articulation of the arms and neck are fragile 
(i believe there was a fix for the neck released after this model), the tracks are clunky, and
the building process was dull and gave me a headache; it could be that i was dehydrated.
</div>
          `,
            frames: [
              // '2021-08-01-lego-wall-e/wall-e-01',
              // '2021-08-01-lego-wall-e/wall-e-02',
              // '2021-08-01-lego-wall-e/wall-e-03',
              '2021-08-01-lego-wall-e/wall-e-04',
              // '2021-08-01-lego-wall-e/wall-e-05',
              '2021-08-01-lego-wall-e/wall-e-07',
              // '2021-08-01-lego-wall-e/wall-e-08',
              // '2021-08-01-lego-wall-e/wall-e-09',
              '2021-08-01-lego-wall-e/wall-e-10',
              '2021-08-01-lego-wall-e/wall-e-11',
            ],
          },
        },
        {
          date: new Date('2021-07-30'),
          showCollapseFrames: true,
          framesCollapsedOnInit: false,
          filterTags: ['climbing'],
          item: {
            type: itemType.POST,
            title: '[climbing wall] sneak peak',
            text: `// breaking in shoes`,
            frames: [
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-01',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-05',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-07',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-10',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-12',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-13',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-16',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-18',
              '2021-07-30-climbing-wall-sneak-peak/07312021-climbing-wall-20',
            ],
          },
        },
        {
          date: new Date('2021-07-18'),
          endDate: new Date('2021-07-30'),
          showCollapseFrames: true,
          filterTags: ['lego'],
          item: {
            type: itemType.POST,
            title: 'LEGO 21323 Ideas Grand Piano',
            // text: `excellent`,
            frames: [
              '2021-07-18-lego-grand-piano/lego-grand-piano-01',
              '2021-07-18-lego-grand-piano/lego-grand-piano-02',
              '2021-07-18-lego-grand-piano/lego-grand-piano-05',
              '2021-07-18-lego-grand-piano/lego-grand-piano-06',
              '2021-07-18-lego-grand-piano/lego-grand-piano-10',
              '2021-07-18-lego-grand-piano/lego-grand-piano-12',
              '2021-07-18-lego-grand-piano/lego-grand-piano-26',
              '2021-07-18-lego-grand-piano/lego-grand-piano-17',
              '2021-07-18-lego-grand-piano/lego-grand-piano-18',
              '2021-07-18-lego-grand-piano/lego-grand-piano-14',
              '2021-07-18-lego-grand-piano/lego-grand-piano-15',
              '2021-07-18-lego-grand-piano/lego-grand-piano-16',
            ],
          },
        },
        // {
        //   date: new Date('2021-07-11'),
        //   showCollapseText: true,
        //   filterTags: ['(socio|econo|political)-systems'],
        //   item: {
        //     type: itemType.BOOK,
        //     title: 'Time, Labor, and Social Domination: A Reinterpretation of Marx\'s Critical Theory',
        //     year: 1993,
        //     author: 'Moishe Postone',
        //     inProgress: true,
        //     inProgressCurrentPage: 21,
        //     inProgressTotalPage: 339,
        //   }
        // },
        {
          date: new Date('2021-07-11'),
          endDate: new Date('2021-07-14'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.POST,
            title: '[moishe postone] rethinking marx\'s critique of capitalism: introduction',
            text: `
_Moishe Postone, "Time, Labor, and Social Domination: A Reinterpretation of Marx's Critical Theory" (1993)_,
_Chapter 1 — Rethinking Marx's critique of capitalism: Introduction (p. 3-21)_

## The crisis of traditional Marxism
  * _Traditional marxism_ refers to:
    > All theoretical approaches that analyze capitalism from the standpoint
    of labor and characterize that society essentially in terms of class relations, structured by
    private ownership of the means of production and a market regulated economy. Relations of 
    domination are understood primarily in terms of class domination and exploitation.
    Socialism is understood implicitly in terms of collective ownership of the means of production and
    economic planning in an industrailzied context. That is, the historical negation of capitalism,
    is seen primarily as a society in which domination and exploitation of one class by another is overcome. (7)
  * The core of all forms of traditional Marxism is a transhistorical conception of labor: 
    _(1)_ labor is the source of all wealth, _(2)_ society rests on a system of exploitation
    * Postone's critique: the traditional approach is attributing transhistorically what Marx analyzed
      as historically specific features of labor in capitalism.
  * The traditional interpretation of capitalism's transformation to a free socialist society: 
    wealth continues to be extracted from proletariat class until it is untenable, 
    creating conditions for abolition of private property and centralized planning; 
    proletariat takes over means of production, ownership, and management.
    * Postone's critique: the focus of this interpretation is a change in the mode of _distribution_,
      but assumes that the same mode of industrial production (to which capitalism gave rise) is still adequate.
      * Note: Martin Hägglund talks a lot about this; changing the mode of distribution still relies
      on capitalism as a mode of production.
  * Traditional Marxist interpretation's limits have become obvious in light of twentieth-century developments.
    * Liberal capitalism (laissez-fiare) leading to partial or or total supersession of the market by 
      the interventionist state (Keynesianism) — state intervention, centralized production, large labor unions,
      large welfare state, government intervention in the economy.
      * The traditional understanding of 'socialism' is central planning and state (or public) ownership; this
      more or less happened and did not solve the problems of capitalism.
      * Traditional interpretation only critiques distribution and not production. In Keynesian capitalism,
      distribution was essentially transformed into what traditional Marxism calls for.
    * Case study: Soviet Union ("actually existing socialism") often considered socialist b/c private property and the market had been
      abolished; continued unfreedom was attributed to repressive bureaucratic institutions.
      However, Soviet Union suggests that the traditional interpretation, focused exclusively on private
      property and the market, is not adequate for an emancipatory critical theory. (11)
    * Claim that human labor is the social source of all wealth has been called into question in light
      of growing scientific knowledge and technology in the process of production.
    * Traditional Marxism's emancipatory ideals are increasingly remote from current-day dissatisfactions
      * Exclusive focus on class
        * Contemporary systemic oppression is often not related to class. (e.g., gender, race)
      * Affirmation of industrial proletarian labor
        * Industrial growth is causing climate catastrophe
        
## Reconstructing a critical theory of modern society
  * The idea is that the traditional interpretation of Marxism (as described above) isn't very helpful as a framework for emancipation today.
  * Moishe is pointing out that, particularly from reading _The Grundrisse_, the traditional interpretation of Marx is not what Marx meant,
    and that a reinterpretation of Marx is still helpful for modern critical theory and actually addresses concerns of today.
`,
          },
        },
        {
          date: new Date('2021-06-15'),
          showCollapseText: true,
          item: {
            type: itemType.BOOK,
            title: 'A Supposedly Fun Thing I\'ll Never Do Again: Essays and Arguments',
            year: 1998,
            author: 'David Foster Wallace',
            text: `<div style="text-align:right;"><i>A Supposedly Fun Thing I'll Never Do Again</i>, 1996</div>`,
          },
        },
        {
          date: new Date('2021-06-04'),
          endDate: new Date('2021-06-06'),
          showCollapseFrames: true,
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Big Sur, CA',
            text: `
testing vaccines w/ friends. photos by M and S.
good job caltrans on JIT highway repair`,
            frames: [
              // '2021-06-04-big-sur/big-sur-01', // me (too many images)
              '2021-06-04-big-sur/big-sur-02',
              // '2021-06-04-big-sur/big-sur-03', // me haircut (too many images)
              '2021-06-04-big-sur/big-sur-04',
              // '2021-06-04-big-sur/big-sur-05', // me and m (too many images)
              '2021-06-04-big-sur/big-sur-06',
              // '2021-06-04-big-sur/big-sur-09', // t
              // '2021-06-04-big-sur/big-sur-12', // d
              // '2021-06-04-big-sur/big-sur-13', // n
              '2021-06-04-big-sur/big-sur-22',
              '2021-06-04-big-sur/big-sur-27',
              '2021-06-04-big-sur/big-sur-28',
              // '2021-06-04-big-sur/big-sur-29', // t
              // '2021-06-04-big-sur/big-sur-30', // n
              '2021-06-04-big-sur/big-sur-31',
              '2021-06-04-big-sur/big-sur-36',
              '2021-06-04-big-sur/big-sur-43',
              '2021-06-04-big-sur/big-sur-48',
              // '2021-06-04-big-sur/big-sur-49', // l
              '2021-06-04-big-sur/big-sur-60',
              '2021-06-04-big-sur/big-sur-62',
              '2021-06-04-big-sur/big-sur-67',
              '2021-06-04-big-sur/big-sur-70',
              '2021-06-04-big-sur/big-sur-73',
              '2021-06-04-big-sur/big-sur-74',
              // '2021-06-04-big-sur/big-sur-76', // group hot tub
              '2021-06-04-big-sur/big-sur-77',
              // '2021-06-04-big-sur/big-sur-78', // t n d tent
              // '2021-06-04-big-sur/big-sur-79', // n s tent
              // '2021-06-04-big-sur/big-sur-80', // s t tent
              '2021-06-04-big-sur/big-sur-81',
              '2021-06-04-big-sur/big-sur-85',
              // '2021-06-04-big-sur/big-sur-88', // n s n sky
              '2021-06-04-big-sur/big-sur-89',
              '2021-06-04-big-sur/big-sur-96',
              '2021-06-04-big-sur/big-sur-104',
              // '2021-06-04-big-sur/big-sur-110', // m s 
              // '2021-06-04-big-sur/big-sur-111', // l
              // '2021-06-04-big-sur/big-sur-112', // group
              // '2021-06-04-big-sur/big-sur-113', // group
              // '2021-06-04-big-sur/big-sur-114', // n l
              '2021-06-04-big-sur/big-sur-116',
              '2021-06-04-big-sur/big-sur-117',
            ],
          },
        },
        {
          date: new Date('2021-05-14'),
          showCollapseText: true,
          item: {
            type: itemType.BOOK,
            title: 'How to Do the Work: Recognize Your Patterns, Heal from Your Past, and Create Your Self',
            year: 2021,
            author: 'Nicole LePera',
          },
        },
        // {
        //   date: new Date('2021-05-14'),
        //   showCollapseText: true,
        //   item: {
        //     type: itemType.BOOK,
        //     filterTags: ['(socio|econo|political)-systems'],
        //     title: 'Virtue Hoarders: The Case Against the Professional Managerial Class',
        //     year: 2021,
        //     author: 'Catherine Liu',
        // text: `👎`,
        //           text: `
        // v disappointing. excellent title and premise that devolves into a diagnosis
        // of PMC as fabricators of sexual assault and racism allegations.
        // for what it's worth, this book seems to be admittedly a troll (by the PMC for the PMC and about the PMC),
        // and the fact that Liu is being published by PMC Left journals is both infuriating and also kind 
        // of a self-reflexive troll about the inconsequentialness of the PMC as an emancipatory force.

        // tldr; she's spot on about some things (i.e., regarding the self-righteous, ineffective, anti-working-class, 
        // virtue-hoarding PMC), and identifies that there are serious issues with identity politics and cancel culture,
        // but she certainly is not writing this in good faith or toward anything resembling freedom from 
        // oppression, making her argument actively harmful.`,
        //  by saying 'i am anti-identity-politics' (which is valid) while clearly existing in (and being 
        // published for) her identities as a leftist, as a woman, as an asian (in full anti-black model-minority force), 
        // her active siding with oppressive forces and sponsorship of oppressive narratives 
        // (that racism and sexual assault are made up), perpetuates the most insidious type of harm. 
        // tldr tldr; this book is trash (and the white boys at the DSA will eat it up).
        //   },
        // },
        {
          date: new Date('2021-05-11'),
          item: {
            type: itemType.POST,
            title: "Bunker Day 421",
          },
        },
        {
          date: new Date('2021-05-08'),
          item: {
            type: itemType.FILM,
            title: "Ms. Purple",
            year: 2019,
            director: 'Justin Chon',
            frames: [
              'purple-01',
              'purple-02',
            ],
          },
        },
        {
          date: new Date('2021-05-03'),
          showCollapseText: true,
          item: {
            type: itemType.BOOK,
            title: 'Adult Children of Emotionally Immature Parents: How to Heal from Distant, Rejecting, Or Self-Involved Parents',
            year: 2015,
            author: 'Lindsay C. Gibson',
          },
        },
        {
          date: new Date('2021-04-25'),
          filterTags: ['things'],
          item: {
            type: itemType.POST,
            title: '[practice #1]',
            text: `
<div style="padding:56.25% 0 0 0;position:relative;">
<iframe src="https://player.vimeo.com/video/542374810?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&sidedock=0&byline=0&portrait=0"
  frameborder="0" 
  allow="autoplay; fullscreen; picture-in-picture" 
  allowfullscreen 
  style="position:absolute;top:0;left:0;width:100%;height:100%;" 
  title="kreisler - praeludium">
</iframe>
</div><script src="https://player.vimeo.com/api/player.js"></script>

(praeludium on iphone)
`,
          },
        },
        {
          date: new Date('2021-04-24'),
          showCollapseText: true,
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            title: 'Family Values: Between Neoliberalism and the New Social Conservatism',
            year: 2017,
            author: 'Melinda Cooper',
            text: `
How and why is there an alliance between the neoconservative family-values religious right and
the neoliberal economists? 
* The neoliberals are against public welfare (i.e. drains the funds of the state) and formed an 
alliance with religious morality neoconservatives on the formation of the family unit as a form of 
private insurance.
> Neo-liberals are particularly concerned about the enormous social costs that derive from the breakdown of the stable Fordist family: the costs that have been incurred, for example, by women who opt for no-fault divorce, women who have children out of wedlock or those who engage in unprotected sex without private insurance; and the fact that these costs accrue to the government and taxpayer rather than the private family.
> Although they are much more prepared than are social conservatives to accommodate changes in the nature and form of relationships within the family, neoliberal economists and legal theorists wish to reestablish the private family as the primary source of economic security and a comprehensive alternative to the welfare state.
>
>If American welfare reform has been singularly focused on the question of marriage promotion and responsible family formation in the past few decades, it is thanks to the ongoing collaboration between neoliberals and social conservatives on this point in particular.

Cooper goes into the history of how policy has evolved to center around creation of the family from post-war 
Keynesianism through the birth of the neoliberal/neoconservative movement in the 1970s, through today. 
And how these policies have led us to a place where:
* the normative family is the only safety net
  * from decline of the Ford family wage and rise of feminism/civil rights, to destruction of welfare 
  (in large part b/c of anti-Black racism) / rise of neoliberalism and return to familial responsibility 
  as social form of welfare
  * introduction/advocacy for 30-year mortgage; easy loans and promotion of home ownership (hint: subprime crisis of 2007-08)
* inherited wealth is required to survive
  * impossible student debt (cannot file bankrupcy like other loans), federal PLUS loan for parents creates intergrnerational debt
  * financial assets grow faster than wages (Piketty)
* gay rights are centered around legal marriage
  * neoliberals were some of first proponents of same-sex marriage b/c it relieves public costs
  * AIDS crisis becomes personal/familial responsibility instead of public responsibility
* the rise of faith-based welfare 
  * religious providers are actively included in govt. contracts to provide prison inmate support,
homeless shelters, soup kitchens, group homes, substance-abuse treatment, welfare-to-work training,
marriage and responsible fatherhood instruction, and whole range of other services for the poor
  * neoliberals are a fan b/c religious groups rely on volunteer labor 
and have alternative sources of funding besides the govt.
  * since the 1996 Clinton welfare reform ('Charitable Choice' provision), religious
organizations have been allowed to provide social services hand-in-hand with their religious mission. (previous
to Clinton's welfare reform, they had to register as a separate non-profit and avoid 'pervasive sectarianism'.)
  * unlikely alliance between evangelical Protestants and Catholics (and decline of mainline 
Protestant churches) began after Roe v. Wade decision in 1973. Formation of the New Right; the Moral Majority 
was pivotal in the election of Ronald Reagan

The neoliberal-neoconservative alliance has consolidated power for and between two groups that have 
very different ideologies and it has worked.

Context/Related ideas and topics: 
* These values are center to the ideologies of both parties. QAnon, Mike Pence, Amy Coney Barrett. 
Confusing standards of morality e.g. Donald Trump, Kavanaugh, dark money, 
Mitch McConnel. Democratic party is just as pro neoliberal+family values, but hides behind meaningless 
social rhetoric. They are the same; just one is more provocative.
* the COVID relief bill ([American Rescue Plan](https://www.congress.gov/bill/117th-congress/house-bill/1319/text)) 
included direct payments (\\$1400), but also much more in child tax credit (\\$3000 - \\$3600 per child).
[Fact Sheet.](https://home.treasury.gov/news/featured-stories/fact-sheet-the-american-rescue-plan-will-deliver-immediate-economic-relief-to-families)
Bad for neoliberals but pro-family values?
`,
          },
        },
        //       {
        //         date: new Date('2021-03-27'),
        //         showCollapseText: true,
        //         useSerif: true,
        //           filterTags: ['(socio|econo|political)-systems'],
        //         item: {
        //           type: itemType.POST,
        //           title: "[fv2] moral crisis of inflation; rise of neoliberalism + neoconservativsm, demise of family wage (1970s)",
        //           text: `
        // _Melinda Cooper, "Family Values: Between Neoliberalism and the New Social Conservatism" (2017)_</br>
        // # Chapter 2 - The Moral Crisis of Inflation: Neoliberalism, Neoconservatism, and the Demise of the Family Wage

        // * The Volcker shock in 1979 drove America into greatest recession since the Great Depression in 
        // order to combat inflation, creating conditions for Reagan revolution
        // and reshaping the landscape for American and global politics over the following decades.

        // * But why was inflation a political crisis to begin with? Most accounts of the economic conditions in the 70s
        // assume that inflation was a crisis for all social classes; this is revisionist.

        // * Because of trade unions, wages continue to rise even with high unemployment (_stagflation_). Inflation was good for wage workers and for debtors, 
        // because it drove interest rates down. It was definitively bad for investors and those who derived 
        // their net worth from financial assets. Inflation "acted like a progressive tax, lending to greater
        // equality in the distribution of wealth." (economist Edward N. Wolff; study on inflation on household wealth between 1969 and 1974)

        // ## AFDC, Welfare, and the American family wage

        // * Aid to Families and Dependent Children (AFDC) was specifically targeted. It was originally created as part of
        // the Social Security Act of 1935 (called Aid to Dependent Children (ADC)); supported widowed mothers; 
        // most recipients were white women. 1939, Social Security Act
        // allowed widows to be covered by more respectable Old Age Insurance (OAI); devaluation of status of ADC.
        // By 1961, 48% of Black women were on ADC, many were single mothers. An affront to ideals of white American motherhood; 
        // there was more policing of the "deservingness" of welfare recipients; introduction
        // of rules and limitations (i.e. man-in-the-house rules refused benefits to women who lived
        // with a man, suitable-home laws allowed denial of aid to unmarried or immoral women)

        // ## Moynihan, the Left, and the Black family wage

        // * Johnson/Moynihan (New Deal Democrat); The Moynihan Report blamed conditions faced by Black Americans 
        // (poverty, crime) on the disintegration of Black families instead of white racism. While the Left disagreed 
        // on the cause, they agreed that the Black family needed to be restored. Agreed on the need to restore 
        // Black manhood via an extension of the New Deal family wage.

        // * Moynihan criticized AFDC for causing breakdown of the family. Welfare to women meant Black men weren't 
        // incentivized to have jobs or wives. Argued for guaranteed basic income but as male breadwinner wage; a new family wage system.

        // ## Nixon and the Black family wage: exorcising AFDC

        // * 1969 — Nixon (with support of Moynihan, Milton Friedman) announced program to replace AFDC with federal program 
        // Family Assistance Plan. Replaced in-kind welfare with cash. Based on Friedman's negative income tax; channeled
        // income redistribution through federal tax system, eliminating administrative costs of welfare programs.

        // * 1970, Democratic Party controlled House approved it. Later that year, it was defeated by coalition of 
        // Republicans and Democrats in the Senate. While liberal Democrats like Moynihan and free-market neoliberals 
        // like Friedman had once agreed on the need for a family wage, they now began to develop philosophies 
        // on non-redistributive family values; turning point and emergence of neoconservativism and American 
        // neoliberalism as mature political phiolosophies.

        // ## Turning against the family wage: neoliberalism and neoconservatism come into their own.

        // * In the 1960s, there was labor union power, civil rights; alliances between New Deal democrats and 
        // moderate conservatives (e.g., Moynihan, Friedman, all worked together with Nixon).

        // * Moynihan trajectory from New Deal Democrat to birth of neoconservatism. Saw Black women in welfare rights movement
        // as hostile to moral premise of Fordist family wage; prefigured term 'welfare queens'. Saw defeat of Nixon's 
        // Black family wage as end to expansionary welfare politics of 1960s.

        // * Milton Friedman from pragmatic proponent of guaranteed basic income to birth of American neoliberalism.
        // Saw Nixon's version of Family Assistance as messy compromise. Increasingly didnt like Nixon;
        // Nixon ran on austerity and tight monetary policy but did not follow through. Increases to Social Security.
        // Ignored Friedman's advice on handling inflation and opted for brief experiment in wage and price controls.
        // Friedman called Nixon "the most socialist of the Presidents of the United States in the twentieth century."
        //   * By 1980, Friedman's critique of welfare was much harsher. "They weaken the family; reduce the incentive to 
        //   work, save, and innovate; reduce the accumulation of capital; and limit our freedom." 
        //   Friedman became increasingly libertarian in 1970s.

        // * Neoliberals abandoned pragmatic accomodation with Keynesian welfare state; new uncompromising position 
        // for social spending cutbacks.

        // * Neoconvervatism emerged as reaction against the New Left. Prepared to promote 'family values' 
        // without the income redistribution part. Endorsed expanding role of state in regulation of sexuality.

        // * Converged on necessity of reinstating the family as the foundation of social and economic order.
        // Alliance would shape next decades, culminating in Clinton's welfare reform of 1996.

        // ## Rise of neoconservatism: inflation, welfare, and moral crisis

        // * Response to New Left counterculture that was against normative policing of legitimate childbearing and sexual morality
        // * Defender of white blue-collar working class against unproductive 'welfare queens'
        // * Rhetoric of social disorder througout 1970s; linked inflation to welfare programs and breakdown of family 
        // (through repetition)

        // ## Neoconservatism and neoliberalism
        // * Friedman: regulation interferes with natural order of market. 
        // Welfare interferes with natural order of the family.
        // * Neoliberals wanted private family welfare, rather than state sponsored welfare
        //   * AFDC under fire; "poor women's alimony"; if women have money then they don't need men; breakdown of family
        //   * Neoliberal welfare reform must strive to restore state of mutual dependence and self-sufficiency
        // * Neoconservatives wanted the state to intervene on behalf of the family.
        // * While the neoliberals in the long term didn't want government involved at all, in the short term, they
        // needed to establish private family welfare intead of state paternalism; so they allied with the neoconservatives.

        // ## Transitions

        // * 1960s; New Deal democrats and free-market neoliberals wanted expansion of family wage to include
        // Black men. Democrats and Republicans "all Keynesians now"; redistributive welfare reform.
        // * Mid 1970s; Nixon had given up on Black family wage. inflation emerged as political concern. 
        // former New Deal Democrats (Moynihan) came out as neoconservatives and free-market neoliberals 
        // (Friedman) lost pragmatic appraoch to income redistribution.
        // Agreed that New Deal and Great Society welfare programs needed to be restricted and qualified. Family needed
        // to be strengthened as general alternative to redistributive welfare. Family responsibility (notion from poor-law) became
        // concerted effort of neoliberals and neoconservatives.
        // * Reagan — large cuts to AFDC even though it only took up 1% of federal budget; left Social Security and Medicare untouched —
        // why the focus? 
        // "AFDC had come to epitomize the specific moral maliase of inflation".
        // Reagan was ultimately not that successful in welfare reforms.
        // * Clinton (the New Democrat) was the one who promised to "end welfare as we know it" — 
        // fruition of neoliberal-neoconservative alliance.

        // `
        //         },
        //       },
        //       {
        //         date: new Date('2021-03-26'),
        //         showCollapseText: true,
        //         useSerif: true,
        //           filterTags: ['(socio|econo|political)-systems'],
        //         item: {
        //           type: itemType.POST,
        //           title: "[fv1] family values: neoliberalism ❤️ the new social conservatism",
        //           text: `
        // _Melinda Cooper, "Family Values: Between Neoliberalism and the New Social Conservatism" (2017)_</br>
        // # Chapter 1 - Between Neoliberalism and the New Social Conservatism
        // Melinda Cooper discusses the alliance on the right between neoliberal economists and religious family-values
        // social conservatives. How and why is this a thing? They don't even care about the same things.

        // Criticially, the breakdown of the post-war Fordist family economic security — _Fordist family_: male worker, stay-at-home wife 
        // and mother, two or more children. Fordist wage was indexed on maintaining wife and children at home.

        // Fordist family maintained: gender/sexual norms; midcentury organization of labor, race, and class. 
        // Its decline of course was an advancement in social liberation. 

        // (1) Social conservatives are concerned about the destruction of family life.

        // (2) Neoliberals, however, are concerned about the social costs of the breakdown of the stable Fordist family. 
        // They want to reestablish the private family as the primary source of economic security; a comprehensive alternative
        // to the welfare state.

        // > If American welfare reform has been singularly focused on the question of marriage promotion
        // > and responsible family formation in the past few decaes, it is thanks to the ongoing collaboration between
        // > neoliberals and social conservatives on this point in particular.

        // Note: there are some left-wing critics (often anti-feminist) who blame neoliberal capitalism for the destruction of family life
        // (despite prominance of family in contemporary social policy):
        // * Wolfgang Streek: introduction of no-fault divorce in 1960s; women no longer dependent on men
        // meant men aren't obligated to care for family → no one to defend Fordist institution of economic security; the family wage.
        // Feminism encouraged women to seek independent wage on par with men, transforming marriage into contract that could
        // be terminated at will. Streek believes feminists (who he believes to be middle class) robbed women 
        // (who he believes to be working class) of the economic security that came from marriage to a
        // Fordist worker.
        // * Nancy Fraser: send-wave feminism
        // colluded w/ neoliberalism in efforts to destroy family wage. Advocates for return to family wage that would 
        // recognize women; while more egalitarian, still advocates for restoration of family.
        // * Other left thinkers have similar ideas; that neoliberal assault
        // on economic and social security was based in destroying the postwar family. 
        // All influenced by work of Karl Polanyi; economic liberalism → social disintegration.

        // _Neoliberalism_: American schools of new economic liberalism; came out of University of Chicago, University of Virginia, etc.
        // in response to social and economic upheavels of 1960s and 70s. Concepts in response to changing gender and racial composition
        // of workforce, civil rights, welfare rights movements, student radicalism. Throughout 1970s, neoliberal intellectuals
        // Milton Friedman etc. helped redefine consensus on state deficit spending, role of central bank, inflation,
        // taxation, consumer protection laws, tuition fees, and welfare; involved in policy decisions of Nixon, Carter, Reagan
        // administrations.

        // _New social conservatism_: Spectrum of conservative movements that emerged in or after 1960s, often in reponse to same set of
        // concerns that mobilized neoliberals. Includes neoconservative movement (concerned w/ domestic social issues), new religious right — conservative
        // Catholics and evangelicals, new paternalism of Lawrence Mead (the principal American architect of welfare-to- work programs), 
        // and communitarian movement in social welfare.

        // > In short, it was only when the liberation movements of the 1960s began to challenge the sexual normativity 
        // > of the family wage as the linchpin and foundation of welfare capitalism that the neoliberal–new social 
        // conservative alliance came into being. 
        // >
        // > What they proposed in response to this “crisis” was not a return 
        // to the Fordist family wage (this particular nostalgia would be the hallmark of the left), but rather 
        // the strategic reinvention of a much older, poor-law tradition of private family responsibility, 
        // using the combined instruments of welfare reform, changes to taxation, and monetary policy. 
        // >
        // > Under their influence, welfare has been transformed from a redistributive program into an immense 
        // federal apparatus for policing the private family responsibilities of the poor, while deficit spending 
        // has been steadily transferred from the state to the private family. 
        // >
        // > Through policies designed to democratize credit markets and inflate asset values, these reformers have sought to revive the tradition 
        // of private family responsibility in the idiom of household debt, while simultaneously accommodating 
        // and neutralizing the most ambitious political desires of the 1960s.

        // Today, wealth distribution not channeled through the Fordist family wage but through private inheritance (Piketty).
        // What's the difference really?
        // > How after all are we to separate the wealth-distributive work of inheritance from the legal and 
        // cultural legitimation of family?
        // >
        // > In what sense can the regulation of sexuality be abstracted from a legal instrument of wealth appropriation that takes the form of family genealogy?
        // `
        //         },
        //       },
        {
          date: new Date('2021-03-22'),
          filterTags: ['philosophy', '(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            title: 'This Life: Secular Faith and Spiritual Freedom',
            year: 2019,
            author: 'Martin Hägglund',
          },
        },
        {
          date: new Date('2021-03-22'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['philosophy', '(socio|econo|political)-systems'],
          item: {
            type: itemType.POST,
            title: 'martin hägglund on: this life, redefining value, democratic socialism',
            text: `
_Summary of Martin Hägglund's "This Life: Secular Faith and Spiritual Freedom" (2019)_</br>

# Secular life as meaningful in and of itself + finitude

Hägglund spends a lot of time on why we should appreciate the 'finitude' of life rather than 
try to escape it (via religion, _or_ as Max Weber does as described below). He uses the 
religious word 'faith' to describe both faith in an 
afterlife and faith in the current life. 
* Living for the end goal of an afterlife necessitates that we don't care 
about _this_ life _in and of itself_, and only as a means to get to the next. 
* He is adamant about the idea that religious people (those that have religious faith) can _also_ have _secular_ 
faith — that is, valuing life in and of itself.
* Instead of seeing the 'finitude' of life as something that is bad, rather it gives us the perspective to 
make this life worth it. We can't appreciate something, or be committed to it, and work for something, 
if we have no chance of losing it. (It follows that the afterlife
of endless time and no pain would be a meaningless existence.) 
* Hägglund doesn't try to convince readers 
that religion is untrue, but rather that it's a good way to waste one's short finite life; and ultimately,
it's destructive to the world in its incentivization of active complacency. 
* **While Hägglund does go into depth on how democratic socialism should look, he starts with 'finitude' and religion.
In order to create a world that is conducive to human freedom and _life_— in order to take action to alleviate 
climate catastrophe and human suffering— we need to give a damn
about _this_ life.**

Critique of socialogist Max Weber's (_The Protestant Ethic and the Spirit of Capitalism_, 1905) claim that secular life suffers from 'disenchantment' / meaninglessness inherent to secular life.
  > When Weber claims that the commitment to earthly progress makes our lives meaningless rather than meaningful, the authority to which he appeals
  > is the devoutly religious author Leo Tolstoy.

* Life isn't about reaching a final fulfillment or completion, but about sustained purpose. Living for eternity
or for some moment of greatness at the end of one's life is at the expense of living one's life with appreciation
for every moment, in view of the finitude of life.

Key to understanding the promise of secular life can be found in work of Karl Marx.
* Marx is often incorrectly conflated w/ totalitarian communist regimes / rather, he was the most important proponent of secular commitment to freedom and democracy.



# Freedom
The idea of freedom has in recent times been coopted by neoliberalism — freedom serves to defend 'the free market' and is reduced to individual liberty.
In response, the left has retreated from or rejected the idea of freedom; this is a mistake.
> Any emancipatory politics — as well as the critique of capitalism — requires a conception of freedom. Only in light
> of a commitment to freedom can we render anything intelligible _as_ oppression, exploitation, or alienation. Moreover,
> only in light of a commitment to freedom can we give an account of what we are trying to achieve and why it matters.

Our freedom requires that we can own the question of what we do with our time. For Marx, political progress is measured by the degree to which it allows for such freedom.
> An emancipated life is not a life that is free from work, but a life in which we pursue work on the basis of our own commitments.

Capitalism _was_ a form of progress:

> We can thus understand why the advent of capitalism is a form of progress for Marx. Wage labor under capitalism
> is historically the first social form which in principle recognized that each of of us "owns" the time of
> our lives and that our lifetime is inherently "valuable." Unlike slaves—who are systemically denied ownership
> of their time—we are "free" to sell our labor to someone who is willing to buy it. Moreover, wage labor
> is explicitly conceived as a _means_ for us to achieve the _end_ of leading a free life.

However, achieving freedom through wage labor is necessarily impossible:
Wealth under capitalism is produced/measured by _labor_ time, which necessarily contradicts the achievement of _free_ time. 
Socially necessary labor time must be overcome in order to achieve emanicpation, yet capitalism requires it.

# How is secularism related

> The cultivation of secular faith is indispensable for progressive politics. The pursuit of emancipation requires that we
> are committeed to improving the material and social conditions of freedom as an end in itself.
> This is why Marx emphasizes that the critique of religion must be accompanied by a critique of the existing
> forms of our life together. That those who are enslaved or live in poverty may need faith in God to carry
> on with their lives is not a reason to promote religious faith but a reason to abolish slavery and poverty.
>
> We live in an epoch when social inequality, climate change, and global injustice are intertwined with the resurgence
> of religious forms of authority that deny the ultimate importance of these matters. A dominant 
> response is to retreat from a secular faith in the possibility of progress, in favor of asserting the necessity
> of a religious sense of "fullness" to sustain our moral and spiritual lives.
> This book seeks to combat all such forms of political theology. In contrast, I offer a secular vision 
> of why _everything depends on what we do with our time together_. The decline of religious faith in 
> eternity is not something to be lamented. Rather, it provides an opportunity to make explicit and 
> strengthen our secular faith in _this life_ as an end in itself.
<div class="markdown-quotee">(Introduction)</div>

> Capitalism and religion have proved to be highly compatible, which from Marx’s perspective is no accident. 
> He keeps returning to how both capitalism and religion are forms of self-alienation. Both capitalism 
> and religion prevent us from recognizing in practice that our own lives—our only lives—are taken away 
> from us when our time is taken away from us. While capitalism alienates us from our own time by subordinating 
> it to the purpose of profit, religions offer the consolation that our time ultimately is insignificant and will be redeemed by eternity. 
> While capitalism makes poverty perennial and distorts the meaning of wealth, religions promote poverty 
> as a virtue and as a path to salvation. While capitalism disables our capacity to lead free lives, 
> religion teaches us that submission leads to liberation. In short, both capitalism and religion make 
> us disown our lives, rather than enabling us to own the question of what we ought to do with our finite time.
>
> Accordingly, Marx famously argues that religion is “the opium of the people.” The suffering caused by 
> social injustice is alleviated by the promise of a religious redemption. Religions serve as a form of “opium” both 
> by diminishing the pain of our not being able to lead flourishing lives and by pacifying us, making us dream 
> of an impossible bliss rather than waking up and taking action to transform the conditions of our only life. 
> As Marx emphasizes, “the abolition of religion as the illusory happiness of human beings” must therefore be 
> understood as “a demand for their real happiness. The call to abandon their illusions 
> about their conditions is a call to abandon a condition which requires illusions.” 
> If we merely criticized religious beliefs as illusions—without being committed to overcoming the 
> forms of social injustice that motivate these illusions—the critique of religion would be empty and patronizing. 
> The task is rather to transform our social conditions in such a way that people no longer need to 
> have recourse to the opium of religion and can affirmatively recognize the irreplaceable value of their own lives.
<div class="markdown-quotee">(Chapter 6: Democratic Socialism)</div>

# Wealth redistribution won't lead to emancipation

Wealth redistribution is intended to emancipate humans from having to work, but the system of capitalism depends
on wage labor in order to generate that wealth. An inherent contradiction.
  > As long as we accept the measure of value that is based on labor time, the exploitation of proletarian labor
will remain necessary for the production of wealth.

Technology should not be seen as something that alienates us from labor, but as something that frees us from it.
Within capitalism, technology is a problem b/c it takes away jobs (that people need in order to survive) / socially necessarily labor, instead of
as a good that frees us from _having_ to labor.
 > The aim is not to glorify proletarian labor but to overcome it.

 * **But we need to redefine value as _free_ time rather than _labor_ time.**

 * We should be designing machines for the sake of increasing socially available free time rather than for the sake of generating profit.

Democratic ownership of the means of production.
  > As long as the means of production are privately owned, the growth of capital—rather than the creation of meaningful
  > forms of labor—will be the aim of the system as a whole, regardless of what our individual intentions may be.

Wealth redistribution won't work b/c of contradictions in the capitalist mode of production.

1. Under capitalism, the measure of our wealth is not our actual capacity to produce goods and reduce necessary labor time. We don't produce goods for
practical purposes but for generating profit. This is why we can have housing crises and homeless families even when we have produced
millions of vacant apartments, which are left empty b/c they can't be sold for profit.

2. Under capitalism, we also don't reduce the necessarily labor time in order to increase free time; we reduce labor time in 
order to extract surplus value out of living labor. Reducing socially necessary labor time doesn't lead to solutions regarding
poverty, but rather creates the problem of unemployment.
Capitalism is not aimed toward solving the problem of unemployment but actively benefits from the existence of unemployment.
Under capitalism, we can't transform the negative value of unemployment into the positive value of free time because
our measure of value is labor time.

3. The more welfare policies and state regulations that prevent the exploitation of living labor, the more restricted are the possibilities of extracting surplus value, and the less
"wealth" is available to distribute in the economy. The fate of a welfare state is bound to the fate of the capitalist economy. The welfare state depends on capitalism (it survives off the wealth generated by wage labor),
but capitalism does not depend on the welfare state.
5. Example: UBI
    > UBI cannot even in principle overcome the dependence on wage labor. On the contrary, any form of universal basic income
is altogethr dependent on the social form of wage labor, since any form of UBI consists in a redistribution of the capital weath that is generated by wage labor.
Under capitalism, wage labor in the service of profit is the necessary source of social wealth. The dependence of wage labor is perpeutated
rather than overcome by a UBI. No form of universal basic income can free us from capitalist exploitation, since only wage labor in the service of
profit can generate teh wealth that is distribuetd in teh formof a UBI. The more we emancipate ourselves from the exploitation of living labor time, the less wealth we have to support our state of freedom. 

# Capitalism tends toward growing inequality

Thomas Piketty (_Capital in the Twenty-First Century_) concludes that "free market" capitalism doesn't promote wealth distribution and doesn't protect individual
liberties.: capitalism tends toward growing inequality based on formula _r > g_. The rate of return on capital (_r_) grows faster, or falls slower, than the growth
rate (_g_) of the economy as a whole. Capitalism favors accumulation of existing wealth over wealth that is based on income.
> The growth of inherited and accumulated wealth outruns the growth of income, leading to what Piketting calls a "terrifying" spiral of
> unequal wealth distribution between those who own capital and those who must subject themselves to wage labor in order to survive.

However, Piketty doesn't argue for overcoming capitalism, but for reforms and regulations to the capitalist system to limit inequality. He advocates for a "social democratic
welfare state that would provide universal health insurance, education, and restrict inequlaity and redistibute wealth through progressive annual tax on global capital." Piketty is concerned with "_distribution_ 
of wealth rather than the _production_ and _measure_ of wealth under capitalism," ignoring (1) unemployment, and (2) the inherent tendency of capitalism toward crises.

# Marx's "The law of the tendential fall" 

Economic crises can't be avoided either through regulations of market (social democracy) or deregulations of market (neoliberalism) b/c the tendency toward crises is inherent in the capitalist mode of production.

Marx's analysis of "the law of the tendential fall".
  * Often misunderstood (by Piketty and others) to mean that capitalism will inevitably kill itself; that the rate of profit will fall irreversibly and lead to the
  destruction of capitalism. Rather than predicting capitalism's destruction, Marx is showing that capitalism can only be kept alive via a "pernicious and self-contradictory dynamic, which is innimical to the production of real social wealth."

  * Why does the rate of profit have a tendency to fall?

    > The rate of profit is the relation between _total amount of capital_ I invest in production of a commodity—e.g., the sum total of the cost of wages, machinery, and raw materials that are required—, and the _amount of surplus_ I can extract from the production of the commodity.

    In order to sustain a capitalist business, we must strive to make the rate of profit _rise_. I have to keep making profit.
    This involves converting surplus time of living labor into surplus value. With labor laws, I am limited in how much I can exploit the living labor of my workers.
    So we can make the technological means of production more efficient / make it more efficient for workers to produce in the same amount of time.

    The contradiction: I am incentivized to develop technologies that replace _living labor time_ (from which I can extract surplus value) with
    _nonliving labor time_ (from which I cannot extract surplus value).
      > Thus, even if the _mass_ of surplus value and profit increases due to the efficiency of the [technology] as a means of production, the _rate_ of profit for my business will tend
      to fall for the same reason. In striving to make profit, I develop ever more efficient technological means of production. On the other hand, the more efficient my technological means of production, the more my rate of profit tends to fall.
      
    ^ I _think_ what Haggslund is saying is: 
      * By definition, profit comes from surplus value; surplus value is any _extra_ value you get after you've paid for the initial materials and put them through
        the process by which they're tranformed into the new commodity to sell at a profit. The only _extra_ value that is added in this process, is human living labor.
        
        \`\`\`
        (Initial value of materials) + (Cost of Labor) 
        = 
        (Initial value of materials) + (Cost of labor) + (Surplus value)
        \`\`\`

        This surplus value comes from paying a wage that is worth less than the labor is worth; from exploiting human living labor.
        (If the wage was equal to the amount of labor that the laborer put in, there would by definition be no surplus value, and no profit.)

        _So_, when we replace living labor with machines, the \`(Initial value of materials)\` on both sides of the equation goes up, 
        so no additional surplus value is extracted; so the rate of profit does not increase. 
        (I may get extra profit initially b/c my competitors don't have this machine yet, but they will.)

Problems:

1. Unemployment 

    > **Because the rate of profit tends to fall, capitalist employers must intensify the exploitation of living labor and/or export production facilities to locations where labor is cheaper to buy.
    Given the reduction of socially necessary labor time, the only way to increase the extraction of relative surplus value—on which profit depends—is to itensify the exploitation of workers
    by lowering the relative value of their wages. Unemployment and the exportation of jobs are not something that can be removed under capitalism, but a necessary
    condition for the production of capital wealth. Lowering the relative value of wages and sustaining a rate of profit depends on a surplus population of the unemployed
    who are willing to work for less, either domestically or in poorer countries to which production is moved.**

2. Tendency toward crises

    > A continuously increasing technological productivity leads to crises under capitalism, since the replacement of living labor time with nonliving production time makes the rate of profit fall. The falling rate of profit can be 
    counteracted through numerous strategies, but ultimately is requires the destruction of large amounts of capital, either thorugh crises of devaluation or through full-on destruction
    of existing capital in war or through other means. Only such a "bust" in the economy can lead to a new "boom."

    War can lead to booms in capitalist economies b/c nonliving production capacities are destroyed and a large amount of living labor time is required to rebuild.

    **It's a mistake to think that the tendency toward crises heralds the end of capitalism.** 

    > Crises are essential to cycles of capitalist accumulation of wealth. For the rate of profit to rise again, the ratio of living labor time must increase relative to nonliving production time, which requires the devaluation
    or destruction of productive capital. Far from being a threat to capitalism, such crises are a condition of possibility for capitaims as a system to reproduce itself.

    Many Marxists hold the incorrect view that capitalism will end in a 'terminal crisis'. This is "deeply misguided":
    > The problem with capitalism is not that it will collapse, but that it can sustain itself only through a self-contradictory dynamic that has pernicious social consequences.
    Given the capitalist measure of social wealth, the free time we gain thanks to technological efficiency cannot be recognized as a value in itself. Increased technological productivity _could_
    give everyone more free time to lead their lives, _if_ we pursued tehcnological innovation for the sake of emancipation rather than for the sake of profit.
    As long as we produce for the sake of profit, however, teh reduction of socially necessary labor time through technological innovation is caught in a vicious contradiction.
    We cannot pursue the reduction of socially necessary labor time as an end in itself, but only as a means of extracting surplus value from workers, which leads to unemployment, exploitation, and crises rather than emancipation.

    The measure of value under capitalism is self-contradictory. We need a political transformation of the economy which allows for a revaluation of value that recognizes socially available free time as an end in itself.

  # Hayek + Neoliberalism + Freedom

  _(Nobel Memorial Prize in Economic Sciences in 1974 / leading figure in formation of Chicago School — reigning neoliberal ideas of virtues of 'free market')_

  Friedrich Hayek argues that free markets are the perfect form of organizing an economic system that promotes freedom and democracy as its highest ideals.
  Prices allow for democratic (and decentralized / non-authoritarian) communication of needs and wants (supply and demand).

  However, "as a capitalist, I do not price my commodities in an attempt to 'communicate' how efficiently I actually can produce the commodity in question.
  If it is more profitable to restrain my production capacities and create an artificial scarcity, I will do that instead. Likewise, as a caspiatlist, I am not trying to obtain
  the right kind of 'information' about the actual needs of the population, sot hat my supply can meet the relevant demands. My aim is rather to manipulate
  both supply and demand for the sake of maximal gain, regardless of the social consequences."

  > Hayek ignores the undemocratic control of the production of wealth under capitalism...The priority of our economy is already determined for us—to labor for the sake of profit—since it is built into the capitalist definition of social wealth.

  While we may have the liberty to make literal choices (no one forces us to do anything), we do not have freedom to engage in fundamental decisions regarding how we live our lives.
  > Freedom requires the ability to participate in decisions regarding the form of life we are leading and not just the liberty to make choices. Moreever, since all forms of choice and decisions are social,
  we must be able to recognize the commitment to our freedom in the institutional forms of life in which we participate. This is why capitalism is an inherently alienating social institution.
  To lead our lives _for the sake of_ profit is self-contradictory and alienating, since the purpose of profit treats our lives as means rather than as ends in themselves.
 
# Principles of Democratic Socialism

> The assumed justification of liberal democracy is that it should allow each of us to lead a free life, and the assumed justification of capitalism is that it should progressively increase our social wealth.
The capitalist measure of value, however, contradicts and betrays these promises of emancipation. To make it possible for us to lead _actual_ free lives and progressively increase our actual social wealth,
we must puruse a practical revaluation of the measure of value that shapes our economy and our material production. The principele of such revaluation are the principles of democratic socialism.

Principles: 
1. **We measure our wealth — both individual and collective — in terms of _socially available free time_.**
    * Not mere _quantity_ of time, but also the _quality_ of our free time. Free time is not necessarily leisure time (could be writing a book, tasks related to a practical identity like being a parent)
    * As opposed to socially necessary labor time (capitalism)
    * Committed to reducing the quantity and improving the quality of our socially necessary labor time (through technological innovation.)
      * The reduction of socially necessary labor time is directly converts to socially available free time, with which we can choose what to do with our lives. The wealthier we are as individuals and the better off we are as a society.
    
      > To be sure, what should count as belonging to the realm of necessity (socially necessary labor time) and what should count as belonging
      to the realm of freedom (socially available free time) is not given and demands an ongoing democratic conversation. THe point, however, is that we
      are in a position to pursue such a conversation only if our measure of wealth reflects a commitment to socially available free time (democratic socialism)
      rather than a commitment to exploiting socially necessary labor tiem (capitalism). Only the revaluation of value can allow us to own our collective commitment to increasing
      the realm of freedom and decreasing the realm of necessity. The first principle of democratic socialism is therefore required for us to build and maintain a free society.

2. **The means  of production are collectively owned and cannot be used for the sake of profit.**
    * As long as the means of profit are privately owned and used for the sake of profit, the measure of our wealth
    is the amount of surplus value derived from the exploitation of living labor.
      > Only if we produce for the sake of socially available free time can we acknowledge the value generated by operations of nonliving technology. Owned collectively, we can engineer technolgoies aimed at generating as much surplus tiem as possible for everyone. We're not forced to convert surplus time into capital, but can freely pursue the reduction of the need for living labor in the process of production.
    * Collective ownership doesn't mean we can't have concrete private property like houses, computers, etc. It does mean that we can't 
    transform property into a commodity that can be bought and sold for profit. The recognition of a property as _yours_ does not mean you have the right to its abstract value as a commodity, only that it is valuable _to_ you and is useful in leading _your_ life.
    * Collective ownership does not mean we need top-down central planning. We are a part of a whole. An example: the Internet—we all have the material resources and socially necessary free time to build applications that are helpful and solve problems. Without profit incentive, applications will be made only to solve problems and needs / its social worth is demonstrated by its "concrete use value rather than [its] abstract exchange value."
      * In capitalist free-market, Internet monopolies form b/c of profit incentive. Private ownership of technological infrastructure makes it harder and harer to compete with companies that built the dominant network. A company has no inherent interest in offering us general Internet access at maximal speed. Technology is built to generate profit rather than to solve actual problems and meet actual needs.
    * Collective ownership doesn't mean we're subject to undemocratic control of our economic life; it makes possible actual economic democracy. Because we own the means of production collectively, we can decide democratically how and what we produce based on abilities we want to cultivate and needs we want to satisfy.
      *  In capitalism, we do already collectively determine which skills we cultivate and which needs we satisfy, but only for sake of generating profit for a corporation. Value is not "the value of our time of our lives but accumulated profit, so we can't see ourselves in the social whole of which we are a part. We can only see ourselves as self-interested, atomically constituted individuals, who have no inherent motivation to care for the common good."

3. **"From each according to his ability, to each according to his needs"**
    * We are collectively committed to producing the necessary means of subsistence, as well as the means and institutions for each person to pursue the activities that matter to them.
    * Distinguishing between the realm of necessity and the realm of freedom; under democratic socialism, we're committed to _sharing_ the socially necessary labor time, but also to _reducing_ it as much as possible through technology.
    * Based on our different abilities, we participate in the social labor necessary for the common good (e.g. food production, construction, health care, child care, education, science, political deliberation)
      * No one is _forced_ to participate in social labor, since everyone is provided for according to their needs.
      * We'll be motivated to participate in social labor b/c we can recognize that social production is for the sake of the common good and our own freedom to lead our life.
        * Capitalist wage labor is inherently alienating b/c we're not part of deciding the purpose of what we're producing and our work is done for the sake of profit that's an end in itself rather than a means to our freedom.
        * For example, participating in garbage removal for the neighborhood can be in realm of freedom b/c the work goes toward reducing socially necessary labor time and increasing socially available free time. Unlike under captialism, we can make sense of why we're doing what we're doing.
    `
            // * [random unrelated thoughts on tolstoy] Hägglund pretty much brings up tolstoy for no good reason except to hate on Max Weber, which is funny,
            // but since he has been brought up, i am v interested in tolstoy b/c he basically admits that God is not real, 
            // but that life is not worth living without Him (aka one should rather suicide), and so he chooses to live — with faith. ‍am 
            // fan of tolstoy for conscious blue-pill taking // more generally, rather than lie to ourselves, it's better to admit that we must make the best choice
            // given the contradictions of life, and own it. (i'm extrapolating of course since tolstoy is literally choosing to lie to himself (whilst knowing that he is lying so is it a lie...))
            ,
          },
        },
        {
          date: new Date('2021-03-15'),
          endDate: new Date('2021-03-16'),
          item: {
            type: itemType.FILM,
            title: "Olive Kitteridge",
            year: 2014,
            director: 'Lisa Cholodenko',
            frames: [
              'olive-kitteridge-01',
              'olive-kitteridge-02',
            ],
            text: '<div style="text-align:right;"><sub>(Four-part HBO miniseries)</sub></div>',
          },
        },
        {
          date: new Date('2021-03-12'),
          item: {
            type: itemType.FILM,
            title: "It's a Sin",
            year: 2021,
            director: 'Peter Hoar',
            frames: [
            ],
            text: `
<div style="text-align:right;"><sub>(Five-part miniseries)
<br />(Written + created by Russell T Davies)</sub></div>`,
          },
        },
        {
          date: new Date('2021-02-28'),
          showCollapseText: true,
          item: {
            type: itemType.FILM,
            title: 'Minari',
            year: 2020,
            director: 'Lee Isaac Chung',
            frames: [
              'minari-02',
              'minari-04',
              'minari-05',
              'minari-03',
            ],
            text: `it's not this film's fault that it's overrated. white hollywood is forever paternalistic and infuriating.`,
          },
        },

        {
          date: new Date('2021-02-20'),
          item: {
            type: itemType.FILM,
            title: 'Nomadland',
            year: 2020,
            director: 'Chloé Zhao',
            frames: [
              'nomadland-02',
              'nomadland-01',
              'nomadland-05',
              'nomadland-03',
            ],
          },
        },
        {
          date: new Date('2021-02-19'),
          item: {
            type: itemType.FILM,
            title: 'Happy End',
            year: 2017,
            director: 'Michael Haneke',
            frames: [
              'happy-end-01',
              'happy-end-02',
              'happy-end-03',
              'happy-end-04',
            ],
          },
        },
        {
          date: new Date('2021-02-16'),
          item: {
            type: itemType.FILM,
            title: 'Abuse of Weakness',
            year: 2013,
            director: 'Catherine Breillat',
            frames: [
              'abuse-weakness-03',
              'abuse-weakness-02',
              'abuse-weakness-04',
              'abuse-weakness-01',
            ],
          },
        },
        {
          date: new Date('2021-02-01'),
          endDate: new Date('2021-02-04'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Death Valley National Park',
            frames: [
              '2021-02-01-death-valley/2021-death-valley-44',
              '2021-02-01-death-valley/2021-death-valley-43',
              '2021-02-01-death-valley/2021-death-valley-42',
              '2021-02-01-death-valley/2021-death-valley-41',
              '2021-02-01-death-valley/2021-death-valley-40',
              '2021-02-01-death-valley/2021-death-valley-39',
              '2021-02-01-death-valley/2021-death-valley-38',
              '2021-02-01-death-valley/2021-death-valley-37',
              '2021-02-01-death-valley/2021-death-valley-36',
              '2021-02-01-death-valley/2021-death-valley-35',
              '2021-02-01-death-valley/2021-death-valley-33',
              '2021-02-01-death-valley/2021-death-valley-32',
              '2021-02-01-death-valley/2021-death-valley-31',
              '2021-02-01-death-valley/2021-death-valley-30',
              '2021-02-01-death-valley/2021-death-valley-29',
              '2021-02-01-death-valley/2021-death-valley-28',
              '2021-02-01-death-valley/2021-death-valley-27',
              '2021-02-01-death-valley/2021-death-valley-25',
              '2021-02-01-death-valley/2021-death-valley-24',
              '2021-02-01-death-valley/2021-death-valley-23',
              '2021-02-01-death-valley/2021-death-valley-22',
              '2021-02-01-death-valley/2021-death-valley-21',
              '2021-02-01-death-valley/2021-death-valley-20',
              '2021-02-01-death-valley/2021-death-valley-19',
              '2021-02-01-death-valley/2021-death-valley-18',
              '2021-02-01-death-valley/2021-death-valley-17',
              '2021-02-01-death-valley/2021-death-valley-15',
              '2021-02-01-death-valley/2021-death-valley-14',
              '2021-02-01-death-valley/2021-death-valley-13',
              '2021-02-01-death-valley/2021-death-valley-12',
              '2021-02-01-death-valley/2021-death-valley-11',
              '2021-02-01-death-valley/2021-death-valley-10',
              '2021-02-01-death-valley/2021-death-valley-09',
              '2021-02-01-death-valley/2021-death-valley-08',
              '2021-02-01-death-valley/2021-death-valley-05',
              '2021-02-01-death-valley/2021-death-valley-04',
              '2021-02-01-death-valley/2021-death-valley-03',
              '2021-02-01-death-valley/2021-death-valley-02',
            ],
          },
        },
        {
          date: new Date('2021-01-28'),
          item: {
            type: itemType.FILM,
            title: 'Paterson',
            year: 2016,
            director: 'Jim Jarmusch',
          },
        },
        {
          date: new Date('2021-01-27'),
          item: {
            type: itemType.FILM,
            title: 'The White Tiger',
            year: 2021,
            director: 'Ramin Bahrani',
          },
        },
        {
          date: new Date('2021-01-25'),
          filterTags: ['philosophy'],
          item: {
            type: itemType.BOOK,
            title: 'The Human Condition',
            year: 1958,
            author: 'Hannah Arendt',
            text: `<div style="text-align:right;"><a href="https://www.anticapitalismbookclub.com/snacks/internet-polis/">the internet is the worst polis</a></div>`,
          },
        },
        {
          date: new Date('2021-01-25'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['philosophy'],
          item: {
            type: itemType.POST,
            title: "(the human condition) the vita activa and the modern age",
            text: `
_Hannah Arendt, "The Human Condition" (1958)_</br>
_Chapter 6 — The_ Vita Activa _and the Modern Age_
* Christianity promoted mortal life to the position of immortality; brought "hope beyond hope"
  * disastrous for the esteem and dignity of politics
  * caring about the world (or aspiring toward _worldly immortality_) was vainglory; "such fame as the world could bestow upon man was an illusion,
    since the world was even more perishable than man, and striving for worldy immortality was meaningless, since life itself was immortal." (p314)
  * "the immortality of individual life became the central creed of Western mankind, that is, only with the rise of Christianity, did life on earth
  also become the highest good of man." Eternal life begins with life on earth — without this life which terminates w/ death, there cannot be eternal life. (p316)
* Process of secularization and modern loss of faith "deprived individual life of its immortality"
  > Modern man, when he lost the certainty of a world to come, was thrown back upon himself and not upon this world; far
  from believing that the world might be potentially immortal, eh was not even sure that it was real... modern man at any rate did not
  again gain this world when he lost the other world, and he did not gain life...he was thrust back upon it, thrown into the closed inwardness
  of introspection, where the highest he could experience were
  the empty processes of reckoning of the mind, its play with itself. (p320)
* Action is almost exclusively understood in terms of making and fabricating
* Meanwhile, we've made labor so easy with tools that elimination of labor can hardly be seen as utopian anymore.
* "For even now, laboring is too lofty, to ambitious a word for what we are doing, or think we are doing, in the world we have come to live in." (p322)
  > The last stage of the laboring society, the society of jobholders, demands of its members a sheer automatic functioning, as though individual life had actually been submerged in the over-all life process of the species and the only active decision still required of the individual were to let go, so to speak, to abandon his individuality, the still individually sensed pain and trouble of living, and acquiesce in a dazed, “tranquilized,” functional type of behavior.

  > It is quite conceivable that the modern age—which began with such an unprecedented and promising outburst of human activity—may end in the deadliest, most sterile passivity history has ever known.

<sub>_Arendt page numbers from "The Human Condition", 2e, 2018_</sub>
`
          },
        },
        {
          date: new Date('2021-01-24'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['philosophy'],
          item: {
            type: itemType.POST,
            title: "(the human condition) action",
            text: `
_Hannah Arendt, "The Human Condition" (1958)_</br>
_Chapter 5 — Action_
* The Problem: **the consequences of action are boundless and unpredictable**
* Greek solution to this was to create the _polis_, a place to distinguish yourself; offered a remedy for the futility of action and speech
  * "The organization of the _polis_, physically secured by the wall around the city...assures the mortal actor that his passing
  existence and fleeting greatness will never lack the reality that comes from being seen, being heard, and generally, appearing before an audience of
  follow men..." (198)
  * Not everyone has access to it. To deprived of it means to be deprived of reality. And no one can live in it all the time. (199)
* substitution of making and fabrication for acting
  * can be done in private — allows shelter from action's calamities (220)
* Plato introduced division between "those who know and do not act and those who act and do not know"; 
  dividing line between rulers and the ruled. The purpose is to eliminate action from human affairs.
  > The commonplace notion already to be found in Plato and Aristotle that every political community consists of those who rule and those 
  who are ruled (on which assumption in turn are based the current definitions of forms of government rule by one or monarchy, 
  rule by few or oligarchy, rule by many or democracy) **rests on a suspicion of action rather than on a contempt for men, and arose from the earnest desire to find a substitute for action rather than from any irresponsible or tyrannical will to power**.

* Solution that Arendt offers is forgiveness — releasing us from the consequences of action so that we can be free to act at all; to mitigate irreversibility of action
  * Alternative to forgiveness is punishment
* Other solution taht Arendt offers is promises; to mitigate unpreditability of action

<sub>_Arendt page numbers from "The Human Condition", 2e, 2018_</sub>
`
          },
        },
        {
          date: new Date('2021-01-24'),
          item: {
            type: itemType.FILM,
            title: 'Boyhood',
            year: 2014,
            director: 'Richard Linklater',
          },
        },
        {
          date: new Date('2021-01-23'),
          item: {
            type: itemType.FILM,
            title: 'Your Name Engraved Herein',
            year: 2020,
            director: 'Patrick Kuang-Hui Liu',
            frames: [
              'your-name-engraved-herein-02',
              'your-name-engraved-herein-01',
              'your-name-engraved-herein-04',
              'your-name-engraved-herein-03',
              'your-name-engraved-herein-06',
              'your-name-engraved-herein-05',
            ],
          },
        },
        {
          date: new Date('2021-01-19'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['philosophy'],
          item: {
            type: itemType.POST,
            title: "(the human condition) work / capitalism / art",
            text: `
# The Durability of the World
_Hannah Arendt, "The Human Condition" (1958)_</br>
_Chapter 4.18 — Work → The Durability of the World_

The work of our hands "fabricates the sheer unending variety of things whose sum total constitutes the human artifice."
* Mostly, but not exclusively, objects for use; "they possess the durability Locke needed for the establishment of
property, the 'value' Adam Smith needed for the exchange market,
and they bear testimony to productivity, which Marx believed to be the test of human nature." (p136)
* Things that last (unlike food that is consumed), but not forever, like a chair, which disintegrates
with use over a long period of time and eventually goes back into nature.

# The Exchange Market
_Chapter 4.22 — Work → The Exchange Market_

* _homo faber_ (unlike _animal laborans_) can inhabit the public realm —
the public realm is the exchange market where the _homo faber_ can show the products
he's made and receive the esteem which is due him.
  > the _homo faber_, the builder of the world and the producer of things, can find his proper
relationship to other people only by exchanging his products with theirs, because these products themselves
are always produced in isolation." (p160)

* a _laboring society_ judges men by the function they perform in the labor process.

   a _manufacturing society_ judges men "not as persons but as producers, according to the quality of
their products"; Marx's self-alienation in labor.(p162)

   in _commercial society (capitalism)_:
  > when _homo faber_ comes out of his isolation,
he appears as a merchant and trader and establishes the exchange market in this capacity. This market must exist
prior to the rise of a manufacturing class, which then **produces exclusively for the market, that is,
produces exchange objects rather than use things.** In this process from isolated craftsmanship to manufacturing for
the exchange market, the finished end product changes its quality somewhat but not altogether.
Durability, which alone determines if a thing can exist as a thing and endure in the world as a
distinct entity, remains the supreme criterion, although it no longer makes a thing fit for use but
rather fit to 'be stored up beforehand' for future exchange. (p163)

No 'absolute' value exists on the exchange market; once a thing transforms into a commodity, it loses its intrinsic worth.
> Universal relativity, that a thing exists only in relation to other things, and loss of intrinsic
worth, that nothing any longer possesses an "objective" value independent of the
ever-changing estimations of supply and demand, are inherent in the very concept of value itself. (p166)

This was the chief problem in the new science of economics b/c:
> _homo faber_, whose whole activity is determined by the constant
use of yardsticks, measurements, rules, and standards, could not bear the loss of 'absolute' standards or yardsticks.
For money, which obviously serves as the common denominator for a variety of things so that they can be
exchanged for each other, by no means possesses the independent and objective existence, transcending all uses and surviving
all manipulation, that a yardstick or any other measurement posseses with regard to the things it is supposed to
measure and to the men who handle them. (p166)

# The Work of Art
_Chapter 4.23 — Work → The Permanence of the World and the Work of Art_

Art is unique b/c it serves no utility whatsoever. And if it enters the exchange
market, it is arbitrarily priced.

> Even if the historical origin of art were of an exclusively religious or mythological character,
the fact is that art has survived gloriously its severance from religion, magic, and myth.

It doesn't corrode like regular objects because we don't use them; "because of their outstanding permanence,
works of art are the most intensely wordly of all tangible things" (p167)

> something immortal achieved by mortal hands, has become tangibly present (p168)

Ordinary things are also art:
> To be sure, an ordinary use object is not and should not be intended to be beautiful; yet whatever has
a shape at all and is seen cannot help being either beautiful, ugly, or something in-between. (p172)

<sub>_Arendt page numbers from "The Human Condition", 2e, 2018_</sub>
`
          },
        },
        {
          date: new Date('2021-01-17'),
          item: {
            type: itemType.FILM,
            title: 'Scenes from a Marriage',
            year: 1973,
            director: 'Ingmar Bergman',
            frames: [
              'scenes-from-marriage-01',
              'scenes-from-marriage-02',
            ],
          },
        },
        {
          date: new Date('2021-01-17'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['philosophy'],
          item: {
            type: itemType.POST,
            title: "(the human condition) labor / joy",
            text: `
_Hannah Arendt, "The Human Condition" (1958)_</br>
_Chapter 3.14 — Labor → Labor and Fertility_

Terms:<br>
* _vita activa_: designates three fundamental human activities: (1) labor, (2) work, (3) action.
* _labor_: activity which corresponds to the biological process of the human body; required for staying alive (common to all living things).
* _work_: activity that corresponds to durable things we make; creates an "artificial" world of things.

Arendt points out that while Marx emphasizes that _labor power_—"as the specifically human mode of
the life force which is capable of creating 'surplus'" (p108)—is what makes humans distinctly human,
his revolutionary ideal is to eliminate labor altogether.

> the revolution, according to Marx, has not the task of emancipating the laboring classes but of
emancipating man from labor; (p104)


>in all stages of his work he defines man as an _animal laborans_ and then leads him into a society
in which this greatest and most human power is no longer necessary. We are left with the rather distressing
alternative between productive slavery and unproductive freedom. (p105)

Arendt on joy / nature / the cycle of labor and rest + regeneration:

> The “blessing or the joy” of labor is the human way to experience the sheer bliss of being alive which we share with all
living creatures, and it is even the only way men, too, can remain and swing contentedly in nature’s prescribed cycle,
toiling and resting, laboring and consuming, with the same happy and purposeless regularity with which day and night and
life and death follow each other. (p106)

It is the ruining of this cycle [in capitalism] that creates misery, not labor itself:
> There is no lasting happiness outside the prescribed cycle of painful exhaustion and pleasurable regeneration, and whatever
throws this cycle out of balance—poverty and misery where exhaustion is followed by wretchedness instead of regeneration, or great
riches and an entirely effortless life where boredom takes the place of exhaustion and where the mills of necessity, of
consumption and digestion, grind an impotent human body mercilessly and barrenly to death—ruins the elemental happiness that comes from being alive. (p108)

Note on humans as laborers for accumulation of wealth: We can experience joy from
the process of laboring, but "labor's products do not become more durable by their abundance and cannot be 'heaped up' and
stored away...;on the contrary, they are only too likely to disappear in the process of appropration to
'perish uselessly' if they are not consumed 'before they spoil.'" (p109)

<sub>_Arendt page numbers from "The Human Condition", 2e, 2018_</sub>
`
          },
        },
        {
          date: new Date('2021-01-16'),
          item: {
            type: itemType.FILM,
            title: 'Smiles of a Summer Night',
            year: 1955,
            director: 'Ingmar Bergman',
            frames: [
            ],
          },
        },
        {
          date: new Date('2021-01-16'),
          showCollapseText: true,
          useSerif: true,
          filterTags: ['philosophy'],
          item: {
            type: itemType.POST,
            title: "(the human condition) the concept of goodness",
            text: `
# The Concept of Goodness
_Hannah Arendt, "The Human Condition" (1958)_</br>
_Chapter 2.10 — The Public and Private Realm → The Location of Human Activities_


* Goodness can only be performed in private. Once it is public — if it's recognized by others, or _even ourselves_,
it becomes performative and no longer good.

* Living a life that is 'good', and therefore completely separate from human witness, is inherently
 lonely. Unbearably lonely; it is only made bearable
by the witness of God — the only possible witness for goodness who does not destroy it.

* A paradox: "the whole story of Jesus seems to testify how love for goodness
arises out of the insight that no man can be good." (75)

→ It is impossible to be 'good'.

→ Further, doing good is destructive — see Machiavelli on "how not to be good", _The Prince_, Ch. 15.

"Machiavelli’s criterion for political action was glory...and badness can no more shine in glory than goodness.
Therefore all methods by which “one may indeed gain power, but not glory” are bad. Badness that comes out of hiding is
impudent and directly destroys the common world; goodness that comes out of hiding and assumes a public role is no
longer good, but corrupt in its own terms and will carry its own corruption wherever it goes." (77)

Machiavelli thought the reformed Church in Italy was dangerous b/c it taught people to be 'good' rather than
to resist evil; "and thus wicked rulers do as much evil as they please":
> Now with regard to religions we shall see that revivals are equally necessary, and the best proof
> of this is furnished by our own, which would have been entirely lost had it not been brought back
> to its pristine principles and purity by Saint Francis and Saint Dominic; for by their voluntary
> poverty and the example of the life of Christ, they revived the sentiment of religion in the hearts
> of men, where it had become almost extinct.
>
> The new orders which they established were so severe
> and powerful that they became the means of saving religion from being destroyed by the licentiousness
> of the prelates and heads of the Church.
>
> **They continued themselves to live in poverty; and by means
> of confessions and preachings they obtained so much influence with the people, that they were able to
> make them understand that it was wicked even to speak ill of wicked rulers, and that it was proper to
> render them obedience and to leave the punishment of their errors to God. And thus these wicked rulers
> do as much evil as they please, because they do not fear a punishment which they do not see nor believe.**
>
> This revival of religion then by Saint Francis and Saint Dominic has preserved it and maintains it to this day.


<div class="markdown-quotee">— Niccolò Machiavelli, <em>Discourses on Livy</em> (1513), Book III., Ch. 1<div>

<sub>_Arendt page numbers from "The Human Condition", 2e, 2018_</sub>
`
          },
        },
        {
          date: new Date('2021-01-15'),
          item: {
            type: itemType.FILM,
            title: 'Blackbird',
            year: 2019,
            director: 'Roger Michell',
          },
        },
        {
          date: new Date('2021-01-11'),
          item: {
            type: itemType.FILM,
            title: 'Babyteeth',
            year: 2020,
            director: 'Shannon Murphy',
            frames: [
              'babyteeth-02',
              'babyteeth-01',
            ],
          },
        },
        {
          date: new Date('2021-01-10'),
          item: {
            type: itemType.FILM,
            title: 'Pieces of a Woman',
            year: 2020,
            director: 'Kornél Mundruczó',
            frames: [
            ],
          },
        },
        {
          date: new Date('2021-01-09'),
          item: {
            type: itemType.BOOK,
            title: 'Song of Solomon',
            year: 1977,
            author: 'Toni Morrison',
            text: `
> “Milkman, if Kennedy got drunk and bored and was sitting around a potbellied stove
> in Mississippi, he might join a lynching party just for the hell of it. Under those
> circumstances his unnaturalness would surface. But I know I wouldn't join one
> no matter how drunk I was or how bored, and I know you wouldn't either, nor any
> black man I know or ever heard tell of. Ever. In any world, at any time, just get up
> and go find somebody white to slice up. But they _can_ do it. And they don't
> even do it for profit, which is why they do most things. They do it for fun.”

<div class="markdown-quotee">— Toni Morrison, <em>Song of Solomon</em>, p156<div>
        `,
          },
        },
        {
          date: new Date('2021-01-09'),
          item: {
            type: itemType.FILM,
            title: 'Illusions',
            year: 1982,
            director: 'Julie Dash',
            frames: [
              'illusions-01',
              'illusions-02',
            ],
          },
        },
        {
          date: new Date('2021-01-07'),
          item: {
            type: itemType.FILM,
            title: 'The Piano Teacher',
            year: 2001,
            director: 'Michael Haneke',
            frames: [
              'piano-teacher-02',
              'piano-teacher-01',
            ],
          },
        },
        {
          date: new Date('2021-01-06'),
          item: {
            type: itemType.BOOK,
            title: 'The Cancer Journals',
            year: 1980,
            author: 'Audre Lorde',
            text: `
>Death, on the other hand, is the final silence. And that might be coming quickly, now, without regard for whether I had ever spoken what needed to be said, or had only betrayed myself into small silences, while I planned someday to speak, or waited for someone else's words.
<div class="markdown-quotee">— Audre Lorde, <em>The Cancer Journals</em> <div>
`,
          },
        },
        {
          date: new Date('2021-01-06'),
          item: {
            type: itemType.FILM,
            title: '35 Shots of Rum',
            year: 2008,
            director: 'Claire Denis',
            frames: [
              '35-rhums-02',
              '35-rhums-01',
            ],
          },
        },
        {
          date: new Date('2021-01-05'),
          item: {
            type: itemType.FILM,
            title: 'Fat Girl',
            year: 2001,
            director: 'Catherine Breillat',
            frames: [
              'fat-girl-01',
              'fat-girl-02',
            ],
            //           text: `
            // notebook:
            // * love the sandwich eating long take
            // * Anaïs Reboux's execution of sad song lyrics
            // * last line v good
            // * Breillat is serious. am successfully a fan
            // > Shattering taboos with her unflinching, often shocking explorations of female sexuality and pleasure, Catherine Breillat emerged as one of the major voices of the New French Extremity, a movement defined by its transgressive focus on the corporeal realms of eroticism and violence. The two are inextricably linked in her daring body of work, which encompasses the controversial exploration of female desire ROMANCE, the provocative coming-of-age portrait FAT GIRL, revisionist fairy tales such as BLUEBEARD and SLEEPING BEAUTY, and overtly autobiographical works like ABUSE OF WEAKNESS. In all, Breillat fearlessly delves into the intricacies of eroticism, power, and sexual politics, forcing viewers to confront that which makes them most uncomfortable and radically redefining the depiction of the female body on-screen.

            // — ["Directed by Catherine Breillat"](https://www.criterionchannel.com/directed-by-catherine-breillat)
            //           `
          },
        },
        {
          date: new Date('2021-01-01'),
          item: {
            type: itemType.POST,
            title: 'anti-(social media) social media',
            text: `
I think we all deserve to be narcisstic teenagers sometimes. I vote for bringing back microblogs and
places where we can brag about the songs we like and the books we read and the films we hated. A place to process our thoughts
and feelings not-in-isolation but also not-in-broadcast-form where all of our self-worth lies in the frictionless placement of the 'like' button.

> I am unavailable because I am playing a computer game that takes up the entire screen.
          `,
          },
        },
        {
          date: new Date('2021-01-01'),
          item: {
            type: itemType.FILM,
            title: 'Code Unknown',
            year: 2000,
            director: 'Michael Haneke',
            frames: [
              'code-unknown-01',
              'code-unknown-02',
            ],
            //           text: `
            // notebook:
            // * long takes / wides where the camera is stationary for a long time while the characters move in and
            // out of frame. makes the place feel real, and therefore the characters too.

            //   e.g., (1) pictured frame on the farm — while camera remains in this position,
            //   two characters go in and out of the left door (separately). kid leaves right and then enters back in, through frame and out again.
            //   (2) in the apartment after georges gets back — camera remains in the outer room as he goes into the
            //   bathroom to wash his hands out of frame — we only hear the sound of the water running.
            // * long takes of characters doing regular things

            //   e.g., ironing several shirts while watching the news.
            //   random spatial cleverness: the TV is visible in the reflection on the far right while binoche's character
            //   interacts with it by looking up toward the camera out of frame.
            // * in all of these, the sound is important
            //           `,
          },
        },
      ],
    }, {
      year: 2020,
      goals: {
        books: 15,
      },
      events: [
        {
          date: new Date('2020-12-31'),
          item: {
            type: itemType.POST,
            title: 'Bunker Day 290',
          },
        },
        {
          date: new Date('2020-12-28'),
          item: {
            type: itemType.POST,
            title: 'Moonlander Batch 5',
            frames: [
              '2020-12-28-moonlander/moon-01',
              '2020-12-28-moonlander/moon-02',
              '2020-12-28-moonlander/moon-03',
              '2020-12-28-moonlander/moon-04',
              '2020-12-28-moonlander/moon-05',
              '2020-12-28-moonlander/moon-06',
              '2020-12-28-moonlander/moon-07',
            ],
          },
        },
        {
          date: new Date('2020-12-25'),
          item: {
            type: itemType.FILM,
            title: 'Kajillionaire',
            year: 2020,
            director: 'Miranda July',
            frames: [
              'kajillionaire-01',
              'kajillionaire-02',
            ],
          },
        },
        {
          date: new Date('2020-12-23'),
          item: {
            type: itemType.FILM,
            title: 'Education',
            year: 2020,
            director: 'Steve McQueen',
            frames: [],
          },
        },
        {
          date: new Date('2020-12-19'),
          item: {
            type: itemType.FILM,
            title: 'Ma Rainey\'s Black Bottom',
            year: 2020,
            director: 'George Wolfe',
            marker: '+',
            frames: [
              'ma-rainey-02',
              'ma-rainey-01',
            ],
            text: `<div style="text-align:right;">Viola Davis is life. Why do we live but for Viola Davis.</div>`,
          },
        },
        {
          date: new Date('2020-12-14'),
          item: {
            type: itemType.FILM,
            title: 'Alex Wheatle',
            year: 2020,
            director: 'Steve McQueen',
            frames: [
              'alex-wheatle-02',
              'alex-wheatle-01',
            ],
          },
        },
        {
          date: new Date('2020-12-12'),
          item: {
            type: itemType.FILM,
            title: 'Red, White and Blue',
            year: 2020,
            director: 'Steve McQueen',
            frames: [
              'red-white-blue-02',
              'red-white-blue-01',
            ],
          },
        },
        {
          date: new Date('2020-12-07'),
          item: {
            type: itemType.FILM,
            title: 'Mulan',
            year: 2020,
            director: 'Niki Caro',
            frames: [],
          },
        },
        {
          date: new Date('2020-12-06'),
          item: {
            type: itemType.FILM,
            title: 'Sorry We Missed You',
            year: 2020,
            director: 'Ken Loach',
            frames: [],
          },
        },
        {
          date: new Date('2020-11-29'),
          item: {
            type: itemType.FILM,
            title: 'Lovers Rock',
            year: 2020,
            director: 'Steve McQueen',
            frames: [
              'lovers-rock-02',
              'lovers-rock-01',
            ],
          },
        },
        {
          date: new Date('2020-11-24'),
          item: {
            type: itemType.FILM,
            title: 'Hillbilly Elegy',
            year: 2020,
            director: 'Ron Howard',
            frames: [],
            //           text: `
            // _rant alert_. critics are the worst. this film has [26% tomatoes](https://www.rottentomatoes.com/m/hillbilly_elegy)
            // for having bad politics (about poor white people) while **miss juneteenth**, made in the same year,
            // but about black people, has the exact same bootstrap politics and has [100% tomatoes](https://www.rottentomatoes.com/m/miss_juneteenth).
            // alice wu's **the half of it**, also made this year, is entirely about catfishing and non-consensual teen romance
            // and has [97% tomatoes](https://www.rottentomatoes.com/m/the_half_of_it). people of color deserve good things too!

            // the politics in _hillbilly_ are completely aligned with that of the liberal mainstream, and while, yes,
            // undoubtedly  terrible, are way better than the politics in _miss juneteenth_ or _the half of it_.
            // (not to mention that the acting and production value of hillbilly was orders of magnitude better.)

            // why is the bar so exceptionally low for poc films? white hollywood does not respect us.

            // or, even worse, white hollywood believes that _only_ black people should pull themselves up by the bootstraps??
            // only queer asian girls should be forced to take their clothes off in the magical hot tub?? okay okay, i'm going to just give
            // them the benefit of the doubt and choose to believe that they just don't think we can make good films.

            // (where were all these anti-bootstraps west virginian film critics when it was time to vote against the centrist democrats?)

            // end rant! love blogs.
            // `,
          },
        },
        {
          date: new Date('2020-11-22'),
          item: {
            type: itemType.FILM,
            title: 'Mangrove',
            year: 2020,
            director: 'Steve McQueen',
            frames: [],
          },
        },
        {
          date: new Date('2020-11-21'),
          filterTags: ['climbing'],
          item: {
            type: itemType.POST,
            title: '[climbing wall] eggy construction',
            frames: [
              '2020-11-21-eggy-construction/climb-01',
              '2020-11-21-eggy-construction/climb-02',
              '2020-11-21-eggy-construction/climb-03',
              '2020-11-21-eggy-construction/climb-04',
              '2020-11-21-eggy-construction/climb-05',
              '2020-11-21-eggy-construction/climb-06',
              '2020-11-21-eggy-construction/climb-07',
              '2020-11-21-eggy-construction/climb-08',
              '2020-11-21-eggy-construction/climb-09',
              '2020-11-21-eggy-construction/climb-10',
              '2020-11-21-eggy-construction/climb-11',
              '2020-11-21-eggy-construction/climb-12',
              '2020-11-21-eggy-construction/climb-13',
              '2020-11-21-eggy-construction/climb-14',
              '2020-11-21-eggy-construction/climb-15',
              '2020-11-21-eggy-construction/climb-16',
            ],
          },
        },
        {
          date: new Date('2020-11-01'),
          item: {
            type: itemType.FILM,
            title: 'Driveways',
            year: 2019,
            director: 'Andrew Ahn',
            text: `<div style="text-align:right;">plug for Andrew Ahn's <b>Spa Night</b> (2016)</div>`,
          },
        },
        {
          date: new Date('2020-10-30'),
          item: {
            type: itemType.POST,
            title: 'eggy orchestra',
            frames: [
              '2020-10-30-eggy-orchestra/orchestra-01',
              '2020-10-30-eggy-orchestra/orchestra-02',
              '2020-10-30-eggy-orchestra/orchestra-03',
              '2020-10-30-eggy-orchestra/orchestra-04',
              '2020-10-30-eggy-orchestra/orchestra-08',
              '2020-10-30-eggy-orchestra/orchestra-05',
              '2020-10-30-eggy-orchestra/orchestra-06',
              '2020-10-30-eggy-orchestra/orchestra-07',
            ],
          },
        },
        {
          date: new Date('2020-10-26'),
          item: {
            type: itemType.BOOK,
            title: 'Color of Violence: The INCITE! Anthology',
            year: 2006,
            author: 'INCITE! Women of Color Against Violence',
            text: `
<div style="text-align:right;">
  <a href="http://www.oregoncampuscompact.org/uploads/1/3/0/4/13042698/undoing_racism_-_three_pillars_-__andrea_smith_.pdf">Heteropatriarchy and the Three Pillars of White Supremacy: Rethinking Women of Color Organizing</a>
</div>`,
          },
        },
        {
          date: new Date('2020-10-23'),
          item: {
            type: itemType.BOOK,
            title: 'The Generalissimo: Chiang Kai-shek and the Struggle for Modern China',
            year: 2009,
            author: 'Jay Taylor',
          },
        },
        {
          date: new Date('2020-10-20'),
          item: {
            type: itemType.FILM,
            title: 'The Trial of the Chicago 7',
            year: 2020,
            director: 'Aaron Sorkin',
            marker: '+',
            frames: [],
          },
        },
        {
          date: new Date('2020-10-15'),
          endDate: new Date('2020-10-18'),
          filterTags: ['outside', 'climbing'],
          item: {
            type: itemType.POST,
            title: 'Joshua Tree National Park',
            text: 'nearly all the photos on this website are taken by m and stolen by me. the joshuas.',
            frames: [
              '2020-10-15-joshua-tree/joshua-tree-01',
              '2020-10-15-joshua-tree/joshua-tree-02',
              '2020-10-15-joshua-tree/joshua-tree-03',
              '2020-10-15-joshua-tree/joshua-tree-04',
              '2020-10-15-joshua-tree/joshua-tree-05',
              '2020-10-15-joshua-tree/joshua-tree-06',
              '2020-10-15-joshua-tree/joshua-tree-07',
              '2020-10-15-joshua-tree/joshua-tree-08',
              '2020-10-15-joshua-tree/joshua-tree-09',
              '2020-10-15-joshua-tree/joshua-tree-10',
              '2020-10-15-joshua-tree/joshua-tree-11',
              '2020-10-15-joshua-tree/joshua-tree-12',
              '2020-10-15-joshua-tree/joshua-tree-13',
              '2020-10-15-joshua-tree/joshua-tree-14',
              '2020-10-15-joshua-tree/joshua-tree-15',
              '2020-10-15-joshua-tree/joshua-tree-16',
              '2020-10-15-joshua-tree/joshua-tree-17',
              '2020-10-15-joshua-tree/joshua-tree-18',
              '2020-10-15-joshua-tree/joshua-tree-19',
              '2020-10-15-joshua-tree/joshua-tree-20',
              '2020-10-15-joshua-tree/joshua-tree-21',
              '2020-10-15-joshua-tree/joshua-tree-22',
              '2020-10-15-joshua-tree/joshua-tree-23',
              '2020-10-15-joshua-tree/joshua-tree-24',
              '2020-10-15-joshua-tree/joshua-tree-25',
              '2020-10-15-joshua-tree/joshua-tree-26',
              '2020-10-15-joshua-tree/joshua-tree-27',
            ],
          },
        },
        {
          date: new Date('2020-10-14'),
          item: {
            type: itemType.BOOK,
            title: 'Dying for an iPhone: Apple, Foxconn, and The Lives of China\'s Workers',
            year: 2020,
            author: 'Jenny Chan, Mark Selden, Pun Ngai',
          },
        },
        {
          date: new Date('2020-10-12'),
          item: {
            type: itemType.BOOK,
            title: 'Rescuing the Bible from Fundamentalism',
            year: 1992,
            author: 'John Shelby Spong',
          },
        },
        {
          date: new Date('2020-10-11'),
          item: {
            type: itemType.BOOK,
            title: 'St. Paul',
            year: 1936,
            author: 'Arthur Darby Nock',
            //           text: `
            // context: i was researching for an essay on the queerness of paul — because paul
            // was obviously a closeted gay boy whose personal shame shaped the entire protestant doctrine,
            // thus condemning us all (those influenced by the West and its colonial tendrils) to a society
            // and human condition that is obsessed with the concept of sin and sexual shame. 🤷🏻‍♂️ sorry, mom.

            // (i gave up on this endeavor for now b/c i'm not sure how much i care. and paul is a very self-righteous boy
            // and i'm not sure he deserves more of my time after all these years of torment.)

            // shame begets shame. we can break the cycles if we try.

            // (be gay. do crimes.)
            // `,
          },
        },
        {
          date: new Date('2020-09-29'),
          item: {
            type: itemType.BOOK,
            title: 'Demons',
            year: 1872,
            author: 'Fyodor Dostoevsky',
          },
        },
        {
          date: new Date('2020-09-28'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Montara State Beach',
            frames: [
              '2020-09-28-montara-state-beach/montara-01',
              '2020-09-28-montara-state-beach/montara-02',
            ],
          },
        },
        {
          date: new Date('2020-09-09'),
          item: {
            type: itemType.POST,
            title: 'California 🔥: Orange Sky Day',
            frames: [
              '2020-09-09-orange-fire/orange-01',
              '2020-09-09-orange-fire/orange-02',
            ],
          },
        },
        {
          date: new Date('2020-09-04'),
          item: {
            type: itemType.FILM,
            title: 'I\'m Thinking of Ending Things',
            year: 2020,
            director: 'Charlie Kaufman',
          },
        },
        {
          date: new Date('2020-09-01'),
          item: {
            type: itemType.FILM,
            title: 'Apollo 11',
            year: 2019,
            director: 'Todd Douglas Miller',
          },
        },
        {
          date: new Date('2020-08-30'),
          item: {
            type: itemType.FILM,
            title: 'The Trader',
            year: 2018,
            director: 'Tamta Gabrichidze',
            frames: [
              'trader-01',
              'trader-02',
            ],
          },
        },
        {
          date: new Date('2020-08-29'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Port of Oakland',
            frames: [
              '2020-08-29-port-of-oakland/port-of-oakland-01',
              '2020-08-29-port-of-oakland/port-of-oakland-02',
              '2020-08-29-port-of-oakland/port-of-oakland-03',
              '2020-08-29-port-of-oakland/port-of-oakland-04',
              '2020-08-29-port-of-oakland/port-of-oakland-05',
            ],
          },
        },
        {
          date: new Date('2020-08-25'),
          item: {
            type: itemType.FILM,
            title: 'The Internet\'s Own Boy: The Story of Aaron Swartz',
            year: 2014,
            director: 'Brian Knappenberger',
            frames: [],
          },
        },
        {
          date: new Date('2020-08-16'),
          endDate: new Date('2020-08-18'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Desolation Wilderness',
            frames: [
              '2020-08-16-desolation-wilderness/desolation-wilderness-01',
              '2020-08-16-desolation-wilderness/desolation-wilderness-02',
              '2020-08-16-desolation-wilderness/desolation-wilderness-03',
              '2020-08-16-desolation-wilderness/desolation-wilderness-04',
              '2020-08-16-desolation-wilderness/desolation-wilderness-05',
              '2020-08-16-desolation-wilderness/desolation-wilderness-06',
              '2020-08-16-desolation-wilderness/desolation-wilderness-07',
              '2020-08-16-desolation-wilderness/desolation-wilderness-08',
              '2020-08-16-desolation-wilderness/desolation-wilderness-09',
              '2020-08-16-desolation-wilderness/desolation-wilderness-10',
              '2020-08-16-desolation-wilderness/desolation-wilderness-11',
              '2020-08-16-desolation-wilderness/desolation-wilderness-12',
              '2020-08-16-desolation-wilderness/desolation-wilderness-13',
              '2020-08-16-desolation-wilderness/desolation-wilderness-14',
              '2020-08-16-desolation-wilderness/desolation-wilderness-15',
              '2020-08-16-desolation-wilderness/desolation-wilderness-16',
              '2020-08-16-desolation-wilderness/desolation-wilderness-17',
              '2020-08-16-desolation-wilderness/desolation-wilderness-18',
            ],
          },
        },
        {
          date: new Date('2020-08-14'),
          item: {
            type: itemType.POST,
            title: 'Speed Cuber',
            frames: [
              '2020-08-22-speed-cube/cube-01',
              '2020-08-22-speed-cube/cube-02',
              '2020-08-22-speed-cube/cube-03',
            ],
          },
        },
        {
          date: new Date('2020-08-14'),
          item: {
            type: itemType.FILM,
            title: 'The Speed Cubers',
            year: 2020,
            director: 'Sue Kim',
            frames: [],
          },
        },
        {
          date: new Date('2020-08-13'),
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            title: 'The Shock Doctrine: The Rise of Disaster Capitalism',
            year: 2006,
            author: 'Naomi Klein',
            text: `
<div style="text-align:right;">
  capitalism book club <a href="https://www.anticapitalismbookclub.com/distillations/shock-doctrine/">graphic distillation</a>
</div>
`,
          },
        },
        {
          date: new Date('2020-07-29'),
          endDate: new Date('2020-07-31'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Mokelumne Wilderness',
            frames: [
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-01',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-02',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-03',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-04',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-05',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-06',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-07',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-08',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-09',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-10',
              '2020-07-30-mokelumne-wilderness/mokelumne-wilderness-11',
            ],
          },
        },
        //       {
        //         date: new Date('2020-06-29'),
        //         filterTags: ['(socio|econo|political)-systems'],
        //         item: {
        //           type: itemType.POST,
        //           title: 'anticapitalismbookclub was hatched',
        //           text: `
        // * [/about](https://www.anticapitalismbookclub.com/about/)
        // * [/free](https://www.anticapitalismbookclub.com/essays/free/)
        //           `,
        //         },
        //       },
        {
          date: new Date('2020-06-26'),
          item: {
            type: itemType.FILM,
            title: 'LA 92',
            year: 2017,
            director: 'Daniel Lindsay and T.J. Martin',
            frames: [],
          },
        },
        {
          date: new Date('2020-06-26'),
          item: {
            type: itemType.FILM,
            title: 'NO! The Rape Documentary',
            year: 2004,
            director: 'Aishah Shahidah Simmons',
          },
        },
        {
          date: new Date('2020-06-16'),
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            title: 'To Die for the People',
            year: 2009,
            author: 'Huey P. Newton',
          },
        },
        {
          date: new Date('2020-06-16'),
          endDate: new Date('2020-06-18'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Shasta National Forest',
            frames: [
              '2020-06-16-shasta/shasta-01',
              '2020-06-16-shasta/shasta-02',
              '2020-06-16-shasta/shasta-03',
              '2020-06-16-shasta/shasta-04',
            ],
            text: 'uh oh bad resolution photos b/c we were in the wild (cell phones survive in the wild)',
          },
        },
        {
          date: new Date('2020-06-07'),
          item: {
            type: itemType.BOOK,
            title: 'The Unpassing',
            year: 2019,
            author: 'Chia-Chia Lin',
          },
        },
        {
          date: new Date('2020-05-31'),
          item: {
            type: itemType.BOOK,
            title: 'Giovanni\'s Room',
            year: 1956,
            marker: '+',
            author: 'James Baldwin',
            text: `
<div style="text-align:right;">
  I can't believe I didn't know about <em>Giovanni's Room</em>
</div>`
          },
        },
        {
          date: new Date('2020-05-03'),
          filterTags: ['(socio|econo|political)-systems'],
          item: {
            type: itemType.BOOK,
            title: 'Revolutionary Suicide',
            year: 1973,
            author: 'Huey P. Newton',
            text: `
> I do not think that life will change for the better without an assault on the Establishment,
> which goes on exploiting the wretched of the earth.
> This belief lies at the heart of the concept of revolutionary suicide. Thus it is better to oppose
> the forces that would drive me to self-murder than to endure them.
> Although I risk the likelihood of death, there is at least the possibility, if not the probability,
> of changing intolerable conditions. This possibility is important, because much in human existence
> is based upon hope without any real understanding of the odds. Indeed, we are all—Black and
> white alike—ill in the same way, mortally ill. But before we die, how shall we live?
> I say with hope and dignity; and if premature death is the result, that death has a meaning
> reactionary suicide can never have. It is the price of self-respect.
>
> Revolutionary suicide does not mean that I and my comrades have a death wish; it means just the opposite.
> We have such a strong desire to live with hope and human dignity that existence without them is impossible.
> When reactionary forces crush us, we must move against these forces, even at the risk of death.
> We will have to be driven out with a stick.

<div class="markdown-quotee">— Huey P. Newton, <em>Revolutionary Suicide</em><div>
`,
          },
        },
        {
          date: new Date('2020-04-16'),
          item: {
            type: itemType.FILM,
            title: 'A Sun',
            year: 2019,
            director: 'Chung Mong-hong',
          },
        },
        {
          date: new Date('2020-04-14'),
          item: {
            type: itemType.FILM,
            title: 'Ben Is Back',
            year: 2018,
            director: 'Peter Hedges',
          },
        },
        {
          date: new Date('2020-04-14'),
          item: {
            type: itemType.FILM,
            title: 'Never Rarely Sometimes Always',
            year: 2020,
            director: 'Eliza Hittman',
            frames: [],
          },
        },
        {
          date: new Date('2020-04-09'),
          endDate: new Date('2020-04-10'),
          filterTags: ['things'],
          item: {
            type: itemType.POST,
            title: 'Borras & Pepe: A Quarantine Production',
            frames: [
              '2020-04-09-borras-pepe/borras-pepe-01',
              '2020-04-09-borras-pepe/borras-pepe-02',
              '2020-04-09-borras-pepe/borras-pepe-03',
              '2020-04-09-borras-pepe/borras-pepe-04',
              '2020-04-09-borras-pepe/borras-pepe-05',
              '2020-04-09-borras-pepe/borras-pepe-07',
              '2020-04-09-borras-pepe/borras-pepe-08',
              '2020-04-09-borras-pepe/borras-pepe-09',
              '2020-04-09-borras-pepe/borras-pepe-10',
              '2020-04-09-borras-pepe/borras-pepe-11',
            ],
            text: `
<div style="padding:61.88% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/581861974?h=566ba2490f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0&sidedock=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Borras &amp;amp; Pepe: A Quarantine Production"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>`,
          },
        },
        {
          date: new Date('2020-04-09'),
          item: {
            type: itemType.BOOK,
            title: 'The Sound and the Fury',
            year: 1929,
            author: 'William Faulkner',
          },
        },
        {
          date: new Date('2020-04-04'),
          item: {
            type: itemType.BOOK,
            title: 'Uncanny Valley',
            year: 2020,
            author: 'Anna Wiener',
          },
        },
        {
          date: new Date('2020-04-02'),
          item: {
            type: itemType.FILM,
            title: 'Okja',
            year: 2017,
            director: 'Bong Joon-ho',
            frames: [],
          },
        },
        {
          date: new Date('2020-04-01'),
          item: {
            type: itemType.FILM,
            title: 'Roma',
            year: 2018,
            director: 'Alfonso Cuarón',
            frames: [],
          },
        },
        {
          date: new Date('2020-04-01'),
          item: {
            type: itemType.POST,
            title: 'egg was hatched',
            text: `computing w/ **[eggybytes](https://www.eggybytes.com/)**`
          },
        },
        {
          date: new Date('2020-03-29'),
          item: {
            type: itemType.FILM,
            title: 'Honey Boy',
            year: 2019,
            director: 'Alma Har\'el',
            frames: [],
          },
        },
        {
          date: new Date('2020-03-17'),
          item: {
            type: itemType.POST,
            title: 'Bunker Day 1',
          },
        },
        {
          date: new Date('2020-03-16'),
          item: {
            type: itemType.BOOK,
            title: 'Voice & Vision: A Creative Approach to Narrative Filmmaking, 3e',
            year: 2018,
            author: 'Mick Hurbis-Cherrier',
          },
        },
        {
          date: new Date('2020-03-11'),
          endDate: new Date('2020-03-16'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Paris',
            frames: [
              '2020-03-11-paris/paris-01',
              '2020-03-11-paris/paris-02',
              '2020-03-11-paris/paris-03',
              '2020-03-11-paris/paris-04',
              '2020-03-11-paris/paris-05',
              '2020-03-11-paris/paris-06',
              '2020-03-11-paris/paris-07',
              '2020-03-11-paris/paris-08',
              '2020-03-11-paris/paris-09',
              '2020-03-11-paris/paris-10',
              '2020-03-11-paris/paris-11',
              '2020-03-11-paris/paris-12',
              '2020-03-11-paris/paris-13',
              '2020-03-11-paris/paris-14',
              '2020-03-11-paris/paris-15',
              '2020-03-11-paris/paris-16',
              '2020-03-11-paris/paris-17',
              '2020-03-11-paris/paris-18',
            ],
          },
        },
        {
          date: new Date('2020-02-27'),
          item: {
            type: itemType.POST,
            title: 'Goodbye VSCO',
            frames: [
              '2020-02-27-bye-vsco/vsco-01',
              '2020-02-27-bye-vsco/vsco-02',
              '2020-02-27-bye-vsco/vsco-03',
              '2020-02-27-bye-vsco/vsco-04',
              '2020-02-27-bye-vsco/vsco-05',
              '2020-02-27-bye-vsco/vsco-06',
              '2020-02-27-bye-vsco/vsco-07',
              '2020-02-27-bye-vsco/vsco-08',
              '2020-02-27-bye-vsco/vsco-09',
              '2020-02-27-bye-vsco/vsco-10',
              '2020-02-27-bye-vsco/vsco-11',
              '2020-02-27-bye-vsco/vsco-12',
              '2020-02-27-bye-vsco/vsco-13',
              '2020-02-27-bye-vsco/vsco-14',
              '2020-02-27-bye-vsco/vsco-15',
              '2020-02-27-bye-vsco/vsco-16',
              '2020-02-27-bye-vsco/vsco-17',
              '2020-02-27-bye-vsco/vsco-18',
              '2020-02-27-bye-vsco/vsco-19',
              '2020-02-27-bye-vsco/vsco-20',
              '2020-02-27-bye-vsco/vsco-21',
              '2020-02-27-bye-vsco/vsco-22',
              '2020-02-27-bye-vsco/vsco-23',
              '2020-02-27-bye-vsco/vsco-24',
              '2020-02-27-bye-vsco/vsco-25',
              '2020-02-27-bye-vsco/vsco-26',
              '2020-02-27-bye-vsco/vsco-27',
              '2020-02-27-bye-vsco/vsco-28',
            ],
          },
        },
        {
          date: new Date('2020-02-28'),
          endDate: new Date('2020-03-01'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Lake Tahoe',
            frames: [
              '2020-03-01-tahoe/tahoe-01',
              '2020-03-01-tahoe/tahoe-03',
              '2020-03-01-tahoe/tahoe-02',
            ],
          },
        },
        {
          date: new Date('2020-02-22'),
          item: {
            type: itemType.FILM,
            title: 'Portrait of a Lady on Fire',
            year: 2019,
            director: 'Céline Sciamma',
          },
        },
        {
          date: new Date('2020-01-10'),
          endDate: new Date('2020-01-13'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Death Valley National Park',
            frames: [
              '2020-01-10-death-valley/death-valley-01',
              '2020-01-10-death-valley/death-valley-02',
              '2020-01-10-death-valley/death-valley-03',
              '2020-01-10-death-valley/death-valley-04',
              '2020-01-10-death-valley/death-valley-05',
              '2020-01-10-death-valley/death-valley-06',
              '2020-01-10-death-valley/death-valley-07',
              '2020-01-10-death-valley/death-valley-08',
              '2020-01-10-death-valley/death-valley-09',
              '2020-01-10-death-valley/death-valley-10',
              '2020-01-10-death-valley/death-valley-11',
              '2020-01-10-death-valley/death-valley-12',
              '2020-01-10-death-valley/death-valley-13',
              '2020-01-10-death-valley/death-valley-14',
              '2020-01-10-death-valley/death-valley-15',
              '2020-01-10-death-valley/death-valley-16',
              '2020-01-10-death-valley/death-valley-17',
              '2020-01-10-death-valley/death-valley-18',
              '2020-01-10-death-valley/death-valley-19',
              '2020-01-10-death-valley/death-valley-20',
              '2020-01-10-death-valley/death-valley-21',
              '2020-01-10-death-valley/death-valley-22',
              '2020-01-10-death-valley/death-valley-23',
              '2020-01-10-death-valley/death-valley-24',
              '2020-01-10-death-valley/death-valley-25',
              '2020-01-10-death-valley/death-valley-26',
              '2020-01-10-death-valley/death-valley-27',
              '2020-01-10-death-valley/death-valley-28',
            ],
          },
        },
        {
          date: new Date('2020-01-03'),
          endDate: new Date('2020-01-05'),
          filterTags: ['outside'],
          item: {
            type: itemType.POST,
            title: 'Lake Tahoe',
            frames: [
              '2020-01-04-tahoe/tahoe-davis-01',
              '2020-01-04-tahoe/tahoe-davis-02',
              '2020-01-04-tahoe/tahoe-davis-03',
              '2020-01-04-tahoe/tahoe-davis-04',
              '2020-01-04-tahoe/tahoe-davis-05',
              '2020-01-04-tahoe/tahoe-davis-06',
              '2020-01-04-tahoe/tahoe-davis-07',
              '2020-01-04-tahoe/tahoe-davis-08',
              '2020-01-04-tahoe/tahoe-davis-09',
              '2020-01-04-tahoe/tahoe-davis-10',
            ],
          },
        },
      ],
    }],
}

export default addCounts(v);
