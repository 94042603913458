import { book, eventList, itemType, person } from './types';

// Date formatting functions

const shortMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
function shortMonthForInt(mint: number) {
  return shortMonths[mint];
}

const shortDays = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
function shortDayForInt(dint: number) {
  return shortDays[dint];
}

function dateToString(date: Date): string {
  const mmm = shortMonthForInt(date.getUTCMonth());
  const d = date.getUTCDate();
  const ddd = shortDayForInt(date.getUTCDay());
  
  return `${ddd}, ${mmm} ${d}`;
}

function dateToStringWithYear(date: Date): string {
  const mmm = shortMonthForInt(date.getUTCMonth());
  const d = date.getUTCDate();
  const ddd = shortDayForInt(date.getUTCDay());
  const yyyy = date.getUTCFullYear()
  
  return `${ddd}, ${mmm} ${d}, ${yyyy}`;
}

export function datesToString(startDate: Date, endDate?: Date): string {
  if (endDate) {
    if (startDate.getUTCFullYear() !==  endDate.getUTCFullYear()) {
      return `${dateToStringWithYear(startDate)} — ${dateToStringWithYear(endDate)}`
    }
    return `${dateToString(startDate)} – ${dateToString(endDate)}`;
  } else {
    return dateToString(startDate);
  }
}

// Returns a dashified, sanitized version of a string
export function sanitize(title: string | undefined): string {
  if (!title) {
    return '';
  }

  return title.trim()
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\W/g, m => /[À-ž]/.test(m) ? m : '-')
    .replace(/^-+|-+$/g, '')
    .toLowerCase();
}

// Counting functions for book/film goals

export function addCounts(p: person) {
  const eventListsWithCounts = p.eventLists.map((el) => addCountsForYear(el));
  p.eventLists = eventListsWithCounts;

  return p;
}

function addCountsForYear(el: eventList) {
  if (el.goals && el.goals.books) {
    let numBooks = el.events.reduce((count, evt) => evt.item.type === itemType.BOOK ? count + 1 : count, 0);

    let bookN = 0;
    const eventsWithBooksCounted = el.events.map((evt) => {
      if (evt.item.type === itemType.BOOK && !(evt.item as book).inProgressCurrentChapter && !(evt.item as book).inProgressCurrentPage) {
        bookN++;
        return {
          ...evt,
          item: {
            ...evt.item,
            numTowardGoal: numBooks - (bookN - 1),
          }
        }
      } else {
        return evt;
      }
    });

    el.events = eventsWithBooksCounted;
  }

  if (el.goals && el.goals.films) {
    let numFilms = el.events.reduce((count, evt) => evt.item.type === itemType.FILM ? count + 1 : count, 0);

    let filmN = 0;
    const eventsWithFilmsCounted = el.events.map((evt) => {
      if (evt.item.type === itemType.FILM) {
        filmN++;
        return {
          ...evt,
          item: {
            ...evt.item,
            numTowardGoal: numFilms - filmN + 1,
          }
        }
      } else {
        return evt;
      }
    });

    el.events = eventsWithFilmsCounted;
  }

  return el;
}
