import React from 'react';

import './Marker.css';
import { itemType } from '../../../types';

type MarkerProps = {
  marker: '+' | undefined,
  type: itemType,
}

function Marker({ marker, type }: MarkerProps) {
  return (
    <>
      {marker && <><div className={`Marker ${{
        [itemType.FILM]: 'Marker-film',
        [itemType.BOOK]: 'Marker-book',
        [itemType.POST]: 'Marker-post',
        [itemType.CLIMB]: 'Marker-climb',
      }[type]}`}></div></>}
    </>
  );
}

export default Marker;
