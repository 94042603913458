import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';

import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you

import './MarkdownText.css';

type MarkdownTextProps = {
  text: string,
  useSerif?: boolean,
}

function MarkdownText({ text, useSerif }: MarkdownTextProps) {
  return (
    <div className={`MarkdownText ${useSerif ? 'Serif' : ''}`}>
      <ReactMarkdown 
        children={text}
        remarkPlugins={[remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex]}
        linkTarget="blank_" />
    </div>
  );
}

export default MarkdownText;
