import React from 'react';

import './LoadingPlaceholder.css';

function LoadingPlaceholder() {
  return (
    <>
      <div className="LoadingPlaceholder">&rarr;</div>
    </>
  );
}

export default LoadingPlaceholder;
