import React, { Fragment, useEffect, useState } from 'react';

import { book, climb, film, filterCategory, itemType, person, post } from '../../../types';
import { sanitize } from '../../../utils';
import BookEvent from './BookEvent';
import ClimbEvent from './ClimbEvent';
import DateSpacer from './DateSpacer';
import FilmEvent from './FilmEvent';
import PhotoOverlay from './PhotoOverlay';
import PostEvent from './PostEvent';
import './Content.css';

const PIXELS_PER_DAY = 5;
const PIXELS_PER_DAY_FILTERED = 1;

type ContentProps = {
  person: person,
  category: filterCategory,
  year: number | null,
  tag: string | null,
  item: string | null,
}

function Content({ person, category, year, tag, item }: ContentProps) {
  const [ photo, setPhoto ] = useState<string | null>(null);

  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        if (photo) {
          setPhoto(null);
        }
      }
    }

    window.addEventListener("keydown", keydownHandler, false);

    return () => {
      window.removeEventListener("keydown", keydownHandler, false);
    };
  }, [photo]);

  return (
    <div className={person.settings?.extendRightSide === true ? "ExtendedContent" : "Content"}>
      {photo && <PhotoOverlay photo={photo} setPhoto={setPhoto} />}
      <div className="Content-verticalLine" />
      {person.eventLists.map((eventList, i) => {
        return (!year || year === eventList.year) && (!tag || (eventList.events.reduce<string[]>((postTags, ev) => {
          if (ev.filterTags) { postTags = postTags.concat(ev.filterTags) }
          return postTags;
        }, []).includes(tag))) && (!item || (eventList.events.reduce<string[]>((sanitizedItems, ev) => {
          if (ev.item.title) { sanitizedItems = sanitizedItems.concat(sanitize(ev.item.title)) }
          return sanitizedItems;
        }, []).includes(item))) && (
            <Fragment key={i}>
              <div className="Content-yearDiv-spacer" />
              <div className="Content-yearDiv">{eventList.year}</div>
              {eventList.events.map((event, j) => {
                const lastDate = j > 0 ? eventList.events[j - 1].date : undefined;
                return (
                  <Fragment key={j}>
                    {lastDate && !item && <DateSpacer date={event.endDate? event.endDate : event.date} lastDate={lastDate} pixelsPerDay={category === filterCategory.ALL && !tag ? PIXELS_PER_DAY : PIXELS_PER_DAY_FILTERED} />}
                    {{
                      [itemType.POST]: ((category === filterCategory.ALL || category === filterCategory.OTHER) &&
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <PostEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            post={event.item as post}
                            showCollapseText={event.showCollapseText}
                            textCollapsedOnInit={event.textCollapsedOnInit}
                            showCollapseFrames={event.showCollapseFrames}
                            framesCollapsedOnInit={event.framesCollapsedOnInit}
                            useSerif={event.useSerif}
                            setPhoto={setPhoto} />),
                      [itemType.FILM]: ((category === filterCategory.ALL || category === filterCategory.FILMS) && 
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <FilmEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            film={event.item as film} setPhoto={setPhoto}
                            showCollapseText={event.showCollapseText}
                            filmGoal={eventList.goals?.films} />),
                      [itemType.BOOK]: ((category === filterCategory.ALL || category === filterCategory.BOOKS) && 
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <BookEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            book={event.item as book}
                            setPhoto={setPhoto}
                            showCollapseText={event.showCollapseText}
                            textCollapsedOnInit={event.textCollapsedOnInit}
                            bookGoal={eventList.goals?.books} />),
                      [itemType.CLIMB]: ((category === filterCategory.ALL || category === filterCategory.CLIMBS) && 
                                        (!tag || (event.filterTags && event.filterTags!.includes(tag))) &&
                                        (!item || sanitize(event.item.title) === item) &&
                          <ClimbEvent
                            date={!event.doNotRenderDate ? event.date : undefined}
                            endDate={!event.doNotRenderDate ? event.endDate : undefined}
                            climb={event.item as climb}
                            showCollapseText={event.showCollapseText}
                            textCollapsedOnInit={event.textCollapsedOnInit}
                            showCollapseFrames={event.showCollapseFrames}
                            framesCollapsedOnInit={event.framesCollapsedOnInit}
                            setPhoto={setPhoto}
                            climbGoal={eventList.goals?.climbs} />),
                    }[event.item.type]}
                  </Fragment>);
              })}
            </Fragment>
          );
      })}
    </div>
  );
}

export default Content;
