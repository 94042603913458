import React, { Dispatch, useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

import LoadingPlaceholder from './LoadingPlaceholder';
import './MasonryPhotoGrid.css';

export const COLUMN_BREAKPOINT = 800;

type MasonryPhotoGridProps = {
  photos: string[],
  setPhoto: Dispatch<React.SetStateAction<string | null>>,
  align: 'left' | 'right',
}

function numColumnsForWidth(width: number) {
  return width >= COLUMN_BREAKPOINT ? 2 : 1;
}

function MasonryPhotoGrid({ photos, setPhoto, align }: MasonryPhotoGridProps) {
  const [ numColumns, setNumColumns ] = useState(numColumnsForWidth(window.innerWidth));
  const [ reorderedPhotos, setReorderedPhotos ] = useState<string[] | null>(null);

  useEffect(() => {
    const resizeListener = () => setNumColumns(numColumnsForWidth(window.innerWidth));

    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  useEffect(() => {
    if (numColumns === 1) {
      setReorderedPhotos(photos);
      return;
    }

    let ps = [];
    const breakpoint = Math.ceil((photos.length - 1) / numColumns);
    const remainder = photos.length % numColumns;

    for (let col = 0; col < numColumns; col++) {
      for (let j = 0; j < breakpoint; j++) {
        ps.push(photos[col + (numColumns * j)]);
      }

      if (remainder > col) {
        ps.push(photos[col + (numColumns * breakpoint)]);
      }
    }

    setReorderedPhotos(ps);
  }, [photos, numColumns]);

  return (
    <div className={`MasonryPhotoGrid-photosDiv MasonryPhotoGrid-${align}`}>
      {reorderedPhotos && reorderedPhotos.map((photo, i) => {
        return (
          <LazyLoad key={i} classNamePrefix="MasonryPhotoGrid-photoDiv" height={50} placeholder={<LoadingPlaceholder />}>
            <img
              src={`${process.env.PUBLIC_URL}/img/${photo}-sm.jpg`}
              onClick={() => setPhoto(photo)}
              onContextMenu={(e) => e.preventDefault()}
              alt={photo} />
          </LazyLoad>
        );
      })}
    </div>
  );
}

export default MasonryPhotoGrid;
